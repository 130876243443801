import { lowResImage } from './embeddedImage';
import { Component, ElementRef, OnDestroy, ViewChild, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { YoutubeDialogComponent } from 'src/app/web/youtube-dialog/youtube-dialog.component';
import { inOutAnimation } from '../../providers/animations-list';
import { environment } from 'src/environments/environment';
import { UtilityFunctionsService } from '../../providers/utility-functions.service';

@Component({
  selector: 'app-first-use-adv',
  templateUrl: './first-use-adv.component.html',
  styleUrls: ['./first-use-adv.component.scss'],
  animations: [
    inOutAnimation
],
})
export class FirstUseAdvComponent implements OnInit, OnDestroy {
  @ViewChild('videoPlayer') videoPlayer: ElementRef;

  isMobile = environment.type === 'mobile';

  lowResImage = lowResImage;
  mobileImage = 'assets/images/landing/adv-background-main-mobile.png';
  highResImage = 'assets/images/landing/adv-background-main.jpg';
  superHighResImage = '/assets/images/landing/adv-background-main_2k.jpg';

  backgroundImage = this.lowResImage;
  imageLoaded = false;

  isUnlimitedStorageFeatureShown: boolean = false;
  is60FPSrenderFeatureShown: boolean = false;
  isShareFeatureShown: boolean = false;
  iframeHeight: string;
  iframeWidth: string;


  constructor(
    public dialog: MatDialog,
    public utilitiService: UtilityFunctionsService
    ) {
    window.document.documentElement.style.setProperty('--approot-background', '#0E0E0E');

    }

    ngOnInit(): void {
      const img = new Image();
      const screenWidth = window.innerWidth;

      img.src = screenWidth >= 1920 ? this.superHighResImage : this.highResImage;
      if(screenWidth > 768) {
        img.onload = () => {
          // this.backgroundImage = screenWidth >= 1920 ? this.superHighResImage : this.highResImage;
          this.imageLoaded = true
        };
      } else {
        this.imageLoaded = true
      }
    }

  calculateIframeDimensions() {
    this.iframeWidth = this.utilitiService.calculateIframeDimensions().iframeWidth;
    this.iframeHeight = this.utilitiService.calculateIframeDimensions().iframeHeight;
  }

  openYoutubeVideo() {
    const videoUrl = 'https://www.youtube.com/embed/NpokSU1x65A';

    this.dialog.open(YoutubeDialogComponent, {
      width: this.iframeWidth,
      height: this.iframeHeight,
      id: 'youtube-dialog',
      panelClass: 'youtube-dialog-container-link',
      data: { videoUrl }
    });
  }

  playBackgroundVideo(): void {
    const videos = document.querySelectorAll('video'); // Select all video elements on the page
    videos.forEach(video => {
      video.muted = true; // Ensure the video is muted
      video.play(); // Play the video
    });
  }

  onFirstFeatureMoreClick(): void {
    this.isUnlimitedStorageFeatureShown = !this.isUnlimitedStorageFeatureShown;
  }

  onSecondFeatureMoreClick(): void {
    this.is60FPSrenderFeatureShown = !this.is60FPSrenderFeatureShown;
  }

  onThirdFeatureMoreClick(): void {
    this.isShareFeatureShown = !this.isShareFeatureShown;
  }

  ngOnDestroy(): void {
    window.document.documentElement.style.setProperty('--approot-background', 'black');

  }

}
