<!-- Should be refactored in future: -->
<!-- 1)remove BEM class naming -->
<!-- 2)use parent css selector for different @media, e.g: 'privacy-page lt-sm' as a parent for nested elements -->
<!-- 3)update HTML view to use ngFor cycles, follow DRY approach (error spans, for example)-->


<div class="privacy-page"  ngClass.lt-sm="privacy-page-lt-sm" ngClass.lt-md="privacy-page-lt-md" ngClass.gt-sm="privacy-page-gt-sm" ngClass.gt-lg="privacy-page-gt-lg"  >
  <!-- <div class="rectangle rectangle-top" ngClass.gt-sm="rectangle-top-gt-sm">   </div> -->

  <form class="privacy-form" >
    <div class="privacy-wrapper">
      <h1 class="privacy-title">3DWay Privacy Policy</h1>
      <p class="privacy-description">Last Updated: August 26, 2024</p>
      <p>This Privacy Policy describes the types of Personal Information that 3DWay may collect from all Users or that you may provide us when accessing and using the Platform. It also describes our policies and procedures on the collection, use, maintenance, protection, and disclosure of your Personal Information when you access and use the Platform and tells you about your privacy rights and the choices you may have with respect to your Personal Information. </p>
    <p><strong>Please read this Privacy Policy carefully before accessing and using the Platform to understand our policies and procedures regarding your Personal Information and how we will treat it.</strong></p>
    <p>Capitalized terms not otherwise defined herein have the meaning set forth in our <a href="https://www.3dway.io/legal/terms-of-service">Terms of Service</a>, which is hereby incorporated by reference. </p>
    <div>
    <p class="article"><strong>Acceptance</strong></p>
    </div>
    <p>By accessing and using our Platform, you agree to the collection and use of your Personal Information in accordance with this Privacy Policy. If you do not agree with our policies and procedures, please do not access or use the Platform.</p>
    <div>
    <p class="article"><strong>Updates</strong></p>
    </div>
    <p>We reserve the right to modify this Privacy Policy at any time by posting an updated Privacy Policy on the Platform. If we make changes, we will notify you by revising the date at the top of the policy. If we make any material changes, we may provide you with a notice on the Platform, and we may also, at our sole discretion, provide Users with an email notification of those changes. You are responsible for regularly reviewing this Privacy Policy, and your continued use of the Platform after we make changes is deemed to be acceptance of the updated Privacy Policy. If any modification is unacceptable to you, you shall cease using the Platform. If you have any questions about this Privacy Policy or if you wish to exercise any of your rights under this Privacy Policy, you may contact us at <a href="mailto:support@3dway.io">support&#64;3dway.io</a>. </p>
    <p class="article"><strong>1. </strong><strong>Personal Information We Collect About You</strong></p>
    <p>To provide the Platform, we may collect Personal Information from you. &ldquo;<strong>Personal information</strong>&rdquo; means any information that identifies, relates to, or could reasonably be linked with you or your household. For example, it could include your name, email address, records of products purchased, internet browsing history, geolocation data, and inferences from other personal information that could create a profile about your preferences and characteristics.</p>
    <p>3DWay collects Personal Information from the following categories of sources: (a) directly from you; (b) indirectly/automatically from you; (c) from other Users; and (d) from third parties. </p>
    <p class="article"><strong>1.1 </strong><strong>Personal Information We Collect Directly From You</strong></p>
    <p>While you are using our Platform, we may ask you to provide us with certain Personal Information about you that is necessary to provide you with our Platform, and you may also choose to provide us with additional Personal Information.</p>
    <p>You may also provide us with information about you at the time of creating an account, providing Feedback, or requesting customer support, which may include the following:</p>
    <ol>
        <li><strong><em>Contact Information</em></strong>: first and last name, email address, billing address, mailing address, business name, and business address;</li>
        <li><strong><em>Personal Information</em></strong>: first and last name and address;</li>
        <li><strong><em>Business Information</em></strong>: business name and address;</li>
        <li><strong><em>Payment Information</em></strong>: our third-party Payment Processor may also collect your credit card number information and provide us with your name, the products or services purchased, the date and time of the transaction;</li>
        <li><strong><em>Mobile Device Information</em></strong>: we may access and receive information that you provide us access to, such as your phone’s camera and related technology and features, photos, files, device type, and operating system;</li>
        <li><strong><em>Communication Information</em></strong>: any content of any communications you send and/or receive through the Platform or through other communication, such as email, to or from us;</li>
        <li><strong><em>Customer Testimonials</em></strong>: any customer testimonials and comments on the Platform, which you provide us through consent; and</li>
        <li><strong><em>Feedback and Support</em></strong>: information you provide through our customer service helpdesk, including subjects, descriptions, request type, and attachments.</li>
    </ol>
    <p><strong><em>We will not ask or require you to provide sensitive information</em></strong><em> such as information related to your finances, health, marital status, or any other information not required to provide the Platform. We will not ask for, and you should not provide us with, information regarding protected classifications, including: race, color, religion, national origin, age, gender identity, sexual orientation, marital status, medical condition, and disability information (as described under the appropriate state or federal law).</em></p>
    <p class="article"><strong>1.2 </strong><strong>Information We Automatically Collect From You</strong></p>
    <p>While you are accessing and using our Platform, we may automatically collect certain information from you. This information may include, but is not limited to:</p>
    <p>● Your device's internet protocol address (e.g., IP address), browser type and version;</p>
    <p>● Access date and time, pages of our Platform that you visit and the time spent on those pages, and other actions while using the Platform;</p>
    <p>● The time you spend accessing the Platform;</p>
    <p>● Geolocation data, such as your precise or approximate location determined from your IP address;</p>
    <p>● When you access the Platform by or through a mobile device, we may collect certain information automatically, including, but not limited to, the type of mobile device you use, your mobile device unique ID, the IP address of your mobile device, your mobile operating system, the type of mobile Internet browser you use, unique device identifiers and other diagnostic data;</p>
    <p>● Information about the device you use to access our Platform, including the hardware model, the type of mobile you use, operating system and version, unique device identifiers, and cookie data;</p>
    <p>● Other diagnostic data.</p>
    <p>The information we automatically collect from you is only statistical data and does not include Personal Information, but we may maintain it or associate it with Personal Information we collect in other ways to help us improve our Platform and deliver a better and more personalized service. We use these technologies for a number of purposes, including enabling you to use the Platform, facilitating the functioning of and your access to the Platform, better understanding how you navigate through the Platform, and detecting and preventing fraud.</p>
    <p>The technologies we may use for this automatic data collection may include: </p>
    <p><strong>Cookies (or browser cookies)</strong>.<strong> </strong>Cookies are small files placed on the hard drive of your computer when you access certain websites that record your preferences. We use cookies to track the use of and improve our Platform. Examples of Cookies we use: (a) Session Cookies (to operate our Platform); (b) Preference Cookies (to remember your preferences and various settings); and Security Cookies (for security purposes).</p>
    <p><strong>Web Browser Storage</strong>. Some websites use the browser's "sessionStorage" and "localStorage" to store data; "sessionStorage" is stored temporarily, but the &ldquo;localStorage&rdquo; is persistent. You may erase them by deleting your browser's history. </p>
    <p><strong>Web Beacons</strong>. Certain sections of our Platform and our emails may contain small electronic files known as web beacons (also referred to as clear gifs, pixel tags, or single-pixel gifs) that permit us to determine whether you performed a specific action, for example, if you visited our Platform or opened an email.</p>
    <p class="article"><strong>1.3 </strong><strong>Third-Party Use of Cookies</strong></p>
    <p>Some content or applications on the Platform are served by third parties, including Payment Processors and content and application providers. These third parties may use cookies, web beacons, or other tracking technologies to collect information about you when you access or use our Platform. The information they collect may be associated with your Personal Information, and/or they may collect information related to your online activities over time and across different websites and other online services. They may use this information to provide you with interest-based (behavioral) advertising or other targeted content. We do not control these third-party tracking technologies or how they may be used. You should contact the responsible provider directly if you have questions about an advertisement or other targeted content. For more information about how to opt out of receiving targeted advertising from many providers, see the <a href="https://optout.networkadvertising.org/?c=1">Network Advertising Initiative&rsquo;s opt-out page</a> and <a href="https://adssettings.google.com/authenticated">Google Ads setting page</a>. </p>
    <p class="article"><strong>1.4 </strong><strong>Personal Information We Collect From Other Users</strong></p>
    <div>
    <p>You may choose to communicate with other Users through the Platform. If you do, they may collect your Personal Information or other information about you that you choose to share with them. The Personal Information that you share with another User through the Platform is entirely between you and the applicable User, and you recognize that we cannot control what the User does with any Personal Information that you provide them. </p>
    </div>
    <p class="article"><strong>1.5 </strong><strong>Personal Information We Collect from Third Parties</strong></p>
    <p>We may collect Personal Information about you from other sources, including: (i) Third-Party Services that you use to link or connect with our Platform, such as your Apple ID or Google Account; and (ii) service providers, advertising networks, and other third parties that, to the extent permitted by applicable law, provide us with additional information about you.</p>
    <p>The information we collect from Third-Party Services may include your username, account name, email address, phone number, business name, physical address, friends lists or contacts, and other information used to connect such Third-Party Services with our Platform, in accordance with the authorization procedures determined by such Third-Party Services.</p>
    <p class="article"><strong>2. </strong><strong>Uploading and Posting Personal Information on the Platform</strong></p>
    <p>You may provide Personal Information to be displayed in public areas or transmitted to other Platform Users or third parties. This Personal Information is posted and transmitted to others at your own risk. While all Platform Users must abide by our Terms of Service, we cannot guarantee that their use of Personal Information that you provide directly to them or through the Platform will be handled in accordance with this Privacy Policy. Commenting may be moderated by 3DWay administrators and subject to approval before appearing on the Platform, and we may remove a comment at any time at our discretion. You need to contact us if you want the Personal Information posted to the comments section removed.</p>
    <p class="article"><strong>3. </strong><strong>How We Use Your Personal Information</strong></p>
    <p>We use the Personal Information you provide, or we collect: </p>
    <ol>
        <li>To present our Platform to you;</li>
        <li>To provide and maintain the functionality of the Platform, including monitoring the usage of our Platform;</li>
        <li>To notify you about changes to our Platform;</li>
        <li>To allow you to participate in interactive features of our Platform when you choose to do so;</li>
        <li>To provide customer support;</li>
        <li>To process payments through the Platform;</li>
        <li>To provide you with news, special offers, and general information about other goods, services, and events that we offer that are similar to those you have already purchased or enquired about unless you have opted not to receive such information;</li>
        <li>For our internal business purposes, such as data analysis, audits, and so forth;</li>
        <li>To gather analysis or valuable information so that we can improve our Platform;</li>
        <li>To fulfill our obligations and enforce our rights arising from any contracts you entered with us;</li>
        <li>To monitor the usage of our Platform;</li>
        <li>To detect security incidents and protect against deceptive, illegal, or unauthorized activities;</li>
        <li>To comply with the law;</li>
        <li>To evaluate and/or conduct a divestiture, restructuring, dissolution, merger, or other transfer or sale of some or all of our assets;</li>
        <li>In any other way we may describe when you provide the information; and</li>
        <li>For any other purpose with your consent.</li>
    </ol>
    <p class="article"><strong>4. </strong><strong>How We Share and Disclose Your Personal Information</strong></p>
    <p>Where permissible under applicable law, we may share your information in the following situations:</p>
    <ol>
      <li><strong>With Service Providers</strong>. We may share your Personal Information with service providers, contractors, and other third parties we use to support our business and who are bound by contractual obligations to keep Personal Information confidential and use it only for the purposes for which we disclose it to them. For example, we may share your Personal Information with our Payment Processor as necessary to process payments through the Platform.</li>
      <li><strong>With Business Partners</strong>. We may share your Personal Information with our business partners to offer you certain products, services, or promotions, and who are bound by contractual obligations to keep Personal Information confidential and use it only for the purposes for which we disclose it to them.</li>
      <li><strong>With Other Users</strong>. When you interact in public areas with other users, such Personal Information may be viewed by all users and may be publicly distributed. Also, if you are a User and interact with other Users through the Platform, any Personal Information entered by you will be viewed by the User.</li>
      <li><strong>For Business Transfers</strong>. We may share or transfer your Personal Information in connection with, or during negotiations of, any merger, sale of company assets, financing, or acquisition of all or a portion of our business to another company.</li>
      <li><strong>As Aggregated Information</strong>. We may disclose aggregated information about our Users and information that does not identify any individual to our advertisers, business partners, or any other third party.</li>
      <li><strong>To Enforce Our Terms of Service</strong>. We may disclose your Personal Information to enforce and administer our Terms of Service and any other agreements we entered with you.</li>
      <li><strong>To Protect Our Rights</strong>. We may disclose your Personal Information to protect the rights, property, or personal safety of our company, its employees, its members, and members of the public.</li>
      <li><strong>For Legal Compliance</strong>. We may disclose your Personal Information to comply with the law, a court order, or legal process, including to respond to (a) notices of intellectual property infringement, (b) claims that User Generated Content or other contents uploaded by you to the Platform violates the rights of third parties, (c) government or regulatory request, and/or (d) investigate the use of or respond to alleged violations or infringement of third-party content.</li>
      <li><strong>With Your Consent</strong>. We may disclose your Personal Information for any other purpose with your consent.</li>
    </ol>
    <p class="article"><strong>5. </strong><strong>How We Secure Your Personal Information</strong></p>
    <p>We use commercially reasonable technical safeguards to secure any Personal Information we collect about you. The secure server software ("SSL") we use encrypts all information you input before sending it to us. The security of your Personal Information is important to us, but remember that no method of transmission over the Internet or method of electronic storage is 100% secure. While we strive to use commercially acceptable means to protect your Personal Information, we cannot guarantee its absolute security. If you have any questions about security on our Platform, you can contact us at <a href="mailto:support@3dway.io">support&#64;3dway.io</a>.</p>
    <p class="article"><strong>6. </strong><strong>Responsible Disclosure of Security Vulnerabilities</strong></p>
    <p class="article">If you have discovered or believe that you have discovered a security vulnerability on the Platform, or if you encounter any Personal Information of any other User, we request that you notify us immediately at <a href="mailto:support@3dway.io">support&#64;3dway.io</a>. If you are conducting any security test of the Platform and encounter any sensitive data (including financial information, proprietary information, or trade secrets of any party), you must stop your test, notify us immediately, and not disclose this data to anyone else. We will use commercially reasonable efforts to identify, investigate, and remedy any potential security vulnerabilities with the Platform within a reasonable timeframe upon notification to us. If we believe that a security vulnerability does or could exist, we reserve the right to limit access to the Platform and to require you to update your password or the account that you use to access the Platform or take any additional security measures before the Platform may be accessed.</p>
    <p class="article"><strong>7. </strong><strong>How You Can Manage Your Personal Information</strong></p>
    <p>After receiving proof of your identity, you may request that we delete or change any or all of your Personal Information (subject to certain exceptions), and we will do our best to respond to your request within thirty (30) days of receipt. You are responsible for keeping your Personal Information up to date. </p>
    <p class="article"><strong>8. </strong><strong>Retention of Your Personal Information</strong></p>
    <p>We may retain your Personal Information for as long as you have our application downloaded to your device. We will also retain and use your Personal Information, even after you have deleted our application, as necessary to comply with our legal obligations, resolve disputes, and enforce our agreements. We will also retain statistical data for internal analysis purposes for any period of time we choose unless we are legally obligated to retain this data for longer periods.</p>
    <p class="article"><strong>9. </strong><strong>Consent to Processing of Your Personal Information in the United States</strong></p>
    <p>Please be aware that your Personal Information may be processed by us (and third parties on our behalf as further described in this Privacy Policy) outside of your home country, including in the United States, where data protection and privacy regulations may not offer the same level of protection as privacy laws in your country. If you create an account with us, you agree to this Privacy Policy, and you consent to the transfer of all information you provide to us in the United States. We will take all the steps reasonably necessary to ensure that your data is treated securely and in accordance with this Privacy Policy, and no transfer of your Personal Information will take place to an organization or a country unless there are adequate controls in place, including the security of your data and other Personal Information.</p>
    <p class="article"><strong>10. </strong><strong>Opting Out of Personalized Ads</strong></p>
    <p>You may choose to opt out of receiving emails and newsletters from us and delete, disable, and manage browser cookies and flash cookies.</p>
    <p>If you no longer want to receive marketing emails from us, you may click the &ldquo;Unsubscribe&rdquo; link in an email, follow the instructions provided in any email we send, or contact us at <a href="mailto:support@3dway.io">support&#64;3dway.io</a>. </p>
    <p>You can opt out of accepting cookies (or browser cookies) by activating the appropriate settings on your browser or setting your browser to alert you when cookies are being sent. However, if you disable or refuse, you may not be able to access and use some parts of our Platform.</p>
    <p class="article"><strong>11. </strong><strong>Third-Party Websites</strong></p>
    <p>We may provide links to third-party websites on the Platform, but this Privacy Policy does not apply when you access third-party websites directly from the Platform. Please note that we have no control or responsibility over their data collection, use, or disclosure practices. When you click on links that take you to external websites, you will be subject to their privacy policies. If you access and transmit information to third-party websites, you do so at your own risk. You should carefully review the privacy policy of any third-party website you visit before using it or disclosing your Personal Information to its provider.</p>
    <p class="article"><strong>12. </strong><strong>Collection of Payment Information</strong></p>
    <p>We may use third-party services such as Stripe or PayPal to process payments on the Plaftform. We will not store or collect your payment card details. The information will be provided directly to our third-party Payment Processor, whose use of your Personal Information is governed by their privacy policy. These Third-Party Services adhere to the standards set by PCI-DSS as managed by the PCI Security Standards Council, which is a joint effort of brands like Visa, MasterCard, American Express, and Discover. PCI-DSS requirements help ensure the secure handling of payment information. For more information about PayPal's privacy policy and/or PayPal&rsquo;s security protocols, please read PayPal's <a href="https://www.paypal.com/ms/legalhub/privacy-full">Privacy Statement</a>. For more information about Stripe's privacy policy and/or security protocols, please visit <a href="https://stripe.com/au/legal/privacy-policy-center">Stripe's Privacy Center</a>. </p>
    <p class="article"><strong>13. </strong><strong>Personal Information of Minors</strong></p>
    <p>The Platform is intended for individuals 18 or older. If you are under 18, you may not access and use the Platform. If we learn or have reason to suspect that you are a User who is under the age of 18, we will have to remove you from the Platform. If you believe we might have any information from or about a child under 18, please contact us at <a href="mailto:support@3dway.io">support&#64;3dway.io</a>.</p>
    <p class="article"><strong>14. </strong><strong>Supplemental Notice to California Residents </strong></p>
    <p>Californian users of the Platform may have additional rights afforded them under California Privacy Laws (defined below). </p>
    <p>Additional Definitions Applicable to California Privacy Laws:</p>
    <p>&ldquo;<strong>CCPA</strong>&rdquo; or &ldquo;<strong>California Consumer Privacy Act</strong>&rdquo; means California Civil Code &sect; 1798.100 et seq.</p>
    <p>"<strong>Consumer</strong>," "<strong>Business</strong>," "<strong>Sell</strong>," and "<strong>Service</strong> <strong>Provider</strong>" will have the meanings given to them in the CCPA.</p>
    <p><strong>California Consumer Privacy Act ("CCPA")</strong></p>
    <p>&nbsp;If the processing of your Personal Information is subject to the CCPA, you have the right to:</p>
    <ol>
      <li>Request us to disclose the categories and specific pieces of Personal Information we collect, use, disclose, and sell;</li>
      <li>Know the categories of Personal Information that we collect and the purposes for which we collected or used such Personal Information and whether that information is sold or shared;</li>
      <li>Know the categories of sources from which the Personal Information was collected;</li>
      <li>Know the categories of third parties with whom we shared or to whom we sell your Personal Information;</li>
      <li>Know whether we collected or sold your Personal Information for business or commercial purposes;</li>
      <li>Request deletion of Personal Information we collected from you, subject to certain exceptions;</li>
      <li>Opt-out of the sale of their Personal Information; and</li>
      <li>Not receive discriminatory treatment by us for exercising their rights set out in the CCPA.</li>
    </ol>
    <p class="article"><strong>Categories of Personal Information Collected by Us</strong></p>
    <p>For information about what categories of Personal Information we may collect from our Platform Users in the twelve (12) months preceding the date this Privacy Policy was last modified, the sources of such information, the purposes for collecting and using that information and what types of third-party Service Provider we may share that information with please see the following sections in this Privacy Policy titled: &ldquo;Personal Information Collected by Us&rdquo;; &ldquo;How We Use Your Personal Information;&rdquo; and &ldquo;How We Share and Disclose Your Personal Information.&rdquo; For more information about how to review and change your Personal Information collected through the Platform, please see the section titled &ldquo;How You Can Manage Your Personal Information.&rdquo;</p>
    <p class="article"><strong>Categories of Personal Information Disclosed for a Business Purpose</strong></p>
    <p>The following is a summary of the categories of Personal Information we have disclosed for a business or commercial purpose in the 12 months preceding the date this Privacy Policy was last modified. </p>
    <table border="1" width="100%" cellspacing="0" cellpadding="0">
      <tbody>
          <tr>
              <td width="50%">
                  <p><strong>Categories of Personal Information we collect include:</strong></p>
              </td>
              <td width="50%">
                  <p><strong>Parties with whom each category of Personal Information may be shared include:</strong></p>
              </td>
          </tr>
          <tr>
              <td width="50%">
                  <p>Identifiers: Real name, alias, postal address, unique personal identifier, online identifier, Internet Protocol address, email address, or other similar identifiers.</p>
              </td>
              <td width="50%">
                  <p>Service Providers, Payment Processors, our affiliates, our business partners, third-party vendors to whom you or your agents authorize us to disclose your Personal Information in connection with your use of the Platform, and other Platform Users to whom you consent to display your Personal Information publicly or through our chats. </p>
              </td>
          </tr>
          <tr>
              <td width="50%">
                  <p>Categories of Personal Information described in California Civil Code &sect;1798.80(e): Name, mailing address, and billing address.</p>
              </td>
              <td width="50%">
                  <p>Service Providers, Payment Processors, our affiliates, our business partners, third-party vendors to whom you or your agents authorize us to disclose your Personal Information in connection with your use of the Platform, and other Users to whom you consent to display your Personal Information publicly or through our chats.</p>
              </td>
          </tr>
          <tr>
              <td width="50%">
                  <p>Financial Information: billing address, bank account number, credit card number, debit card number, or any other financial information.</p>
              </td>
              <td width="50%">
                  <p>Payment Processors, our affiliates, and our business partners to whom you or your agents authorize us to disclose your Personal Information in connection with the paid services provided on the Platform.</p>
              </td>
          </tr>
          <tr>
              <td width="50%">
                  <p>Commercial Information: Records and history of products or services purchased, obtained, or considered, or other purchasing consuming histories or tendencies.</p>
              </td>
              <td width="50%">
                  <p>Service Providers, Payment Processors, our affiliates, our business partners, and third-party vendors to whom you or your agents authorize us to disclose your Personal Information in connection with the paid services provided on the Platform.</p>
              </td>
          </tr>
          <tr>
              <td width="50%">
                  <p>Internet and other similar network activity</p>
              </td>
              <td width="50%">
                  <p>Service Providers, Payment Processors, our affiliates, our business partners, and third-party vendors to whom you or your agents authorize us to disclose your Personal Information in connection with your use of the Platform.</p>
              </td>
          </tr>
          <tr>
              <td width="50%">
                  <p>Geolocation Data</p>
              </td>
              <td width="50%">
                  <p>Service Providers, Payment Processors, our affiliates, our business partners, and third-party vendors to whom you or your agents authorize us to disclose your Personal Information in connection with your use of the Platform.</p>
              </td>
          </tr>
      </tbody>
  </table>

    <p>Such categories of Personal Information may be collected, used, or disclosed for business and commercial purposes, including the following examples:</p>
    <ol>
      <li>To operate and improve our Platform;</li>
      <li>To provide you with our Platform, respond to your inquiries, and give you the necessary support;</li>
      <li>To respond to law enforcement requests and as required by applicable law, court order, or governmental regulations;</li>
      <li>As described to you when collecting your Personal Information or as otherwise set forth in the CCPA;</li>
      <li>For internal administrative and auditing purposes; and</li>
      <li>To detect security incidents and protect against malicious, deceptive, fraudulent, or illegal activity, including, when necessary, to prosecute those responsible for such activities.</li>
    </ol>
    <p class="article"><strong>Do Not Sell My Personal Information</strong></p>
    <p>As a California consumer, you have the right to opt out of the sale of your Personal Information. To make such a request, contact us at <a href="mailto:support@3dway.io">support&#64;3dway.io</a>. </p>
    <p>In the past 12 months, we have not sold your Personal Information except to the extent used in Website Analytics.</p>
    <p class="article"><strong>Minors</strong></p>
    <div>
    <p>Our Platform is intended for individuals at the age of 18 years old or older. If you are under the age of 18, you have the right to request and obtain the removal of content or information you have publicly posted under the California Business and Professions Code Section 22581. Be aware that your request does not guarantee the complete or comprehensive removal of content or information posted online, as the law may not permit or require removal in certain circumstances.</p>
    </div>
    <p><strong>Do Not Track Features</strong></p>
    <p>California law requires us to let you know how we respond to web browser Do Not Track (DNT) signals. Do Not Track is a preference you can set in your web browser to inform websites that you do not want to be tracked. You can enable or disable Do Not Track by visiting the Preferences or Settings page of your web browser. However, at this stage, there is no uniform technology standard for recognizing and implementing DNT signals. As such, we do not currently respond to or support DNT browser signals or any other mechanism that automatically communicates your choice not to be tracked online. </p>
    <p><strong>California&rsquo;s Shine the Light Law</strong></p>
    <p>Under California's Shine the Light Law, California Consumers may request the type of Personal Information we disclosed to third parties for their direct marketing purposes and a list of those third parties (if any). We have not disclosed Personal Information to third parties for direct marketing purposes.</p>
    <p>To make any requests regarding your California Privacy Rights, contact us at <a href="mailto:support@3dway.io">support&#64;3dway.io</a>.</p>
    <p class="article"><strong>15. </strong><strong>Supplemental Notice To Individuals that Reside In the European Economic Area</strong></p>
    <div>
    <p>Users of the Platform who are residents of the European Economic Area (EEA) may have additional rights afforded to them under the EU General Data Protection Regulation (GDPR) and European Union Member States, including the United Kingdom and Switzerland.</p>
    </div>
    <p class="article"><strong>Making a request in relation to your Personal Information</strong></p>
    <div>
    <p>The GDPR gives EEA consumers various rights with respect to the Personal Information we collect, including the right to (subject to certain limitations):</p>
    </div>
    <ol>
      <li>Request copies of your Personal Information;</li>
      <li>Access, update, or delete the Personal Information we have on you;</li>
      <li>Request that we correct any information you believe is inaccurate, or request us to complete information you believe is incomplete;</li>
      <li>Request erasure of your Personal Information that we have collected, under certain conditions;</li>
      <li>Request that we restrict the processing of your Personal Information, under certain conditions;</li>
      <li>Object to processing of your Personal Information, under certain conditions;</li>
      <li>Request that we transfer the data we have collected to another organization, or directly to you, under certain conditions;</li>
      <li>Withdraw consent at any time where we are relying on consent to process your Personal Information.</li>
    </ol>
    <div>
    <p>Please note that we may ask you to verify your identity before responding to such requests. You will not have to pay a fee to access your Personal Information or to exercise any of the other rights. However, we may charge a reasonable fee if your request is clearly unfounded, repetitive, or excessive.</p>
    <p>Should you wish to report a complaint or if you feel that we have not addressed your concern in a satisfactory manner, you may contact your local Data Protection Authority about our collection and use of your Personal Information.</p>
    </div>
    <p class="article"><strong>Legal Basis for Processing</strong></p>
    <div>
    <p>We need a lawful basis to collect, use, and disclose your Personal Information as a controller. Our lawful basis will depend on the information concerned and the context in which it is processed. Generally, we rely on the following lawful basis for processing Personal Information:</p>
    </div>
    <ol>
      <li>We need to perform a contract with you;</li>
      <li>You have given us permission to do so;</li>
      <li>The processing is in our legitimate interest, and it is not overridden by your rights;</li>
      <li>For payment processing purposes;</li>
      <li>To comply with the law.</li>
    </ol>
    <div>
    <p>By creating an account and/or by accessing and using the Platform, you are agreeing to our Terms of Service and this Privacy Policy (collectively, &ldquo;<strong>Contracts</strong>&rdquo;). You acknowledge and agree that we may rely upon these Contracts as a legal basis for processing your Personal Information.</p>
    </div>
    <p class="article"><strong>Transfers of Personal Information</strong></p>
    <p>If you live in the EEA, we will only transfer your Personal Information from the EEA to countries outside the EEA on the basis of appropriate safeguards, such as the European Commission&rsquo;s Standard Contractual Clauses or their equivalent under applicable law.</p>
    <p class="article"><strong>16. </strong><strong>Contact Information</strong></p>
    <p>If you have any questions or complaints about this Privacy Policy or our handling of your Personal Information, please contact us at <a href="mailto:support@3dway.io">support&#64;3dway.io</a>.</p>
    </div>
  </form>
  <!-- <div class="rectangle rectangle-bottom" ngClass.gt-sm="rectangle-bottom-gt-sm">  </div> -->
</div>
