<app-webnav #navbarComponent (navbarButtonClick)="onWebSandboxNavbarClicked($event)"></app-webnav>
<div fxLayout="column" class="page-wrapper">
  <div class="splash-wrapper" ngClass.gt-md="splash-wrapper-gt-md" fxLayout="column" fxLayoutAlign="flex-start center">
    <app-fullsize-dragdrop></app-fullsize-dragdrop>

    <div class="landing-top-part"   >

      <h2 class="splash-label" >
        <a href="https://apps.apple.com/us/app/3dway/id6448911494" title="Open the app">
          <mat-icon class="mat-3dway-logo" svgIcon="3dway-logo"></mat-icon>
          <span>3DWay</span>
        </a>
      </h2>
      <p class="tagline">The best place to <span class="white">Discover</span> and <span class="white">Experience 3D</span></p>

    </div>

    <app-thumbnail-gallery [sourceCards]="userDataService.uploadedCards"  (cardClicked)="onUploadedCardClicked($event)"  class="zero-margin last-slider" >
      <!-- <app-card-thumbs-upload  class="gallery-thumb" *ngFor="let upCard of webUploadService.uploadingModels"  (stopUploadButtonClicked)="onStopUploadButtonClicked($event)" [uploadCard]="upCard" fxFlex="0 1 calc(33.3334% - 6.33px)" fxLayout="column" fxLayoutAlign="start center"></app-card-thumbs-upload> -->
      <app-card-thumbs-upload  class="gallery-thumb" *ngFor="let upCard of uploadService.uploadModels | callback: filterUploadCards" [uploadCard]="upCard" fxFlex="0 1 235px" fxLayout="column" fxLayoutAlign="start center"></app-card-thumbs-upload>

    </app-thumbnail-gallery>

  </div>

</div>
<p class="watch">
  <a class="watch-link" (click)="openYoutubeVideo()"><span>Watch the film</span> <i class="material-icons">play_circle_outline</i></a>
</p>
<app-footer></app-footer>
