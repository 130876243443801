<div class="search-overlay">
  <div class="search-input-wrapper" fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="5px">
    <input fxFlex="1 1 100" class="search-input" placeholder="" type="search" #searchInput />
    <button fxFlex="0 0 70px" class="cancel-btn" (click)="onCancelBtnClicked()" mat-flat-button>Cancel</button>
  </div>
    <div class="search-content-wrapper">
      <ng-content #name></ng-content>
    </div>
  <!-- <div  class="search-content-wrapper"  >
    <div *ngFor="let item of items" class="search-category" fxLayout="row" fxLayoutAlign="space-between center">
      <mat-icon svgIcon="{{item.icon}}">search</mat-icon>
      <div class="search-title">{{item.name}}</div>
    </div>
  </div> -->
</div>
