import { environment } from 'src/environments/environment';
import { ModelCardRendered, ICard, SUBSCRIPTION_STATUS } from './../../models/card';
import { Inject, Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { PURCHASE_PROCESS } from './firebase-auth.service';
import { SettingsService } from './settings.service';
import { RollbarService, CustomRollbar } from './rollbarlog.service';
// import { CdvPurchase } from "cordova-plugin-purchase/www/store.d";
import 'cordova-plugin-purchase/www/store.d'
import { Platform } from '@angular/cdk/platform';
import { HttpHeaders } from '@angular/common/http';
import { I3DWayUser } from 'src/app/models/user';


@Injectable({
  providedIn: 'root'
})
export class IappurchaseService {
  isStatusCheckActivated: boolean = false;
  nativeIndividualPurchaseSubscription: Subject<PURCHASE_PROCESS> = new Subject();
  nativeSubscriptionPurchaseSubscription: Subject<PURCHASE_PROCESS> = new Subject();
  lastSubState: string;
  lastPurchaseAppStoreReciept: string;
  capstore: CdvPurchase.Store;
  localTransaction: CdvPurchase.Transaction;

  constructor(public settingService: SettingsService,
    @Inject(RollbarService) private rollbar: CustomRollbar,private platform: Platform
    ) {

    // this.store.verbosity = this.store.DEBUG;

    // this.store.validator = environment.purchaseValidatorUrl;
    // this.store.validator = 'https://validator.fovea.cc/v1/validate?appName=3dway.io&apiKey=e0b8869e-a33c-43fd-9ece-3955d933a630';
    // this.store.validator = 'https://validator-app-iizq337k2a-uc.a.run.app/api/v1/verify_receipt';
  }

  public initCapStore(userToken: string, userId: string): void {
    // Made to avoid errors on web
    if(window.CdvPurchase) {
      this.capstore = new window.CdvPurchase.Store();
      this.capstore.initialize([CdvPurchase.Platform.APPLE_APPSTORE]).then((resp) => {
        console.log('*******  initialize response', resp);
      });
      this.capstore.verbosity = CdvPurchase.LogLevel.WARNING;
      this.capstore.applicationUsername = userId;
      this.setValidator(userToken);
      console.log('*******  validator url: ', environment.purchaseValidatorUrl);
      console.log('*******  platform ready' );

    }
  }

  public setValidator(userToken: string) {
    if(this.settingService.devicePlatform === 'ios') {
      const target = {
        url: environment.purchaseValidatorUrl,
        headers: {
          'Accept': 'application/json',
          'Authorization': 'Bearer ' + userToken,
        }
      }
      this.capstore.validator = target;
      // this.capstore.validator = 'https://validator.iaptic.com/v3/validate?appName=3dway.io&apiKey=e0b8869e-a33c-43fd-9ece-3955d933a630';
      console.log('setValidator token updated' );
    }
  }


  public activateCheckingUserSubscription(currentUser: I3DWayUser): void {
    console.warn('%%SUB: Checking User Subscription Status');

    if (this.settingService.devicePlatform === 'ios' && this.isStatusCheckActivated === false) {
      this.isStatusCheckActivated = true;

      this.capstore.register([
        {
          id: environment.basicSubscriptionId,
          type: CdvPurchase.ProductType.PAID_SUBSCRIPTION,
          platform: CdvPurchase.Platform.APPLE_APPSTORE
        }
      ]);

      setTimeout(() => {
        this.capstore.update().then((resp) => {

          console.log('CAPSTORE PRODUCTS:', this.capstore.products);
          console.log('CAPSTORE TRANSACTIONS', this.capstore.localTransactions);

        });
      }, 200);

      // Run some code only when the store is ready to be used
      this.capstore.ready(() => {

        this.capstore.when()
          // .requested((p: IAPProduct) => {
          //   console.log('purchase requested')
          //   this.nativeSubscriptionPurchaseSubscription.next(PURCHASE_PROCESS.PROCESSING);
          // })
          // .registered((product: IAPProduct) => {
          //   console.log('Registered: ' + JSON.stringify(product));
          // })
          .updated((prod) => {
            // console.log('%%SUB: Product updated: ' + JSON.stringify(prod));
            const product1 = this.capstore.get(environment.basicSubscriptionId);

            if (product1) {
              // currentUser.isSubscribedUser = product1?.owned;
              if (product1?.owned) {
                currentUser.subscriptionStatus = SUBSCRIPTION_STATUS.SUBSCRIBER;
              }

              // console.log('%%SUB: verified purch', this.capstore.verifiedPurchases);
            }

          })
          .approved((p) => {
            // this.rollbar.info(`SUBSCRIPTION approved ${p.products} }`);
            this.rollbar.info(`SUBSCRIPTION approved, user: ${this.settingService.getOriginalUidFromLocalstorage() }`);
            console.log('%%SUB: Approved, verification started')
            p.verify();
          })
          .verified((p: CdvPurchase.VerifiedReceipt) => {
            console.log(`%%SUB: verified ${p.id}`);
            this.rollbar.info(`SUBSCRIPTION verified, user: ${this.settingService.getOriginalUidFromLocalstorage() }`);
            currentUser.subscriptionStatus = SUBSCRIPTION_STATUS.SUBSCRIBER;

            // currentUser.subscriptionStatus = p .collection[0].isExpired

            // if(this.isOwned(p.collection, environment.basicSubscriptionId)) {
            //   currentUser.subscriptionStatus = SUBSCRIPTION_STATUS.SUBSCRIBER;
            // }
            // else {
            //   currentUser.subscriptionStatus = SUBSCRIPTION_STATUS.TRIAL_EXPIRED;
            // }

            // console.log(`%%SUB: isOwned ${this.isOwned(p, environment.basicSubscriptionId)}`);

            // currentUser.subscriptionStatus = SUBSCRIPTION_STATUS.SUBSCRIBER;
            this.nativeSubscriptionPurchaseSubscription.next(PURCHASE_PROCESS.SUCCESS);
            p.finish();
          })
          .finished((p) => {
            this.rollbar.info(`SUBSCRIPTION finished, user: ${this.settingService.getOriginalUidFromLocalstorage()} }`);
            console.log('%%SUB: finished: ', p)
          })

        // Track all store errors
        this.capstore.error((err) => {
          this.rollbar.error('Store Error ' + JSON.stringify(err));
          console.error('Store Error ' + JSON.stringify(err));
          this.nativeIndividualPurchaseSubscription.next(PURCHASE_PROCESS.ERROR);
          this.nativeSubscriptionPurchaseSubscription.next(PURCHASE_PROCESS.ERROR);
        });
      });
    }
  }


  public activateConsumablePurchase(cards: ModelCardRendered[], productId: string): any {
    // if (this.settingService.devicePlatform === 'ios') {
    //   this.rollbar.info('activateConsumablePurchase started;')
    //   this.store.register([{
    //     id: productId,
    //     type: this.store.CONSUMABLE
    //   }]);

    //   // Refresh the status of in-app products
    //   this.store.refresh();

    //   this.store.once(productId)
    //     .updated((p: IAPProduct) => {
    //       if(p.state === this.store.REGISTERED) {
    //         console.log('CONSUMABLE REGISTRED!')
    //         return this.store.order(productId).then((resp) => console.log(resp));
    //       }
    //     })
    //     .requested((p: IAPProduct) => {
    //       console.log('purchase requested')
    //       this.nativeIndividualPurchaseSubscription.next(PURCHASE_PROCESS.PROCESSING);
    //     })
    //     .cancelled((p: IAPProduct) => {
    //       console.log('purchase cancelled');
    //       this.nativeIndividualPurchaseSubscription.next(PURCHASE_PROCESS.DEFAULT);
    //     })
    //     .approved((p: IAPProduct) => {
    //       console.log('Approved, verification started');
    //       this.nativeIndividualPurchaseSubscription.next(PURCHASE_PROCESS.VERIFICATION);
    //       p.verify();
    //     })
    //     .verified((p: IAPProduct) => {
    //       console.log(`verified ${p.alias}`)
    //       console.log('PRODUCT ITSELF: ', p);
    //       const reciept: AppStoreReceipt = p.transaction as AppStoreReceipt;
    //       this.lastPurchaseAppStoreReciept = reciept.appStoreReceipt;
    //       this.nativeIndividualPurchaseSubscription.next(PURCHASE_PROCESS.SUCCESS);
    //       p.finish();
    //     })
    //     .owned(p => console.log(`you now own ${p.alias}`));

    // }
    // else {
    //   console.error('InAppPurchase works only on ios')
    //   this.nativeIndividualPurchaseSubscription.next(PURCHASE_PROCESS.ERROR);

    //   // this.nativeIndividualPurchaseSubscription.next(PURCHASE_PROCESS.SUCCESS);

    //   }

    //   return new Promise((resolve, reject) => {
    //     resolve(true);
    //   });

  }

  public orderBasicSubscription(currentUser: string): void {
    this.nativeSubscriptionPurchaseSubscription.next(PURCHASE_PROCESS.PROCESSING);
    console.log('ENV BASIC SUBSCRIPTION ID: ', environment.basicSubscriptionId);
    console.log('CAPSTORE getApplicationUsername: ', this.capstore.getApplicationUsername() );
    this.rollbar.info(`SUBSCRIPTION order started, user: ${this.settingService.getOriginalUidFromLocalstorage() } }`);

    this.capstore.get(environment.basicSubscriptionId, CdvPurchase.Platform.APPLE_APPSTORE).getOffer().order({ applicationUsername: currentUser }).then((resp) => {
      console.log('%%SUB: ORDERED; PROMISE ', resp);
      if(resp?.isError) {
        this.rollbar.error(`SUBSCRIPTION order error, user: ${this.settingService.getOriginalUidFromLocalstorage()} , ${resp} }`);
        this.nativeSubscriptionPurchaseSubscription.next(PURCHASE_PROCESS.ERROR);
      }
      // console.log('%%SUB: ERROR AFTER ORDER; PROMISE ', resp);
      // this.nativeSubscriptionPurchaseSubscription.next(PURCHASE_PROCESS.ERROR);
    });
  }


  public testOrderSubscription(currentUser: string): void {
    this.orderBasicSubscription(currentUser);
  }

  public testOrderConsumable(currentUser: string): void {
    // this.store.order('generic.3dmodel.purchase', { applicationUsername: currentUser}).then((resp) => {
    //   console.log(resp);
    // }).error((err) => { console.error(err) });
  }

  public restorePurchases(): void {
    this.capstore.restorePurchases();
  }

  public manageSubscriptions(): void {
    this.capstore.manageSubscriptions();
  }

  public manageBilling(): void {
    this.capstore.manageBilling();
  }

}
