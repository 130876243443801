import { EditCollectionComponent } from './pages/edit-collection/edit-collection.component';
import { ServiceSettingsComponent } from './pages/service-settings/service-settings.component';
import { LibraryComponent } from './pages/library/library.component';
import { DraftsComponent } from './pages/drafts/drafts/drafts.component';
import { HomeComponent } from './pages/home/home.component';
import { SceneDetailsComponent } from './pages/scenes/scene-details/scene-details.component';
import { ScenesHomeComponent } from './pages/scenes/scenes-home/scenes-home.component';
import { ScenesWorldComponent } from './pages/scenes/scenes-world/scenes-world.component';
import {NgModule} from '@angular/core';
import {RouterModule, Routes, ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';

import { Error404Component } from './pages/error404/error404.component';
// import { RedirectGuard } from './shared/providers/redirect-guard';
import { ModelsHomeComponent } from './pages/models/models-home/models-home.component';
import { AlbumsHomeComponent } from './pages/albums/albums-home/albums-home.component';
import { PhotosHomeComponent } from './pages/photos/photos-home/photos-home.component';
import { PhotosWorldComponent } from './pages/photos/photos-world/photos-world.component';
import { AuthGuard } from './shared/providers/auth.guard';
import { GeolocationAlbumsComponent } from './pages/albums/geolocation-albums/geolocation-albums.component';
import { PortfolioComponent } from './pages/portfolio/portfolio.component';

const routes: Routes = [
  // {path: 'ourway', canActivate: [RedirectGuard], component: RedirectGuard, data: { externalUrl: 'https://about.3dway.io/'} },
  // {path: 'status3dwayio', canActivate: [RedirectGuard], component: RedirectGuard, data: { externalUrl: 'https://status.3dway.io'} },
  // {path: 'about3dwayio', canActivate: [RedirectGuard], component: RedirectGuard, data: { externalUrl: 'https://about.3dway.io/'} },
  // {path: 'careers3dwayio', canActivate: [RedirectGuard], component: RedirectGuard, data: { externalUrl: 'https://careers.3dway.io'} },
  {path: 'scenes', component: ScenesWorldComponent, data: {animation: 'editCollectionPageAnimation'}},
  {path: 'home', component: HomeComponent, data: {animation: 'homePageAnimation'}},
  {path: 'editcollection', component: EditCollectionComponent, data: {animation: 'editCollectionPageAnimation'}},

  {path: 'portfolio', component: PortfolioComponent, data: {animation: 'homePageAnimation'}},
  {path: 'service', component: ServiceSettingsComponent},
  {path: 'library', component: LibraryComponent, data: {animation: 'libraryPageAnimation'} },


  // {path: 'drafts', component: DraftsComponent, data: {animation: 'libraryPageAnimation'}},
  {path: 'library', component: LibraryComponent, data: {animation: 'libraryPageAnimation'} },

  // DEPRECATED PART; TO MAKE IT WORK, YOU NEED TO START SPANNER INSTANCE ***
  {path: 'geolocation', component: GeolocationAlbumsComponent},
  {path: 'photos', component: PhotosWorldComponent},
  // ***

  {path: 'scenes/:sceneId', component: SceneDetailsComponent},
  {path: 'home/:sceneId', component: SceneDetailsComponent, data: {animation: 'cardDetailsPageAnimation'}},
  {path: ':workspaceName/photos', canActivate: [AuthGuard], component: PhotosHomeComponent},
  {path: ':workspaceName/albums', canActivate: [AuthGuard], component: AlbumsHomeComponent},
  {path: ':workspaceName/models', canActivate: [AuthGuard], component: ModelsHomeComponent},
  {path: ':workspaceName/scenes', canActivate: [AuthGuard], component: ScenesHomeComponent},
  {path: '404', component: Error404Component},
  {path: ':workspaceName', canActivate: [AuthGuard], component: ModelsHomeComponent},
  // TEMPORARY REDIRECT TO TODAY SCREEN AS DEFAULT
  {path: '**', redirectTo: '/library'}
  // {path: '**', redirectTo: '/library'}

];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'top', onSameUrlNavigation: 'reload' }),
  ],
  exports: [RouterModule],
  providers: [
    {
        provide: 'externalUrlRedirectResolver',
        useValue: (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
            window.location.href = (route.data as any).externalUrl;
        }
    }
]
})
export class MobileRoutingModule {
}
