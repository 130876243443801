import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-models-home',
  templateUrl: './models-home.component.html',
  styleUrls: ['./models-home.component.scss']
})
export class ModelsHomeComponent implements OnInit {

  constructor(private route: ActivatedRoute) { }
  workspaceTitle: string;

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      // console.log('workspaceName:' + params.get('workspaceName'));
      this.workspaceTitle = params.get('workspaceName');
    });
  }

}
