import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MobileCommonService {
  private signOutSubject: Subject<void> = new Subject<void>();

  constructor() { }

  public triggerSignOut() {
    this.signOutSubject.next();
  }

  public getSignOutSubject(): Subject<void> {
    return this.signOutSubject;
  }
}

