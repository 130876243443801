import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-review-form',
  templateUrl: './review-form.component.html',
  styleUrls: ['./review-form.component.scss']
})
export class ReviewFormComponent implements OnInit {
  @Output() cancelBtnClciked = new EventEmitter();
  @Output() sendBtnClciked = new EventEmitter();


  constructor() { }

  ngOnInit() {
  }

  onCancelBtnClicked(): void {
    this.cancelBtnClciked.emit();
  }

  onSendBtnClicked(): void {
    this.sendBtnClciked.emit();
  }

}
