import { SettingsService } from './../../providers/settings.service';
import { StatusBar } from '@capacitor/status-bar';

import { Component, OnInit, Input, OnChanges, SimpleChanges, Inject, ViewChild, ElementRef, AfterViewInit, OnDestroy, EventEmitter } from '@angular/core';
import { geolocationAnimation, reviewFormAnimation, modelCardAnimationToFullscreenAdvancedAnimation } from '../../providers/animations-list';
import { Router } from '@angular/router';
import { PageScrollService, PageScrollInstance } from 'ngx-page-scroll-core';
import { DOCUMENT } from '@angular/common';
import { CardsService } from '../../providers/cards.service';
import { ICard, ModelCardDetails, ModelCardRendered } from 'src/app/models/card';

@Component({
  selector: 'app-card-details',
  templateUrl: './card-details.component.html',
  styleUrls: ['./card-details.component.scss'],
  animations: [geolocationAnimation, reviewFormAnimation,  modelCardAnimationToFullscreenAdvancedAnimation],
})
export class CardDetailsComponent implements OnInit, OnDestroy, OnChanges, AfterViewInit {
  @ViewChild('cardDetailsEl')
  public cardDetailsEl: ElementRef;

  @ViewChild('infoBlock')
  public infoBlock: ElementRef;

  @Input() data: ModelCardDetails;

  public card: ModelCardRendered[] = [];
  public currentDeatailedCard: ModelCardDetails;

  // TODO: think later about logic of small preview/big preview image optimization;
  public currentDescription: string;

  public isReviewFormShown: boolean = false;

  public blurAmount: string = '0';

  private pageScrollInstance: PageScrollInstance;
  private detailsScrollInstance: PageScrollInstance;

  public pageScrollEmitter: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor( private router: Router, public cardService: CardsService,
    public settingService: SettingsService, private pageScrollService: PageScrollService, @Inject(DOCUMENT) private document: any) { }

  ngOnInit() {
    this.card = [this.currentDeatailedCard];
    this.initMockCardDetails();
    if(this.settingService.devicePlatform === 'ios') {
      StatusBar.hide().catch(err => {
        console.log('err');
      }) ;
    };
  }

  ngAfterViewInit(): void {

    this.pageScrollInstance = this.pageScrollService.create({
      document: this.document,
      scrollFinishListener: this.pageScrollEmitter,
      scrollTarget: '.cardDetails',
      duration: 500,
      scrollViews: [this.cardDetailsEl.nativeElement],
      advancedInlineOffsetCalculation: true,
      easingLogic: (t: number, b: number, c: number, d: number) => {
        t /= d/2;
        if (t < 1) return c/2*t*t*t + b;
        t -= 2;
        return c/2*(t*t*t + 2) + b;
     }
    });

    this.detailsScrollInstance = this.pageScrollService.create({
      document: this.document,
      scrollTarget: '.card-header',
      duration: 500,
      scrollOffset: 0,
      scrollViews: [this.cardDetailsEl.nativeElement],
      advancedInlineOffsetCalculation: true,
      easingLogic: (t: number, b: number, c: number, d: number) => {
          t /= d/2;
          if (t < 1) return c/2*t*t*t + b;
          t -= 2;
          return c/2*(t*t*t + 2) + b;
       }
    });

    this.scrollToDetailsBlock();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.initMockCardDetails();
  }

  public scrollToDetailsBlock(): void {
    console.log(this.infoBlock.nativeElement);
    this.pageScrollService.start(this.detailsScrollInstance);

    // this.tapRateBlock.nativeElement.scrollIntoView({ behavior: 'smooth'});
  }

  public initMockCardDetails(): void {
    this.currentDeatailedCard = this.data;

    this.currentDescription = this.currentDeatailedCard.cardDescription;
  }

  // public showMoreDescription(): void {
  //   this.isShortDescriptionVisible = false;
  //   this.currentDescription = this.currentDeatailedCard.detailsFullDescription;
  // }

  // public toggleGeolocation(): void {
  //   this.isGeolocationVisible = !this.isGeolocationVisible;
  // }

  public showReviewForm(): void {
    this.isReviewFormShown = true;
  }

  public hideReviewForm(): void {
    this.isReviewFormShown = false;
  }

  // Workaround for safari not blurring correctly items;
  public triggerBlur(): void {
    this.blurAmount = 'blur(' + (5 + Math.random()).toString() + 'px)';
  }

  public scrollToTop(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.pageScrollEmitter.subscribe((ev) => {
          resolve(true);
      } )
      this.pageScrollService.start(this.pageScrollInstance);
    });
  }

  public onSwipeDown(event: Event): void {
    this.cardService.triggerToggleFullscreenSubject();
  }

  ngOnDestroy() {
  }

}
