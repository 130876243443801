import { UploadFileService } from './../../providers/upload-file.service';
import { Component, Input, OnInit, OnChanges, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { CARD_PURCHASE_STATUS, GALLERY_MODE, IThumbCard, ModelCardRendered, ModelCardRenderedSelection } from 'src/app/models/card';
import { ICardCollection } from 'src/app/models/collection';
import { hideWithDelay, inOutAnimation, librarySectionSwitchAnimation } from '../../providers/animations-list';
import { IGroupedCard, UPLOAD_STATUS } from 'src/app/models/groupedCard';
import { SettingsService } from '../../providers/settings.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-thumbnail-gallery',
  templateUrl: './thumbnail-gallery.component.html',
  styleUrls: ['./thumbnail-gallery.component.scss'],
  animations: [inOutAnimation,librarySectionSwitchAnimation, hideWithDelay]
})
export class ThumbnailGalleryComponent implements OnInit {
  @Input() notInCollection: ICardCollection;
  @Input() savedCardCollection: ICardCollection[];

  @Input() disabledCards: ModelCardRenderedSelection[];
  @Input() showCardPrice: boolean = false;

  @Input() sourceCollections: ICardCollection[];
  @Input() sourceCards: ModelCardRenderedSelection[];
  @Input() galleryTitle: string;
  @Input() galleryMode: GALLERY_MODE = GALLERY_MODE.DEFAULT;

  @Output() cardClicked = new EventEmitter<ModelCardRendered>();
  @Output() cardPriceTagClicked = new EventEmitter<ModelCardRendered>();

  @Output() collectionClicked = new EventEmitter<ICardCollection>();
  @Output() defaultCollectionClciked = new EventEmitter<ICardCollection>();

  @Output() titleClicked = new EventEmitter<string>();
  @Output() customBtnClicked = new EventEmitter<string>();

  CARD_PURCHASE_STATUS = CARD_PURCHASE_STATUS;
  GALLERY_MODE = GALLERY_MODE;

  constructor(
    public uploadService: UploadFileService,
    public settingService: SettingsService,
    ) { }

  ngOnInit(): void {
  }

  titleClick(event: Event): void {
    event.stopPropagation();
    this.titleClicked.emit(this.galleryTitle);
  }

  onThumbCardClick(card: ModelCardRenderedSelection): void {
    if(card && !card.isDisabled) this.cardClicked.emit(card);
  }

  onThumbCardClickWithDisableCheck(card: ModelCardRenderedSelection): void {
    if(this.isCardInDisabledArray(card)) return;
    if(card && !card.isDisabled) this.cardClicked.emit(card);
  }

  onPriceTagClicked(ev: Event, card: ModelCardRenderedSelection) {
    ev.stopPropagation();
    if(card && !card.isDisabled) this.cardPriceTagClicked.emit(card);
  }

  onThumbCollectionClick(collection: ICardCollection): void {
    if(collection) this.collectionClicked.emit(collection);
  }

  onDefaultCollectionThumbClick() {
    this.defaultCollectionClciked.emit(this.notInCollection);
  }

  hasButtonsInHeader(): boolean {
    if(this.galleryTitle === 'Not in Collection') return true;
    return false;
  }

  organizeBtnClick(event: Event): void {
    event.stopPropagation();
    event.stopImmediatePropagation();
    this.customBtnClicked.emit('Organize');
  }

  isAnyCardSelected(): boolean {
    return this.sourceCards.filter(card => card.isSelected).length > 0
  }


  isCardInDisabledArray(card: ModelCardRenderedSelection): boolean {
    if(card && this.disabledCards && this.disabledCards.length > 0) {
      const isCardDisabled = this.disabledCards.find(disabledCard => disabledCard.id === card.id);
      if(isCardDisabled) return true;
    }
    return false
  }

  public getCompositeClass(): string {
    if(environment.type === 'mobile') return 'mobilelayout';
    if(environment.type === 'web') return 'weblayout';
  }

  public getThumbWrapperCombinedClass(): any {
    const classObject = {
      'at-least-one-selected': this.isAnyCardSelected()
    };

    // Add the dynamic class from getCompositeClass()
    const compositeClass = this.getCompositeClass();
    if (compositeClass) {
      classObject[compositeClass] = true;
    }

    return classObject; // Return the classObject
  }


  public getCombinedClass(sourceCard): any {
    return {
        [this.getCompositeClass()]: true,
        'thumb-selected': sourceCard?.isSelected,
        'thumb-disabled': this.isCardInDisabledArray(sourceCard),
        'inReview': sourceCard?.purchaseStatus === CARD_PURCHASE_STATUS.IN_REVIEW
    };
  }



  public getNumberOfUploadingCardsForCollection(collection: ICardCollection): number {

    let filteredByCollectionCards
    const groupedCards = this.uploadService.uploadModels.filter((groupedCard) => {return groupedCard.uploadStatus.status === UPLOAD_STATUS.INPROGRESS || groupedCard.uploadStatus.status === UPLOAD_STATUS.PROCESSING});
    if(collection) {
      filteredByCollectionCards = groupedCards.filter(card => card.linkedCollectionId === collection.collectionId);
      return filteredByCollectionCards.length
    } else {
      filteredByCollectionCards = groupedCards.filter(card =>
        card.linkedCollectionId === undefined ||
        card.linkedCollectionId === null ||
        card.linkedCollectionId === '');
      return filteredByCollectionCards.length
    }
  }

  public getNumberOfUploadingCardsAllCollections(): number {
    const groupedCards = this.uploadService.uploadModels.filter((groupedCard) => {return groupedCard.uploadStatus.status === UPLOAD_STATUS.INPROGRESS || groupedCard.uploadStatus.status === UPLOAD_STATUS.PROCESSING});
    return groupedCards.length
  }

  public ifCollectionHasAnyCardsInUploadingState(collection: ICardCollection): boolean {
    const groupedCards = this.uploadService.uploadModels.filter((groupedCard) => {return groupedCard.uploadStatus.status === UPLOAD_STATUS.INPROGRESS});
    if(collection) {
      const filteredByCollectionCards = groupedCards.filter(card => card.linkedCollectionId === collection.collectionId);
      return filteredByCollectionCards.length > 0
    } else {
      const filteredByCollectionCards = groupedCards.filter(card =>
        card.linkedCollectionId === undefined ||
        card.linkedCollectionId === null ||
        card.linkedCollectionId === '');
      return filteredByCollectionCards.length > 0
    }
  }

  getProperCardNumber(num: number, collection: ICardCollection): number {
    const correctionNumber = this.getNumberOfUploadingCardsForCollection(collection);
    return num - correctionNumber;
  }

  cardPosterOrMockImage(cardPosterMobile: string, sourceCard: ModelCardRenderedSelection): string {
    if(cardPosterMobile === undefined || cardPosterMobile === null || cardPosterMobile === '') {
      return '/assets/images/gradientMock.png'
    }
    return cardPosterMobile
  }

  processcardPosterMobile(cardPosterMobile: string, collPoster?: string): string {
    if(collPoster) return collPoster;
    if(cardPosterMobile === undefined || cardPosterMobile === null) {
      return '/assets/images/gradientMock.png'
    }
    return cardPosterMobile
  }


}
