import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UploadFileService } from '../../providers/upload-file.service';
import { IGroupedCard, UPLOAD_STATUS } from 'src/app/models/groupedCard';
import { ICardCollection } from 'src/app/models/collection';

@Component({
  selector: 'app-thumb-composite',
  templateUrl: './thumb-composite.component.html',
  styleUrls: ['./thumb-composite.component.scss']
})
export class ThumbCompositeComponent implements OnInit {
  @Input() compositeMode: 'allModels' | 'collection' | 'aggregated-static';
  @Input() notInCollection: ICardCollection;
  @Input() collection: ICardCollection;
  @Output() compositeThumbClicked = new EventEmitter<ICardCollection>();

  constructor(
   public uploadService: UploadFileService,
  ) { }

  ngOnInit(): void {
  }


  public getProperUploadCard(cardNumber: number): IGroupedCard {
    if(this.compositeMode === 'allModels') return this.isInThisCollectionUploadingCard(undefined, cardNumber);
    if(this.compositeMode === 'collection') return this.isInThisCollectionUploadingCard(this.collection, cardNumber);
    if(this.compositeMode === 'aggregated-static') return this.isInThisCollectionUploadingCard(this.collection, cardNumber);
  }

  public getProperBackgroundImage(cardNumber: number): { [key: string]: string } {
    let backgroundImageUrl: string;
    if (this.compositeMode === 'allModels') backgroundImageUrl = this.processcardPosterMobileForUnorganizedv2(cardNumber);
    if (this.compositeMode === 'collection') backgroundImageUrl = this.processcardPosterMobile(this.collection.collectionCards[cardNumber]?.cardPosterMobile, this.collection.collectionPosterMobile)
    if (this.compositeMode === 'aggregated-static') backgroundImageUrl = this.processcardPosterMobile(this.collection.collectionCards[cardNumber]?.cardPosterMobile, this.collection.collectionPosterMobile)
    return { 'background-image': `url(${backgroundImageUrl})` };
  }

  public getIfThisCardShown(cardNumber: number): boolean {
    if (this.compositeMode === 'allModels') return this.isInThisCollectionUploadingCard(this.collection, cardNumber) === undefined;
    if (this.compositeMode === 'collection') return this.isInThisCollectionUploadingCard(this.collection, cardNumber) === undefined;
    if (this.compositeMode === 'aggregated-static') return true;
  }

  public getIfThisUploadCardShown(cardNumber: number): boolean {
    if (this.compositeMode === 'allModels') return !!this.isInThisCollectionUploadingCard(this.collection, cardNumber);
    if (this.compositeMode === 'collection') return !!this.isInThisCollectionUploadingCard(this.collection, cardNumber);
    if (this.compositeMode === 'aggregated-static') return false;
  }

  public isInThisCollectionUploadingCard(collection: ICardCollection, n: number = 0): IGroupedCard {

    let filteredByCollectionCards
    const groupedCards = this.uploadService.uploadModels.filter((groupedCard) => {return groupedCard.uploadStatus.status === UPLOAD_STATUS.INPROGRESS || groupedCard.uploadStatus.status === UPLOAD_STATUS.PROCESSING});
    if(collection) {
      filteredByCollectionCards = groupedCards.filter(card => card.linkedCollectionId === collection.collectionId);
    } else {
filteredByCollectionCards = groupedCards.filter(card =>
      card.linkedCollectionId === undefined ||
      card.linkedCollectionId === null ||
      card.linkedCollectionId === '');
}
    if(filteredByCollectionCards.length >= n) return filteredByCollectionCards[n];
  }

  public getNumberOfUploadingCardsForCollection(collection: ICardCollection): number {

    let filteredByCollectionCards
    const groupedCards = this.uploadService.uploadModels.filter((groupedCard) => {return groupedCard.uploadStatus.status === UPLOAD_STATUS.INPROGRESS || groupedCard.uploadStatus.status === UPLOAD_STATUS.PROCESSING});
    if(collection) {
      filteredByCollectionCards = groupedCards.filter(card => card.linkedCollectionId === collection.collectionId);
      return filteredByCollectionCards.length
    } else {
      filteredByCollectionCards = groupedCards.filter(card =>
        card.linkedCollectionId === undefined ||
        card.linkedCollectionId === null ||
        card.linkedCollectionId === '');
      return filteredByCollectionCards.length
    }
  }

  processcardPosterMobile(cardPosterMobile: string, collPoster?: string): string {
    if(collPoster) return collPoster;
    if(cardPosterMobile === undefined || cardPosterMobile === null) {
      return '/assets/images/gradientMock.png'
    }
    return cardPosterMobile
  }

  public getUploadingCardByNumber(n: number): IGroupedCard {
    const groupedCards = this.uploadService.uploadModels.filter((groupedCard) => {return groupedCard.uploadStatus.status === UPLOAD_STATUS.INPROGRESS || groupedCard.uploadStatus.status === UPLOAD_STATUS.PROCESSING});
    if(groupedCards.length > n) return groupedCards[n];
  }

  getProperCardNumber(num: number, collection: ICardCollection): number {
    const correctionNumber = this.getNumberOfUploadingCardsForCollection(collection);
    return num - correctionNumber;
  }

  processcardPosterMobileForUnorganizedv2(positionShift: number): string {
    // REWORK LATER; THIS IS A QUICK FIX; NOT WORKING FULLY;
    const uploadingCardsShift = this.getNumberOfUploadingCardsForCollection(undefined);

    // Extract 'collectionPosterMobile' values from savedCardCollection
    // const collectionPosterMobiles = this.savedCardCollection.map(collection => collection.collectionPosterMobile);

    // Extract 'cardPosterMobile' values from notInCollection.collectionCards
    const cardPosterMobiles = this.notInCollection.collectionCards.map(card => card.cardPosterMobile);
    // Combine both arrays into a single array
    // const aggregatedCardArray = [ ...cardPosterMobiles, ...collectionPosterMobiles];

    const aggregatedCardArray = [ ...cardPosterMobiles];
    if(aggregatedCardArray.length === 0) return '/assets/images/gradientMock.png';
    // if(aggregatedCardArray.length > 0 && aggregatedCardArray.length < positionShift) return '/assets/images/gradientMock.png';
    if(aggregatedCardArray[positionShift - uploadingCardsShift]) return aggregatedCardArray[positionShift - uploadingCardsShift];
    if(aggregatedCardArray[positionShift]) return aggregatedCardArray[positionShift];
    return '/assets/images/gradientMock.png'
  }

  onCompositeThumbClicked(): void {
    this.compositeThumbClicked.emit(this.notInCollection);
  }


}
