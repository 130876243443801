import { BreakPoint, BREAKPOINT } from '@angular/flex-layout';

export const CUSTOM_BREAKPOINTS: BreakPoint[] = [
  {
    alias: 'xs',
    mediaQuery: 'screen and (min-width: 0px) and (max-width: 767.99px)',
    priority: 1000,
  },
  {
    alias: 'sm',
    mediaQuery: 'screen and (min-width: 769px) and (max-width: 1439.99px)',
    priority: 900,
  },
  {
    alias: 'md',
    mediaQuery: 'screen and (min-width: 1440px) and (max-width: 1727.99px)',
    priority: 800,
  },
  {
    alias: 'lg',
    mediaQuery: 'screen and (min-width: 1728px) and (max-width: 1919.99px)',
    priority: 700,
  },
  {
    alias: 'xl',
    mediaQuery: 'screen and (min-width: 1920) and (max-width: 2559.99px)',
    priority: 600,
  },
  // {
  //   alias: 'lt-sm',
  //   overlapping: true,
  //   mediaQuery: 'screen and (max-width: 767.98px)',
  //   priority: 950,
  // },
  // {
  //   alias: 'lt-md',
  //   overlapping: true,
  //   mediaQuery: 'screen and (max-width: 1023.99px)',
  //   priority: 850,
  // },
  // {
  //   alias: 'lt-lg',
  //   overlapping: true,
  //   mediaQuery: 'screen and (max-width: 1279.99px)',
  //   priority: 750,
  // },
  // {
  //   alias: 'lt-xl',
  //   overlapping: true,
  //   priority: 650,
  //   mediaQuery: 'screen and (max-width: 1919.99px)',
  // },

  // more than mobile
  {
    alias: 'gt-xs',
    overlapping: true,
    mediaQuery: 'screen and (min-width: 769px)',
    priority: 1100,
  },
  // more than tablet
  {
    alias: 'gt-sm',
    overlapping: true,
    mediaQuery: 'screen and (min-width: 960px)',
    priority: 1200,
  },
  // more than macbook15
  {
    alias: 'gt-md',
    overlapping: true,
    mediaQuery: 'screen and (min-width: 1441px)',
    priority: 1300,
  },
  // more than macbook16
  {
    alias: 'gt-lg',
    overlapping: true,
    // temporary fix; original value: 1729px
    mediaQuery: 'screen and (min-width: 1728px)',
    priority: 1400,
  },
  // more than desktop fullHD
  {
    alias: 'gt-xl',
    overlapping: true,
    mediaQuery: 'screen and (min-width: 1920px)',
    priority: 1500,
  }
];

export const CustomBreakPointsProvider = {
  provide: BREAKPOINT,
  useValue: CUSTOM_BREAKPOINTS,
  multi: true
};
