import { GALLERY_MODE } from './../../../models/card';
import { ModelCardRendered, ModelCardRenderedSelection, SWITCH_PANEL_ITEM } from 'src/app/models/card';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { inOutAnimation } from '../../providers/animations-list';
import { UtilityFunctionsService } from '../../providers/utility-functions.service';



@Component({
  selector: 'app-card-buy-panel',
  templateUrl: './card-buy-panel.component.html',
  styleUrls: ['./card-buy-panel.component.scss'],
  animations: [
    inOutAnimation
  ]
})


export class CardBuyPanelComponent implements OnInit {
  @Input() cardsToSelect: ModelCardRenderedSelection[];
  @Input() disableClick: boolean = false;

  @Input() title: string;

  @Output() cardSelected = new EventEmitter<ModelCardRenderedSelection[]>();

  GALLERY_MODE = GALLERY_MODE;

  currentPortfolioSwitchPanelMode: SWITCH_PANEL_ITEM;
  filteredAllModels: any;

  constructor(
    public utilityService: UtilityFunctionsService
  ) { }

  ngOnInit(): void {
  }

  onCardSelected(cardClicked: ModelCardRenderedSelection): void {
    if(this.disableClick) return

    if (this.cardsToSelect.filter((card) => { return card.isSelected }).length > 0) {
      if (cardClicked.isSelected) {
        cardClicked.isSelected = false;
        this.cardSelected.emit([]);
      } else {
        this.cardsToSelect[this.cardsToSelect.findIndex((card) => card.isSelected)].isSelected = false;
        cardClicked.isSelected = true;
        this.cardSelected.emit([cardClicked]);
      }
    } else {
      cardClicked.isSelected = !cardClicked.isSelected;
      this.cardSelected.emit([cardClicked]);
    }
  }


  public setSwitchPanelItem(item: SWITCH_PANEL_ITEM): void {
    this.currentPortfolioSwitchPanelMode = item;

  }

}
