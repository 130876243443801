import { GALLERY_MODE } from './../../../models/card';
import { ModelCardRendered, ModelCardRenderedSelection, SWITCH_PANEL_ITEM } from 'src/app/models/card';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { inOutAnimation } from '../../providers/animations-list';
import { UtilityFunctionsService } from '../../providers/utility-functions.service';



@Component({
  selector: 'app-card-select-panel',
  templateUrl: './card-select-panel.component.html',
  styleUrls: ['./card-select-panel.component.scss'],
  animations: [
    inOutAnimation
  ]
})


export class CardSelectPanelComponent implements OnInit {
  @Input() cardsToSelect: ModelCardRenderedSelection[];
  @Input() disableForSelectCards: ModelCardRenderedSelection[];
  @Input() userSelectedCards: ModelCardRendered[];
  @Input() showCardPrice: boolean = false;
  @Input() singleSelectMode: boolean = false;
  @Input() disableClick: boolean = false;

  @Input() title: string;

  @Output() cardSelected = new EventEmitter<ModelCardRenderedSelection[]>();
  @Output() cardSelectSwitchPanelClick = new EventEmitter<SWITCH_PANEL_ITEM>();

  GALLERY_MODE = GALLERY_MODE;

  public isSwitchPanelShown: boolean = false;
  currentPortfolioSwitchPanelMode: SWITCH_PANEL_ITEM;
  filteredAllModels: any;

  constructor(
    public utilityService: UtilityFunctionsService
  ) { }

  ngOnInit(): void {
    // if(this.userSelectedCards && this.cardsToSelect) {
    //   this.cardsToSelect.forEach((currentCard) => {
    //     const isCardSelected = this.userSelectedCards.find(userSelectedCard => userSelectedCard.cardPosterMobile === currentCard.cardPosterMobile )
    //     if(isCardSelected) {
    //       currentCard.isSelected = true;
    //     }
    //     else currentCard.isSelected = false;
    //   })
    //   console.log(this.cardsToSelect)
    // }
  }

  onCardSelected(cardClicked: ModelCardRenderedSelection): void {
    if(this.disableClick) return

    if (this.singleSelectMode) {
      if (this.cardsToSelect.filter((card) => { return card.isSelected }).length > 0) {
        if (cardClicked.isSelected) {
          cardClicked.isSelected = false;
          this.cardSelected.emit([]);
        } else {
          this.cardsToSelect[this.cardsToSelect.findIndex((card) => card.isSelected)].isSelected = false;
          cardClicked.isSelected = true;
          this.cardSelected.emit([cardClicked]);
        }
      } else {
        cardClicked.isSelected = !cardClicked.isSelected;
        this.cardSelected.emit([cardClicked]);
      }
    } else {
      cardClicked.isSelected = !cardClicked.isSelected;
      this.cardSelected.emit(this.cardsToSelect.filter((card) => { return card.isSelected }))
    }


  }

  selectAllItems(): void {
    this.cardsToSelect.forEach(card => card.isSelected = true);
    this.cardSelected.emit(this.cardsToSelect.filter((card) =>{ return card.isSelected }))
  }

  selectOrDeselectAllItems(): void {
    const isAllCardsSelected = this.cardsToSelect.filter((card) =>{ return card.isSelected}).length === this.cardsToSelect.length;
    if(isAllCardsSelected) {
      this.cardsToSelect.forEach(card => card.isSelected = false);
    } else {
      this.cardsToSelect.forEach(card => card.isSelected = true);
    }
    this.cardSelected.emit(this.cardsToSelect.filter((card) =>{ return card.isSelected }))
  }

  public showSwitchPanel(): void {
    this.isSwitchPanelShown = true;
  }

  public setSwitchPanelItem(item: SWITCH_PANEL_ITEM): void {
    this.currentPortfolioSwitchPanelMode = item;

  }

  public onSwitchPanelClick(button: SWITCH_PANEL_ITEM): void {
    this.currentPortfolioSwitchPanelMode = button;
    this.cardSelectSwitchPanelClick.emit(button);
  }

}
