import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sortByDate'
})
export class SortByDatePipe implements PipeTransform {

  transform(cards: any[], field: string = 'modifiedDate'): any[] {
    if (!Array.isArray(cards)) {
      return [];
    }

    return cards.sort((a, b) => {
      const dateA = new Date(a[field]);
      const dateB = new Date(b[field]);
      return dateB.getTime() - dateA.getTime();
    });
  }

}
