<!-- <app-action-bar></app-action-bar> -->
<app-navbar></app-navbar>
<div class="albums-page" fxLayout="column" ngClass.lt-sm="albums-page-lt-sm" ngClass.lt-md="albums-page-lt-md" ngClass.gt-sm="albums-page-gt-sm" ngClass.gt-lg="albums-page-gt-lg">
  <app-location-breadcrumbs class="albums-navigation-breadcrumbs" [locationCode]="queryLocationCode"></app-location-breadcrumbs>
  <app-card-details fxFlex="1 1 100" fxLayout="column" fxLayoutAlign="start stretch" *ngIf="queryLocationName == 'scene'" [data]="cardDetails"  ></app-card-details>

  <!-- <mat-form-field class="tags-list">
      <mat-chip-list #chipList aria-label="Fish selection" >
          <mat-chip
          *ngFor="let tag of mockAlbumImages"
          [selectable]="true"
          [removable]="true"
          (removed)="removeElement(tag)"
          >
          {{tag.albumInternalName}}
          <mat-icon matChipRemove *ngIf="true">cancel</mat-icon>
        </mat-chip>
        <input
          #tagInput
          [formControl]="tagCtrl"
          [matAutocomplete]="auto"
          [matChipInputFor]="chipList"
          [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
          [matChipInputAddOnBlur]="true"
          (matChipInputTokenEnd)="add($event)">
      </mat-chip-list>
      <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
        <mat-option *ngFor="let tag of filteredTags | async" [value]="tag.albumInternalName">
          {{tag.albumInternalName}}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field> -->


  <section class="albums-block" *ngIf="queryLocationName !== 'scene'" fxLayout="row wrap" fxLayoutGap.lt-sm="12px" fxLayoutGap.lt-md="22px" fxLayoutGap.gt-sm="25px" fxLayoutGap.gt-lg="32px" fxLayoutAlign="start stretch">
    <!-- <mat-spinner *ngIf="mockAlbumImages.length == 0 || isLocationLoading"></mat-spinner> -->
    <div *ngFor="let album of mockAlbumImages" (click)="getAlbumByLocation(album.albumType, album.albumLocationCode)"  fxLayout="column nowrap" fxLayoutAlign="start start"  fxFlex.lt-sm="1 0 260px" fxFlex.lt-md="0 1 calc(50% - 22px)"  fxFlex.gt-sm="0 1 calc(33.3% - 25px)" fxFlex.gt-lg="0 1 calc(33.3% - 32px)"  class="album">
      <div class="album-image-wrapper" fxLayout="row" fxLayoutAlign="center stretch" fxFlex="0 1 300px" style="width:100%" >
        <div fxFlex="66.6" [ngStyle]="{'background-image': 'url(' + album.albumImages[0] + ')'}"></div>
        <div fxFlex="33.3" >
          <div fxLayout="column" class="album-side-img-wrapper" fxLayoutAlign="center stretch">
              <div fxFlex="50" [ngStyle]="{'background-image': 'url(' + album.albumImages[1] + ')'}" ></div>
              <div fxFlex="50" [ngStyle]="{'background-image': 'url(' + album.albumImages[2] + ')'}"></div>
          </div>
        </div>
      </div>
      <div class="album-details" fxLayout="row" fxLayoutAlign="space-between center">
        <h3 class="album-title" fxLayout="column">{{getCurrentLocationType()}}</h3>
        <h4 class="album-count" fxLayout="column">Pixar</h4>
      </div>
      <div class="album-footer">
        <h1>{{album.albumName}}</h1>
      </div>
    </div>
  </section>
</div>
