import { UserDataService } from 'src/app/shared/providers/user-data.service';
import { SettingsService } from 'src/app/shared/providers/settings.service';
import { UploadFileService } from 'src/app/shared/providers/upload-file.service';
import { Component, ElementRef, OnInit, ViewChild, AfterViewInit, OnDestroy, Input, EventEmitter, Output } from '@angular/core';
import { Keyboard } from '@capacitor/keyboard';

@Component({
  selector: 'app-name-change-dialogue',
  templateUrl: './name-change-dialogue.component.html',
  styleUrls: ['./name-change-dialogue.component.scss']
})
export class NameChangeDialogueComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('inputTitle') inputOne: ElementRef;

  @Input() inputValue: string;
  @Output() valueChanged = new EventEmitter<string>();
  isKeyboardShown: boolean;
  public isEnterAnimationDone: boolean = false;


  constructor(public uploadFileService: UploadFileService, public userDataService: UserDataService, public settingService: SettingsService) { }

  ngOnInit(): void {

    if(this.settingService.devicePlatform === 'ios') {
      Keyboard.addListener('keyboardWillShow', info => {
        console.log('keyboard did show with height:', info.keyboardHeight);
        window.document.documentElement.style.setProperty('--keyboard-height', (info.keyboardHeight - 40) + 'px');
        this.isKeyboardShown = true;
      });

      Keyboard.addListener('keyboardWillHide', () => {
        console.log('keyboard did hide');
        window.document.documentElement.style.setProperty('--keyboard-height', '0px');
        this.isKeyboardShown = false;
      });
    }
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.selectInput();
    }, 5);
    setTimeout(() => {
      this.isEnterAnimationDone = true;
    }, 425);
  }

  onInputChange(ev: string): void {
    this.valueChanged.emit(ev);
    console.log(ev);
  }

  selectInput() {
    const inputElem: HTMLInputElement = this.inputOne.nativeElement;
    inputElem.setSelectionRange(inputElem.value.length, inputElem.value.length)
  }

  ngOnDestroy(): void {
    // workaround to be sure that value will be emitted even if no changes to input is done;
    this.valueChanged.emit(this.inputValue);

    window.document.documentElement.style.setProperty('--keyboard-height', '0px');
    if(this.settingService.devicePlatform === 'ios') { Keyboard.removeAllListeners(); }
  }

}
