import { Component, OnInit, ViewChild, ElementRef, AfterViewInit, Output, EventEmitter } from '@angular/core';

interface ISearchCategory {
  name: string;
  icon: string;
}
@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})

export class SearchComponent implements OnInit, AfterViewInit {
  @ViewChild('searchInput') searchInput: ElementRef<HTMLInputElement>;

  @Output() cancelSearchClicked = new EventEmitter<string>();

  public items: ISearchCategory[] =  [
    {name: 'America', icon: 'location'},
    {name: 'Los Angeles', icon: 'location'},
    {name: 'Scenes', icon: 'layers'},
    {name: 'Models', icon: 'cube'},
    {name: 'Caliseum', icon: 'search-glass'},
    {name: 'Pets', icon: 'search-glass'},

  ];

  constructor() { }

  ngOnInit() {
  }

  ngAfterViewInit() {
    // this.searchInput.nativeElement.focus();
  }

  searchBarFocus() {
    this.searchInput.nativeElement.focus();
  }

  onCancelBtnClicked(): void {
    this.cancelSearchClicked.emit('cancel!');
  }

}
