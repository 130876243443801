import { WebUploadService } from './../webupload.service';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { INavpanelAction, NAVPANEL_ACTION_TYPE } from 'src/app/models/card';

@Component({
  selector: 'app-webpanel',
  templateUrl: './webpanel.component.html',
  styleUrls: ['./webpanel.component.scss']
})
export class WebpanelComponent implements OnInit {
  @Input() webpanelActionItems: INavpanelAction[] = [];
  @Output() webpanelActionClickEvent = new EventEmitter<INavpanelAction>();

  NAVPANEL_ACTION_TYPE = NAVPANEL_ACTION_TYPE;

  constructor(
    public webUploadService: WebUploadService
  ) { }

  ngOnInit(): void {
  }

  public convertToStringArray(sourceString: string): string[] {
    return sourceString.split(' ');
  }

  public navpanelActionItemClick(action: INavpanelAction, event: Event) {
    console.log('navpanelActionItemClick', action);
    // if(action.actionType === NAVPANEL_ACTION_TYPE.UPLOAD_MODEL) return;
    if(action.actionType === NAVPANEL_ACTION_TYPE.ADD_VIDEO) return;
    this.webpanelActionClickEvent.emit(action);
  }



}
