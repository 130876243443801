<!-- <app-action-bar></app-action-bar> -->
<app-navbar></app-navbar>
<div fxLayout="column" fxLayoutAlign="center center" fxFlex="0 1 100" class="scenes-wrapper" ngClass.gt-sm="gt-sm" ngClass.gt-lg="gt-lg"
ngClass.lt-md="lt-md" ngClass.lt-sm="lt-sm">
  <div class="main-block">
      <div class="scenes-illustration-wrapper">
          <img class="scenes-illustration" src="/assets/images/modelsPage/illustration-models-home.svg">
      </div>
      <h1>Ready to add some scenes?</h1>
  </div>
</div>
