
<!-- <app-card-fullscreen  *ngIf="isFullScreenShown" (fullscreenCloseClick)="onFullscreenClose()" (fullscreenMoreClick)="onFullscreenMore()" [fullscreenImg]="currentPreviewImage"></app-card-fullscreen> -->
<app-review-form  [ngStyle]="{'-webkit-backdrop-filter': blurAmount, 'backdrop-filter': blurAmount}" [@reviewFormAnimation] (@reviewFormAnimation.done)="triggerBlur()" *ngIf="isReviewFormShown" (cancelBtnClciked)="hideReviewForm()" (sendBtnClciked)="hideReviewForm()"></app-review-form>


<div class="cardDetails" #cardDetailsEl (swipedown)="onSwipeDown($event)">
  <app-models-card [cardsRaw]="card"></app-models-card>

<!-- <div class="cardDetails" [@modelCardAnimationToFullscreenAdvancedAnimation]="isFullScreenModeActivated ? 'fullscreen' : 'normal'" (@modelCardAnimationToFullscreenAdvancedAnimation.done)="showFullScreenComponent($event)"> -->

  <!-- DEPRECATED; -->
  <!-- <ng-template [ngIf]="type === CARD_TYPE.LOCATION">
    <div class="grid-image-wrapper" fxLayout="row" fxLayoutAlign="center stretch" fxFlex="0 1 300px" style="width:100%" >
      <div fxFlex="66.6" [ngStyle]="{'background-image': 'url(' + currentDeatailedCard.preview.previewImages[0] + ')'}"></div>
      <div fxFlex="33.3" >
        <div fxLayout="column" class="grid-side-img-wrapper" fxLayoutAlign="center stretch">
            <div fxFlex="50" [ngStyle]="{'background-image': 'url(' + currentDeatailedCard.preview.previewImages[1] + ')'}" ></div>
            <div fxFlex="50" [ngStyle]="{'background-image': 'url(' + currentDeatailedCard.preview.previewImages[2] + ')'}"></div>
        </div>
      </div>
    </div>
  </ng-template> -->

  <!-- <ng-template [ngIf]="type === CARD_TYPE.DEFAULT"> -->
    <!-- <div class="cardDetails-main-img" [ngStyle]="{'background-image': 'url(' + currentPreviewImage + ')'}">
      <div class="img-shade">
      </div>
    </div> -->

  <!-- </ng-template> -->
    <div class="cardDetails-wrapper" fxLayout="column">
      <!-- <ng-template [ngIf]="type === CARD_TYPE.DEFAULT">
        <div *ngIf="currentDeatailedCard.preview.previewImages" class="cardDetails-previews" fxLayout="row nowrap" fxLayoutAlign="start start" fxFlex.lt-sm="1 0 90px">
          <main *ngFor="let preview of currentDeatailedCard.preview.previewImages" [ngClass]="{'active': preview === currentPreviewImage}" (click)="changeImgPreview(preview)">
            <img src="{{preview}}">
          </main>
        </div>
      </ng-template> -->

      <!-- DEPRECATED; -->
      <!-- <ng-template [ngIf]="type === CARD_TYPE.LOCATION">
        <div class="cardDetails-location-title">{{currentDeatailedCard?.locationData?.locationCode}}</div>
      </ng-template> -->

      <!-- <div class="cardDetails-type-author" fxLayout="row" fxLayoutAlign="space-between center">
        <h3 class="cardDetails-type" fxLayout="row">{{currentDeatailedCard.cardType}}</h3>
        <h4 class="cardDetails-author" fxLayout="column">{{currentDeatailedCard.cardAuthor}}</h4>
      </div>
      <div class="cardDetails-title-row" fxLayout="row" fxLayoutAlign="space-between start">
        <h1 class="cardDetails-title">{{currentDeatailedCard.cardTitle}}</h1>
      </div> -->
      <div class="cardDetails-rating-block" fxLayout="row" fxLayoutAlign="space-between stretch">
        <div class="cardDetails-rating-wrapper" fxLayout="column" fxLayoutAlign="space-between center">
          <div class="cardDetails-rating-count">{{currentDeatailedCard.detailsNumberOfVotes}} Ratings</div>
          <div class="cardDetails-rating-mark">
            <span>{{currentDeatailedCard.detailsRatingValue}}</span>
          </div>
          <!-- <star-rating class="showRating" staticColor="default" [readOnly]="true" [showHalfStars]="true" [starType]="'svg'" [rating]="currentDeatailedCard.detailsRatingValue"></star-rating> -->
        </div>
        <div class="tap-rate-block"  fxLayout="column" fxLayoutAlign="space-between end">
          <h3>Tap to Rate
            <button class="review-btn" mat-icon-button (click)="showReviewForm()">
              <mat-icon svgIcon="edit"></mat-icon>
            </button>
          </h3>
          <!-- <star-rating class="setRating" staticColor="default" [showHalfStars]="true" [starType]="'svg'" [rating]="0"></star-rating> -->
        </div>
        <!-- <input type="button" class="cardDetails-price" fxLayout="column" [ngClass]="{'make3d': currentDeatailedCard.detailsPrice === 'MAKE 3D'}" value="{{currentDeatailedCard.detailsPrice}}" > -->
      </div>
      <!-- <div class="cardDetails-details">
        <p class="cardDetails-about-txt">{{currentDescription}}<a class="more-btn" *ngIf="isShortDescriptionVisible" (click)="showMoreDescription()">more</a></p>
      </div> -->

      <!-- <div class="categories-block">
        <app-categories-list></app-categories-list>
      </div> -->

      <!-- <div class="geolocation-block">
        <button class="geolocation-btn" (click)="toggleGeolocation()" mat-icon-button>
          <mat-icon svgIcon="location"></mat-icon>
        </button>
        <a class="geolocation-link" (click)="toggleGeolocation()">Farley St., Los Angeles, California</a>
        <div class="geolocation-image" [@geolocationAnimation] *ngIf="isGeolocationVisible" [ngStyle]="{'background-image': 'url(' + '/assets/images/cardDetailsComponent/sample-map.png' + ')'}"></div>
      </div> -->



      <!-- <div>
        <div class="row">
              <div class="switch-toggle switch-3 switch-candy">
                <div class="radio" *ngFor="let option of options">
                  <input type="radio" name="state-d" id="{{option}}" [(ngModel)]="currentDetailsTabValue" [value]="option" />
                  <label for="{{option}}">{{option}}
                  </label>
                </div>
              </div>
        </div>
      </div> -->


        <div class="feedback-block" >
          <app-feedback-cards ngClass="rowMode" fxLayout="row nowrap" fxLayoutAlign="start start" fxFlex="0 1 100%" [userFeedback]="currentDeatailedCard.feedbackBlock"></app-feedback-cards>
        </div>



        <div class="information-block" #infoBlock>
          <mat-tab-group class="info-tabs" animationDuration="0ms" dynamicHeight="false">
            <mat-tab label="ABOUT">
               Passionate and fiery, Merida is a headstrong teenager of royal upbringing who is struggling to take control of her own destiny. She feels most at home in the outdoors honing her impressive athletic skills as an archer and swordfighter, and racing across the magnificent Highland countryside with her faithful horse, Angus. With a spirit as vibrant as her untamed hair, Merida also has a softness of heart, especially when it comes to her wee triplet brothers.
            </mat-tab>
            <mat-tab label="DETAILS">
              <div *ngFor="let info of currentDeatailedCard.informationBlock" class="info-section" fxLayout="row" fxLayoutAlign="space-between center" >
                <span class="info-type">{{info.name}}</span>
                <span class="info-value">{{info.value}}</span>
              </div>
            </mat-tab>
          </mat-tab-group>
        </div>
      </div>

</div>

  <!-- TODO: DELETE AFTER REFACTORING; -->
  <!-- ASSETS SECTIONS -->
  <!-- <ng-template [ngIf]="currentDetailsTabValue === DETAILS_TAB.ASSETS">
    <app-models-card *ngIf="type === CARD_TYPE.DEFAULT" [cards]="currentDeatailedCard.assetCards"  style="margin-left: -10px;display: block;"></app-models-card>
    <div class="photos-section" *ngIf="type === CARD_TYPE.LOCATION" >
      <mat-spinner class="spinn" *ngIf="assetPhotos.length == 0"></mat-spinner>
      <div class="photo-grid-wrapper" *ngIf="assetPhotos.length > 0" >
        <div class="double-row " fxLayoutAlign="center stretch">
          <div class="grid-img" fxFlex="50" align-sel [ngStyle]="{'background-image': 'url(' + assetPhotos[0] + ')'}" ></div>
          <div class="grid-img" fxFlex="50" [ngStyle]="{'background-image': 'url(' + assetPhotos[1] + ')'}"></div>
        </div>
        <div *ngFor="let photo of assetPhotos.slice(2,assetPhotos.length)" class="single-row grid-img"  [ngStyle]="{'background-image': 'url(' + photo + ')'}"></div>
      </div>
  </div>
  </ng-template> -->
  <!-- <ng-template [ngIf]="currentDetailsTabValue === DETAILS_TAB.MORE">
    <app-models-card [cards]="currentDeatailedCard.moreLikeThisCards"  style="margin-left: -10px;display: block;"></app-models-card>
  </ng-template> -->
