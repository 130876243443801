<!-- <app-action-bar></app-action-bar> -->
<app-navbar></app-navbar>
<div fxLayout="column" fxLayoutAlign="center center" fxFlex="0 1 100" class="models-wrapper" ngClass.gt-sm="gt-sm"
  ngClass.gt-lg="gt-lg" ngClass.lt-md="lt-md" ngClass.lt-sm="lt-sm">
  <div class="main-block">
    <div class="models-illustration-wrapper">
      <img class="models-illustration" src="/assets/images/albumsPage/illustration-albums-home.svg">
    </div>
    <h1>Ready to add some albums?</h1>
    <h3>The albums you create are shown here</h3>
  </div>
</div>
