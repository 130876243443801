<div class="left-part" fxFlex="1 1 67%">
  <app-card-thumbs-upload *ngIf="getIfThisUploadCardShown(0)"  class="gallery-thumb" [isStopBtnAllowed]="false" [uploadCard]="getProperUploadCard(0)" (thumbCardClicked)="onCompositeThumbClicked()" ></app-card-thumbs-upload>
  <div *ngIf="getIfThisCardShown(0)" class="gallery-thumb-img-composite img-left" [ngStyle]="getProperBackgroundImage(0)"></div>
</div>
<div class="right-part" fxFlex="1 1 33%" fxLayout="column" fxLayoutAlign="start start">
    <app-card-thumbs-upload *ngIf="getIfThisUploadCardShown(1)"  class="one gallery-thumb-img-composite  img-top" [smallProgressBar]="true" [isStopBtnAllowed]="false" [uploadCard]="getProperUploadCard(1)" (thumbCardClicked)="onCompositeThumbClicked()" ></app-card-thumbs-upload>
    <div *ngIf="getIfThisCardShown(1)"  class="gallery-thumb-img-composite  img-top" [ngStyle]="getProperBackgroundImage(1)" fxFlex="1 1 100"></div>
    <app-card-thumbs-upload *ngIf="getIfThisUploadCardShown(2)"  class="two gallery-thumb-img-composite  img-bottom" [smallProgressBar]="true" [isStopBtnAllowed]="false" [uploadCard]="getProperUploadCard(2)" (thumbCardClicked)="onCompositeThumbClicked()" ></app-card-thumbs-upload>
    <div *ngIf="getIfThisCardShown(2)"  class="gallery-thumb-img-composite  img-bottom" [ngStyle]="getProperBackgroundImage(2)" fxFlex="1 1 100"></div>
</div>
