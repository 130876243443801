import { Directive, ElementRef, EventEmitter, Input, OnInit, OnDestroy, Output } from '@angular/core';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import {fromIntersectionObserver, IntersectionStatus} from './intersection-observer';

@Directive({
  selector: '[appIntersectionObserver]'
})
export class IntersectionObserverDirective implements OnInit, OnDestroy {
  @Input() intersectionDebounce = 0;
  @Input() intersectionRootMargin = '0px';
  @Input() intersectionRoot: HTMLElement;
  @Input() intersectionThreshold: number | number[];

  @Output() visibilityChange = new EventEmitter<IntersectionStatus>();

  private destroy$ = new Subject();

  constructor(private element: ElementRef) {}

  ngOnInit() {
    // WORKAROUND: to get correct values of top/bottom inset on iphone, timeout is being set;
    // WORKAROUND: data collected from intersection observer directive;
    // window.setTimeout(()=>{
    //   this.initObserver();
    // }, 550)

    this.initObserver();

  }

  ngOnDestroy() {
    this.destroy$.next(status);
  }

  private initObserver(): void {
    const element = this.element.nativeElement;
    const config = {
      root: this.intersectionRoot,
      rootMargin: this.intersectionRootMargin,
      // rootMargin: '0px 0px -60px 0px',
      threshold: [0.1]
    };
    // Threshold changed to make diff between current/next card;
    //  threshold: [0.02, 0.98]



    fromIntersectionObserver(
      element,
      config,
      this.intersectionDebounce
    ).pipe(
      takeUntil(this.destroy$)
    ).subscribe((status) => {
      this.visibilityChange.emit(status);
    });
  }
}
