import { UserDataService } from 'src/app/shared/providers/user-data.service';
import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit, OnDestroy, ElementRef, ViewChild, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { Keyboard } from '@capacitor/keyboard';
import { PageScrollService } from 'ngx-page-scroll-core';
import { CardsService } from 'src/app/shared/providers/cards.service';
import { MockDataService } from 'src/app/shared/providers/mock-data.service';
import { SettingsService } from 'src/app/shared/providers/settings.service';
import { UploadFileService } from 'src/app/shared/providers/upload-file.service';
import { FOOTER_MODE, NAVBAR_BTN } from 'src/app/models/menu';

@Component({
  selector: 'app-create-new-collection',
  templateUrl: './create-new-collection.component.html',
  styleUrls: ['./create-new-collection.component.scss']
})

export class CreateNewCollectionComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('inputTitle') inputOne: ElementRef;

  FOOTER_MODE = FOOTER_MODE;


  public currentNewCollectionFooterMode: FOOTER_MODE = FOOTER_MODE.NEW_COLLECTION;
  public isKeyboardShown: boolean;
  public isEnterAnimationDone: boolean = false;



  constructor(
    public mockDataService: MockDataService,private readonly router: Router, public pageScrollService: PageScrollService,
    public settingService: SettingsService, public cardService: CardsService, public uploadService: UploadFileService, public userDataService: UserDataService,
    @Inject(DOCUMENT) private document: any
    ) {
    }

  ngOnInit(): void {

    this.userDataService.newCollectionName = '';

    if(this.settingService.devicePlatform === 'ios') {
      Keyboard.addListener('keyboardWillShow', info => {
        console.log('keyboard did show with height:', info.keyboardHeight);
        window.document.documentElement.style.setProperty('--keyboard-height', (info.keyboardHeight - 40) + 'px');
        this.isKeyboardShown = true;
      });

      Keyboard.addListener('keyboardWillHide', () => {
        console.log('keyboard did hide');
        window.document.documentElement.style.setProperty('--keyboard-height', '0px');
        this.isKeyboardShown = false;
      });
    }
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.selectInput();
    }, 5);
    setTimeout(() => {
      this.isEnterAnimationDone = true;
    }, 425);
  }



  selectInput() {
    const inputElem: HTMLInputElement = this.inputOne.nativeElement;
    inputElem.setSelectionRange(inputElem.value.length, inputElem.value.length)
  }


  public onNavbarBtnClick(navbarBtn: NAVBAR_BTN): void {
    switch (true) {
      case navbarBtn === NAVBAR_BTN.BACK:
        this.router.navigateByUrl('/library');
        break;
    }
  }

  public onFootermodeChanged(value: FOOTER_MODE): void {
    this.currentNewCollectionFooterMode = value;
  }

  ngOnDestroy(): void {
    window.document.documentElement.style.setProperty('--keyboard-height', '0px');
    if(this.settingService.devicePlatform === 'ios') { Keyboard.removeAllListeners(); }
  }


}
