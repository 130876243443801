import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'priceformat'
})
export class PriceformatPipe implements PipeTransform {

  transform(value: unknown, ...args: unknown[]): unknown {
    if(value) return '$' + value + '.00' ;
    return 'Not for sale';
  }

}
