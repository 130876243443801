import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SWITCH_PANEL_ITEM } from 'src/app/models/card';

@Component({
  selector: 'app-library-section-switch',
  templateUrl: './library-section-switch.component.html',
  styleUrls: ['./library-section-switch.component.scss']
})
export class LibrarySectionSwitchComponent implements OnInit {
  @Input() selectedSwitchBtn = SWITCH_PANEL_ITEM.ALL;
  @Output() switchBtnClicked = new EventEmitter<SWITCH_PANEL_ITEM>();

  constructor() { }

  isLeftBtnActive: boolean = false;
  isCenterBtnActive: boolean = false;
  isRightBtnActive: boolean = false;
  isReviewBtnActive: boolean = false;


  SWITCH_PANEL_ITEM = SWITCH_PANEL_ITEM


  ngOnInit(): void {
    switch (this.selectedSwitchBtn) {
      case SWITCH_PANEL_ITEM.UPLOADS:
        this.isLeftBtnActive = true;
        break;
      case SWITCH_PANEL_ITEM.LIKES:
        this.isCenterBtnActive = true;
        break;
      case SWITCH_PANEL_ITEM.PURCHASES:
        this.isRightBtnActive = true;
        break;
      case SWITCH_PANEL_ITEM.ALL:
        this.isReviewBtnActive = true;
        break;
      default:
        break;
    }
  }

  switchBtnClick(btnName: SWITCH_PANEL_ITEM): void {
    this.isLeftBtnActive = false;
    this.isCenterBtnActive = false;
    this.isRightBtnActive = false;
    this.isReviewBtnActive = false;

    switch (btnName) {
      case SWITCH_PANEL_ITEM.UPLOADS:
        this.isLeftBtnActive = true;
        this.switchBtnClicked.emit(SWITCH_PANEL_ITEM.UPLOADS);
        break;
      case SWITCH_PANEL_ITEM.LIKES:
        this.isCenterBtnActive = true;
        this.switchBtnClicked.emit(SWITCH_PANEL_ITEM.LIKES);
        break;
      case SWITCH_PANEL_ITEM.PURCHASES:
        this.isRightBtnActive = true;
        this.switchBtnClicked.emit(SWITCH_PANEL_ITEM.PURCHASES);
        break;
      case SWITCH_PANEL_ITEM.ALL:
        this.isReviewBtnActive = true;
        this.switchBtnClicked.emit(SWITCH_PANEL_ITEM.ALL);
        break;
      default:
        break;
    }

  }

}
