import { Subscription } from 'rxjs';
import { ModelsCardComponent } from 'src/app/shared/components/models-card/models-card.component';
import { UserDataService } from 'src/app/shared/providers/user-data.service';
// eslint-disable-next-line max-len
import { CARDS_MODE, CARD_CONTROL_BTN, CARD_PURCHASE_STATUS, COLLECTION_CONTROL_BTN, ICard, ICardAction, INavpanelAction, LIBARY_MODE, ModelCardRenderedSelection, NAVPANEL_ACTION_TYPE, SUBSCRIPTION_STATUS, SWITCH_PANEL_ITEM, GALLERY_MODE } from './../../models/card';
import { Component, OnInit, ViewChild, AfterViewInit, Inject, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
// eslint-disable-next-line max-len
import { pageFadingAnimation, fullscreenCardAnimation, cardFeedAnimation, cardFeedNavbarAnimation, inOutAnimation, collectionDetailsFadeAnimation, fullscreenPanelAnimation, modelFeedAnimation, navpanelAnimation } from 'src/app/shared/providers/animations-list';
import { MockDataService } from 'src/app/shared/providers/mock-data.service';
import { CardsService } from 'src/app/shared/providers/cards.service';
import { DOCUMENT } from '@angular/common';
import { PageScrollService } from 'ngx-page-scroll-core';
import { SettingsService } from 'src/app/shared/providers/settings.service';
import { UploadFileService } from 'src/app/shared/providers/upload-file.service';
import { CARD_TYPE, IThumbCard, ModelCardRendered } from 'src/app/models/card';
import { ICardCollection, ICollectionEditOptions } from 'src/app/models/collection';
import { UtilityFunctionsService } from 'src/app/shared/providers/utility-functions.service';
import { IGroupedCard, UPLOAD_STATUS } from 'src/app/models/groupedCard';
import { CollectionDetailsComponent } from 'src/app/shared/components/collection-details/collection-details.component';
import { CardSelectPanelComponent } from 'src/app/shared/components/card-select-panel/card-select-panel.component';
import { IAdditionalFooterData } from 'src/app/models/common';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { DialogData, ConfirmationDialogComponent } from 'src/app/shared/components/dialog/confirmation-dialog.component';
import { PURCHASE_PROCESS, FirebaseAuthService } from 'src/app/shared/providers/firebase-auth.service';
import { FOOTER_MODE, NAVBAR_BTN } from 'src/app/models/menu';
import { NavbarComponent } from 'src/app/shared/components/navbar/navbar.component';


@Component({
  selector: 'app-portfolio',
  templateUrl: './portfolio.component.html',
  styleUrls: ['./portfolio.component.scss'],
  animations: [
    pageFadingAnimation,
    fullscreenCardAnimation,
    fullscreenPanelAnimation,
    collectionDetailsFadeAnimation,
    cardFeedAnimation,
    cardFeedNavbarAnimation,
    navpanelAnimation,
    modelFeedAnimation,
    inOutAnimation
],
})
export class PortfolioComponent implements OnInit, OnDestroy {

  @ViewChild('collectionDetailsComponent', { static: false })
  public collectionDetailsComponent: CollectionDetailsComponent;

  @ViewChild('cardSelectRef')
  public cardSelectRef: CardSelectPanelComponent;

  @ViewChild('modelCardsFeed')
  public cardFeedRef: ModelsCardComponent;

  @ViewChild('navbarRef')
  public navbarRef: NavbarComponent;

  LIBARY_MODE = LIBARY_MODE;
  FOOTER_MODE = FOOTER_MODE;
  SWITCH_PANEL_ITEM = SWITCH_PANEL_ITEM;
  CARDS_MODE = CARDS_MODE;
  PURCHASE_PROCESS = PURCHASE_PROCESS;
  GALLERY_MODE = GALLERY_MODE;

  public currentPortfolioFooterMode: FOOTER_MODE = FOOTER_MODE.PORTFOLIO
  public currentPortfolioSwitchPanelMode: SWITCH_PANEL_ITEM = SWITCH_PANEL_ITEM.ALL;
  public cardFeedMode: CARDS_MODE = CARDS_MODE.MODEL_CARD_FEED;

  public portfolioAdditionalFooterData: IAdditionalFooterData = {priceData: undefined};

  public portfolioNavpanelItems: INavpanelAction[];

  public filteredAllModels: ICard[] = [];
  public cardSelectData: ModelCardRenderedSelection[];

  public isCollectionDetailsShown: boolean;
  public cardFeedCardStartPosition: number;
  public modelCardsMock: ModelCardRendered[];
  public isModelFeedShown: boolean = false;
  public collectionDetailsSource: ICardCollection;
  public collectionEditOptions: ICollectionEditOptions;
  public filterCardMode: NAVPANEL_ACTION_TYPE;
  public cardToEdit: ICard;
  public isNavpanelShown: boolean;
  public navpanelAnimationState: string;

  public isCardSelectShown: boolean;
  public isBuySelectShown: boolean;

  public isCollectionSelectShown: boolean;
  public isCreateNewCollectionShown: boolean;
  public isCardPublishShown: boolean;
  public isPriceSelectShown: boolean;
  public isNameChangeDialogueShown: boolean;
  public isMoveCardProcessActivated: boolean;


  public lastSelectedPrice: number;
  public lastNameInputValue: string;
  public nameDialogDefaultValue: string;
  public currentPurchaseState: PURCHASE_PROCESS = PURCHASE_PROCESS.DEFAULT
  public cardPurchaseStateChangeSubscription: Subscription;
  public cardsToPurchaseIds: string[];
  public fileToUpload: File;
  public isSubscriptionSplashShown: boolean;
  public uploadErrorSub: Subscription;
  public uploadSub: Subscription;



  constructor(
    public mockDataService: MockDataService,private readonly router: Router, public pageScrollService: PageScrollService,
    public settingService: SettingsService, public cardService: CardsService, public uploadService: UploadFileService,
    public userDataService: UserDataService, public utilityService: UtilityFunctionsService,
    public firebaseAuth: FirebaseAuthService,
    @Inject(DOCUMENT) private document: any, public dialog: MatDialog,private readonly route: ActivatedRoute,
    private cdr: ChangeDetectorRef,
    ) {}

  ngOnInit() {
    this.filteredAllModels = this.userDataService.allUserCards

    this.uploadErrorSub = this.uploadService.uploadErrorSubscription.subscribe(()=> {
      this.handleNavpanelAction({ actionName: 'Upload Model usdz', actionType: NAVPANEL_ACTION_TYPE.TRIAL_EXPIRED });
    })

    this.cardPurchaseStateChangeSubscription = this.cardService.onCardPurchaseStateChange.subscribe((purchaseState) => {
      this.currentPurchaseState = purchaseState;
      switch (purchaseState) {
        case PURCHASE_PROCESS.ERROR:
          this.portfolioAdditionalFooterData.priceData = undefined
          this.collectionDetailsSource?.collectionCards?.forEach(card => card.isSelected = false);
          break;
        case PURCHASE_PROCESS.SUCCESS:
          console.log('PURCHASE_PROCESS.SUCCESS -> PORTFOLIO');
          this.portfolioAdditionalFooterData.priceData = undefined
          this.collectionDetailsSource?.collectionCards?.forEach(card => card.isSelected = false);

          // COMPLEX LOGIC FOR PURCHASED LIKED CARD GOES HERE:
          // EASY STEP: FIND CARD IN LIKED AND MOVE TO PURCHASED --

          if(!this.isCollectionDetailsShown) {
            const purchaseCard = this.userDataService.removeFromSavedCardsById(this.cardsToPurchaseIds[0]);
            this.userDataService.purchaseSingleCard(purchaseCard);
            const purchasedCardIndexModelFeed = this.cardFeedRef.cards.findIndex((cardToFind) => {
              return cardToFind.id === this.cardsToPurchaseIds[0];
            })
            this.cardFeedRef.cards[purchasedCardIndexModelFeed].purchaseStatus = CARD_PURCHASE_STATUS.PURCHASED;
          }
          if(this.isCollectionDetailsShown) {
            const purchaseCard = this.userDataService.removeFromSavedCardsById(this.cardsToPurchaseIds[0]);
            this.userDataService.purchaseSingleCard(purchaseCard);
            const purchasedCardIdInCollection = this.collectionDetailsSource.collectionCards.findIndex((cardToFind) => { return cardToFind.id === this.cardsToPurchaseIds[0] });
            this.collectionDetailsSource.collectionCards[purchasedCardIdInCollection].purchaseStatus = CARD_PURCHASE_STATUS.PURCHASED;
            if(this.isModelFeedShown) {
              const purchasedCardIndexModelFeed = this.cardFeedRef.cards.findIndex((cardToFind) => {
                return cardToFind.id === this.cardsToPurchaseIds[0];
              })
              this.cardFeedRef.cards[purchasedCardIndexModelFeed].purchaseStatus = CARD_PURCHASE_STATUS.PURCHASED;
            }
            if(this.isBuySelectShown) {
              this.cardSelectData = this.collectionDetailsSource.collectionCards.filter(card => card.purchaseStatus !== CARD_PURCHASE_STATUS.PURCHASED);
            }
          }
           // Case, when user in the 'buy' menu
          //  if(this.isBuySelectShown) {
          //     this.cardSelectData = this.collectionDetailsSource.collectionCards.filter(card => card.purchaseStatus !== CARD_PURCHASE_STATUS.PURCHASED);
          //  }

          this.utilityService.showSnackBar('Purchase added to Library', 750);
          break;
        default:
          break;
      }
      this.cdr.detectChanges();
    })

    this.uploadSub = this.userDataService.uploadedCardRecieved.subscribe((uploadingCard) => {
      this.filteredAllModels = this.userDataService.allUserCards;
    });
  }

  public onFootermodeChanged(value: FOOTER_MODE): void {
    this.currentPortfolioFooterMode = value;
  }

  public onNameDialogValueChanged(value: string): void {
    this.lastNameInputValue = value;
    return
  }

  public filterUploadCards(groupedCard: IGroupedCard) {
    if(groupedCard.linkedCollectionId) return false;
    return groupedCard.uploadStatus.status === UPLOAD_STATUS.INPROGRESS || groupedCard.uploadStatus.status === UPLOAD_STATUS.PROCESSING
  }

  public onModelCardPressed() {
    this.currentPortfolioFooterMode = FOOTER_MODE.CARD_FULLSCREEN;
  }

  public onModelCardClicked(card: ModelCardRendered) {
    this.currentPortfolioFooterMode = this.currentPortfolioFooterMode === FOOTER_MODE.DEFAULT || this.currentPortfolioFooterMode === FOOTER_MODE.SHOW_BUY_BTN ? FOOTER_MODE.CARD_FULLSCREEN : FOOTER_MODE.DEFAULT;
  }

  public onAllModelsCardClicked(card: ICard): void {
    this.handleCardClick(card, CARDS_MODE.COMPOSITE_FEED, this.filteredAllModels);
  }


  public onPurchasedModelsCardClicked(card: ICard): void {
    this.handleCardClick(card, CARDS_MODE.COMPOSITE_FEED, this.userDataService.userPurchasedCards);
  }

  public onSliderLikedCardClicked(card: ICard): void {
    this.handleCardClick(card, CARDS_MODE.COMPOSITE_FEED, this.userDataService.savedCards);
  }

  public onUploadedCardClicked(card: ICard): void {
    this.handleCardClick(card, CARDS_MODE.COMPOSITE_FEED, this.userDataService.uploadedCards);
  }

  public onLikedCollectionsClicked(collection: ICardCollection): void {
    this.handleCollectionClick(collection, {mode: 'liked'});
  }

  public onPurchasedCollectionClicked(collection: ICardCollection): void {
    this.handleCollectionClick(collection, {mode: 'purchased'});
  }

  public onHideNavpanelEventEmitted() {
    this.isNavpanelShown = false;
  }

  private handleCardClick(card: ICard, cardFeedMode: CARDS_MODE, cardArray: ICard[]): void {
    console.log('handleCardClick', card)
    this.utilityService.triggerHaptics();
    this.cardFeedCardStartPosition = cardArray.findIndex(thumb => thumb.cardPosterMobile === card.cardPosterMobile);
    this.modelCardsMock = cardArray;
    this.cardFeedMode = cardFeedMode;
    this.isModelFeedShown = true;
    this.currentPortfolioFooterMode = FOOTER_MODE.DEFAULT;
  }

  private handleCollectionClick(collection: ICardCollection, collectionEditOptions: ICollectionEditOptions): void {
    this.utilityService.triggerHaptics();
    this.collectionDetailsSource = collection;
    this.collectionDetailsSource.showFullscreenBackgroundPreview = true;
    this.currentPortfolioFooterMode = FOOTER_MODE.LIBRARY_LIKED_COLLECTIONS_DEATAIL;
    this.collectionEditOptions = collectionEditOptions;
    this.filterCardMode = NAVPANEL_ACTION_TYPE.FILTER_ALL_MODELS;
    this.isCollectionDetailsShown = true;
  }

  public onPortfolioSwitchPanelClick(button: SWITCH_PANEL_ITEM): void {
    this.currentPortfolioSwitchPanelMode = button;
    switch (button) {
      case SWITCH_PANEL_ITEM.UPLOADS:
        this.filteredAllModels = this.userDataService.uploadedCards;
        break;
      case SWITCH_PANEL_ITEM.LIKES:
        this.filteredAllModels = this.userDataService.getSavedCards();
        break;
      case SWITCH_PANEL_ITEM.PURCHASES:
        this.filteredAllModels = this.userDataService.userPurchasedCards;
        break;
      case SWITCH_PANEL_ITEM.ALL:
        this.filteredAllModels = this.userDataService.allUserCards;
        break;
      default:
        break;
    }
  }

  public onNavbarBtnClick(navbarBtn: NAVBAR_BTN): void {
    console.log('Portofolio handler onNavbarBtnClick: ', navbarBtn);
    switch (true) {
      case navbarBtn === NAVBAR_BTN.MORE_OPTIONS:
        if(this.currentPortfolioFooterMode === FOOTER_MODE.PROFILE) {
          this.portfolioNavpanelItems = this.userDataService.getProfileNavPanelItems();
      }
        if (this.collectionDetailsSource && this.collectionEditOptions.mode === 'liked') {
          // BUTTONS LOGIC:
          const actionNaming = this.userDataService.isCollectionExitsInSaved(this.collectionDetailsSource) ? 'Remove from Library' : 'Add to Library';
          this.portfolioNavpanelItems = [
            { actionName: actionNaming, actionType: NAVPANEL_ACTION_TYPE.UNLIKE_COLLECTION }
          ]
        } else if (this.collectionDetailsSource && this.collectionEditOptions.mode === 'purchased') {
          this.portfolioNavpanelItems = [
            { actionName: 'Filter Purchases', actionType: NAVPANEL_ACTION_TYPE.FILTER_PURCHASES }
          ]
        }
        this.isNavpanelShown = true;
        this.utilityService.triggerHaptics();
        break;
      case navbarBtn === NAVBAR_BTN.UPLOAD:

      // UPLOAD_MODEL FOR SUBSCRIBED;
      if (this.firebaseAuth.currentUser.subscriptionStatus === SUBSCRIPTION_STATUS.SUBSCRIBER) {
        console.log('PROCEED TO UPLOAD!');
        if(this.settingService.devicePlatform === 'ios')  {
            this.uploadService.pickAndUploadFileNative();
        } else {
          this.utilityService.showErrorSnackBar('Was too lazy to make this feature available on web; This feature is only available on iOS devices.', 750)
        }
        break;
      }

      // UPLOAD_MODEL_LIMIT_REACHED
      if (this.firebaseAuth.currentUser.subscriptionStatus === SUBSCRIPTION_STATUS.TRIAL_EXPIRED) {
        this.isSubscriptionSplashShown = true;
        this.currentPortfolioFooterMode = FOOTER_MODE.SUBSCRIPTION_SPLASH_SCREEN;
        break;
      }

      if (this.firebaseAuth.currentUser.subscriptionStatus === SUBSCRIPTION_STATUS.EXPIRED) {
        this.isSubscriptionSplashShown = true;
        this.currentPortfolioFooterMode = FOOTER_MODE.SUBSCRIPTION_SPLASH_SCREEN;
        break;
      }

      // UPLOAD_MODEL_NOT_SUBSCRIBED
      if (this.firebaseAuth.currentUser.subscriptionStatus === SUBSCRIPTION_STATUS.GUEST) {
        this.isSubscriptionSplashShown = true;
        this.currentPortfolioFooterMode = FOOTER_MODE.SUBSCRIPTION_SPLASH_SCREEN;
        break;
      }

      // UPLOAD_MODEL_FREE_TRIAL;
      if (this.firebaseAuth.currentUser.subscriptionStatus === SUBSCRIPTION_STATUS.TRIAL) {
        console.log('FREE TRIAL UPLOAD MODE');
        console.log('PROCEED TO UPLOAD!');
        if(this.settingService.devicePlatform === 'ios')  {
          this.uploadService.pickAndUploadFileNative();
        } else {
          this.utilityService.showErrorSnackBar('Was too lazy to make this feature available on web; This feature is only available on iOS devices.', 750)
        }
        break;
      }

      this.utilityService.rollbarError(`Sorry, seems we having troubles with your subscription status; Please, contact support ${this.userDataService.userSubscriptionStatus}`)
      this.utilityService.showErrorSnackBar('Sorry, seems we having troubles with your subscription status; Please, contact support', 750);




        break;
      case navbarBtn === NAVBAR_BTN.FREE_TRIAL:
        this.utilityService.triggerHaptics();
        this.userDataService.tryForFreeClicked();
        this.isSubscriptionSplashShown = false;
        this.currentPortfolioFooterMode = FOOTER_MODE.PORTFOLIO;
        this.utilityService.showImportantSnackBar('3 models free, subscribe after to store your entire portfolio', 150);
        if(this.settingService.devicePlatform === 'ios')  {
          setTimeout(() => {
            this.uploadService.pickAndUploadFileNative();
          }, 2750);
        }
        break;
      case navbarBtn === NAVBAR_BTN.SUBSCRIBE:
        this.utilityService.triggerHaptics();
        try {
          // DO NOT FORGET ADDING CURRENT USER;
          this.firebaseAuth.getUserUid().then((uid) => {
            // this.iapPurchaseService.orderBasicSubscription(uid);
          });
        } catch (error) {
          console.log(error)
        }
        break;
      case navbarBtn === NAVBAR_BTN.SELECT_ALL:
        this.cardSelectRef.selectOrDeselectAllItems();
        break;
      case navbarBtn === NAVBAR_BTN.DOWNLOAD_SELECTED_MODELS:
        this.utilityService.notImplemented();
        break;
      case navbarBtn === NAVBAR_BTN.PUBLISH:
        this.userDataService.sendModelForReview(this.cardToEdit);
        this.currentPortfolioFooterMode = FOOTER_MODE.PORTFOLIO;
        this.isCardPublishShown = false;
        this.isModelFeedShown = false;
        this.utilityService.showSnackBar('Model successfuly sent to review', 0);
        this.onPortfolioSwitchPanelClick(this.currentPortfolioSwitchPanelMode); // Refresh cards data after moving
        break;
      case navbarBtn === NAVBAR_BTN.BUY:
          console.log('BUY BTN CLICKED');
          const purchasedCards = this.collectionDetailsSource.collectionCards.filter(card => card.purchaseStatus !== CARD_PURCHASE_STATUS.PURCHASED && card.isSelected);
          console.log(' SELECTED CARD TO PURCHASE: ', purchasedCards);

          this.cardsToPurchaseIds = purchasedCards.map(card => card.id);
          this.cardService.processConsumableInAppPurchaseRequest(purchasedCards);
        break;
      case navbarBtn === NAVBAR_BTN.SAVE && this.currentPortfolioFooterMode === FOOTER_MODE.RENAME_ITEM:
        console.log('SAVE CLICK ON RENAME CLICKED')
          const editCardIndex = this.userDataService.uploadedCards.findIndex((card) => {
            return card.cardPosterMobile === this.cardToEdit.cardPosterMobile && card.cardTitle === this.cardToEdit.cardTitle
          })
          this.cardToEdit.cardTitle = this.lastNameInputValue === '' ? this.userDataService.getNewUploadFileDefaultName() : this.lastNameInputValue;
          this.userDataService.uploadedCards[editCardIndex].modifiedDate = this.utilityService.getCurrentDateWithNanoseconds();
          this.userDataService.uploadedCards[editCardIndex].cardTitle = this.lastNameInputValue === '' ? this.userDataService.getNewUploadFileDefaultName() : this.lastNameInputValue;
          this.isNameChangeDialogueShown = false;
          this.currentPortfolioFooterMode = FOOTER_MODE.DEFAULT;

          this.cardService.updateModelPostRequest(this.cardToEdit).then((res) => {
            this.utilityService.showSnackBar('Model renamed succesfully', 500);
          }).catch(error => this.utilityService.showErrorSnackBar(`Error occured on rename: ${JSON.stringify(error.error)}`, 500));

        break;
      case navbarBtn === NAVBAR_BTN.SAVE && this.isModelFeedShown:
          console.log('SAVE FEED CLICK');
          console.log('DEFAULT SAVE')
          // SHOULD BE REWORKED !
          this.utilityService.notImplemented();

          // this.isCollectionSelectShown = false;
          // this.isModelFeedShown = false;
          // this.currentPortfolioFooterMode = FOOTER_MODE.PORTFOLIO;

          // const selectedCollectionToMove: ICardCollection = this.userDataService.allUserDraftCollections.find(col => col.isSelected);
          // this.userDataService.mutableOrganizeAllCardsToDraftCollectionOld([this.cardToEdit], selectedCollectionToMove);
          // this.filteredAllModels = this.userDataService.allUserCards;

          // this.cardService.updateCollectionPostRequest(selectedCollectionToMove).then((res) => {
          //   console.log(res);
          //   this.utilityService.showSnackBar('Collection updated', 500);
          // }).catch(error => this.utilityService.showErrorSnackBar(`Error occured on update: ${JSON.stringify(error.error)}`, 500));
        break;
      case navbarBtn === NAVBAR_BTN.SAVE:
          console.log('DEFAULT SAVE')
          // SHOULD BE REWORKED !
          this.utilityService.notImplemented();
          // this.isCollectionSelectShown = false;
          // this.currentPortfolioFooterMode = FOOTER_MODE.PORTFOLIO;

          // const selectedCards: ModelCardRenderedSelection[] = (this.userDataService.allUserCards as ModelCardRenderedSelection[]).filter(card => card.isSelected);
          // const selectedCollection: ICardCollection = this.userDataService.allUserDraftCollections.find(col => col.isSelected);

          // this.userDataService.mutableOrganizeAllCardsToDraftCollectionOld(selectedCards, selectedCollection);
          // this.onPortfolioSwitchPanelClick(this.currentPortfolioSwitchPanelMode); // Refresh cards data after moving

          // this.cardService.updateCollectionPostRequest(selectedCollection).then((res) => {
          //   console.log(res);
          //   this.utilityService.showSnackBar('Collection updated', 500);
          // }).catch(error => this.utilityService.showErrorSnackBar(`Error occured on update: ${JSON.stringify(error.error)}`, 500));


        break;
      case navbarBtn === NAVBAR_BTN.BACK_PROFILE:
        this.currentPortfolioFooterMode = FOOTER_MODE.PORTFOLIO;
        break;
      case navbarBtn === NAVBAR_BTN.BACK && this.isBuySelectShown:

        this.currentPortfolioFooterMode = FOOTER_MODE.LIBRARY_LIKED_COLLECTIONS_DEATAIL;
        this.isBuySelectShown = false
        break;
      case navbarBtn === NAVBAR_BTN.BACK && this.isCreateNewCollectionShown:
        this.isCreateNewCollectionShown = false;
        this.currentPortfolioFooterMode = FOOTER_MODE.LIBRARY_SELECT_MODELS;
        this.isCardSelectShown = true;
        setTimeout(() => {
          this.cardSelectRef.showSwitchPanel();
          this.cardSelectRef.setSwitchPanelItem(this.currentPortfolioSwitchPanelMode);
        }, 200);
        break;
      case navbarBtn === NAVBAR_BTN.BACK && this.isNameChangeDialogueShown:
        this.currentPortfolioFooterMode = FOOTER_MODE.DEFAULT;
        this.isNameChangeDialogueShown = false
        break;
      case navbarBtn === NAVBAR_BTN.BACK && this.isCardPublishShown && this.isPriceSelectShown:
        this.currentPortfolioFooterMode = FOOTER_MODE.PUBLISH_SELECTED_MODELS;
        this.isPriceSelectShown = false;
        break;
      case navbarBtn === NAVBAR_BTN.BACK && this.isCardPublishShown:
        this.currentPortfolioFooterMode = FOOTER_MODE.DEFAULT;
        this.isCardPublishShown = false;
        break;
      case navbarBtn === NAVBAR_BTN.BACK && this.isCollectionDetailsShown && this.isCardSelectShown:
        this.currentPortfolioFooterMode = FOOTER_MODE.LIBRARY_LIKED_COLLECTIONS_DEATAIL;
        this.isCardSelectShown = false;
        break;
      case navbarBtn === NAVBAR_BTN.BACK && this.isCollectionDetailsShown && this.isModelFeedShown && this.isCollectionSelectShown:
        this.currentPortfolioFooterMode = FOOTER_MODE.DEFAULT;
        this.isCollectionSelectShown = false;
        break;
      case navbarBtn === NAVBAR_BTN.BACK && this.isCollectionSelectShown && this.isCollectionDetailsShown :
        this.utilityService.triggerHaptics();
        this.isCollectionSelectShown = false;
        this.collectionDetailsSource.collectionCards.forEach(card => card.isSelected = false);
        this.currentPortfolioFooterMode = FOOTER_MODE.LIBRARY_SELECT_MODELS_DISABLED;
        setTimeout(() => {
          this.isCardSelectShown = true;
        }, 400);
      break;
      case navbarBtn === NAVBAR_BTN.SEARCH:
        this.utilityService.showImportantSnackBar('Coming soon', 250, 750);
        // this.cardService.getUploadStatusRequest();
        // this.cardService.activateTrialPostRequest();
        // this.uploadService.pickAndUploadFileNative();

        // if (this.isSearchActive) this.searchRef.searchBarFocus();
        // this.isSearchActive = true;
        // this.currentLibraryMode = LIBARY_MODE.SEARCH;
        // this.currentLibraryFooterMode = FOOTER_MODE.SEARCH;
        break;
      case navbarBtn === NAVBAR_BTN.BACK && this.isCollectionDetailsShown :
        this.currentPortfolioFooterMode = FOOTER_MODE.PORTFOLIO;
        this.isCollectionDetailsShown = false;
        break;
      case navbarBtn === NAVBAR_BTN.BACK && this.isCollectionSelectShown && this.isMoveCardProcessActivated :
        this.currentPortfolioFooterMode = FOOTER_MODE.DEFAULT;
        this.isCollectionSelectShown = false;
        this.isMoveCardProcessActivated = false;
        break;
      // Works for organize process back on collection select shown
      case navbarBtn === NAVBAR_BTN.BACK && this.isCollectionSelectShown :
          this.utilityService.triggerHaptics();
          this.isCollectionSelectShown = false;
          this.userDataService.allUserCards.forEach(card => card.isSelected = false);
          this.currentPortfolioFooterMode = FOOTER_MODE.LIBRARY_SELECT_MODELS_DISABLED;
          setTimeout(() => {
            this.isCardSelectShown = true;
          }, 400);
        break;
      case navbarBtn === NAVBAR_BTN.BACK && this.isCardSelectShown :
        this.currentPortfolioFooterMode = FOOTER_MODE.PORTFOLIO;
        this.isCardSelectShown = false;
        break;
      case navbarBtn === NAVBAR_BTN.BACK :
        this.router.navigateByUrl('/library');
        break;
      default:
        console.error('Portofolio handler onNavbarBtnClick: ', navbarBtn, ' not handled properly!!!');
        this.router.navigateByUrl('/library');
        break;
    }
  }

  public onNavpanelActionItemClicked(item: INavpanelAction): void {
    console.log(item.actionName);
    this.handleNavpanelAction(item);
    this.isNavpanelShown = false;
  }

  public handleNavpanelAction(action: INavpanelAction): void {
    const actionName = action.actionType
    switch (true) {
      case actionName === NAVPANEL_ACTION_TYPE.VIEW_ACCOUNT:
        console.log('VIEW ACCOUNT CLICKED')
        this.navbarRef.navbarBtnClick(NAVBAR_BTN.ACCOUNT);
        break;
      case actionName === NAVPANEL_ACTION_TYPE.SIGN_OUT:
        this.navbarRef.onLogoutBtnEvent()
        break;
      case actionName === NAVPANEL_ACTION_TYPE.SETTINGS:
        this.router.navigateByUrl('/service');
        break;
      case actionName === NAVPANEL_ACTION_TYPE.CONTACT_US:
        this.utilityService.notImplemented();
        break;
      case actionName === NAVPANEL_ACTION_TYPE.LINK_PAYMENTS:
        this.utilityService.notImplemented();
        break;


      case actionName === NAVPANEL_ACTION_TYPE.UPLOAD_MODEL:
        this.utilityService.notImplemented('IN PROGRESS NOW ! ');
        // console.log('NAVPANEL UPLOAD ACTION');
        // this.fileToUpload = action.optionalData;
        // this.nameDialogDefaultValue = this.uploadService.getLastFileName() ? this.uploadService.getLastFileName() : 'default';
        // this.uploadService.requestFileUpload(this.fileToUpload, undefined, this.lastNameInputValue);
        // this.currentPortfolioFooterMode = FOOTER_MODE.LIBRARY;
        // this.isNameChangeDialogueShown = false;
        break;
      case actionName === NAVPANEL_ACTION_TYPE.RENAME_CARD:
        this.nameDialogDefaultValue = this.cardToEdit.cardTitle;
        this.lastNameInputValue = this.cardToEdit.cardTitle;
        this.currentPortfolioFooterMode = FOOTER_MODE.RENAME_ITEM;
        this.isNameChangeDialogueShown = true;
        break;
      case actionName === NAVPANEL_ACTION_TYPE.DELETE_CARD:
        this.openDeleteCardConfirmationDialog(this.cardToEdit);
        break;
      case actionName === NAVPANEL_ACTION_TYPE.UNLIKE_CARD:
        this.cardFeedRef.dispatchCurrentCardLikeClick()
        // this.userDataService.removeFromSavedCards(this.cardToEdit);
        break;
      case actionName === NAVPANEL_ACTION_TYPE.FILTER_ALL_MODELS:
        this.setFilteringMode(NAVPANEL_ACTION_TYPE.FILTER_ALL_MODELS)
        break;
      case actionName === NAVPANEL_ACTION_TYPE.FILTER_SAVED_MODELS:
        this.setFilteringMode(NAVPANEL_ACTION_TYPE.FILTER_SAVED_MODELS)
        break;
      case actionName === NAVPANEL_ACTION_TYPE.FILTER_PURCHASE_MODELS:
        this.setFilteringMode(NAVPANEL_ACTION_TYPE.FILTER_PURCHASE_MODELS)
        break;
      case actionName === NAVPANEL_ACTION_TYPE.FILTER_UPLOADED_MODELS:
        this.setFilteringMode(NAVPANEL_ACTION_TYPE.FILTER_UPLOADED_MODELS)
        break;
      case actionName === NAVPANEL_ACTION_TYPE.UNLIKE_COLLECTION:
        this.collectionDetailsComponent.dispatchLikeClick();
        break;
      case actionName === NAVPANEL_ACTION_TYPE.FILTER_PURCHASES:
        window.setTimeout(() => { this.onCollectionDetailsControlBtnClick(COLLECTION_CONTROL_BTN.FILTER_MODELS) }, 500)
        break;
      case actionName === NAVPANEL_ACTION_TYPE.CONTACT_US:
        this.utilityService.notImplemented();
        break;
      case actionName === NAVPANEL_ACTION_TYPE.CANCEL_PUBLISHING:
        this.openCancelPublishingCardConfirmationDialog(this.cardToEdit)
        break;
      default:
        break;
    }
  }

  public onCardControlBtnClicked(action: ICardAction): void {
    this.utilityService.triggerHaptics();
    this.cardToEdit = action.card;
    console.log('card clicked', action);

    switch (action.controlAction) {
      case CARD_CONTROL_BTN.CONTROL_ACTIONS_DEFAULT:
        this.portfolioNavpanelItems = [
          {actionName: 'Add to Library', actionType: NAVPANEL_ACTION_TYPE.UNLIKE_CARD },
        ]
        this.isNavpanelShown = true;
        break;
      case CARD_CONTROL_BTN.CONTROL_ACTION_FOR_REVIEW_PUBLISH_REJECT:
        this.portfolioNavpanelItems = [
          {actionName: 'Rename Model', actionType: NAVPANEL_ACTION_TYPE.RENAME_CARD },
          {actionName: 'Delete Model', actionType: NAVPANEL_ACTION_TYPE.DELETE_CARD }
        ]
        this.isNavpanelShown = true;
        break;
      case CARD_CONTROL_BTN.CONTROL_ACTION_FOR_UPLOADED:
        this.portfolioNavpanelItems = [
          {actionName: 'Rename Model', actionType: NAVPANEL_ACTION_TYPE.RENAME_CARD },
          {actionName: 'Delete Model', actionType: NAVPANEL_ACTION_TYPE.DELETE_CARD }
        ]
        this.isNavpanelShown = true;
        break;
      case CARD_CONTROL_BTN.CONTROL_ACTION_FOR_EDIT_CARD:
        this.portfolioNavpanelItems = [
        ]
        this.isNavpanelShown = true;
        break;
      case CARD_CONTROL_BTN.CONTROL_ACTION_FOR_LIKED:
        this.portfolioNavpanelItems = [
          {actionName: 'Remove from Library', actionType: NAVPANEL_ACTION_TYPE.UNLIKE_CARD },
        ]
        this.isNavpanelShown = true;
        break;
      case CARD_CONTROL_BTN.CONTROL_ACTIONS_FOR_PURCHASED:
        this.portfolioNavpanelItems = [
        ]
        this.isNavpanelShown = true;
        break;
      case CARD_CONTROL_BTN.CONTROL_ACTION_FOR_IN_REVIEW:
        this.portfolioNavpanelItems = [
          {actionName: 'Cancel Publishing', actionType: NAVPANEL_ACTION_TYPE.CANCEL_PUBLISHING },
          {actionName: 'Delete Model', actionType: NAVPANEL_ACTION_TYPE.DELETE_CARD },
        ]
        this.isNavpanelShown = true;
        break;
      case CARD_CONTROL_BTN.CONTROL_ACTION_REJECTED:
        this.utilityService.notImplemented();
        break;
      case CARD_CONTROL_BTN.CONTROL_ACTION_MORE_FOR_REJECTED:
        this.portfolioNavpanelItems = [
          {actionName: 'Cancel Publishing', actionType: NAVPANEL_ACTION_TYPE.CANCEL_PUBLISHING },
          {actionName: 'Delete Model', actionType: NAVPANEL_ACTION_TYPE.DELETE_CARD },
        ]
        this.isNavpanelShown = true;
        break;
      case CARD_CONTROL_BTN.CONTROL_ACTION_FOR_PUBLISHED:
        this.portfolioNavpanelItems = [
          {actionName: 'Cancel Publishing', actionType: NAVPANEL_ACTION_TYPE.CANCEL_PUBLISHING },
        ]
        this.isNavpanelShown = true;
        break;
      case CARD_CONTROL_BTN.PUBLISH_SINGLE_CARD:
        console.log('PUBLISH SINGLE CARD', action);

        const selectedCardsToPublish = this.userDataService.uploadedCards.find(card => card.id === action.card.id);
        this.userDataService.sendModelForReview(selectedCardsToPublish);
        this.currentPortfolioFooterMode = FOOTER_MODE.PORTFOLIO;
        this.isModelFeedShown = false;


        // this.cardSelectData = [this.userDataService.uploadedCards.find(card => card.cardContentMobile === action.card.cardContentMobile)];
        // this.currentPortfolioFooterMode = FOOTER_MODE.PUBLISH_SELECTED_MODELS;
        // this.isCardPublishShown = true;
        // this.cardToEdit = this.userDataService.uploadedCards.find(card => card.cardContentMobile === action.card.cardContentMobile);
        break;
      case CARD_CONTROL_BTN.BUY_MODEL:
        this.cardsToPurchaseIds = [action.card.id];
        this.cardService.processConsumableInAppPurchaseRequest([action.card]);
        break;
      case CARD_CONTROL_BTN.CONTROL_BACK_BTN:
        this.utilityService.triggerHaptics();
        console.log('Portfolio, onModelFeedBackBtnClick: ');

        switch (true) {
          case this.isCollectionDetailsShown:
            this.isModelFeedShown = false;
            this.currentPortfolioFooterMode = FOOTER_MODE.LIBRARY_LIKED_COLLECTIONS_DEATAIL;
            break;
          default:
            this.onPortfolioSwitchPanelClick(this.currentPortfolioSwitchPanelMode);

            this.isModelFeedShown = false;
            this.currentPortfolioFooterMode = FOOTER_MODE.PORTFOLIO;
            break;
        }
        this.isModelFeedShown = false;
        break;
      default:
        break;
    }
  }


  public onTopNavAdditionalMenuBtnClicked(): void {
    this.utilityService.triggerHaptics();
    this.navbarRef.navbarBtnClick(NAVBAR_BTN.ACCOUNT);
    // this.isNavpanelShown = true;
    // this.portfolioNavpanelItems = this.userDataService.getTopNavPanelMenuItems();
  }

  public onCollectionDetailsControlBtnClick(controlEvent: COLLECTION_CONTROL_BTN): void {
    if(controlEvent === COLLECTION_CONTROL_BTN.DOWNLOAD_MODELS) {
      this.currentPortfolioFooterMode = FOOTER_MODE.CARD_DOWNLOAD_PURCHASED;
      this.cardSelectData = JSON.parse(JSON.stringify(this.collectionDetailsSource.collectionCards.filter(cardRaw => cardRaw.purchaseStatus === CARD_PURCHASE_STATUS.PURCHASED)));
      this.cardSelectData.forEach(cardRaw => {
        cardRaw.isSelected = false;
        cardRaw.isDisabled = undefined;
      });
      this.isCardSelectShown = true;
    }
    if(controlEvent === COLLECTION_CONTROL_BTN.BUY_MODELS) {
      this.currentPortfolioFooterMode = FOOTER_MODE.CARD_BUY_SECTION;
      this.cardSelectData = this.collectionDetailsSource.collectionCards.filter(card =>  card.purchaseStatus === CARD_PURCHASE_STATUS.DEFAULT_3DWAY);
      this.cardSelectData.forEach(cardRaw => cardRaw.isSelected = false);
      this.portfolioAdditionalFooterData.priceData = undefined;
      this.cardSelectData.forEach(cardToSel => {
        if(cardToSel.purchaseStatus === CARD_PURCHASE_STATUS.PURCHASED) {
          cardToSel.isDisabled = true;
          cardToSel.isSelected = true;
        }
      })

      this.isBuySelectShown = true;
    }
    if(controlEvent === COLLECTION_CONTROL_BTN.FILTER_MODELS) {
        /// COMMON SERVICE MOVE

      this.portfolioNavpanelItems = this.userDataService.getAvailableFiltersForCollection(this.collectionDetailsSource)

      const filterMode  = this.portfolioNavpanelItems.find(item => item.actionType === this.filterCardMode)
      if(filterMode) filterMode.isSelected = true;
      else {
        this.filterCardMode = NAVPANEL_ACTION_TYPE.FILTER_ALL_MODELS;
        this.portfolioNavpanelItems.find(item => item.actionType === NAVPANEL_ACTION_TYPE.FILTER_ALL_MODELS).isSelected = true;
      }
      this.isNavpanelShown = true;
    }
  }

  public onCollectionDetailsThumbClick(thumbNumber: number): void {
    this.utilityService.triggerHaptics();
    console.log('on thumb click (library)');
    this.cardFeedCardStartPosition = thumbNumber;
    this.modelCardsMock = this.collectionDetailsSource.collectionCards;
    this.currentPortfolioFooterMode = FOOTER_MODE.DEFAULT;
    this.cardFeedMode = CARDS_MODE.COMPOSITE_FEED;
    this.isModelFeedShown = true;
  }

    // FULLSCREEN PANELS HANDLERS:
    public onCardSelected(cardsSelected: ModelCardRenderedSelection[]) {
      if(this.isCollectionDetailsShown) {
              // setting additional purchase data;
        if(this.currentPortfolioFooterMode === FOOTER_MODE.CARD_BUY_SECTION) {
          const selectedCardPrice = cardsSelected.filter(card => card.purchaseStatus !== CARD_PURCHASE_STATUS.PURCHASED ).reduce((acc, val) => {
            return acc = acc + val.cardPrice;
          }, 0)
          this.portfolioAdditionalFooterData.priceData = '$' + selectedCardPrice.toString() + '.00' ;
          console.log(selectedCardPrice);
          return
        }
        if(this.currentPortfolioFooterMode === FOOTER_MODE.CARD_DOWNLOAD_PURCHASED) {
          const isAllDownloadCardsSelected = this.collectionDetailsSource.collectionCards.filter(card => card.purchaseStatus === CARD_PURCHASE_STATUS.PURCHASED).length === cardsSelected.length;
          if(isAllDownloadCardsSelected) this.portfolioAdditionalFooterData.selectToggleLabel = 'Cancel';
          else this.portfolioAdditionalFooterData.selectToggleLabel = 'Select All';

          const selectedForDownloadPrice = cardsSelected.reduce((acc, val) => {
            return acc = acc + val.cardPrice;
          }, 0)
          this.portfolioAdditionalFooterData.priceData = '$' + selectedForDownloadPrice.toString() + '.00' ;
          console.log(selectedForDownloadPrice);
          return;
        }
        if(cardsSelected?.length > 0) {
          this.currentPortfolioFooterMode = FOOTER_MODE.LIBRARY_SELECT_MODELS
        } else {
          this.currentPortfolioFooterMode = FOOTER_MODE.LIBRARY_SELECT_MODELS_DISABLED
        }
      } else if(cardsSelected?.length > 0) {
        this.currentPortfolioFooterMode = FOOTER_MODE.LIBRARY_SELECT_MODELS
      } else {
        this.currentPortfolioFooterMode = FOOTER_MODE.LIBRARY_SELECT_MODELS_DISABLED
      }
      console.log(cardsSelected?.length);
    }

    public onCollectionSelected(collectionSelected: ICardCollection): void {
      if(collectionSelected.isSelected) {
        this.currentPortfolioFooterMode = FOOTER_MODE.LIBRARY_SELECT_COLLECTION_TO_MOVE
      } else {
        this.currentPortfolioFooterMode = FOOTER_MODE.LIBRARY_SELECT_COLLECTION_TO_MOVE_DISABLED
      }
    }

    public onCardSelectSwitchPanelClicked(switchPanleBtn: SWITCH_PANEL_ITEM): void {
      switch (switchPanleBtn) {
        case SWITCH_PANEL_ITEM.UPLOADS:
          this.cardSelectData = this.userDataService.uploadedCards;
          break;
        case SWITCH_PANEL_ITEM.LIKES:
          this.cardSelectData = this.userDataService.getSavedCards();
          break;
        case SWITCH_PANEL_ITEM.PURCHASES:
          this.cardSelectData = this.userDataService.userPurchasedCards;
          break;
        case SWITCH_PANEL_ITEM.ALL:
          this.cardSelectData = this.userDataService.allUserCards;
          break;
        default:
          break;
      }
    }

  public onCardToPublishSeletected(cardsToPurch: ModelCardRenderedSelection[]): void {
    // setting additional purchase data;
    console.log('CARDS LENGTH TO PURCH', cardsToPurch.length);
    const isAllCardsSelected = this.userDataService.uploadedCards.length === cardsToPurch.length;
    if (isAllCardsSelected) this.portfolioAdditionalFooterData.selectToggleLabel = 'Cancel';
    else this.portfolioAdditionalFooterData.selectToggleLabel = 'Select All';
  }

  public onShowCardPriceToPublishSelectEvent(cardToSetPrice: ModelCardRenderedSelection): void {
    console.log('onShowCardPriceToPublishSelectEvent', cardToSetPrice)
    this.cardToEdit = cardToSetPrice;
    this.currentPortfolioFooterMode = FOOTER_MODE.SET_PRICE;
    this.isPriceSelectShown = true;
  }

  public onPriceSelected(price: number): void {
    this.lastSelectedPrice = price;
  }

  private setFilteringMode(filterMode: NAVPANEL_ACTION_TYPE) {
    this.filterCardMode = filterMode
    const navpanelItems: INavpanelAction[] = [
      {actionName: 'All Models', actionType: NAVPANEL_ACTION_TYPE.FILTER_ALL_MODELS },
      {actionName: 'Saved Models', actionType: NAVPANEL_ACTION_TYPE.FILTER_SAVED_MODELS },
      {actionName: 'By You Models', actionType: NAVPANEL_ACTION_TYPE.FILTER_UPLOADED_MODELS },
      {actionName: 'Purchased Models', actionType: NAVPANEL_ACTION_TYPE.FILTER_PURCHASE_MODELS },
    ]
    navpanelItems.find(item => item.actionType === this.filterCardMode).isSelected = true;
    this.portfolioNavpanelItems = navpanelItems;
  }

  public onCardToPurchaseSelected(cardsToPurch: ModelCardRenderedSelection[]): void {
    // setting additional purchase data;
    console.log('Card Selected -> ', cardsToPurch);
    const isAllCardsSelected = this.collectionDetailsSource.collectionCards.length === cardsToPurch.length;
    if(isAllCardsSelected) this.portfolioAdditionalFooterData.selectToggleLabel = 'Cancel';
    else this.portfolioAdditionalFooterData.selectToggleLabel = 'Select All';

    const selectedCardPrice = cardsToPurch.reduce((acc, val) => {
      return acc = acc + val.cardPrice;
    }, 0)
    this.portfolioAdditionalFooterData.priceData = '$' + selectedCardPrice.toString() + '.00' ;
    console.log(selectedCardPrice)
  }

  public openCancelPublishingCardConfirmationDialog(card: ICard) {
    const dialogConfig: MatDialogConfig<DialogData> = {
      data: {
        dialogTitle: undefined,
        dialogText: `Are you sure you want to cancel publishing model "${card.cardTitle}" ? `,
        confirmBtntext: 'Yes',
        declineBtnText: 'No'
      }
    };
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result === true) {
        // OUTDATED LOGIC !!!!!!! SHOULD BE REWORKED !!!!!!!

        this.userDataService.cancelModelPublishing(card);
        this.currentPortfolioFooterMode = FOOTER_MODE.LIBRARY;
        const indx = this.userDataService.uploadedCards.findIndex((findCard) => { return findCard.id === card.id});
        this.userDataService.uploadedCards[indx].purchaseStatus = CARD_PURCHASE_STATUS.UPLOADED;
        this.currentPortfolioFooterMode = FOOTER_MODE.EDIT_COLLECTION;
        this.isModelFeedShown = false;

        this.cardService.cancelReviewModelPostRequest(card).then((resp) => {
            this.utilityService.showSnackBar('Review cancelled succesfully.', 500);
          }).catch(error => this.utilityService.showErrorSnackBar(`Error occured on cancelling review: ${JSON.stringify(error.error)}`, 500));

      }
    });
  }

  public openDeleteCardConfirmationDialog(card: ICard) {
    const dialogConfig: MatDialogConfig<DialogData> = {
      data: {
        dialogTitle: undefined,
        dialogText: `This model will be deleted from your library`,
        confirmBtntext: 'Yes',
        declineBtnText: 'No'
      }
    };
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result === true) {
        this.userDataService.deleteCardFromPortfolio(card);
        this.isModelFeedShown = false;
        this.currentPortfolioFooterMode = FOOTER_MODE.PORTFOLIO;

        this.onPortfolioSwitchPanelClick(this.currentPortfolioSwitchPanelMode);

        this.cardService.deleteModelPostRequest(card).then((resp) => {
          this.utilityService.showSnackBar('Model deleted succesfully.', 500);
          // update filtered cards:
          if(this.filterCardMode === NAVPANEL_ACTION_TYPE.FILTER_UPLOADED_MODELS) {
            this.filteredAllModels = this.userDataService.uploadedCards;
          }
          if(this.filterCardMode === NAVPANEL_ACTION_TYPE.FILTER_ALL_MODELS) {
            this.filteredAllModels = this.userDataService.allUserCards;
          }
        }).catch(error => this.utilityService.showErrorSnackBar(`Error occured on delete: ${JSON.stringify(error.error)}`, 500));

      }
    });
  }

  public ngOnDestroy(): void {
    this.cardPurchaseStateChangeSubscription.unsubscribe();
    this.uploadErrorSub.unsubscribe();
  }

}
