<div class="render-limit-selector">
  <div class="render-limit-options" [class.maxRowElements5]="inputMaxElementsInRow === 5" [class.maxRowElements4]="inputMaxElementsInRow === 4"  >
    <button
      *ngFor="let limit of renderLimitOptions"
      [class.selected]="isSelected(limit)"
      [class.no-limit-option]="limit === null"
      (click)="setRenderLimit(limit)"
    >
      {{ limit === null ? 'No Limit' : limit }}
    </button>
  </div>
</div>
