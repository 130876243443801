import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Platform } from '@angular/cdk/platform';
import { FirebaseAuthService } from './firebase-auth.service';

@Injectable({
  providedIn: 'root'
})
export class WebMobileRestrictionGuard implements CanActivate {

  constructor(
    private platform: Platform,
    private router: Router,
    private firebaseAuth: FirebaseAuthService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

    // Check if the device is running on Android or iOS
    if (this.platform.ANDROID || this.platform.IOS) {
      // Check if the device width is less than 768 pixels
      if (window.innerWidth < 768) {
        // If the user is authenticated, sign them out and redirect to the library
        if (this.firebaseAuth.currentUser) {
          this.firebaseAuth.logout().then(() => {
            this.router.navigate(['/']);
          });
          return false; // Prevent navigation to the original route
        } else {
          // If the user is not authenticated, redirect them to the library
          this.router.navigate(['/']);
          return false; // Prevent navigation to the original route
        }
      }
    }

    // Allow navigation if the device is not on Android/iOS or has a width >= 768px
    return true;
  }
}
