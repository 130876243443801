	/* eslint-disable @typescript-eslint/indent */
import { Component, OnInit, Input, HostListener } from '@angular/core';

export interface FeedbackData {
  message: string;
  rating: number;
  author: string;
  date: string;
}

@Component({
  selector: 'app-feedback-cards',
  templateUrl: './feedback-card.component.html',
  styleUrls: ['./feedback-card.component.scss']
})
export class FeedbackCardComponent implements OnInit {

  constructor() { }

  @Input() userFeedback: FeedbackData[];


	@HostListener('touchstart', ['$event'])
	onElementTouchStart(event) {
    event.stopPropagation();
  }

  // @HostListener('touchend' ['$event'])
	// onElementTouchEnd(event) {
  //   event.stopPropagation();
	// }

  ngOnInit() {
  }

}
