import { Component, Input, OnInit, OnChanges, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { ICard, IThumbCard, SLIDER_CARD_TYPE, CARD_PURCHASE_STATUS } from 'src/app/models/card';
import { ICardCollection, ICardCollectionMockSource } from 'src/app/models/collection';
import { UploadFileService } from '../../providers/upload-file.service';

@Component({
  selector: 'app-card-horizontal-slider',
  templateUrl: './card-horizontal-slider.component.html',
  styleUrls: ['./card-horizontal-slider.component.scss']
})
export class CardHorizontalSliderComponent implements OnInit, OnChanges {
  @Input() title: string;
  @Input() inputCards: IThumbCard[] ;
  @Input() sourceCollections: ICardCollection[];
  @Input() sourceCards: ICard[];
  @Input() isSmallSize: boolean = false;

  CARD_PURCHASE_STATUS = CARD_PURCHASE_STATUS


  @Output() slideClicked = new EventEmitter<string>();
  @Output() titleClicked = new EventEmitter<string>();
  @Output() collectionClicked = new EventEmitter<ICardCollection>();
  @Output() cardClicked = new EventEmitter<ICard>();




  SLIDER_CARD_TYPE = SLIDER_CARD_TYPE;

  constructor(public uploadService: UploadFileService,) { }



  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes['inputImg']) {
      // this.thumbs = this.inputImg;
      // if(this.inputImg && this.inputImg[0] &&  !this.isComplexThumbCard(this.inputImg[0])) {
      //   this.thumbs = this.inputImg as string[];
      // }
    }
  }

  titleClick(event: Event): void {
    console.log('titleclick')
    event.stopPropagation();
    this.titleClicked.emit(this.title);
  }

  hasButtonsInHeader(): boolean {
    if(this.title === 'Portfolio') return true;
    return false;
  }

  handleSliderFileInput(event: any): void {
    const file = (event.target as HTMLInputElement).files.item(0);
    if(file) {
      this.uploadService.requestFileUpload(file);
    }
    event.stopPropagation();
}

  isComplexThumbCard(card: string | IThumbCard): card is IThumbCard {
    return (card as IThumbCard).type !== undefined;
  }

  onCollectionThumbClicked(collectionThumb: ICardCollection, event: Event): void {
    event.stopPropagation();
    this.collectionClicked.emit(collectionThumb);
  }

  onCardThumbClicked(collectionThumb: ICard, event: Event): void {
    event.stopPropagation();
    this.cardClicked.emit(collectionThumb);
  }

  onThumbClick(cardThumb: string, event: Event): void {
    event.stopPropagation();
    this.slideClicked.emit(cardThumb);

    return
  }

}
