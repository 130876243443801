<div class="profilte-top-wrapper" fxLayout="row" fxLayoutAlign="space-between center" >
  <h1 class="top-title">Your Account</h1>
  <!-- <button class="top-sign-out-btn" (click)="logoutClick()">
      Sign Out
  </button> -->
</div>
<div class="profile-wrapper">
  <br>
  <br>
  <div class="profile-region" >
    <div class="region-head" fxLayout="row" (click)="toggleRegion($event, PROFILE_REGION.PERSONAL)" fxLayoutAlign="space-between stretch" >
      <h2 class="region-title" (click)="toggleRegion($event, PROFILE_REGION.PERSONAL)">Account Data</h2>
      <svg *ngIf="!isAccountRegionShown" class="arrow-right"  width="7" height="14" viewBox="0 0 7 14" fill="none" xmlns="http://www.w3.org/2000/svg" (click)="toggleRegion($event, PROFILE_REGION.PERSONAL)">
        <path d="M0.75 0.75L5.75 7L0.75 13.25" stroke="white" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" (click)="toggleRegion($event, PROFILE_REGION.PERSONAL)"/>
      </svg>
      <svg *ngIf="isAccountRegionShown" class="arrow-down"  width="14" height="7" viewBox="0 0 14 7" fill="none" xmlns="http://www.w3.org/2000/svg" (click)="toggleRegion($event, PROFILE_REGION.PERSONAL)" >
        <path d="M13.25 0.75L7 5.75L0.75 0.75" stroke="white" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" (click)="toggleRegion($event, PROFILE_REGION.PERSONAL)" />
      </svg>

    </div>
    <ng-container #profileRegion *ngIf="this.isAccountRegionShown" >
        <div class="profile-section" fxLayout="column" [@slideToggle]="isAccountRegionShown">
          <div class="bottom-part" fxFlex="1 1 100" fxLayout="row" fxLayoutAlign="center center">
            <!-- <div class="section-delete-acc" (click)="deleteAccClick()"><span> Close my account and delete my data</span></div> -->
            <button class="profile-btn" (click)="deleteAccClick()">
              <span> Close my account and delete my data</span>
            </button>
          </div>
        </div>
    </ng-container>

    <div class="region-head" fxLayout="row" (click)="toggleRegion($event, PROFILE_REGION.SUBSCRIPT)" fxLayoutAlign="space-between stretch" >
      <h2 class="region-title" (click)="toggleRegion($event, PROFILE_REGION.SUBSCRIPT)">Subscription</h2>
      <svg *ngIf="!isSubscriptionRegionShown" class="arrow-right"  width="7" height="14" viewBox="0 0 7 14" fill="none" xmlns="http://www.w3.org/2000/svg" (click)="toggleRegion($event, PROFILE_REGION.PERSONAL)">
        <path d="M0.75 0.75L5.75 7L0.75 13.25" stroke="white" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" (click)="toggleRegion($event, PROFILE_REGION.PERSONAL)"/>
      </svg>
      <svg *ngIf="isSubscriptionRegionShown" class="arrow-down"  width="14" height="7" viewBox="0 0 14 7" fill="none" xmlns="http://www.w3.org/2000/svg" (click)="toggleRegion($event, PROFILE_REGION.PERSONAL)" >
        <path d="M13.25 0.75L7 5.75L0.75 0.75" stroke="white" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" (click)="toggleRegion($event, PROFILE_REGION.PERSONAL)" />
      </svg>
    </div>

    <ng-container #subscriptionRegion *ngIf="this.isSubscriptionRegionShown"  >
      <div class="profile-section" fxLayout="column" [@slideToggle]="isSubscriptionRegionShown">
        <div class="profile-section-bottom-part" fxFlex="1 1 100" fxLayout="row" fxLayoutAlign="center center">
          <button class="profile-btn disabledButClickable" (click)="onManageSubscriptionClicked()">
            <span>Manage Subscription</span>
          </button>
        </div>
      </div>
  </ng-container>

  <div class="region-head" *ngIf="!environment.production" fxLayout="row" (click)="toggleRegion($event, PROFILE_REGION.RENDER_LIMIT)" fxLayoutAlign="space-between stretch" >
    <h2 class="region-title" (click)="toggleRegion($event, PROFILE_REGION.RENDER_LIMIT)">Render Limit</h2>
    <svg *ngIf="!isRenderLimitRegionShown" class="arrow-right"  width="7" height="14" viewBox="0 0 7 14" fill="none" xmlns="http://www.w3.org/2000/svg" (click)="toggleRegion($event, PROFILE_REGION.RENDER_LIMIT)">
      <path d="M0.75 0.75L5.75 7L0.75 13.25" stroke="white" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" (click)="toggleRegion($event, PROFILE_REGION.PERSONAL)"/>
    </svg>
    <svg *ngIf="isRenderLimitRegionShown" class="arrow-down"  width="14" height="7" viewBox="0 0 14 7" fill="none" xmlns="http://www.w3.org/2000/svg" (click)="toggleRegion($event, PROFILE_REGION.RENDER_LIMIT)" >
      <path d="M13.25 0.75L7 5.75L0.75 0.75" stroke="white" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" (click)="toggleRegion($event, PROFILE_REGION.PERSONAL)" />
    </svg>
  </div>

  <ng-container  #renderLimitRegion *ngIf="this.isRenderLimitRegionShown && !environment.production"  >
    <div class="profile-section" fxLayout="column" [@slideToggle]="isRenderLimitRegionShown">
      <div class="profile-section-bottom-part" fxFlex="1 1 100" fxLayout="row" fxLayoutAlign="center center">
        <app-renderlimit-select [inputMaxElementsInRow]="4"></app-renderlimit-select>
      </div>
    </div>
  </ng-container>

  <div class="region-head" *ngIf="!environment.production" fxLayout="row" (click)="betaSettings()" fxLayoutAlign="space-between center" >
    <h2 class="region-title" (click)="betaSettings()">Beta Settings</h2>
  </div>
  <div class="region-head" fxLayout="row" (click)="signOut()" fxLayoutAlign="space-between center" >
    <h2 class="region-title" (click)="signOut()">Sign Out</h2>
  </div>

  </div>



</div>
<p class="terms-text">
  <u><a href="https://www.3dway.io/legal/terms-of-service">Terms of Service</a></u> and <u><a href="https://www.3dway.io/legal/privacy-policy">Privacy Policy</a></u>.
 </p>



