import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-albums-home',
  templateUrl: './albums-home.component.html',
  styleUrls: ['./albums-home.component.scss']
})
export class AlbumsHomeComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
