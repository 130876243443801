import { UserDataService } from 'src/app/shared/providers/user-data.service';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NgxFileDropEntry, FileSystemFileEntry, FileSystemDirectoryEntry } from 'ngx-file-drop';
import { WebUploadService } from '../webupload.service';
import { inOutAnimation } from 'src/app/shared/providers/animations-list';
import { WebNavService } from '../webnavigation.service';
import { Subscription } from 'rxjs';


@Component({
  selector: 'app-fullsize-dragdrop',
  templateUrl: './fullsize-dragdrop.component.html',
  styleUrls: ['./fullsize-dragdrop.component.scss'],
  animations: [
    inOutAnimation
  ],
})
export class FullsizeDragdropComponent implements OnInit, OnDestroy {
  isFileOver: boolean = false;
  @Input() modeLocal: boolean = false;
  @Input() collectionToUploadId: string;
  public forceShowDragZone: boolean = false
  public dragzoneEventSubscription: Subscription;
  public uploadSubscription: Subscription;


  constructor(
    public uploadService: WebUploadService,
    public userDataService: UserDataService,
    public webNavService: WebNavService
  ) { }

  ngOnInit(): void {
    this.dragzoneEventSubscription = this.webNavService.webNavForceShowDragZoneActivatedEvent.subscribe((forceShowDragZone: boolean) => {
      // DIRTY HACK : modeLocal is used only for second tier menus, so we can be sure that it wont be shown for second tier menus
      // if(!this.modeLocal) {
        this.forceShowDragZone = true;
        console.log('forceShowDragZone', forceShowDragZone, this.forceShowDragZone)
      // }
    });

    this.uploadSubscription = this.uploadService.internalUploadProcessStarted.subscribe(() => {
      if(this.forceShowDragZone) this.forceShowDragZone = false;
    });
  }

  onDropLabelClick(): void {
    this.forceShowDragZone = false;
  }

  fileOver(event: any): void {
    // const fileDropElement = document.querySelector('ngx-file-drop') as HTMLElement;
    // fileDropElement.style.pointerEvents = 'auto';
    this.isFileOver = true;
  }

  fileLeave(event: any): void {
    // const fileDropElement = document.querySelector('ngx-file-drop') as HTMLElement;
    // fileDropElement.style.pointerEvents = 'none';
    this.userDataService.dragOver = false;
    this.isFileOver = false;
  }


  public dropped(files: NgxFileDropEntry[]) {
    this.isFileOver = false;
    this.userDataService.dragOver = false;
    this.uploadService.uploadFilesWithPreProcessing(files, this.collectionToUploadId);
  }

  ngOnDestroy(): void {
    if(this.dragzoneEventSubscription) this.dragzoneEventSubscription.unsubscribe();
    if(this.uploadSubscription) this.uploadSubscription.unsubscribe();
  }






}
