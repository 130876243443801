/* eslint-disable max-len */
import { trigger, transition, style, animate, state, query, group, animateChild, sequence } from '@angular/animations';
const NAVBAR_HEIGHT_TOP = '58px'; // 54px old value; // 63
const NAVBAR_HEIGHT_BOTTOM = '57px'; // 70px old value // 64
const NAVBAR_HEIGHT_SUM = '115px' // 120px old value;

// const COLLECTION_DETAILS_TOP_MARGIN = '160px';
// const COLLECTION_DETAILS_DESCRIPTION_HEIGHT = '275px';
const COLLECTION_DETAILS_HEIGHT = '507px'


export const pageFadingAnimation =  trigger(
  'pageFadeAnimation',
  [
    transition(
      ':enter',
      [
      query('.page-transition-overlay', [
        style({'opacity': '1', 'z-index' : '10', 'width': '100vw', 'height': '100vh'}),
        animate('0.30s ease-out',
                style({'opacity': '0', 'z-index' : '10', 'width': '100vw', 'height': '100vh'}))
      ]),
      ]
    ),
    // transition(
    //   ':leave',
    //   [
    //     query('.page-transition-overlay', [
    //       style({'opacity': '1'}),
    //       animate('0.47s ease-out',
    //               style({'opacity': '0'}))
    //     ]),
    //   ]
    // )
  ]
  );
  export const inOutAnimationFast = trigger(
    'inOutAnimationFast',
    [
      transition(
        ':enter',
        [
          style({opacity: 0 }),
          animate('0.15s ease-out',
                  style({opacity: 1 }))
        ]
      ),
      transition(
        ':leave',
        [
          style({ opacity: 1 }),
          animate('0.15s ease-out',
                  style({opacity: 0 }))
        ]
      )
    ]
  )


  export const inOutAnimation = trigger(
    'inOutAnimation',
    [
      transition(
        ':enter',
        [
          style({opacity: 0 }),
          animate('0.3s ease-out',
                  style({opacity: 1 }))
        ]
      ),
      transition(
        ':leave',
        [
          style({ opacity: 1 }),
          animate('0.3s ease-out',
                  style({opacity: 0 }))
        ]
      )
    ]
  )

export const inOutAnimationWithMargin = trigger(
  'inOutAnimationWithMargin',
  [
    transition(
      ':enter',
      [
        style({opacity: 0, 'margin-top' : '70px', position: 'absolute', top: '0', width: '100%' }),
        animate('0.3s  ease-out',
                style({opacity: 1 ,  'margin-top' : '*'}))
      ]
    ),
    transition(
      ':leave',
      [
        style({ opacity: 1,  'margin-top' : '*',  position: 'absolute', top: '0', width: '100%'}),
        animate('0.15s  ease-out',
                style({opacity: 0, 'margin-top' : '70px' }))
      ]
    )
  ]
)

export const librarySectionSwitchAnimation = trigger(
  'librarySectionSwitchAnimation',
      [
        transition(
          ':enter',
          [
            style({opacity: 0 , position: 'absolute', width: '100%' }),
            animate(`{{ enterTime }} {{ delayTime }} ease-out`,
                    style({opacity: 1 }))
          ],
          { params: { enterTime: '55ms', delayTime: '55ms' }}
        ),
        transition(
          ':leave',
          [
            style({ opacity: 1 , position: 'absolute', width: '100%'}),
            animate('{{ leaveTime }} ease-out',
                    style({opacity: 0 }))
          ],
          { params: { leaveTime: '55ms' }}
        )
      ]
)

export const fadeWithDelay = trigger(
  'fadeWithDelay',
  [
    transition(
      ':enter',
      [
        style({opacity: 0 }),
        animate('0.15s 1.5s ease-out',
                style({opacity: 1 }))
      ]
    ),
    transition(
      '* => void',
      [
        style({ opacity: 1 }),
        animate('0.4s ease-out',
                style({opacity: 0 }))
      ]
    )
  ]
)



export const fullscreenCardAnimation =  trigger(
    'fullscreenCardAnimation',
    [
      transition(
        ':enter',
        [
          style({opacity: 0 }),
          animate('0.15s ease-out',
                  style({opacity: 1 }))
        ]
      ),
      transition(
        ':leave',
        [
          style({ opacity: 1 }),
          animate('0.15s ease-out',
                  style({opacity: 0 }))
        ]
      )
    ]
  );

export const geolocationAnimation =  trigger(
    'geolocationAnimation',
    [
      transition(
        ':enter',
        [
          style({height: 0, opacity: 0.4 }),
          animate('0.15s ease-out',
                  style({height: '315px', opacity: 1 }))
        ]
      ),
      transition(
        ':leave',
        [
          style({ height: '315px', opacity: 1 }),
          animate('0.15s ease-out',
                  style({height: 0, opacity: 0.4 }))
        ]
      )
    ]
  );

export const fullscreenPanelAnimation =  trigger(
  'fullscreenPanelAnimation',
  [
    transition(
      ':enter',
      [
        // style({height: 0, opacity: 0.4 }),
        // animate('0.23s ease-out',
        //         style({height: '90vh', opacity: 1 }))
        query('.background-overlay', [
          style({'opacity': '0'}),
        ]),
        query('.fullscreen-panel', [
          style({'transform': 'translateY(100%)'}),
        ]),
        group([
          query('.background-overlay', [animate('0.25s ease-out', style({ 'opacity':'1' }))]),
          query('.fullscreen-panel', [animate('0.35s ease-out', style({'transform': 'translateY(0px)'}))]),
        ]),
      ]
    ),
    transition(
      ':leave',
      [
        // style({height: 0, opacity: 0.4 }),
        // animate('0.23s ease-out',
        //         style({height: '90vh', opacity: 1 }))
        query('.background-overlay', [
          style({'opacity': '1'}),
        ]),
        query('.fullscreen-panel', [
          style({'transform': 'translateY(0px)'})
        ]),
        group([
          query('.background-overlay', [animate('0.25s ease-out', style({ 'opacity':'0' }))]),
          query('.fullscreen-panel', [animate('0.35s ease-out', style({'transform': 'translateY(100%)'}))]),
        ]),
      ]
    )
  ]
);

// export const cardSelectPanelAnimation =  trigger(
//   'cardSelectPanelAnimation',
//   [
//     transition(
//       ':enter',
//       [
//         // style({height: 0, opacity: 0.4 }),
//         // animate('0.23s ease-out',
//         //         style({height: '90vh', opacity: 1 }))
//         query('.dark-background-overscroll', [
//           style({'opacity': '0'}),
//         ]),
//         query('.card-select-panel-wrapper', [
//           style({'transform': 'translateY(100%)'}),
//         ]),
//         group([
//           query('.dark-background-overscroll', [animate('0.25s ease-out', style({ 'opacity':'1' }))]),
//           query('.card-select-panel-wrapper', [animate('0.42s ease-out', style({'transform': 'translateY(0px)'}))]),
//         ]),
//       ]
//     ),
//     transition(
//       ':leave',
//       [
//         // style({height: 0, opacity: 0.4 }),
//         // animate('0.23s ease-out',
//         //         style({height: '90vh', opacity: 1 }))
//         query('.dark-background-overscroll', [
//           style({'opacity': '1'}),
//         ]),
//         query('.card-select-panel-wrapper', [
//           style({'transform': 'translateY(0px)'})
//         ]),
//         group([
//           query('.dark-background-overscroll', [animate('0.25s ease-out', style({ 'opacity':'0' }))]),
//           query('.card-select-panel-wrapper', [animate('0.42s ease-out', style({'transform': 'translateY(100%)'}))]),
//         ]),
//       ]
//     )
//   ]
// );

export const navpanelAnimation =  trigger(
  'navpanelAnimation',
  [
    transition(
      ':enter',
      [
        // style({height: 0, opacity: 0.4 }),
        // animate('0.23s ease-out',
        //         style({height: '90vh', opacity: 1 }))
        query('.navpanel-overlay', [
          style({'opacity': '0'}),
        ]),
        query('.navpanel-wrapper', [
          style({'transform': 'translateY(100%)'}),
        ]),
        query('.navpanel-action-item', [
          style({'opacity': '0.6'}),
        ], { optional: true }),

        group([
          query('.navpanel-overlay', [animate('0.22s ease-out', style({ 'opacity':'1' }))]),
          query('.navpanel-action-item', [animate('0.3s 0.13s ease-out', style({ 'opacity':'1' }))], { optional: true }),
          query('.navpanel-wrapper', [animate('0.25s ease-out', style({'transform': 'translateY(0px)'}))]),
        ]),
      ]
    ),
    transition(
      ':leave',
      [
        // style({height: 0, opacity: 0.4 }),
        // animate('0.23s ease-out',
        //         style({height: '90vh', opacity: 1 }))
        query('.navpanel-overlay', [
          style({'opacity': '1'}),
        ]),
        query('.navpanel-wrapper', [
          style({'transform': 'translateY(0px)'})
        ]),
        query('.navpanel-action-item', [
          style({'opacity': '0.6'}),
        ], { optional: true }),
        group([
          query('.navpanel-action-item', [animate('0.22s  ease-out', style({ 'opacity':'0.4' }))], { optional: true }),
          query('.navpanel-overlay', [animate('0.22s ease-out', style({ 'opacity':'0' }))]),
          query('.navpanel-wrapper', [animate('0.25s ease-out', style({'transform': 'translateY(100%)'}))]),
        ]),
      ]
    ),
    transition(
      '* => hidden',
      [
        // style({height: 0, opacity: 0.4 }),
        // animate('0.23s ease-out',
        //         style({height: '90vh', opacity: 1 }))
        query('.navpanel-overlay', [
          style({'opacity': '1'}),
        ]),
        query('.navpanel-wrapper', [
          style({'transform': 'translateY(0px)'})
        ]),
        group([
          query('.navpanel-overlay', [animate('0.23s ease-out', style({ 'opacity':'0' }))]),
          query('.navpanel-wrapper', [animate('0.33s ease-out', style({'transform': 'translateY(100%)'}))]),
        ]),
      ]
    )
  ]
);

export const editCollaboratorFormAnimation =  trigger(
  'editCollaboratorFormAnimation',
  [
    transition(
      'show-all => review-collaborator',
      [
        // query('.show-all-container' , [style({opacity: '1', position: 'absolute', top: '0', width: '100%'})],),
        query('.review-collaborator-container' , [style({opacity: '0', position: 'absolute', top: '0', width: '100%' })],),
        sequence([
          // query('.show-all-container', [
          //   animate('120ms ease-out', style({ opacity: '0'}))
          // ]),
          query('.review-collaborator-container', [
            animate('400ms ease-out', style({  opacity: 1}))
          ])
        ]),
      ]
    ),
    transition(
      'review-collaborator => show-all',
      [
        // query('.show-all-container' , [style({opacity: '1', position: 'absolute', top: '0', width: '100%'})],),
        query('.review-collaborator-container' , [style({opacity: '1', position: 'absolute', top: '0', width: '100%' })],),
        sequence([
          // query('.show-all-container', [
          //   animate('120ms ease-out', style({ opacity: '0'}))
          // ]),
          query('.review-collaborator-container', [
            animate('120ms ease-out', style({  opacity: 0}))
          ])
        ]),
      ]
    ),
  ]
);

export const reviewFormAnimation =  trigger(
    'reviewFormAnimation',
    [
      transition(
        ':enter',
        [
          style({height: 0, opacity: 0.4 }),
          animate('0.23s ease-out',
                  style({height: '90vh', opacity: 1 }))
        ]
      ),
      transition(
        ':leave',
        [
          style({ height: '90vh', opacity: 1 }),
          animate('0.23s ease-out',
                  style({height: 0, opacity: 0.4 }))
        ]
      )
    ]
  );

export const hideWithDelay =
  trigger('hideWithDelay', [
    transition(
      ':leave',
      [
        style({opacity: 1 }),
        animate('500ms 2500ms ease-out',
                style({opacity: 0 }))
      ]
    ),
  ]);



// TODO: FIX THIS:
// export const fullscreenCardAnimationTransform =
// trigger('fullscreenCardAnimationTransform',   [
//   transition(
//     ':enter',
//     [
//       style({transform: 'scale(0.935, 0.84) translate(0%, -1.5%)', 'z-index': 1000}),
//       animate('0.5s ease-out',
//               style({transform: 'scale(1, 1) translate(0%, 0%)', 'z-index': 1000}))
//     ]
//   ),
//   transition(
//     ':leave',
//     [
//       style({ opacity: 1 }),
//       animate('0.3s ease-out',
//               style({opacity: 0 }))
//     ]
//   )
// ]);


export const navigationOpacityAnimation =
trigger('navigationOpacityAnimation',   [
  transition(
    ':enter',
    [
      style({opacity: '0.2'}),
      animate('0.23s ease-in', style({ opacity: '1'}))
    ]
  ),
  transition(
    ':leave',
    [
      style({opacity: '1'}),
      animate('0.23s ease-out', style({ opacity: '0'}))
    ]
  )
]);

export const fullscreenCardAnimationMovement =
trigger('fullscreenCardAnimationMovement',   [
  transition(
    ':enter',
    [
      style({transform: 'translateY(-100vh)', position: 'absolute' }),
      animate('0.21s ease-out',
              style({ transform: 'translateY(0)', position: 'absolute' }))
    ]
  ),
  transition(
    ':leave',
    [
      style({ transform: 'translateY(0)', position: 'absolute' }),
      animate('0.21s ease-out',
              style({transform: 'translateY(-100vh)', position: 'absolute' }))
    ]
  )
]);

export const modelCardAnimationToFullscreenAdvancedAnimation =
trigger('modelCardAnimationToFullscreenAdvancedAnimation',   [
  transition(
    'normal => fullscreen',
    [
      query('.cardDetails-main-img', [
        style({'top': '*'}),
      ]),
      // query('.cardDetails-main-img:after', [
      //   style({'opacity': '1'}),
      // ]),
      query('.cardDetails-wrapper', [
        style({'margin-top': '*'}),
      ]),
      query('.img-shade', [
        style({ 'height': '250px', opacity: '1' }),
      ]),
      group([
        query('.cardDetails-main-img', [animate('0.66s ease-out', style({ 'top': '0' }))]),
        query('.img-shade', [animate('0.77s ease-in', style({'height': '40px', opacity: '0'}))]),
        // query('.cardDetails-main-img:after', [animate('7.7s ease-out', style({ 'opacity': '0' }))]),
        query('.cardDetails-wrapper', [animate('0.77s ease-out', style({'margin-top': 'calc(100vh + 18px)'}))])
      ]),
      // query('.img-shade', [
      //   style({ opacity: '*', }),
      //   animate('0.3s ease-in',
      //           style({opacity: '0' }))
      // ])
    ]
  ),
  transition(
    'fullscreen => normal',
    [
      query('.cardDetails-main-img', [
        style({'top': '0'}),
      ]),
      query('.cardDetails-wrapper', [
        style({'top': '100vh', 'margin-top': '0'}),
      ]),
      query('.img-shade', [
        style({ 'height': '50px'}),
      ]),
      group([
        query('.cardDetails-main-img', [animate('0.77s ease-out', style({ 'top': 'calc(-100vh + 250px + var(--safe-area-inset-top))' }))]),
        query('.img-shade', [animate('0.77s ease-out', style({'height': '250px'}))]),
        query('.cardDetails-wrapper', [animate('0.77s ease-out', style({'top': 'calc(var(--safe-area-inset-top) + 18px)'}))]),
      ]),
    ]
  )
]);


// export const fullScreenButtonOpacityAnimation =
// trigger('fullScreenButtonOpacityAnimation',   [
//   transition(
//     ':enter',
//     [
//       query('.fullscreen-btn-close, .fullscreen-btn-more', [
// eslint-disable-next-line , max-len
//           style({'opacity': '0'}),
//           animate('0.3s ease-out',
//                   style({ 'opacity': '1' }))
//         ]),
//     ]
//   ),

// ]);


// New VARIANT;
// export const newFullscreenCardAnimationMovement =
// trigger('newFullscreenCardAnimationMovement',   [
//   transition(
//     ':enter',
//     [
//       query(':self', [
//         style({opacity: '0', 'width': 'calc(100vw - 24px)', 'height' : '250px', 'top': '-100px', left: '12px' }),
//         animate('0.15s ease-out',
//                 style({opacity: '1'}))
//       ]),
//       query(':self', [
//         style({'width': 'calc(100vw - 24px)', 'height' : '250px', 'top': '-100px', left: '12px' }),
//         animate('0.47s ease-out',
//                 style({'width': 'calc(100vw - 24px)', 'height' : '100vh', 'top': '0'}))
//       ]),
//       query(':self', [
//         style({'width': 'calc(100vw - 24px)', left: '12px' }),
//         animate('0.15s ease-out',
//                 style({'width': '100vw', left: '0' }))
//       ]),
//     ]
//   ),
//   transition(
//     ':leave',
//     [
//       style({ transform: 'translateY(0)', position: 'absolute' }),
//       animate('0.21s ease-out',
//               style({transform: 'translateY(-100vh)', position: 'absolute' }))
//     ]
//   )
// ]);

export const fullscreenCardToModelCard =
trigger('fullscreenCardToModelCard',   [
  transition(
    '* => void',
    [
      query('.fullscreen-btn-close, .fullscreen-btn-more', [style({ opacity: '1', })]),
      query('.cardDetails-fullscreen-wrapper', [style({ left: '0', 'width': '100vw', 'height': '100vh', top: '0', 'border-radius': '0' })]),
      group([
        query('.fullscreen-btn-close, .fullscreen-btn-more', [animate('0.3s ease-out', style({ 'opacity': '0' }))]),
        query('.cardDetails-fullscreen-wrapper', [animate('0.26s ease-out',
          style({ left: '12px', 'width': 'calc(100vw - 24px)', 'height': `calc(100vh - ${NAVBAR_HEIGHT_SUM} - var(--safe-area-inset-top) - var(--safe-area-inset-bottom))`, top: `calc(var(--safe-area-inset-top) + ${NAVBAR_HEIGHT_TOP})`, 'border-radius': '12px' }))
        ]),
      ]),
      query('.cardDetails-fullscreen-wrapper', [
        style({ opacity: '*', }),
        animate('0.15s ease-in',
                style({opacity: '0' }))
      ]),
    ]
  ),
  transition(
    'void => *',
    [
      query('.fullscreen-btn-close, .fullscreen-btn-more', [style({ opacity: '0', })]),
      query('.cardDetails-fullscreen-wrapper', [
        // eslint-disable-next-line max-len
        style({ left: '12px', 'width': 'calc(100vw - 24px)', 'height': `calc(100vh - ${NAVBAR_HEIGHT_SUM} - var(--safe-area-inset-top) - var(--safe-area-inset-bottom))`, top: `calc(var(--safe-area-inset-top) + ${NAVBAR_HEIGHT_TOP})`, 'border-radius': '12px', 'opacity': '0'}),
        animate('0.15s ease-in',
                style({opacity: '1' }))
      ]),
      query('.cardDetails-fullscreen-wrapper', [
      // eslint-disable-next-line max-len
        style({ left: '12px', 'width': 'calc(100vw - 24px)', 'height': `calc(100vh - ${NAVBAR_HEIGHT_SUM} - var(--safe-area-inset-top) - var(--safe-area-inset-bottom))`, top: `calc(var(--safe-area-inset-top) + ${NAVBAR_HEIGHT_TOP})`, 'border-radius': '12px' }),
        animate('0.26s ease-out',
                style({ left: '0', 'width': '100vw', 'height': '100vh', top: '0', 'border-radius': '0' }))
      ]),
      query('.fullscreen-btn-close, .fullscreen-btn-more', [animate('0.3s ease-in', style({'opacity': '1'}))]),
    ]
  )
]);


// export const fullscreenCardAnimation =
// trigger('fullscreenCardAnimation', [
//   state('in', style({ transform: 'translateX(0)', opacity: 1 })),
//   // transition('void => *', [
//   //   style({ transform: 'translateX(-120%)', opacity: 0 }),
//   //   animate('0.3s ease-out')
//   // ]),
//   transition('* => void', [
//     animate('0.3s ease-out', style({ transform: 'translateX(100%)', opacity: 0 }))
//   ])
// ]);

export const cardFeedAnimation =
trigger('cardFeedAnimation',   [
  // transition('cardFeed => cardDetails', [
  //   query('app-card-details', [
  //     style({ opacity: '1', width: '100vw', height: '100vh', position: 'absolute', top: '0', 'z-index': 2})
  //   ]),
  //   query(':enter .cardDetails-main-img', [
  // eslint-disable-next-line , max-len
  //     style({ top: `calc(var(--safe-area-inset-top) + ${NAVBAR_HEIGHT_TOP})`, left: '12px', height: `calc(100vh - ${NAVBAR_HEIGHT_SUM} - var(--safe-area-inset-top) - var(--safe-area-inset-bottom))`, width: 'calc(100vw - 24px)', 'background-position-y': 'top',  'border-radius': '12px'})
  //   ]),
  //   query(':enter .cardDetails-wrapper', [style({'margin-top': 'calc(100vh + 18px)'})]),

  //   // query(':leave', [
  //   //   style({  position: 'relative',  'z-index': 5})
  //   // ]),
  //   // query(':leave', animateChild()),
  //   group([
  //     // query(':leave', [
  //     //   animate('250ms ease-out', style({ opacity: '0'}))
  //     // ]),
  //     query(':enter', [
  //       animate('250ms ease-out', style({ opacity: '1'}))
  //     ]),

  //   ]),
  //   group([
  //     query(':enter .cardDetails-main-img', [
  //       animate('770ms ease-out', style({ top: 'calc(-100vh + 250px + var(--safe-area-inset-top))',  height: '100vh',  'background-position-y': 'bottom', 'border-radius': '0'}))
  //     ]),
  //     query('.cardDetails-wrapper',
  //       [animate('770ms ease-out', style({'margin-top': 'calc(var(--safe-area-inset-top) + 18px)'})
  //     )])
  //   ]),
  //   query(':enter .cardDetails-main-img', [
  //     animate('170ms ease-out', style({ left: '0', width: '100vw'}))
  //   ])
  // ]),
  // transition('cardDetails => cardFeed', [
    // query(':enter', [
    //   style({ opacity: '0.9', display: 'block', position: 'absolute', top: '0'})
    // ]),
  //   query(':leave', [
  //     style({ opacity: '1', display: '*', position: '*'})
  //   ]),
  //   query(':leave .cardDetails-main-img', [
  //     style({ top: '*', left: '*', height: '*', width: '*', 'background-position-y': '*',  'border-radius': '*'})
  //   ]),
  //   query(':leave .cardDetails-wrapper', [
  //     style({ 'margin-top': '*'})
  //   ]),

  //   query(':leave .img-shade', [
  //     style({ opacity: '1' }),
  //   ]),

  //   query(':leave .cardDetails-main-img', [
  //     animate('170ms ease-out', style({ left: '12px', width: 'calc(100vw - 24px)'}))
  //   ]),

  //   group([
  //     query(':leave .cardDetails-main-img', [
  // eslint-disable-next-line max-len
  //       animate('770ms ease-out', style({ top: `calc(var(--safe-area-inset-top) + ${NAVBAR_HEIGHT_TOP})`, height: `calc(100vh - ${NAVBAR_HEIGHT_SUM} - var(--safe-area-inset-top) - var(--safe-area-inset-bottom))`, 'background-position-y': 'top',  'border-radius': '12px'}))
  //     ]),
  //     query(':leave .cardDetails-wrapper', [
  //       animate('770ms ease-out', style({ 'margin-top': 'calc(100vh + 18px)' }))
  //     ]),
  //     query(':leave .img-shade', [
  //       animate('770ms ease-out', style({ 'opacity': '0.9' }))
  //     ]),
  //   ]),

  //   group([
  //     // query(':enter', [
  //     //   animate('50ms ease-out', style({ opacity: '1'}))
  //     // ]),
  //     query(':leave', [
  //       animate('500ms ease-out', style({ opacity: '0'}))
  //     ])
  //   ])
  // ]),
  transition('cardFeed => cardFullscreenPreview', [
      // query('.fullscreen-btn-close, .fullscreen-btn-more', [style({ opacity: '0', })]),
      query('.cardDetails-fullscreen-wrapper', [
        // eslint-disable-next-line max-len
        style({ left: '12px', 'width': 'calc(100vw - 24px)', 'height': `calc(100vh - ${NAVBAR_HEIGHT_SUM} - var(--safe-area-inset-top) - var(--safe-area-inset-bottom))`, top: `calc(var(--safe-area-inset-top) + ${NAVBAR_HEIGHT_TOP})`, 'border-radius': '12px', 'opacity': '0'}),
        animate('0.15s ease-in',
                style({opacity: '1' }))
      ]),
      query('.cardDetails-fullscreen-wrapper', [
      // eslint-disable-next-line max-len
        style({ left: '12px', 'width': 'calc(100vw - 24px)', 'height': `calc(100vh - ${NAVBAR_HEIGHT_SUM} - var(--safe-area-inset-top) - var(--safe-area-inset-bottom))`, top: `calc(var(--safe-area-inset-top) + ${NAVBAR_HEIGHT_TOP})`, 'border-radius': '12px' }),
        animate('0.26s ease-out',
                style({ left: '0', 'width': '100vw', 'height': '100vh', top: '0', 'border-radius': '0' }))
      ]),
      // query('.fullscreen-btn-close, .fullscreen-btn-more', [animate('0.3s ease-in', style({'opacity': '1'}))]),
    ]
  ),
  transition('cardFullscreenPreview => cardFeed', [
      // query('.fullscreen-btn-close, .fullscreen-btn-more', [style({ opacity: '1', })]),
      query('.cardDetails-fullscreen-wrapper', [style({ left: '0', 'width': '100vw', 'height': '100vh', top: '0', 'border-radius': '0' })]),
      group([
        // query('.fullscreen-btn-close, .fullscreen-btn-more', [animate('0.3s ease-out', style({ 'opacity': '0' }))]),
        query('.cardDetails-fullscreen-wrapper', [animate('0.26s ease-out',
          style({ left: '12px', 'width': 'calc(100vw - 24px)', 'height': `calc(100vh - ${NAVBAR_HEIGHT_SUM} - var(--safe-area-inset-top) - var(--safe-area-inset-bottom))`, top: `calc(var(--safe-area-inset-top) + ${NAVBAR_HEIGHT_TOP})`, 'border-radius': '12px' }))
        ]),
      ]),
      query('.cardDetails-fullscreen-wrapper', [
        style({ opacity: '*', }),
        animate('0.15s ease-in',
                style({opacity: '0' }))
      ]),
    ]
  ),

  transition(
    'cardDetails => cardFullscreenPreview', [
      query(':enter', [
        style({'opacity': '0'}),
      ]),
      query('.cardDetails-main-img', [
        style({'top': '*'}),
      ]),
      // query('.cardDetails-main-img:after', [
      //   style({'opacity': '1'}),
      // ]),
      query('.cardDetails-wrapper', [
        style({'margin-top': '*'}),
      ]),
      query('.img-shade', [
        style({ 'height': '250px', opacity: '1' }),
      ]),
      group([
        query('.cardDetails-main-img', [animate('0.66s ease-out', style({ 'top': '0' }))]),
        query('.img-shade', [animate('0.77s ease-in', style({'height': '40px', opacity: '0'}))]),
        // query('.cardDetails-main-img:after', [animate('7.7s ease-out', style({ 'opacity': '0' }))]),
        query('.cardDetails-wrapper', [animate('0.77s ease-out', style({'margin-top': 'calc(100vh + 18px)'}))])
      ]),
      query(':enter', [
        animate('300ms ease-in', style({ opacity: '1'}))
      ])
    ]
  ),
  transition(
    'cardFullscreenPreview => cardDetails', [
      query('.cardDetails-main-img', [
        style({'top': '0'}),
      ]),
      query('.cardDetails-wrapper', [
        style({'top': '100vh', 'margin-top': '0'}),
      ]),
      query('.img-shade', [
        style({ 'height': '50px'}),
      ]),
      group([
        query('.cardDetails-main-img', [animate('0.77s ease-out', style({ 'top': 'calc(-100vh + 250px + var(--safe-area-inset-top))' }))]),
        query('.img-shade', [animate('0.77s ease-out', style({'height': '250px'}))]),
        query('.cardDetails-wrapper', [animate('0.77s ease-out', style({'top': 'calc(var(--safe-area-inset-top) + 18px)'}))]),
      ]),
    ]
  )


]);


export const cardAnimation =
trigger('cardAnimation', [
  transition('* => true', [
      query('.card-inner-wrapper', [
        style({'height': '250px'}),
      ]),
      // query(':leave', [
      //   style({'opacity': '1'}),
      // ]),
      // query(':enter', [
      //   style({'opacity': '0'}),
      // ]),
      // query('.cardDetails-wrapper', [
      //   style({'top': '100vh', 'margin-top': '0'}),
      // ]),
      // query('.img-shade', [
      //   style({ 'height': '50px'}),
      // ]),
      group([
        // query(':leave', [animate('0.17s ease-out', style({ 'opacity': '0' }))]),
        query('.card-inner-wrapper', [animate('0.27s ease-out', style({ 'height': '146px' }))]),
        // query(':enter', [animate('0.27s ease-out', style({ 'opacity': '1' }))]),

        // query('.img-shade', [animate('0.77s ease-out', style({'height': '250px'}))]),
        // query('.cardDetails-wrapper', [animate('0.77s ease-out', style({'top': 'calc(var(--safe-area-inset-top) + 18px)'}))]),
      ]),
  ]),
  transition('true => false', [
    query('.card-inner-wrapper', [
      style({'height': '146px'}),
    ]),
    // query('.cardDetails-wrapper', [
    //   style({'top': '100vh', 'margin-top': '0'}),
    // ]),
    // query('.img-shade', [
    //   style({ 'height': '50px'}),
    // ]),
    group([
      query('.card-inner-wrapper', [animate('0.27s ease-out', style({ 'height': '250px' }))]),
      // query('.img-shade', [animate('0.77s ease-out', style({'height': '250px'}))]),
      // query('.cardDetails-wrapper', [animate('0.77s ease-out', style({'top': 'calc(var(--safe-area-inset-top) + 18px)'}))]),
    ]),
  ]),
]);


export const cardFeedNavbarAnimation =
trigger('cardFeedNavbarAnimation', [
  transition('cardDetails => cardFeed', [
      query(':leave ' , [style({display: 'none'})],),
      query(':enter' , [style({opacity: '0' })],),
      sequence([
        query(':leave', [
          animate('900ms ease-out', style({ opacity: '0'}))
        ]),
        query(':enter', [
          animate('300ms ease-out', style({  opacity: 1}))
        ])
      ]),
  ])
]);

export const navbarDelayAnimation =
trigger('navbarDelayAnimation', [
  transition('* => true', [
      query('.navbar-footer-wrapper' , [style({opacity: '1' })],),
      query('.navbar-footer-wrapper', [
        animate('10ms 2000ms ease-out', style({  opacity: 0}))
      ])
  ]),
]);

export const homeOverscrollAnimation =
trigger('homeOverscrollAnimation', [
  transition('* => true', [
      query(':self' , [style({opacity: '0' })],),
      query(':self', [
        animate('400ms ease-in-out', style({  opacity: 1}))
      ])
  ]),
    transition('true => void', [
      query(':self' , [style({opacity: '1' })],),
      query(':self', [
        animate('750ms ease-in-out', style({  opacity: 0}))
      ])
  ]),
]);

export const libraryCardsGroundFadeOutAnimation =
  trigger('libraryCardsGroundFadeOutAnimation', [
    transition('* => true', [
        query(':self' , [style({opacity: '1' })],),
        group([
          query(':self', [
            animate('800ms cubic-bezier(0,1.38,1,.85)', style({  opacity: 0}))
          ])
        ])
    ]),
]);

export const collectionDetailsFadeAnimation =
  trigger('collectionDetailsFadeAnimation', [
    transition('* => true', [
        query(':self' , [style({opacity: '0' })],),
        group([
          query(':self', [
            animate('750ms ease-in-out', style({  opacity: 1}))
          ])
        ])
    ]),
    transition('true => *', [
      query('.collection-details-content, .dark-background-overscroll' , [style({opacity: '1' })],),
      sequence([
        query('.collection-details-content, .dark-background-overscroll', [
          animate('650ms ease-in-out', style({ opacity: '0'}))
        ]),
      ]),
  ]),
]);

export const modelFeedAnimation =
  trigger('modelFeedAnimation', [
    transition(':enter', [
        query('.feed-overlap .model-card-wrapper' , [style({opacity: '0' })],),
        group([
          query('.feed-overlap .model-card-wrapper', [
            animate('750ms ease-in-out', style({  opacity: 1}))
          ])
        ])
    ]),
    transition('* => void', [
      query('.feed-overlap .model-card-wrapper' , [style({opacity: '1' })],),
      sequence([
        query('.feed-overlap .model-card-wrapper', [
          animate('650ms ease-in-out', style({ opacity: '0'}))
        ]),
      ]),
  ]),
]);

export const navbarmodelFeedAnimation =  trigger('navbarmodelFeedAnimation', [
    transition('default => modelFeed', [
      // query(':leave', [
      //   style({
      //     opacity: '1',
      //   })
      // ]),
      // query(':leave', animateChild()),
      // sequence([
      //   query(':leave', [
      //     animate('500ms ease-out', style({ opacity: '0'}))
      //   ]),
      // ]),
      query('.navbar-background-gradient', [
        style({
          opacity: '1'
        })
      ]),
      query('button', [
        style({
          opacity: '1',
        })
      ]),
      group([
          query('.navbar-background-gradient', [
            animate('710ms ease-out', style({ opacity: '0', 'z-index': 0})),
          ]),
          query('button', [
            animate('210ms ease-out', style({  opacity: '0'}))
          ])
      ]),
  ]),
      transition('default => transitionToFeed', [
        query('.navbar-background-gradient', [
          style({
            opacity: '1',
            'z-index': 0
          })
        ]),
        query('.library-btn-left, .search-btn, .foot-main', [
          style({
            opacity: '1',
          })
        ], { optional: true }), // Make this query optional
        group([
          query('.navbar-background-gradient, .foot-main', [
            animate('710ms ease-out', style({ opacity: '0', 'z-index': 0})),
          ], { optional: true }), // Make this query optional
          query('.library-btn-left, .search-btn', [
            animate('910ms ease-out', style({ opacity: '0'}))
          ], { optional: true }) // Make this query optional
        ]),
    ]),
]);

export const feedWebCollectionDetailsAnimation =
trigger('feedWebCollectionDetailsAnimation', [
  transition('* => true', [
      // query('.collection-details-content' , [style({top: `calc(100vh - 395px`})],),
      query('.collection-details-content' , [style({top: `calc(100vh - var(--animation-height-shift-fix)`})],),
      query('.collection-details-description-background' , [style({opacity: '0'})],),
      query('.dark-background-overscroll' , [style({opacity: '0', bottom: '-500px' })],),
      query('.models-thumb-wrapper' , [style({'background-color': '#0000001f' })],),
      group([
        query('.collection-details-description-background', [
          animate('250ms ease-out', style({  opacity: 1}))
        ]),
        query('.models-thumb-wrapper', [
          animate('250ms ease-out', style({  'background-color': '#000000fc'}))
        ]),
      ]),
      group([
        query('.collection-details-content', [
          animate('500ms ease-out', style({  top: 0}))
        ]),
        query('.dark-background-overscroll', [
          animate('500ms ease-out', style({  opacity: 1, bottom: '-100px'}))
        ])
      ])
  ]),

  transition('* => void', [
    query('.collection-details-content' , [style({opacity: '1' })],),
    query('.dark-background-overscroll' , [style({opacity: '1' })],),
    query('.collection-details-description-background' , [style({opacity: '1'})],),
    query('.models-thumb-wrapper' , [style({opacity: '1'})],),
    query('.collDetails-card-title' , [style({opacity: '1' })],),
    query('.collDetails-card-about-short' , [style({opacity: '1' })],),
    query('.collDetails-button-column-wrapper' , [style({opacity: '1' })], { optional: true }),

    group([
      query('.collection-details-content, .dark-background-overscroll, .collection-details-description-background, .models-thumb-wrapper ',  [
        animate('550ms ease-out', style({opacity: '0'}))
      ]),
      query('.collDetails-card-title, .collDetails-card-about-short, .collDetails-button-column-wrapper',  [
        animate('250ms ease-out', style({opacity: '0'}))
      ]),
    ]),
  ]),
]);

export const feedCollectionDetailsAnimation =
trigger('feedCollectionDetailsAnimation', [
  transition('* => true', [
      query('.collection-details-content' , [style({top: `calc(100vh - ${COLLECTION_DETAILS_HEIGHT} - var(--safe-area-inset-bottom)/2)`})],),
      query('.collection-details-description-background' , [style({opacity: '0'})],),
      query('.dark-background-overscroll' , [style({opacity: '0', bottom: '-500px' })],),
      query('.models-thumb-wrapper' , [style({'background-color': '#0000001f' })],),
      group([
        query('.collection-details-description-background', [
          animate('250ms ease-out', style({  opacity: 1}))
        ]),
        query('.models-thumb-wrapper', [
          animate('250ms ease-out', style({  'background-color': '#000000fc'}))
        ]),
      ]),
      group([
        query('.collection-details-content', [
          animate('500ms ease-out', style({  top: 0}))
        ]),
        query('.dark-background-overscroll', [
          animate('500ms ease-out', style({  opacity: 1, bottom: '-100px'}))
        ])
      ])
  ]),

  transition('* => void', [
    query('.collection-details-content' , [style({opacity: '1' })],),
    query('.dark-background-overscroll' , [style({opacity: '1' })],),
    query('.collection-details-description-background' , [style({opacity: '1'})],),
    query('.models-thumb-wrapper' , [style({opacity: '1'})],),
    query('.collDetails-card-title' , [style({opacity: '1' })],),
    query('.collDetails-card-about-short' , [style({opacity: '1' })],),
    query('.collDetails-button-column-wrapper' , [style({opacity: '1' })], { optional: true }),

    group([
      query('.collection-details-content, .dark-background-overscroll, .collection-details-description-background, .models-thumb-wrapper ',  [
        animate('550ms ease-out', style({opacity: '0'}))
      ]),
      query('.collDetails-card-title, .collDetails-card-about-short, .collDetails-button-column-wrapper',  [
        animate('250ms ease-out', style({opacity: '0'}))
      ]),
    ]),
  ]),

  // transition('* => void', [
  //   query('.collection-details-content' , [style({opacity: '1', top: '0' })],),
  //   query('.dark-background-overscroll' , [style({opacity: '1', bottom: '-100px' })],),
  //   query('.collection-details-description-background' , [style({opacity: '1'})],),
  //   query('.models-thumb-wrapper' , [style({opacity: '1', 'background-color': '#000000fc'})],),

  //   group([
  //     query('.collection-details-content', [
  //       animate('700ms ease-out', style({top: `calc(100vh - ${COLLECTION_DETAILS_HEIGHT} - var(--safe-area-inset-bottom)/2)`}))
  //     ]),
  //     query('.dark-background-overscroll', [
  //       animate('700ms ease-out', style({  opacity: 0, bottom: '-500px'}))
  //     ])
  //   ]),
  //   group([
  //     query('.models-thumb-wrapper', [
  //       animate('300ms ease-out', style({  opacity: 0,  'background-color': '#0000001f'}))
  //     ]),
  //     query('.collection-details-description-background', [
  //       animate('300ms ease-out', style({  opacity: 0}))
  //     ]),

  //   ]),

  // ]),

]);

// Routable animations
export const slideInAnimation =
  trigger('routeAnimations', [
    transition('homePageAnimation => editCollectionPageAnimation, homePageAnimation => libraryPageAnimation, libraryPageAnimation => editCollectionPageAnimation', [
      query(':enter, :leave', [
        style({
          position: 'absolute',
          top: 0,
          right: 0,
        })
      ]),
      query(':enter', [
        style({ opacity: '0'})
      ]),
      query(':leave', [
        style({ opacity: '1'})
      ]),
      group([
        query(':enter', [
          animate('600ms ease-in-out', style({  opacity: '1'}))
        ]),
        query(':leave', [
          animate('300ms ease-out', style({  opacity: '0'}))
        ]),
      ]),
    ]),
    transition('libraryPageAnimation => homePageAnimation, editCollectionPageAnimation => homePageAnimation', [
      query(':enter, :leave', [
        style({
          position: 'absolute',
          top: 0,
          right: 0,
        })
      ]),
      query(':enter', [
        style({ opacity: '0'})
      ]),
      query(':leave', [
        style({ opacity: '1'})
      ]),
      group([
        query(':enter', [
          animate('600ms ease-out', style({  opacity: '1'}))
        ]),
        query(':leave', [
          animate('300ms ease-out', style({  opacity: '0'}))
        ]),
      ]),
    ]),
    // added 100ms timeout for proper scroll logic functionality to avoid blinks;
    transition('editCollectionPageAnimation => libraryPageAnimation', [
      query(':enter, :leave', [
        style({
          position: 'absolute',
          top: 0,
          right: 0,
        })
      ]),
      query(':enter', [
        style({ opacity: '0'})
      ]),
      query(':leave', [
        style({ opacity: '1'})
      ]),
      group([
        query(':enter', [
          animate('500ms 100ms ease-out', style({  opacity: '1'}))
        ]),
        query(':leave', [
          animate('300ms ease-out', style({  opacity: '0'}))
        ]),
      ]),
    ]),

    // Transition from cardFeed to cardDetails

    transition('homePageAnimation => cardDetailsPageAnimation', [
      query(':enter', [
        style({ opacity: '1', width: '100vw', height: '100vh', position: 'absolute', top: '0', 'z-index': 2})
      ]),
      query(':enter .cardDetails-main-img', [
        // eslint-disable-next-line max-len
        style({ top: `calc(var(--safe-area-inset-top) + ${NAVBAR_HEIGHT_TOP})`, left: '12px', height: `calc(100vh - ${NAVBAR_HEIGHT_SUM} - var(--safe-area-inset-top) - var(--safe-area-inset-bottom))`, width: 'calc(100vw - 24px)', 'background-position-y': 'top',  'border-radius': '12px'})
      ]),
      query(':enter .cardDetails-wrapper', [style({'margin-top': 'calc(100vh + 18px)'})]),
      query(':leave', [
        style({  position: 'relative',  'z-index': 5})
      ]),
      // query(':leave', animateChild()),
      group([
        query(':leave', [
          animate('250ms ease-out', style({ opacity: '0'}))
        ]),
        query(':enter', [
          animate('250ms ease-out', style({ opacity: '1'}))
        ]),
      ]),
      group([
        query(':enter .cardDetails-main-img', [
          animate('770ms ease-out', style({ top: 'calc(-100vh + 250px + var(--safe-area-inset-top))',  height: '100vh',  'background-position-y': 'bottom', 'border-radius': '0'}))
        ]),
        query('.cardDetails-wrapper',
          [animate('770ms ease-out', style({'margin-top': 'calc(var(--safe-area-inset-top) + 18px)'})
        )])
      ]),
      query(':enter .cardDetails-main-img', [
        animate('170ms ease-out', style({ left: '0', width: '100vw'}))
      ])
    ]),

    // Transition from card navigation to model cards;

    transition('cardDetailsPageAnimation => homePageAnimation', [
      query(':enter', [
        style({ opacity: '0.9', display: 'block', position: 'absolute', top: '0'})
      ]),
      query(':leave', [
        style({ opacity: '1', display: 'block', position: 'relative', 'z-index' : '5'})
      ]),
      query(':leave .cardDetails-main-img', [
        style({ top: '*', left: '*', height: '*', width: '*', 'background-position-y': '*',  'border-radius': '*'})
      ]),
      query(':leave .cardDetails-wrapper', [
        style({ 'margin-top': '*'})
      ]),

      query(':leave .img-shade', [
        style({ opacity: '1' }),
      ]),

      query(':leave .cardDetails-main-img', [
        animate('170ms ease-out', style({ left: '12px', width: 'calc(100vw - 24px)'}))
      ]),

      group([
        query(':leave .cardDetails-main-img', [
          // eslint-disable-next-line max-len
          animate('770ms ease-out', style({ top: `calc(var(--safe-area-inset-top) + ${NAVBAR_HEIGHT_TOP})`, height: `calc(100vh - ${NAVBAR_HEIGHT_SUM} - var(--safe-area-inset-top) - var(--safe-area-inset-bottom))`, 'background-position-y': 'top',  'border-radius': '12px'}))
        ]),
        query(':leave .cardDetails-wrapper', [
          animate('770ms ease-out', style({ 'margin-top': 'calc(100vh + 18px)' }))
        ]),
        query(':leave .img-shade', [
          animate('770ms ease-out', style({ 'opacity': '0.9' }))
        ]),

      ]),

      group([
        query(':enter', [
          animate('50ms ease-out', style({ opacity: '1'}))
        ]),
        // query(':leave .img-shade', [
        //   animate('250ms ease-out', style({ 'opacity': '0.8' }))
        // ]),
        query(':leave', [
          animate('500ms ease-out', style({ opacity: '0'}))
        ])
      ])
      // group([
      //   query(':enter .cardDetails-main-img', [
      //     animate('770ms ease-out', style({ top: 'calc(-100vh + 250px + env(safe-area-inset-top))',  height: '100vh',  'background-position-y': 'bottom', 'border-radius': '0'}))
      //   ]),
      //   query('.cardDetails-wrapper',
      //     [animate('770ms ease-out', style({'margin-top': 'calc(env(safe-area-inset-top) + 18px)'})
      //   )])
      // ]),
      // query(':enter .cardDetails-main-img', [
      //   animate('170ms ease-out', style({ left: '0', width: '100vw'}))
      // ])
    ])


  ]);
