<mat-card style="margin: 25px">
  <mat-card-content>
    <div [formGroup]="checkoutForm">
      <mat-spinner style="margin: 0 auto;" *ngIf="!elementsOptions?.clientSecret"></mat-spinner>
      <ng-container *ngIf="elementsOptions?.clientSecret">
        <ngx-stripe-payment
          [clientSecret]="elementsOptions?.clientSecret"
        ></ngx-stripe-payment>
      </ng-container>
      <div style="margin-bottom: 20px"></div>
      <mat-divider></mat-divider>
      <div style="margin-bottom: 20px"></div>
      <div class="wrapper" style="text-align: right;padding-top: 14px;" [ngClass]="{'loading': !elementsOptions?.clientSecret}">
        <!-- <button mat-stroked-button color="warn" [disabled]="paying.value" (click)="clear()" style="margin-right: 16px">
          CLEAR
        </button> -->
        <button mat-raised-button color="accent" [disabled]="paying.value" (click)="collectPayment()">
          <ng-container *ngIf="paying.value === true; else paymentLabel">
            <span>PROCESSING...</span>
          </ng-container>
          <ng-template #paymentLabel>
            <span>PAY <span *ngIf="amount">{{ amount | currency }}</span></span>
          </ng-template>
        </button>
      </div>
    </div>
  </mat-card-content>
</mat-card>


      <!-- <div style="display: flex; flex-direction: row; gap: 10px">
        <mat-form-field appearance="outline" style="flex: 1">
          <mat-label>Name</mat-label>
          <input matInput formControlName="name" />
        </mat-form-field>
        <mat-form-field appearance="outline" style="flex: 1">
          <mat-label>Email</mat-label>
          <input matInput formControlName="email" type="email" />
        </mat-form-field>
      </div>
      <div style="display: flex; flex-direction: row; gap: 10px">
        <mat-form-field appearance="outline" style="flex: 1">
          <mat-label>Address</mat-label>
          <input matInput formControlName="address" />
        </mat-form-field>
        <mat-form-field appearance="outline" style="flex: 1">
          <mat-label>ZIP Code</mat-label>
          <input matInput formControlName="zipcode" />
        </mat-form-field>
        <mat-form-field appearance="outline" style="flex: 1">
          <mat-label>City</mat-label>
          <input matInput formControlName="city" />
        </mat-form-field>
      </div>
      <div style="margin-bottom: 10px"></div> -->
      <!-- <ng-container *ngIf="elementsOptions.clientSecret">
        <ngx-stripe-elements [stripe]="stripe" [elementsOptions]="elementsOptions">
          <ngx-stripe-payment [appearance]="appearance"></ngx-stripe-payment>
        </ngx-stripe-elements>
      </ng-container> -->
