import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-price-select-panel',
  templateUrl: './price-select-panel.component.html',
  styleUrls: ['./price-select-panel.component.scss']
})
export class PriceSelectPanelComponent implements OnInit {

  @Input() selectedPriceOption: number;
  @Output() priceSelected = new EventEmitter<number>();
  public priceOptionsPopular: string[] = ['14', '24', '44']
  public priceOptionsAdditional: string[] = ['4', '9', '19', '29', '39', '49', '59', '69', '79', '89', '99', '149', '249']

  constructor() { }

  ngOnInit(): void {
  }

  onPriceChange(price: number): void {
    this.priceSelected.emit(price);
  }

}
