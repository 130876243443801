import { WebNavService } from './../webnavigation.service';
import { UserDataService } from '../../shared/providers/user-data.service';
import { Component, OnInit, AfterViewInit, OnDestroy, ElementRef, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { UtilityFunctionsService } from 'src/app/shared/providers/utility-functions.service';
import { CardsService } from 'src/app/shared/providers/cards.service';
import { IGroupedCard, UPLOAD_STATUS } from 'src/app/models/groupedCard';
import { ICard } from 'src/app/models/card';
import { FirebaseAuthService } from 'src/app/shared/providers/firebase-auth.service';
import { YoutubeDialogComponent } from '../youtube-dialog/youtube-dialog.component';
import { UploadFileService } from 'src/app/shared/providers/upload-file.service';
import { WebUploadService } from '../webupload.service';
import { FOOTER_MODE, NAVBAR_BTN } from 'src/app/models/menu';
import { Router } from '@angular/router';
import { User } from 'firebase/auth';

@Component({
  selector: 'app-webuploadsandbox',
  templateUrl: './webuploadsandbox.component.html',
  styleUrls: ['./webuploadsandbox.component.scss']
})
export class WebuploadsandboxComponent implements OnInit, AfterViewInit, OnDestroy {
  private videoId = 'cSYix2jbzVg';
  private player;
  public errorMessage: string = 'login failed'
  public uploadSub: Subscription;
  public authSub: Subscription;
  public notLoggedSubscription: Subscription;


  public totalFiles: number = 0;
  public uploadCards: IGroupedCard[] = [];
  isUserAuthenticated: boolean;

  constructor(
    public dialog: MatDialog,
    public authService: FirebaseAuthService,
    public webUploadService: WebUploadService,
    public uploadService: UploadFileService,
    public utitltyService: UtilityFunctionsService,
    public cardService: CardsService,
    public userDataService: UserDataService,
    public router: Router,
    public webNavService: WebNavService,
    ) {

      this.uploadSub = this.webUploadService.uploadTaskNotifier.subscribe(({ groupedCard }) => {
        // Update the card's progress or add a new card
        const existingCardIndex = this.uploadCards.findIndex(card => card.id === groupedCard.id);
        if (existingCardIndex >= 0) {
          // Update existing card's progress
          this.uploadCards[existingCardIndex] = groupedCard;
        } else {
          // Add new card
          this.uploadCards.push(groupedCard);
        }
      });

      this.authSub = this.authService.firebaseUser.subscribe((user: User) => {
        this.isUserAuthenticated = false;
        if (user) this.isUserAuthenticated = true;
      });

      this.notLoggedSubscription = this.authService.notLoggedUserSubscription.subscribe(() => {
        this.isUserAuthenticated = false;
      })



    }

  ngAfterViewInit() {
  }

  ngOnInit() {

  }

  dropped(files: any) {
    // this.uploadService.uploadFilesWithPreProcessing(files);
  }

  showBrowseBtn() {
  console.log('showBrowseBtn');
  }

  handleSliderFileInput(files: Event) {
    console.log(files);
  }


  scrollToElement(id): void {
    const element = window.document.getElementById(id);
    console.log(element);
    element.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
      inline: 'nearest'
    });
  }

  openYoutubeVideo() {
    const videoUrl = 'https://www.youtube.com/embed/NpokSU1x65A';

    this.dialog.open(YoutubeDialogComponent, {
      width: '650px',
      data: { videoUrl }
    });
  }

  onEmittedUploadedFiles(files: number) {
    this.totalFiles = this.totalFiles + files;
  }

  async onSignInWithAppleClicked()  {
    try {
      await this.authService.loginWithAppleWeb();
    } catch (error) {

    }
  }

  public onWebSandboxNavbarClicked(navbarBtn: NAVBAR_BTN): void {
    console.log(navbarBtn)

    switch (true) {
      case navbarBtn === NAVBAR_BTN.LIBRARY:
      this.router.navigateByUrl('/');
      break;
      case navbarBtn === NAVBAR_BTN.HOME:
      this.router.navigateByUrl('/home');
    }
  }

  public filterUploadCards(groupedCard: IGroupedCard) {
    return groupedCard.uploadStatus.status === UPLOAD_STATUS.INPROGRESS || groupedCard.uploadStatus.status === UPLOAD_STATUS.PROCESSING
  }

  onStopUploadButtonClicked(card: IGroupedCard) {
    // this.uploadService.abortCardRender(card);
    console.log('abort card render clicked');
  }

  onUploadedCardClicked(card: ICard) {
    console.log(card);
  }

  ngOnDestroy(): void {
    this.uploadSub.unsubscribe();
    this.notLoggedSubscription.unsubscribe();
  }
}
