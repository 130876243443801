<div class="reconstruction-card" fxLayout="row wrap" fxLayoutAlign="center stretch" (click)="onRecCardClicked()" >
  <div class="rec-img" fxFlex="100px" [ngStyle]="{'background-image': 'url(' + previewImage + ')'}" ></div>
  <div class="rec-card-body" [ngClass]="{'show-border': isControlPanelVisible}" fxFlex fxLayout="column" fxLayoutAlign="space-between stretch">
    <h3 class="rec-title">{{title}}</h3>
    <div class="rec-info"  fxLayout="row" fxLayoutAlign="space-between center" >
        <div class="rec-category">{{category}}</div>
        <div class="rec-author">{{author}}</div>
    </div>
    <mat-progress-bar [ngClass]="{
                                  'danger-bar': progressValue <= 20,
                                  'warn-bar': (progressValue > 21) && (progressValue < 50),
                                  'ok-bar': progressValue > 51
                                }" [value]="progressValue">
    </mat-progress-bar>
    <div class="rec-footer" fxLayout="row" fxLayoutAlign="space-between center" >
      <div class="rec-percentage">{{progressValue}}%</div>
      <div class="rec-est-time">{{estimation}}</div>
    </div>
  </div>
  <div *ngIf="isControlPanelVisible" [@inOutAnimation] class="rec-controls-section" fxLayout="row" fxLayoutAlign="space-between center">
    <div class="rec-controls-btns" fxLayout="row" fxLayoutAlign="space-between center">
      <button mat-icon-button (click)="onRecControlsClicked($event, 'stop')">
        <mat-icon svgIcon="stop">stop</mat-icon>
      </button>
      <button mat-icon-button (click)="onRecControlsClicked($event, 'pause')">
        <mat-icon svgIcon="pause">pause</mat-icon>
      </button>
      <button mat-icon-button (click)="onRecControlsClicked($event, 'play')">
        <mat-icon svgIcon="play">play_arrow</mat-icon>
      </button>
    </div>
    <div class="rec-link-view">
      <button mat-flat-button (click)="$event.stopPropagation()">View</button>
    </div>
  </div>
</div>
