<div class="header-bar" [ngClass]="{'hasButtons' : hasButtonsInHeader() }" fxLayout="row" fxLayoutAlign="space-between center">
  <div class="header-left" (click)="titleClick($event)"><h1 *ngIf="title">{{title}}</h1></div>
  <div class="header-right" fxLayout="row">
    <h3 *ngIf="title === 'Published'" class="see-all-btn" (click)="titleClick($event)">See All</h3>
    <!-- <button *ngIf="title === 'Discover'" class="standart-btn">Analytics</button> -->
    <!-- <button *ngIf="title === 'Portfolio'" (click)="newCollectionClick()" class="standart-btn">New Collection</button> -->
    <div *ngIf="title === 'Portfolio'" class="upload-container">
      <!-- <mat-spinner *ngIf="progressBarValue" class="upload-spinner" [diameter]="30" [value]="progressBarValue" [mode]="'determinate'"></mat-spinner> -->
      <label for="file-upload" class="button-upload-container">
        <span class="library-upload">Upload</span>
      </label>
        <input type="file"
        multiple
        id="file-upload"
        class="file-input"
        accept="model/vnd.pixar.usdz, model/usd, model/usda, model/usdc"
        (change)="handleSliderFileInput($event)"
        >
    </div>
  </div>
</div>
  <div class="slide-thumb-wrapper" [ngClass]="{'size-small' : isSmallSize, 'size-standart' : !isSmallSize}" fxLayout="row nowrap" fxLayoutAlign="start start">
    <ng-container *ngIf="sourceCollections">
      <div class="slide-thumb" *ngFor="let collection of sourceCollections" fxFlex="1 1 110px" fxLayout="column" fxLayoutAlign="start center"
      (click)="onCollectionThumbClicked(collection, $event)"
      [ngStyle]="{'background-image': 'url(' + collection.collectionPosterMobile + ')'}">
    </div>
    </ng-container>

    <ng-container *ngIf="sourceCards && !isSmallSize">
      <div class="slide-thumb" *ngFor="let card of sourceCards" fxFlex="1 1 110px" fxLayout="column" fxLayoutAlign="start center"
      (click)="onCardThumbClicked(card, $event)"
      [ngClass]="{'inReview' : card.purchaseStatus === CARD_PURCHASE_STATUS.IN_REVIEW, 'rejected' : card.purchaseStatus === CARD_PURCHASE_STATUS.REJECTED }"
      [ngStyle]="{'background-image': 'url(' + card.cardPosterMobile + ')'}">
    </div>
    </ng-container>

    <ng-container *ngIf="sourceCards && isSmallSize">
      <div class="slide-thumb" *ngFor="let card of sourceCards" fxFlex="1 1 85px" fxLayout="column" fxLayoutAlign="start center"
      (click)="onCardThumbClicked(card, $event)"
      [ngClass]="{'inReview' : card.purchaseStatus === CARD_PURCHASE_STATUS.IN_REVIEW, 'rejected' : card.purchaseStatus === CARD_PURCHASE_STATUS.REJECTED }"
      [ngStyle]="{'background-image': 'url(' + card.cardPosterMobile + ')'}">
    </div>
    </ng-container>

    <ng-container *ngIf="inputCards">
      <ng-container *ngFor="let thumb of inputCards">
        <ng-container *ngIf="thumb.type === SLIDER_CARD_TYPE.STANDART">
          <div class="slide-thumb"  fxFlex="1 1 110px" fxLayout="column" fxLayoutAlign="start center"
            (click)="onThumbClick(thumb.images[0], $event)"
            [ngStyle]="{'background-image': 'url(' + thumb.images[0] + ')'}">
          </div>
        </ng-container>
        <ng-container *ngIf="thumb.type === SLIDER_CARD_TYPE.COMPOSITE">
          <div class="slide-thumb-composite" (click)="onThumbClick(thumb.images[0], $event)" fxFlex="1 1 165px"
            fxLayout="row nowrap">
            <div class="left-part" fxFlex="1 1 110px">
              <div class="thumb-img-composite img-left" [ngStyle]="{'background-image': 'url(' + thumb.images[0] + ')'}">
              </div>
            </div>
            <div class="right-part" fxFlex="1 1 55px" fxLayout="column" fxLayoutAlign="start start">
              <div class="thumb-img-composite  img-top" [ngStyle]="{'background-image': 'url(' + thumb.images[1] + ')'}"
                fxFlex="1 1 100"></div>
              <div class="thumb-img-composite  img-bottom" [ngStyle]="{'background-image': 'url(' + thumb.images[2] + ')'}"
                fxFlex="1 1 100"></div>
            </div>
          </div>
        </ng-container>
      </ng-container>
    </ng-container>
  </div>
