import { SettingsService } from './../../providers/settings.service';
import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';

import { StatusBar } from '@capacitor/status-bar';

import { CardsService } from '../../providers/cards.service';


@Component({
  selector: 'app-card-fullscreen',
  templateUrl: './card-fullscreen.component.html',
  styleUrls: ['./card-fullscreen.component.scss']
})
export class CardFullscreenComponent implements OnInit, OnDestroy {
  @Input() fullscreenImg: string = '';

  constructor(public cardService: CardsService, public settingService: SettingsService) { }

  ngOnInit() {
    if(this.settingService.devicePlatform === 'ios') {
      StatusBar.hide().catch(err => {
        console.log('err');
      }) ;
    };

  }

  ngOnDestroy() {
    if(this.settingService.devicePlatform === 'ios') {
      StatusBar.show().catch(err => {
        console.log('No implementation for status bar in web/android');
      }) ;
    }
  }

  onSwipeUp(event: Event) {
    this.cardService.triggerToggleFullscreenSubject();
  }

}
