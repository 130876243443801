     <!-- Color Inputs -->
     <div>
      <label for="color1">Color 1: (Bot)</label>
      <input id="color1" type="color" [(ngModel)]="settingsService.colors[0].base" />
      <label for="alpha1">Transparency:</label>
      <input id="alpha1" type="range" [(ngModel)]="settingsService.colors[0].alpha" min="0" max="1" step="0.01" />
    </div>

    <div>
      <label for="color2">Color 2: (Top)</label>
      <input id="color2" type="color" [(ngModel)]="settingsService.colors[1].base" />
      <label for="alpha2">Transparency:</label>
      <input id="alpha2" type="range" [(ngModel)]="settingsService.colors[1].alpha" min="0" max="1" step="0.01" />
    </div>

    <!-- Bezier Points Inputs -->
    <div *ngFor="let point of settingsService.bezierPoints; let i = index">
      <label for="bezier{{ i }}">Bezier Point {{ i + 1 }}:</label>
      <input id="bezier{{ i }}" type="number" [(ngModel)]="settingsService.bezierPoints[i]" placeholder="Bezier Point" step="0.01" />
    </div>
    <ng-container *ngIf="settingsService.applyTo === 'model-feed'">
      <label for="customPadding">Custom padding:</label>
      <input id="customPadding" type="number" [(ngModel)]="settingsService.customPadding" placeholder="Custom Padding" step="1" />
      <br>
    </ng-container>
    <ng-container *ngIf="settingsService.applyTo === 'collection-details'">
      <label for="customHeight">Custom height:</label>
      <input id="customHeight" type="number" [(ngModel)]="settingsService.customHeight" placeholder="Custom Height" step="1" />
      <br>
    </ng-container>

    <mat-checkbox [(ngModel)]="settingsService.showStyleTweaker" ><strong>Show Tweaker (You could turn on it on service page)</strong></mat-checkbox>
    <!-- Gradient Output -->
    <br><br>
    <textarea class="blacked-input-web" [(ngModel)]="settingsService.gradientData" placeholder="Gradient Output"></textarea>
    <br>

    <div>
      <label><input type="radio" [(ngModel)]="settingsService.applyTo" value="model-feed" /> Apply to Model Feed</label>
      <label><input type="radio" [(ngModel)]="settingsService.applyTo" value="collection-details" /> Apply to Collection Details</label>
    </div>
    <br>
    <!-- Apply Gradient Button -->
    <button mat-flat-button color="default" (click)="settingsService.updateStyles()">Apply styles</button>
    <br><br><br>
