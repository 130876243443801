import { SettingsService } from './../shared/providers/settings.service';
import { UtilityFunctionsService } from 'src/app/shared/providers/utility-functions.service';
import { UserDataService } from 'src/app/shared/providers/user-data.service';
import { EventEmitter, Injectable, Output } from '@angular/core';
import { FOOTER_MODE } from '../models/menu';
import { CARDS_MODE, CARD_CONTROL_BTN, CARD_PURCHASE_STATUS, CARD_TYPE, ICard, ICardAction, ICardFeedModificator, INavpanelAction, NAVPANEL_ACTION_TYPE, SWITCH_PANEL_ITEM } from '../models/card';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { DialogData, ConfirmationDialogComponent } from '../shared/components/dialog/confirmation-dialog.component';
import { FirebaseAuthService } from '../shared/providers/firebase-auth.service';
import { CardsService } from '../shared/providers/cards.service';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { User } from 'firebase/auth';

@Injectable({
  providedIn: 'root'
})
export class WebNavService {
  @Output() public webNavSwitchPanelChange: EventEmitter<SWITCH_PANEL_ITEM> = new EventEmitter<SWITCH_PANEL_ITEM>();
  @Output() public webNavForceShowDragZoneActivatedEvent: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() public webAuthPopupClosed: EventEmitter<boolean> = new EventEmitter<boolean>();


  public get notAuthorizedUserBefore(): boolean {
    return this.settingsService.getOriginalUidFromLocalstorage() === null;
  }

  public isUserAuthenticated: boolean = false;
  public footerMode: FOOTER_MODE = FOOTER_MODE.LIBRARY;
  public currentWebSwitchPanelMode: SWITCH_PANEL_ITEM = SWITCH_PANEL_ITEM.ALL;

  public addWebPanelItems: INavpanelAction[] = [
    { actionName: 'New Collection', actionType: NAVPANEL_ACTION_TYPE.NEW_COLLECTION },
    { actionName: 'Upload Model', actionType: NAVPANEL_ACTION_TYPE.UPLOAD_MODEL }
  ]

  public editCollectionMoreWebPanelItems: INavpanelAction[] = [
    { actionName: 'Edit Collection', actionType: NAVPANEL_ACTION_TYPE.EDIT_COLLECTION },
    { actionName: 'Delete Collection', actionType: NAVPANEL_ACTION_TYPE.DELETE_COLLECTION }
  ]

  public editCollectionAddWebPanelItems: INavpanelAction[] = [
    { actionName: 'Add from Library', actionType: NAVPANEL_ACTION_TYPE.ADD_FROM_LIBRARY },
    { actionName: 'Upload Model', actionType: NAVPANEL_ACTION_TYPE.UPLOAD_MODEL }
  ]

  public proftileDevAccountWebPanelItems: INavpanelAction[] = [
    { actionName: 'Settings', actionType: NAVPANEL_ACTION_TYPE.SETTINGS },
    { actionName: 'Sign Out', actionType: NAVPANEL_ACTION_TYPE.SIGN_OUT },
  ]

  public profileProdAccountWebPanelItems: INavpanelAction[] = [
    { actionName: 'Sign Out', actionType: NAVPANEL_ACTION_TYPE.SIGN_OUT },
  ]

  public collectionDetailsWebPanelItemsLiked: INavpanelAction[] = [
    {actionName: 'Remove Collection', actionType: NAVPANEL_ACTION_TYPE.LIKE_COLLECTION },
  ]

  public collectionDetailsWebPanelItemsUnliked: INavpanelAction[] = [
    {actionName: 'Add Collection', actionType: NAVPANEL_ACTION_TYPE.LIKE_COLLECTION },
  ]

  public filteredAllModels: ICard[];
  public firebaseUserSubscription: Subscription;
  public notLoggedSubscription: Subscription;

  constructor(
    public settingsService: SettingsService,
    public userDataService: UserDataService,
    public cardService: CardsService,
    public firebaseAuth: FirebaseAuthService,
    public utilityService: UtilityFunctionsService,
    public dialog: MatDialog,
    public router: Router
  ) {


    this.isUserAuthenticated = !!this.firebaseAuth.currentUser;
    this.notLoggedSubscription = this.firebaseAuth.notLoggedUserSubscription.subscribe(() => {
      this.isUserAuthenticated = false;

    })

    this.firebaseUserSubscription = this.firebaseAuth.firebaseUser.subscribe((user: User) => {
      this.isUserAuthenticated = false;
      if (user) {
        this.isUserAuthenticated = true;
      }
    });


  }

  public onWebNavSwitchPanelClick(button: SWITCH_PANEL_ITEM): void {
    this.currentWebSwitchPanelMode = button;
    this.webNavSwitchPanelChange.emit(button);
  }


  public getOptionsBtnItems(card: ICard, feedMode: CARDS_MODE, feedModificator?: ICardFeedModificator ): INavpanelAction[] {
    let action: ICardAction
    switch (true) {
      case card.purchaseStatus === undefined || card.purchaseStatus === CARD_PURCHASE_STATUS.DEFAULT_3DWAY && this.userDataService.isCardExitsInSaved(card):
        action = { card, controlAction: CARD_CONTROL_BTN.CONTROL_ACTION_FOR_LIKED }
        break;
      case card.purchaseStatus === CARD_PURCHASE_STATUS.DRAFT_LIKED:
        action = { card, controlAction: CARD_CONTROL_BTN.CONTROL_ACTION_FOR_LIKED }
        break;
      case card.purchaseStatus === CARD_PURCHASE_STATUS.DRAFT_NOT_LIKED:
        action = { card, controlAction: CARD_CONTROL_BTN.CONTROL_ACTIONS_FOR_UNLIKED }
        break;
      case feedMode === CARDS_MODE.EDIT_CARD:
        action = { card, controlAction: CARD_CONTROL_BTN.CONTROL_ACTION_FOR_EDIT_CARD }
        break;
      case card.purchaseStatus === CARD_PURCHASE_STATUS.DEFAULT_3DWAY:
        action = { card, controlAction: CARD_CONTROL_BTN.CONTROL_ACTIONS_DEFAULT }
        break;
      case card.purchaseStatus === CARD_PURCHASE_STATUS.UPLOADED:
        action = { card, controlAction: CARD_CONTROL_BTN.CONTROL_ACTION_FOR_UPLOADED }
        break;
      case card.purchaseStatus === CARD_PURCHASE_STATUS.PURCHASED:
        action = { card, controlAction: CARD_CONTROL_BTN.CONTROL_ACTIONS_FOR_PURCHASED }
        break;
      case card.purchaseStatus === CARD_PURCHASE_STATUS.IN_REVIEW:
        action = { card, controlAction: CARD_CONTROL_BTN.CONTROL_ACTION_FOR_IN_REVIEW }
        break;
      case card.purchaseStatus === CARD_PURCHASE_STATUS.REJECTED:
        action = { card, controlAction: CARD_CONTROL_BTN.CONTROL_ACTION_MORE_FOR_REJECTED }
        break;
      case card.purchaseStatus === CARD_PURCHASE_STATUS.PUBLISHED:
        action = { card, controlAction: CARD_CONTROL_BTN.CONTROL_ACTION_FOR_PUBLISHED }
        break;
      case card.purchaseStatus === CARD_PURCHASE_STATUS.REVIEW_PUBLISH_REJECT:
        action = { card, controlAction: CARD_CONTROL_BTN.CONTROL_ACTION_FOR_REVIEW_PUBLISH_REJECT }
        break;

      default:
        break;
      }
      return this.getNavpanelItemsBasedOnCardAction(action, feedModificator);
    }


  public getNavpanelItemsBasedOnCardAction(action: ICardAction, feedModificator?: ICardFeedModificator): INavpanelAction[] {
    let navpanelItems: INavpanelAction[] = [];
    console.log('card clicked', action);

    switch (action.controlAction) {
      case CARD_CONTROL_BTN.CONTROL_ACTIONS_DEFAULT:
        if(action.card.cardType === CARD_TYPE.COLLECTION) {
          const isExistInSaved = this.userDataService.isCollectionExitsInSaved(action.card.id);
          const actionText = isExistInSaved ? 'Remove from Library' : 'Add to Library';
          const actionItself = isExistInSaved ? NAVPANEL_ACTION_TYPE.UNLIKE_COLLECTION : NAVPANEL_ACTION_TYPE.LIKE_COLLECTION;
          navpanelItems = [
            {actionName: actionText, actionType: actionItself },
          ]
        } else {
          const isExistInSaved = this.userDataService.isCardExitsInSaved(action.card);
          const actionText = isExistInSaved ? 'Remove from Library' : 'Add to Library';
          navpanelItems = [
            {actionName: actionText, actionType: NAVPANEL_ACTION_TYPE.UNLIKE_CARD  },
          ]
        }

        break;
      case CARD_CONTROL_BTN.CONTROL_ACTION_FOR_REVIEW_PUBLISH_REJECT:
        // default
        navpanelItems = [
          {actionName: 'Rename Model', actionType: NAVPANEL_ACTION_TYPE.RENAME_CARD },
          {actionName: 'Delete Model', actionType: NAVPANEL_ACTION_TYPE.DELETE_CARD }
        ]
        // modificator for edit collection:
        if(feedModificator?.isFeedForEditCollection) {
          navpanelItems = [
            {actionName: 'Rename Model', actionType: NAVPANEL_ACTION_TYPE.RENAME_CARD },
            {actionName: 'Remove from Collection', actionType: NAVPANEL_ACTION_TYPE.REMOVE_CARD_FROM_COLLECTION }
          ]
        }
        break;
      case CARD_CONTROL_BTN.CONTROL_ACTION_FOR_UPLOADED:
        // default
        navpanelItems = [
          {actionName: 'Rename Model', actionType: NAVPANEL_ACTION_TYPE.RENAME_CARD },
          {actionName: 'Delete Model', actionType: NAVPANEL_ACTION_TYPE.DELETE_CARD }
        ]
        // modificator for edit collection:
        if(feedModificator?.isFeedForEditCollection) {
          navpanelItems = [
            {actionName: 'Rename Model', actionType: NAVPANEL_ACTION_TYPE.RENAME_CARD },
            {actionName: 'Remove from Collection', actionType: NAVPANEL_ACTION_TYPE.REMOVE_CARD_FROM_COLLECTION }
          ]
        }
        break;
      case CARD_CONTROL_BTN.CONTROL_ACTION_FOR_EDIT_CARD:
        navpanelItems = [
        ]

        break;
      case CARD_CONTROL_BTN.CONTROL_ACTION_FOR_LIKED:
        // default
        navpanelItems = [
          {actionName: 'Remove from Library', actionType: NAVPANEL_ACTION_TYPE.UNLIKE_CARD },
        ]
        // modificator for edit collection:
        if(feedModificator?.isFeedForEditCollection) {
          navpanelItems = [
            {actionName: 'Remove from Library', actionType: NAVPANEL_ACTION_TYPE.REMOVE_CARD_FROM_COLLECTION },
          ]
        }
        break;
      case CARD_CONTROL_BTN.CONTROL_ACTIONS_FOR_PURCHASED:
        navpanelItems = [
        ]

        break;
      case CARD_CONTROL_BTN.CONTROL_ACTION_FOR_IN_REVIEW:
        navpanelItems = [
          {actionName: 'Cancel Publishing', actionType: NAVPANEL_ACTION_TYPE.CANCEL_PUBLISHING },
        ]

        break;
      case CARD_CONTROL_BTN.CONTROL_ACTION_REJECTED:
        navpanelItems = [
        ]
        break;
      case CARD_CONTROL_BTN.CONTROL_ACTION_MORE_FOR_REJECTED:
        navpanelItems = [
          {actionName: 'Cancel Publishing', actionType: NAVPANEL_ACTION_TYPE.CANCEL_PUBLISHING },
          {actionName: 'Delete Model', actionType: NAVPANEL_ACTION_TYPE.DELETE_CARD },
        ]

        break;
      case CARD_CONTROL_BTN.CONTROL_ACTION_FOR_PUBLISHED:
        navpanelItems = [
          {actionName: 'Cancel Publishing', actionType: NAVPANEL_ACTION_TYPE.CANCEL_PUBLISHING },
        ]

        break;
      default:
        navpanelItems = [
        ]
        break;
    }
    return navpanelItems;
  }

  public getCardControlActionBasedOnNavpanelItem(action: INavpanelAction, card: ICard): ICardAction {
    let cardAction: ICardAction;
    switch (action.actionType) {
      case NAVPANEL_ACTION_TYPE.UNLIKE_CARD:
        cardAction = { card, controlAction: CARD_CONTROL_BTN.UNLIKE_DRAFT_CARD }
        break;
      case NAVPANEL_ACTION_TYPE.LIKE_COLLECTION:
        cardAction = { card, controlAction: CARD_CONTROL_BTN.LIKE_DRAFT_CARD }
        break;
      case NAVPANEL_ACTION_TYPE.UNLIKE_COLLECTION:
        cardAction = { card, controlAction: CARD_CONTROL_BTN.UNLIKE_DRAFT_CARD }
        break;
      case NAVPANEL_ACTION_TYPE.CANCEL_PUBLISHING:
        cardAction = { card, controlAction: CARD_CONTROL_BTN.CANCEL_PUBLISH }
        break;
      case NAVPANEL_ACTION_TYPE.DELETE_CARD:
        cardAction = { card, controlAction: CARD_CONTROL_BTN.DELETE_MODEL }
        break;
      case NAVPANEL_ACTION_TYPE.RENAME_CARD:
        cardAction = { card, controlAction: CARD_CONTROL_BTN.RENAME_CARD }
        break;
      case NAVPANEL_ACTION_TYPE.REMOVE_CARD_FROM_COLLECTION:
        cardAction = { card, controlAction: CARD_CONTROL_BTN.REMOVE_CARD_FROM_COLLECTION }
        break;
      default:
        console.log('cardAction not found for INavpanelAction', action);
        break;
    }
    return cardAction;
  }

  public activateDragZoneForceShow(): void {
    this.webNavForceShowDragZoneActivatedEvent.emit(true);
  }

  public deleteAccRequest() {
    const dialogConfig: MatDialogConfig<DialogData> = {
      data: {
        dialogTitle: 'Are you sure?',
        dialogText: `Your account will be deleted permanently`,
      }
    };
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(async result => {
      if (result === true) {
        console.log('delete acc confirmed');

       await this.cardService.disableUserRequest().then(async (resp) => {
            console.log('User request disabled successfully:', resp);
            this.dialog.closeAll();
            this.firebaseAuth.clearLocalStorageData();
            this.utilityService.showImportantSnackBar('Your account has been deleted successfully', 500);
            this.signOut();
        }).catch(async (err) => {
            if(err?.error?.status) {
              console.error('Error disabling user request:', err.error.status);
              this.utilityService.showErrorSnackBar('Error disabling user request:' + err.error.status, 1500);
            } else {
              console.error('Error disabling user request:', err);
              this.utilityService.showErrorSnackBar('Error disabling user request:' + err, 1500);
            }
        });

        setTimeout(() => {
        this.footerMode = FOOTER_MODE.LIBRARY;
        }, 500);
      }
    });
  }

  public async signOut() {
    this.firebaseAuth.clearLocalStorageData();
    this.firebaseAuth.logout();
    this.userDataService.cleanAllUserData();
    this.router.navigateByUrl('/?logout=true');
    // if(environment.type === 'web') {
    //   setTimeout(() => {
    //   location.reload();
    //   }, 50);
    // }
  }

}
