// NEW DEV VARIANT

export const environment = {
  type: 'web',
  production: false,
  hmr: false,
  config: {
      intercomAppId: 'dzdiosi6',
  },
  appId: 'com.dev-3dway-llc.app.dev',
  webDebugApiLoginUrl: 'http://localhost:4200',
  rollbarFullLogEnabled: true,
  showServicePageAndShowDebugData: true,
  uploadFilePostfix: '',
  universalLinkBaseUrl: 'https://3dway.io',
  purchaseValidatorUrl: 'https://validator-back-dev.3dway.io/api/v1/verify_receipt_sandbox',
  basicSubscriptionId: 'basesubscription.dev.3dway.io',
  firebaseConfig: {
    apiKey: 'AIzaSyAdg8i0_XyJIXw3J5s-7Gbv26CU6NgYJU8',
    authDomain: 'web-dev.corp.3dway.io',
    projectId: 'data-dev-dwayllc',
    storageBucket: 'models-and-scenes-dev',
    messagingSenderId: '900901699747',
    appId: '1:900901699747:web:acbc611260bd981320bf16',
    measurementId: 'G-QC8RR091NB'
  },
  stripeKey: 'pk_test_51P7VF5RxJziVH0QfLbcVsGAETxsFuANnN8fNBeZtuKFGs4E1Qpbl97KUkpeYHLw6b6mYv9h1huPu9DRAm0klNgVZ00OWOVZRJZ',
  endpoints: {
    purchaseWeb: 'https://purchases-back-dev.3dway.io/api/v1',
    purchaseIos: 'https://web-main-back-dev.3dway.io/api/v1',
    contentReview: 'https://content-review-back-dev.3dway.io/api/v1',
    webBackend: 'https://web-main-back-dev.3dway.io/api/v1',
    upload: 'https://upload-back-dev.3dway.io/api/v1',
    authUrl: 'https://auth-back-dev.3dway.io/v1/auth',
  }
 };

// // PROD VARIANT

//  export const environment = {
//   production: false,
//   hmr: false,
//   config: {
//       intercomAppId: 'dzdiosi6',
//   },
//   appId: 'com.3dway-llc.app',
//   webDebugApiLoginUrl: 'http://localhost:4200',
//   rollbarFullLogEnabled: false,
//   showServicePageAndShowDebugData: true,
//   uploadFilePostfix: '',
//   universalLinkBaseUrl: 'https://3dway.io',
//   purchaseValidatorUrl: 'https://validator-back.3dway.io/api/v1/verify_receipt_sandbox',
//   basicSubscriptionId: 'basesubscription.3dway.io',
//   firebaseConfig: {
//     apiKey: 'AIzaSyAIuEQi7FAxY96TuwkchoigXrbDkeRZs7o',
//     authDomain: 'prod-data-dwayllc.firebaseapp.com',
//     projectId: 'prod-data-dwayllc',
//     storageBucket: 'models-and-scenes-prod',
//     messagingSenderId: '526232867167',
//     appId: '1:526232867167:web:c5b9f479821f212248e6ea',
//     measurementId: 'G-ZPK3PKVBTP'
//   },
//   endpoints: {
//     purchaseIos: 'https://web-main-back.3dway.io/api/v1',
//     contentReview: 'https://content-review-back.corp.3dway.io/api/v1',
//     webBackend: 'https://web-main-back.3dway.io/api/v1',
//     upload: 'https://upload-back.3dway.io/api/v1',
//     authUrl: 'https://auth-back.3dway.io/v1/auth',
//   }
//  };
