<!-- <app-action-bar class="absolute-neg-margin"></app-action-bar> -->
<app-navbar></app-navbar>
<div class="page-wrapper" ngClass.gt-sm="gt-sm" ngClass.gt-lg="gt-lg" ngClass.lt-md="lt-md" ngClass.lt-sm="lt-sm">
  <app-location-breadcrumbs class="photos-navigation-breadcrumbs" [locationCode]="queryLocationCode"></app-location-breadcrumbs>
  <!-- <mat-spinner *ngIf="imageGallreySections.length == 0 || isPhotosLoading"></mat-spinner> -->
  <ng-container  *ngFor="let section of imageGallreySections;trackBy:trackByTrackIdSectionItem">
  <h2 class="section-date-heading">{{convertDateToMonthDayString(section.sectionDate)}}</h2>
  <div class="image-list-view" [style.height]="section.sectionHeight">
    <div *ngFor="let photo of section.sectionItems;trackBy:trackByTrackIdGalleryItem" class="image-view"
      [ngStyle]="{'height' : photo.height.toString() + 'px', 'background-image': 'url(' + photo.path + ')',  'width' : photo.width.toString() + 'px', 'transform': 'translate(' + photo.left + 'px, ' + photo.top + 'px)' }">
    </div>
  </div>
</ng-container>
</div>
