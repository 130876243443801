    <div class="navpanel-action-item" [ngClass]="navpanelAction.actionType" *ngFor="let navpanelAction of webpanelActionItems"  [hidden]="navpanelAction.optionalData?.disabled" (click)="navpanelActionItemClick(navpanelAction, $event)">
      <!-- BASIC VIEW ON WEBPANEL ACTION -->
      <!-- <ng-container *ngIf="navpanelAction.actionType !== NAVPANEL_ACTION_TYPE.UPLOAD_MODEL"> -->
      <ng-container>

        <span [ngClass]="navpanelAction.actionType" *ngFor="let word of convertToStringArray(navpanelAction.actionName)" >{{word}}&nbsp;</span>
        <!-- <span *ngIf="navpanelAction.actionType === NAVPANEL_ACTION_TYPE.NEW_COLLECTION" class="additional-span">in Portfolio</span> -->
        <svg *ngIf="navpanelAction.isSelected" class="nav-checkbox" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M1 8.94766L5.2 13.1477L15 3.34766" stroke="white" stroke-width="2" stroke-linecap="round"/>
        </svg>
      </ng-container>

      <!-- SPECIFIC CASES -->
      <!-- <ng-container *ngIf="navpanelAction.actionType === NAVPANEL_ACTION_TYPE.UPLOAD_MODEL">
         <label for="file-upload" class="button-upload-container">
           <span class="library-upload" *ngFor="let word of convertToStringArray(navpanelAction.actionName)">{{word}}&nbsp;</span>
         </label>
           <input #fileinput type="file"
           webkitdirectory=""
           multiple=""
           id="file-upload"
           class="file-input"
           (change)="webUploadService.handleFileInputWebpanel($event, navpanelAction.optionalData?.collectionId)"
           >
     </ng-container> -->


    </div>
