

export enum LOCALSTORAGE_VAR {
  CUSTOM_UID = 'customUid',
  ORIGINAL_UID = 'originalUid',
  LOG_LEVEL = 'logLevel',
  FRAME_NUMBER = 'frameNumber',
  ADDITIONAL_SETTINGS_ENABLED = 'additionalSettingsEnabled',
  SUBSCRIPTION_STATUS_CACHE = 'subscriptionStatusCache',
}
