<div class="errorpage" ngClass.lt-sm="errorpage-lt-sm" ngClass.lt-md="errorpage-lt-md" ngClass.gt-sm="errorpage-gt-sm" ngClass.gt-lg="errorpage-gt-lg" fxLayout="column" fxFlex>
  <h1 class="error-headline">
    <p>Uh-oh!</p>
    <p>That page doesn’t exist yet.</p>
  </h1>
  <div class="error-img-wrapper" fxLayout="column" fxLayoutAlign="center center" fxFlex.lt-sm="1 1 205px"  fxFlex.lt-md="1 1 268px" fxFlex.gt-sm="1 1 292px" fxFlex.gt-sm="1 1 388px">
      <img class="dimensions-image" src="/assets/images/errorpage/cat.svg">
  </div>
  <div class="error-additional-text" fxLayout="column" fxLayoutAlign="center center"  fxFlex="1 1 100">
    <p>Head to our <a routerLink="/">homepage</a> that does exist, or ask for help with our messenger in the lower-right corner.</p>
  </div>
</div>

