import { Component, OnInit, ViewEncapsulation, Output, EventEmitter } from '@angular/core';
import { Subject } from 'rxjs';


export interface IRecCard {
  previewImage: string;
  progressValue: number;
  title: string;
  category: string;
  author: string;
  estimation: string;
  locationSegments: string[];
  controlButtonName?: string;
}

@Component({
  selector: 'app-footer-menu',
  templateUrl: './footer-menu.component.html',
  styleUrls: ['./footer-menu.component.scss'],
})

export class FooterMenuComponent implements OnInit {
  public parentSubject: Subject<any> = new Subject();
  @Output() recCtrlsClicked = new EventEmitter();

  public img1: string = '/assets/images/menuImages/rec-img-1.jpg';
  public img2: string = '/assets/images/menuImages/rec-img-2.jpg';
  public img3: string = '/assets/images/menuImages/rec-img-3.jpg';
  public img4: string = '/assets/images/menuImages/rec-img-4.jpg';

  public recCards: IRecCard[] = [
    {
      previewImage: this.img2,
      progressValue: 100,
      title: 'GRIFFITH OBSERVATORY',
      category: 'ARCHITECTURE',
      author: 'PIXAR',
      estimation: '2d 16h 15m',
      locationSegments: ['California', 'Los Angeles', 'Mount Hollywood', 'Observatory Rd']
    },
    {
      previewImage: this.img3,
      progressValue: 100,
      title: 'OLD DOWNTOWN',
      category: 'ARCHITECTURE',
      author: 'PIXAR',
      estimation: '3d 6h 34m',
      locationSegments: ['California', 'Los Angeles', 'Downtown area', 'Main Rd']
    },
    {
      previewImage: this.img4,
      progressValue: 15,
      title: 'VILLA MCARTNEY',
      category: 'ARCHITECTURE',
      author: 'PIXAR',
      estimation: '2d 16h 15m',
      locationSegments: ['California', 'San Francisco', 'Sunnyvalley']
    },
    {
      previewImage: this.img2,
      progressValue: 80,
      title: 'GRIFFITH OBSERVATORY',
      category: 'ARCHITECTURE',
      author: 'PIXAR',
      estimation: '2d 16h 15m',
      locationSegments: ['California', 'Los Angeles', 'Mount Hollywood', 'Observatory Rd']
    },
    {
      previewImage: this.img1,
      progressValue: 30,
      title: 'HOLLYWOOD',
      category: 'ARCHITECTURE',
      author: 'PIXAR',
      estimation: '57m',
      locationSegments: ['California', 'Los Angeles', 'North Hollywood', 'Hollywood blvd']
    },
    {
      previewImage: this.img2,
      progressValue: 100,
      title: 'OLD DOWNTOWN',
      category: 'ARCHITECTURE',
      author: 'PIXAR',
      estimation: '3d 6h 34m',
      locationSegments: ['California', 'Los Angeles', 'Downtown area', 'Main Rd']
    },
    {
      previewImage: this.img3,
      progressValue: 15,
      title: 'VILLA MCARTNEY',
      category: 'ARCHITECTURE',
      author: 'PIXAR',
      estimation: '2d 16h 15m',
      locationSegments: ['California', 'San Francisco', 'Sunnyvalley']
    },
    {
      previewImage: this.img2,
      progressValue: 95,
      title: 'GRIFFITH OBSERVATORY',
      category: 'ARCHITECTURE',
      author: 'PIXAR',
      estimation: '2d 16h 15m',
      locationSegments: ['California', 'Los Angeles', 'Mount Hollywood', 'Observatory Rd']
    },
  ];
  constructor() { }

  ngOnInit() {
  }

  public onChildRecCardClicked(event: string): void {
    this.parentSubject.next(event);
  }

  public onChildRecCardControlsClicked(event: IRecCard): void {
    this.recCtrlsClicked.emit(event);
  }
}
