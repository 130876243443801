import { Component, EventEmitter, Inject, OnDestroy, OnInit, Output } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { UserDataService } from 'src/app/shared/providers/user-data.service';
import { INavpanelAction, NAVPANEL_ACTION_TYPE, SUBSCRIPTION_STATUS } from 'src/app/models/card';
import { DialogData, ConfirmationDialogComponent } from 'src/app/shared/components/dialog/confirmation-dialog.component';
import { WebUploadService } from '../webupload.service';
import { CardsService } from 'src/app/shared/providers/cards.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-websubscription',
  templateUrl: './websubscription.component.html',
  styleUrls: ['./websubscription.component.scss']
})
export class WebsubscriptionComponent implements OnInit, OnDestroy {
  @Output() websubscriptionclicked = new EventEmitter<INavpanelAction>();

  SUBSCRIPTION_STATUS = SUBSCRIPTION_STATUS;

  inputValue: string = '';

  heading: string = '';
  mainBtnTitle: string = 'Subscribe';
  inputPlaceHolder: string = '';
  isDisabled: boolean;
  appActivationSubscription: Subscription;

  constructor(
    public userDataService: UserDataService,
    public dialog: MatDialog,
    public uploadService: WebUploadService,
    public cardService: CardsService,
    @Inject(MAT_DIALOG_DATA) public activatedAfterStripeRedirect: boolean
  ) {
    this.isDisabled = activatedAfterStripeRedirect;
  }

  ngOnInit(): void {
    // This thing is done, when user hits 'back' btn in browser and previous disabled state will be exist forever;
    // So we cancel it on 'appActivation' hook, but not cancel if we wait for confirmation after paymenent
    this.appActivationSubscription = this.cardService.onApplicationActivationSubject.subscribe(() => {
      if(this.activatedAfterStripeRedirect) return;
      else {
        this.isDisabled = false;
      }
    });
  }

  onMainBtnClick(event: Event): void {
    event.stopPropagation();
    console.log('websubscriptionclicked' );
    if(this.uploadService.getIsNoUploadInProgress()) {
      this.websubscriptionclicked.emit({ actionName: 'Stripe Hosted Checkout', actionType: NAVPANEL_ACTION_TYPE.STRIPE_HOSTED_CHECKOUT });
      this.isDisabled = true;
    } else {
      this.openGoToStripeConfirmation();
    }

  }

  onFreeTrialClick(): void {
    this.websubscriptionclicked.emit({ actionName: 'Free trial activation', actionType: NAVPANEL_ACTION_TYPE.FREE_TRIAL_ACTIVATION });
    console.log('onFreeTrialClick' );
  }

  public openGoToStripeConfirmation() {
    const dialogConfig: MatDialogConfig<DialogData> = {
      data: {
        dialogTitle: 'Are you sure?',
        dialogText: `You have unfinished uploading models for rendering, are you sure you want to proceed for subscription right now" ? `,
        confirmBtntext: 'Yes',
        declineBtnText: 'No'
      }
    };
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result === true) {
        this.isDisabled = true;
        this.websubscriptionclicked.emit({ actionName: 'Stripe Hosted Checkout', actionType: NAVPANEL_ACTION_TYPE.STRIPE_HOSTED_CHECKOUT });
      }
    });
  }

  ngOnDestroy(): void {
    this.appActivationSubscription.unsubscribe();
  }

}
