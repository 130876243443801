import { Component, Inject, OnInit, EventEmitter, Output } from '@angular/core';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';

@Component({
  template: `
  <div class="snackbar-content">
    <div class="progress-section">
      <mat-progress-spinner mode="determinate" [value]="progressValue" diameter="20"></mat-progress-spinner>
       <h1>Render is stopping...</h1>
    </div>
    <button mat-button (click)="onCancel()">Cancel</button>
  </div>
  `,
  styles:  [`
  .snackbar-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .progress-section {
    display: flex;
    align-items: center;
  }
  mat-progress-spinner {
    margin-right: 10px;
  }
  h1 {
    margin-right: 10px;
    font-size: 14px;
    font-weight: 500;
  }
`]
})

export class StopRenderSnackBarComponent implements OnInit {
  @Output() actionTaken = new EventEmitter<boolean>();
  @Output() cancelled = new EventEmitter<void>();

  progressValue = 100;  // Start value

  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: any) {}

  ngOnInit() {
    const intervalDuration = 100; // 100ms for smoother transition
    const decrementValue = (4 * 1000) / intervalDuration; // Total duration divided by interval duration

    const interval = setInterval(() => {
      this.progressValue -= (100 / decrementValue);  // Decrement by calculated value
      if (this.progressValue <= 0) {
        clearInterval(interval);
        this.actionTaken.emit(true); // Emit false when time runs out
      }
    }, intervalDuration);
  }

  onCancel() {
    this.cancelled.emit();
  }
}
