import { GALLERY_MODE } from './../../../models/card';
import { UserDataService } from 'src/app/shared/providers/user-data.service';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ModelCardRenderedSelection, ModelCardRendered } from 'src/app/models/card';

@Component({
  selector: 'app-card-publish-panel',
  templateUrl: './card-publish-panel.component.html',
  styleUrls: ['./card-publish-panel.component.scss']
})
export class CardPublishPanelComponent implements OnInit {

  @Input() cardsToSelect: ModelCardRenderedSelection[];
  @Input() userSelectedCards: ModelCardRendered[];
  @Input() title: string;

  @Output() cardSelected = new EventEmitter<ModelCardRenderedSelection[]>();
  @Output() showCardPriceSelect = new EventEmitter<ModelCardRenderedSelection>();

  GALLERY_MODE = GALLERY_MODE


  constructor(public userDataService: UserDataService) { }

  ngOnInit(): void {
    this.userDataService.changeStateIsNavbarMainActionDisabledTo(true);
    // if(this.userSelectedCards && this.cardsToSelect) {
    //   this.cardsToSelect.forEach((currentCard) => {
    //     const isCardSelected = this.userSelectedCards.find(userSelectedCard => userSelectedCard.cardPosterMobile === currentCard.cardPosterMobile )
    //     if(isCardSelected) {
    //       currentCard.isSelected = true;
    //     }
    //     else currentCard.isSelected = false;
    //   })
    //   console.log(this.cardsToSelect)
    // }
  }

  onCardSelected(cardClicked: ModelCardRenderedSelection): void {
    console.log('onCardSelected publish panel click')



    // if(cardClicked.cardPrice === undefined || cardClicked.cardPrice === 0) {
    //   this.showCardPriceSelect.emit(cardClicked);
    //   return;
    // }

    cardClicked.isSelected = !cardClicked.isSelected;
    this.cardSelected.emit(this.cardsToSelect.filter((card) =>{ return card.isSelected }))

    if (this.cardsToSelect.filter((card) => { return card.isSelected }).length > 0) {
      this.userDataService.changeStateIsNavbarMainActionDisabledTo(false);
    } else {
      this.userDataService.changeStateIsNavbarMainActionDisabledTo(true);
    }
  }

  onPriceTagClicked(cardTagClicked: ModelCardRenderedSelection): void {
    this.showCardPriceSelect.emit(cardTagClicked);

  }

  selectAllItems(): void {
    this.cardsToSelect.forEach(card => card.isSelected = true);
    this.cardSelected.emit(this.cardsToSelect.filter((card) =>{ return card.isSelected }))
  }

  selectOrDeselectAllItems(): void {
    const isAllCardsSelected = this.cardsToSelect.filter((card) =>{ return card.isSelected}).length === this.cardsToSelect.length;
    if(isAllCardsSelected) {
      this.cardsToSelect.forEach(card => card.isSelected = false);
    } else {
      this.cardsToSelect.forEach(card => card.isSelected = true);
    }
    this.cardSelected.emit(this.cardsToSelect.filter((card) =>{ return card.isSelected }))


  }
}
