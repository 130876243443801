<ng-template [ngIf]="mode != 'navbar'">
  <div class="location-breadcrumbs-wrapper" ngClass.lt-sm="lt-sm">
    <div class="location-breadcrumb-arrow"*ngFor="let segment of locationSegments" >
      <a *ngIf="mode != 'navbar'" [routerLink]="'./'" [queryParams]="{location: segment.type, code: segment.code}" queryParamsHandling="merge">{{segment.name}}</a>
    </div>
  </div>
</ng-template>

<ng-template [ngIf]="mode === 'navbar'">
  <div class="location-breadcrumbs-wrapper" ngClass.lt-sm="lt-sm">
    <div class="location-breadcrumb-arrow"*ngFor="let segment of locationLinks" >
      <a *ngIf="mode === 'navbar'" [routerLink]="segment.url" >{{segment.name}}</a>
    </div>
  </div>
</ng-template>

