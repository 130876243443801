import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-photos-home',
  templateUrl: './photos-home.component.html',
  styleUrls: ['./photos-home.component.scss']
})
export class PhotosHomeComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
