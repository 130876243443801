import { Component, OnInit, Inject, HostListener } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UtilityFunctionsService } from 'src/app/shared/providers/utility-functions.service';

@Component({
  selector: 'app-youtube-dialog',
  templateUrl: './youtube-dialog.component.html',
  styleUrls: ['./youtube-dialog.component.scss']
})
export class YoutubeDialogComponent implements OnInit {
  videoUrl: string;
  iframeHeight: string;
  iframeWidth: string;
  showCloseBtn: boolean = false;
  btnShown: boolean = true;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<YoutubeDialogComponent>,
    public utilitiService: UtilityFunctionsService,
  ) {
    this.videoUrl = data.videoUrl;
    this.calculateIframeDimensions();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.calculateIframeDimensions();
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.showCloseBtn = true;
    }, 500);
  }

  closeModal() {
    this.btnShown = false;
    this.dialogRef.close();
  }


  calculateIframeDimensions() {
    this.iframeWidth = this.utilitiService.calculateIframeDimensions().iframeWidth;
    this.iframeHeight = this.utilitiService.calculateIframeDimensions().iframeHeight;
    // const maxWidth = 1660;
    // const maxViewportWidth = window.innerWidth * 0.6;
    // const viewportWidth = Math.min(window.innerWidth - 20, maxWidth, maxViewportWidth);
    // const maxViewportHeight = window.innerHeight * 0.6;
    // // const aspectRatio = 16 / 8.28;
    // const aspectRatio = 16 / 9;

    // if (maxViewportHeight * aspectRatio <= viewportWidth) {
    //   this.iframeHeight = maxViewportHeight + 'px';
    //   this.iframeWidth = (maxViewportHeight * aspectRatio) + 'px';
    // } else {
    //   this.iframeWidth = viewportWidth + 'px';
    //   this.iframeHeight = (viewportWidth / aspectRatio) + 'px';
    // }
    // console.log('this.iframeWidth', this.iframeWidth, 'this.iframeHeight', this.iframeHeight)
  }





}

