import { MockDataService } from './../../../shared/providers/mock-data.service';
import { Component, OnInit, AfterViewInit } from '@angular/core';
import { navigationOpacityAnimation } from 'src/app/shared/providers/animations-list';
import { ActivatedRoute } from '@angular/router';
import { ModelCardDetails } from 'src/app/models/card';
import { FOOTER_MODE } from 'src/app/models/menu';





@Component({
  selector: 'app-scene-details',
  templateUrl: './scene-details.component.html',
  styleUrls: ['./scene-details.component.scss'],
  animations: [navigationOpacityAnimation]
})
export class SceneDetailsComponent implements OnInit, AfterViewInit {
  public isNavbarVisible: boolean = true;
  public cardDetails: ModelCardDetails;
  public FOOTER_MODE = FOOTER_MODE;

  public isNavAnimationDisabled: boolean;
  public isFullscreenModeActivated: boolean;

  constructor(private mockDataService: MockDataService,
              private readonly route: ActivatedRoute,) { }

  ngOnInit() {
    this.isNavAnimationDisabled = true;
    this.cardDetails = this.mockDataService.getMockData();

    this.route.queryParamMap.subscribe(queryParams => {
      this.isFullscreenModeActivated = queryParams.get('fullscreen') === 'true' ? true : false ;
    });

  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.isNavAnimationDisabled = false;
  });
  }

  public onNavigationStatechanged(isVisible: boolean) {
    this.isNavbarVisible = isVisible;
  }

}
