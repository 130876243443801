// credits: https://github.com/yeikos/js.merge

export class Merge {

  merge(clone: boolean, ...items: any[]): any;
  merge(...items: any[]): any;
  merge(...items: any[]) {
    return this._merge(items[0] === true, false, items);
  }

  recursive(clone: boolean, ...items: any[]): any;
  recursive(...items: any[]): any;
  recursive(...items: any[]) {
    return this._merge(items[0] === true, true, items);
  }

  clone < T >(input: T): T {

    if (Array.isArray(input)) {

      const output = [];

      // eslint-disable-next-line @typescript-eslint/prefer-for-of
      for (let index = 0; index < input.length; ++index) {
        output.push(this.clone(input[index]));
      }

      return output as any;

    } else if (this.isPlainObject(input)) {

      const output: any = {};

      // eslint-disable-next-line guard-for-in
      for (const index in input) {
        output[index] = this.clone(input[index]);
      }

      return output as any;

    } else {

      return input;

    }

  }

  // eslint-disable-next-line @typescript-eslint/ban-types
  isPlainObject(input: any): input is Object {
    return input && typeof input === 'object' && !Array.isArray(input);
  }

  private _recursiveMerge(base: any, extend: any) {

    if (!this.isPlainObject(base)) {
      return extend;
    }

    // eslint-disable-next-line guard-for-in
    for (const key in extend) {
      base[key] = (this.isPlainObject(base[key]) && this.isPlainObject(extend[key])) ?
        this._recursiveMerge(base[key], extend[key]) :
        extend[key];
    }

    return base;

  }

  private _merge(isClone: boolean, isRecursive: boolean, items: any[]) {

    let result;

    if (isClone || !this.isPlainObject(result = items.shift())) {
      result = {};
    }

    // eslint-disable-next-line @typescript-eslint/prefer-for-of
    for (let index = 0; index < items.length; ++index) {

      const item = items[index];

      if (!this.isPlainObject(item)) {
        continue;
      }

      for (const key in item) {
        if (key === '__proto__') {
          continue;
        }
        const value = isClone ? this.clone(item[key]) : item[key];
        result[key] = isRecursive ? this._recursiveMerge(result[key], value) : value;
      }

    }

    return result;

  }

}
