

import { Directive, ElementRef, Renderer2, Input, OnDestroy, OnInit } from '@angular/core';
import { MediaObserver, MediaChange } from '@angular/flex-layout';
import { Subscription } from 'rxjs';

@Directive({
  selector: '[appResponsiveClass]'
})
export class ResponsiveDirective implements OnInit, OnDestroy {
  private mediaSubscription: Subscription;
  private currentClassName: string;

  @Input('appResponsiveClass') customPrefix: string;

  constructor(private mediaObserver: MediaObserver, private el: ElementRef, private renderer: Renderer2) {}

  ngOnInit(): void {
    this.mediaSubscription = this.mediaObserver.asObservable().subscribe((changes: MediaChange[]) => {
      // Handle the most recent change
      const change = changes[0];
      if (change) {
        this.updateClasses(change.mqAlias);
      }
    });
  }

  ngOnDestroy(): void {
    this.mediaSubscription.unsubscribe();
  }

  private updateClasses(mqAlias: string): void {
    const prefix = this.customPrefix || '';
    const newClassName = `${prefix}-${mqAlias}`;

    if (this.currentClassName) {
      this.renderer.removeClass(this.el.nativeElement, this.currentClassName);
    }
    this.renderer.addClass(this.el.nativeElement, newClassName);
    this.currentClassName = newClassName;
  }

  // private updateClasses(mqAlias: string): void {
  //   // List of media query aliases to respond to
  //   const validAliases = ['gt-xs', 'gt-sm', 'gt-md', 'gt-lg', 'gt-xl'];

  //   // Check if the current alias is in the list of valid aliases
  //   if (validAliases.includes(mqAlias)) {
  //     const prefix = this.customPrefix || '';
  //     const newClassName = `${prefix}-${mqAlias}`;

  //     if (this.currentClassName) {
  //       this.renderer.removeClass(this.el.nativeElement, this.currentClassName);
  //     }
  //     this.renderer.addClass(this.el.nativeElement, newClassName);
  //     this.currentClassName = newClassName;
  //   }
  // }

}




