import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';


import { defineCustomElements } from '@ionic/pwa-elements/loader';

if (environment.production) {
  enableProdMode();
}

const bootstrap = () => platformBrowserDynamic().bootstrapModule(AppModule);

// if (typeof window['cordova'] !== 'undefined') {
//   document.addEventListener('deviceready', () => {
//     bootstrap();
//   }, false);
// } else {
//   bootstrap();
// }


bootstrap().catch(err => console.log(err));

// Call the element loader after the platform has been bootstrapped
defineCustomElements(window);
