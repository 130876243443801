<!-- <img *ngIf="posterSrc && showPoster" class="card-img poster-img" src="{{posterSrc}}"> -->
<div *ngIf="cardLoadProgress.length > 0 && settingsService.showServicePageAndShowDebugDataDataOnCard" class="service-label">
  <span  >{{getProgressLabel()}}</span>
  <br>
  <span>Visible Lag: {{this.getLagLabel()}}</span>
  <br>
  <span>Network: {{this.settingsService.networkStatus.connectionType}}</span>
</div>
<!-- <picture *ngIf="posterSrc && showPoster" class="card-img poster-img">
  <source srcset="{{posterSrc}}" type="image/webp">
  <source [srcset]="getFallbackSrc()" type="image/jpeg">
  <img  class="card-img poster-img" src="{{posterSrc}}">
</picture> -->
<div class="video-overlay"></div>
<video [ngClass]="{'canPlay' : videoCanPlay}" #target (canplaythrough)="onCanPlayThrough()" (loadstart)="onLoadStart($event)" (loadeddata)="onLoadedData()" (loadedmetadata)="onLoadedMetadata()" (progress)="onProgress($event)"    muted="true" loop="true" preload="none" playsinline  style="object-position:center;">
  <source src="{{videoSrc}}" type="video/mp4">
</video>
