import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  CanActivate,
  Router
} from '@angular/router';
import { Observable } from 'rxjs';

const availableWorkspaces: string[] = ['pixar', 'disney', 'tesla'];

@Injectable({
  providedIn: 'root'
})


export class AuthGuard implements CanActivate {
  constructor(private router: Router) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    const requestedWorkspace: string[] = next.paramMap.getAll('workspaceName');
    if (requestedWorkspace.length === undefined || requestedWorkspace.length === 0) {
        this.redirectTo404();
    }
    // eslint-disable-next-line max-len
    return availableWorkspaces.find((workspaceName) => workspaceName === requestedWorkspace[0]) ? this.processRouting(next) : this.redirectTo404();
  }

  redirectTo404(): boolean {
    this.router.navigateByUrl('/404');
    return false;
  }

  processRouting(nextRoute: ActivatedRouteSnapshot): boolean {
    if (nextRoute.url.length === 1) {
      this.router.navigateByUrl(nextRoute.paramMap.getAll('workspaceName')[0].toString() + '/models');
    }
    return true;
  }
}
