import { Component, OnInit, ViewChild, inject } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { StripePaymentElementComponent, StripeService } from 'ngx-stripe';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { PaymentIntent } from '@stripe/stripe-js';
import { CardsService } from 'src/app/shared/providers/cards.service';
import { StripeElementsOptions } from '@stripe/stripe-js';
import { UtilityFunctionsService } from 'src/app/shared/providers/utility-functions.service';
import { FirebaseAuthService } from 'src/app/shared/providers/firebase-auth.service';

@Component({
  selector: 'app-webstripepayment',
  templateUrl: './webstripepayment.component.html',
  styleUrls: ['./webstripepayment.component.scss']
})

export class WebstripepaymentComponent implements OnInit {
  @ViewChild(StripePaymentElementComponent)
  paymentElement!: StripePaymentElementComponent;

  clientId: string;
  clientSecret: string;

  paying = new BehaviorSubject<boolean>(false);



  checkoutForm = this.fb.group({
    name: ['Ricardo', [Validators.required]],
    email: ['support@ngx-stripe.dev', [Validators.required]],
    address: ['Av. Ramon Nieto 313B 2D', [Validators.required]],
    zipcode: ['36205', [Validators.required]],
    city: ['Vigo', [Validators.required]],
    amount: [999, [Validators.required, Validators.pattern(/\d+/)]],
  });

  appearance: any = {
    theme: 'stripe',
    labels: 'floating',
    variables: {
      colorPrimary: '#673ab7',
    },
  };

  elementsOptions: StripeElementsOptions = {
    locale: 'en',

  };
  uid: string;


  get amount() {
    const amountValue = this.checkoutForm.get('amount')?.value;
    if (!amountValue || amountValue < 0) return 0;

    return Number(amountValue) / 100;
  }

  constructor(
    public stripe: StripeService,
    public cardsService: CardsService,
    public http: HttpClient,
    private fb: UntypedFormBuilder,
    private dialog: MatDialog,
    public utilityService: UtilityFunctionsService,
    public firebaseAuth: FirebaseAuthService
  ) { }

  async ngOnInit() {
    const amount = this.checkoutForm.get('amount')?.value;

      this.cardsService.createPurchaseIntent().then((pi) => {
        console.log('pi', pi);
          this.clientSecret = pi.clientSecret;
          this.elementsOptions.clientSecret = this.clientSecret;
      });

      this.uid = await this.firebaseAuth.getUserUid();
  }


  clear() {
    this.checkoutForm.patchValue({
      name: '',
      email: '',
      address: '',
      zipcode: '',
      city: '',
    });
  }

  collectPayment() {
    if (this.paying.value || this.checkoutForm.invalid) return;
    this.paying.next(true);

    // const { name, email, address, zipcode, city } = this.checkoutForm.getRawValue();

    this.stripe
      .confirmPayment({
        elements: this.paymentElement.elements,
        confirmParams: {
          payment_method_data: {
            billing_details: {
              name: this.uid,
              // name: name as string,
              // email: email as string,
              // address: {
              //   line1: address as string,
              //   postal_code: zipcode as string,
              //   city: city as string,
              // },
            },
          },
        },
        redirect: 'if_required',
      })
      .subscribe({
        next: (result) => {
          this.paying.next(false);
          if (result.error) {
            console.log(result.error);
          } else if (result.paymentIntent.status === 'succeeded') {
            console.log('Payment processed successfully');
            this.utilityService.showImportantSnackBar('Payment processed successfully', 0);
            // this.dialog.open(WebpopupdialogComponent, {
            //   data: {
            //     type: 'success',
            //     message: 'Payment processed successfully',
            //   },
            // });
          }
        },
        error: (err) => {
          this.paying.next(false);
          console.log(err);
          // this.dialog.open(WebpopupdialogComponent, {
          //   data: {
          //     type: 'error',
          //     message: err.message || 'Unknown Error',
          //   },
          // });
        },
      });
  }



}
