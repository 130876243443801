import { MobileHelperService } from './../../providers/mobile-helper.service';
import { SettingsService } from './../../providers/settings.service';
import { SplashScreen } from '@capacitor/splash-screen';
import { Component, EventEmitter, OnInit, Output, Input, OnDestroy } from '@angular/core';
import { Haptics, ImpactStyle } from '@capacitor/haptics';
import { inOutAnimation } from '../../providers/animations-list';

@Component({
  selector: 'app-splash-screen',
  templateUrl: './splash-screen.component.html',
  styleUrls: ['./splash-screen.component.scss'],
  animations: [inOutAnimation]
})
export class SplashScreenComponent implements OnInit, OnDestroy {
  @Input() showSignInForm: boolean = false;
  @Input() progressBarValue: number = 0;
  @Output() signInAppleClicked = new EventEmitter<boolean>();
  @Output() signInGoogleClicked = new EventEmitter<boolean>();
  @Output() closeClicked = new EventEmitter<boolean>();


  public isProgressBarShown = false;
  public timerInterval: number;
  public isActionActivated: boolean;

  constructor(
    public settingService: SettingsService,
    public mobileHelperService: MobileHelperService

  ) { }

  ngOnInit(): void {
    // show progress bar after 2 sec;
    // setTimeout(() => {
    //   this.isProgressBarShown = true;

    //   let timePassed: number = 0;

    //   this.timerInterval = window.setInterval(() => {
    //     console.log('trigger ', timePassed)

    //     timePassed = timePassed + 50;
    //     if(timePassed/100 <= 100) {
    //       this.progressBarValue = this.progressBarValue + 0.5;
    //     }
    //     else {
    //       clearInterval(this.timerInterval);
    //     }
    //   }, 50);
    // }, 1500);

    // in case any error show after 12 secs;
    setTimeout(() => {
      this.showSignInForm = true;
      this.isProgressBarShown = false;
    }, 300);
  }

  closeBtnClicked(): void {
    if(this.settingService.devicePlatform === 'ios') {
      Haptics.impact({
        style: ImpactStyle.Light
      });
    }
    setTimeout(() => {
      this.closeClicked.emit();
      console.log('onCloseClicked Click');
    }, 100);
  }

  showSplash(): void {
    console.log('Splash clicked')
      SplashScreen.show();
      setTimeout(() => {
          SplashScreen.hide();
      }, 3000);
  }
  onSignInWithAppleClick(): void {
    if(this.settingService.devicePlatform === 'ios') {
      Haptics.impact({
        style: ImpactStyle.Light
      });
    }
    setTimeout(() => {
      this.isActionActivated = true;
      this.signInAppleClicked.emit();
      console.log('onSignInWithAppleClick Click');
    }, 100);
  }

  onSignInWithGoogleClick(): void {
    setTimeout(() => {
      this.isActionActivated = true;
      this.signInGoogleClicked.emit();
      console.log('onSignInWithGoogleClick Click');
    }, 100);
  }

  ngOnDestroy(): void {
    clearInterval(this.timerInterval);
    if(!this.isActionActivated) {
      this.mobileHelperService.mobileAuthPopupClosed.emit(true);
    }
  }

}
