import { ICollaborator } from './../../models/collaboration';
import { DEFAULT_USER_COLLECTION_ID, ICardCollection, ICardCollectionMockSource } from 'src/app/models/collection';
import { CARD_PURCHASE_STATUS, ICardMockSource, IThumbCard, ModelCardService, SLIDER_CARD_TYPE } from './../../models/card';
import { IRecCard } from './../components/footer-menu/footer-menu.component';
import { Injectable, OnInit } from '@angular/core';
import { Router, UrlTree, UrlSegment } from '@angular/router';
import { CARD_TYPE, ICard, ModelCardRendered, ModelCardDetails } from 'src/app/models/card';
import { environment } from 'src/environments/environment';

const mockBaseUrl = 'storage-dev.3dway.io';

@Injectable({
  providedIn: 'root'
})
export class MockDataService {

  constructor(private router: Router) {
    this.mockCardsDiscovery = this.fillMockedCardsDiscoverFeed();
  }

  urlTree: UrlTree;
  urlSegments: UrlSegment[];

  public sampleUSDZmodelGSpathPreview: string = 'gs://storage-3dway-dev/users/default/sample_model.usdz';
  public genericMockCardNameBasis: string = `https://${mockBaseUrl}/preview/HLS_50_2500_/`;
  public mockCardsDiscovery: ModelCardService[] = [];

  public modelCardsMockSourceShort: ModelCardRendered[] = [{
    id: 'N/A',
    modifiedDate: (new Date()).toString(),
    cardContentMobile: `https://${mockBaseUrl}/preview/MODELFEED_HLS_10_1688_1750/02/02_1688_1750k.mp4`,
    cardPosterWeb: '',
    cardContentWeb: '',
    cardType : CARD_TYPE.MODEL,
    cardCategory: 'Sculpture',
    cardAuthor: 'Natallia Slabko',
    cardTitle: 'Model 1',
    cardDescription: 'The mecha is co-piloted by two different players, the first serving as a pilot, and the second serving as a gunner.',
    cardPosterMobile: `https://${mockBaseUrl}/preview/MODELFEED_HLS_10_1688_1750/02/02_poster.webp`,
    cardPrice: this.generateRandomPrice(),
    purchaseStatus: CARD_PURCHASE_STATUS.DEFAULT_3DWAY
  },
  {
    id: 'N/A',
    modifiedDate: (new Date()).toString(),
    cardContentMobile: `https://${mockBaseUrl}/preview/MODELFEED_HLS_10_1688_1750/03/03_1688_1750k.mp4`,
    cardPosterWeb: '',
    cardContentWeb: '',
    cardType: CARD_TYPE.MODEL,
    cardCategory: 'Sculpture',
    cardAuthor: 'Natallia Slabko',
    cardTitle: 'Model 2',
    cardDescription: 'The mecha is co-piloted by two different players, the first serving as a pilot, and the second serving as a gunner.',
    cardPosterMobile: `https://${mockBaseUrl}/preview/MODELFEED_HLS_10_1688_1750/03/03_poster.webp`,
    cardPrice: this.generateRandomPrice(),
    purchaseStatus: CARD_PURCHASE_STATUS.DEFAULT_3DWAY
  }, {
    id: 'N/A',
    modifiedDate: (new Date()).toString(),
    cardContentMobile: `https://${mockBaseUrl}/preview/MODELFEED_HLS_10_1688_1750/04/04_1688_1750k.mp4`,
    cardPosterWeb: '',
    cardContentWeb: '',
    cardType : CARD_TYPE.MODEL,
    cardCategory: 'Sculpture',
    cardAuthor: 'Natallia Slabko',
    cardTitle: 'Model 3',
    cardDescription: 'The mecha is co-piloted by two different players, the first serving as a pilot, and the second serving as a gunner.',
    cardPosterMobile: `https://${mockBaseUrl}/preview/MODELFEED_HLS_10_1688_1750/04/04_poster.webp`,
    cardPrice: this.generateRandomPrice(),
    purchaseStatus: CARD_PURCHASE_STATUS.DEFAULT_3DWAY
  }, {
    id: 'N/A',
    modifiedDate: (new Date()).toString(),
    cardPosterWeb: '',
    cardContentWeb: '',
    cardContentMobile: `https://${mockBaseUrl}/preview/MODELFEED_HLS_10_1688_1750/05/05_1688_1750k.mp4`,
    cardType: CARD_TYPE.MODEL,
    cardCategory: 'Sculpture',
    cardAuthor: 'Natallia Slabko',
    cardTitle: 'Model 4',
    cardDescription: 'The mecha is co-piloted by two different players, the first serving as a pilot, and the second serving as a gunner.',
    cardPosterMobile: `https://${mockBaseUrl}/preview/MODELFEED_HLS_10_1688_1750/05/05_poster.webp`,
    cardPrice: this.generateRandomPrice(),
    purchaseStatus: CARD_PURCHASE_STATUS.DEFAULT_3DWAY
  }, {
    id: 'N/A',
    modifiedDate: (new Date()).toString(),
    cardContentMobile: `https://${mockBaseUrl}/preview/MODELFEED_HLS_10_1688_1750/06/06_1688_1750k.mp4`,
    cardPosterWeb: '',
    cardContentWeb: '',
    cardType: CARD_TYPE.MODEL,
    cardCategory: 'Sculpture',
    cardAuthor: 'Natallia Slabko',
    cardTitle: 'Model 5',
    cardDescription: 'The mecha is co-piloted by two different players, the first serving as a pilot, and the second serving as a gunner.',
    cardPosterMobile: `https://${mockBaseUrl}/preview/MODELFEED_HLS_10_1688_1750/06/06_poster.webp`,
    cardPrice: this.generateRandomPrice(),
    purchaseStatus: CARD_PURCHASE_STATUS.DEFAULT_3DWAY
  }, {
    id: 'N/A',
    modifiedDate: (new Date()).toString(),
    cardContentMobile: `https://${mockBaseUrl}/preview/MODELFEED_HLS_10_1688_1750/07/07_1688_1750k.mp4`,
    cardPosterWeb: '',
    cardContentWeb: '',
    cardType: CARD_TYPE.MODEL,
    cardCategory: 'Sculpture',
    cardAuthor: 'Natallia Slabko',
    cardTitle: 'Model 6',
    cardDescription: 'The mecha is co-piloted by two different players, the first serving as a pilot, and the second serving as a gunner.',
    cardPosterMobile: `https://${mockBaseUrl}/preview/MODELFEED_HLS_10_1688_1750/07/07_poster.webp`,
    cardPrice: this.generateRandomPrice(),
    purchaseStatus: CARD_PURCHASE_STATUS.DEFAULT_3DWAY
  }, {
    id: 'N/A',
    modifiedDate: (new Date()).toString(),
    cardContentMobile: `https://${mockBaseUrl}/preview/MODELFEED_HLS_10_1688_1750/08/08_1688_1750k.mp4`,
    cardPosterWeb: '',
    cardContentWeb: '',
    cardType: CARD_TYPE.MODEL,
    cardCategory: 'Sculpture',
    cardAuthor: 'Natallia Slabko',
    cardTitle: 'Model 7',
    cardDescription: 'The mecha is co-piloted by two different players, the first serving as a pilot, and the second serving as a gunner.',
    cardPosterMobile: `https://${mockBaseUrl}/preview/MODELFEED_HLS_10_1688_1750/08/08_poster.webp`,
    cardPrice: this.generateRandomPrice(),
    purchaseStatus: CARD_PURCHASE_STATUS.DEFAULT_3DWAY
  },
  {
    id: 'N/A',
    modifiedDate: (new Date()).toString(),
    cardContentMobile: `https://${mockBaseUrl}/preview/MODELFEED_HLS_10_1688_1750/09/09_1688_1750k.mp4`,
    cardPosterWeb: '',
    cardContentWeb: '',
    cardType: CARD_TYPE.MODEL,
    cardCategory: 'Sculpture',
    cardAuthor: 'Natallia Slabko',
    cardTitle: 'Model 8',
    cardDescription: 'The mecha is co-piloted by two different players, the first serving as a pilot, and the second serving as a gunner.',
    cardPosterMobile: `https://${mockBaseUrl}/preview/MODELFEED_HLS_10_1688_1750/09/09_poster.webp`,
    cardPrice: this.generateRandomPrice(),
    purchaseStatus: CARD_PURCHASE_STATUS.DEFAULT_3DWAY
  },
  {
    id: 'N/A',
    modifiedDate: (new Date()).toString(),
    cardContentMobile: `https://${mockBaseUrl}/preview/MODELFEED_HLS_10_1688_1750/10/10_1688_1750k.mp4`,
    cardPosterWeb: '',
    cardContentWeb: '',
    cardType: CARD_TYPE.MODEL,
    cardCategory: 'Sculpture',
    cardAuthor: 'Natallia Slabko',
    cardTitle: 'Model 9',
    cardDescription: 'The mecha is co-piloted by two different players, the first serving as a pilot, and the second serving as a gunner.',
    cardPosterMobile: `https://${mockBaseUrl}/preview/MODELFEED_HLS_10_1688_1750/10/10_poster.webp`,
    cardPrice: this.generateRandomPrice(),
    purchaseStatus: CARD_PURCHASE_STATUS.DEFAULT_3DWAY
  },
];

public mockPublishedModels = [
  {
    cardContentMobile: `https://${mockBaseUrl}/preview/MODELFEED_HLS_10_1688_1750/09/09_1688_1750k.mp4`,
    cardType: CARD_TYPE.MODEL,
    cardAuthor: 'Natallia Slabko',
    cardTitle: 'Model 8',
    cardDescription: 'The mecha is co-piloted by two different players, the first serving as a pilot, and the second serving as a gunner.',
    cardPosterMobile: `https://${mockBaseUrl}/preview/MODELFEED_HLS_10_1688_1750/09/09_poster.webp`,
    cardPrice: 0,
  },
  {
    cardContentMobile: `https://${mockBaseUrl}/preview/MODELFEED_HLS_10_1688_1750/07/07_1688_1750k.mp4`,
    cardType: CARD_TYPE.MODEL,
    cardAuthor: 'Natallia Slabko',
    cardTitle: 'Model 6',
    cardDescription: 'The mecha is co-piloted by two different players, the first serving as a pilot, and the second serving as a gunner.',
    cardPosterMobile: `https://${mockBaseUrl}/preview/MODELFEED_HLS_10_1688_1750/07/07_poster.webp`,
    cardPrice: 0
  },
  {
    cardContentMobile: `https://${mockBaseUrl}/preview/MODELFEED_HLS_10_1688_1750/06/06_1688_1750k.mp4`,
    cardType: CARD_TYPE.MODEL,
    cardAuthor: 'Natallia Slabko',
    cardTitle: 'Model 5',
    cardDescription: 'The mecha is co-piloted by two different players, the first serving as a pilot, and the second serving as a gunner.',
    cardPosterMobile: `https://${mockBaseUrl}/preview/MODELFEED_HLS_10_1688_1750/06/06_poster.webp`,
    cardPrice: 0
  },
  {
    cardContentMobile: `https://${mockBaseUrl}/preview/MODELFEED_HLS_10_1688_1750/04/04_1688_1750k.mp4`,
    cardType : CARD_TYPE.MODEL,
    cardAuthor: 'Natallia Slabko',
    cardTitle: 'Model 3',
    cardDescription: 'The mecha is co-piloted by two different players, the first serving as a pilot, and the second serving as a gunner.',
    cardPosterMobile: `https://${mockBaseUrl}/preview/MODELFEED_HLS_10_1688_1750/04/04_poster.webp`,
    cardPrice: 0
  },
  {
    cardContentMobile: `https://${mockBaseUrl}/preview/MODELFEED_HLS_10_1688_1750/02/02_1688_1750k.mp4`,
    cardType : CARD_TYPE.MODEL,
    cardAuthor: 'Natallia Slabko',
    cardTitle: 'Model 2',
    cardDescription: 'The mecha is co-piloted by two different players, the first serving as a pilot, and the second serving as a gunner.',
    cardPosterMobile: `https://${mockBaseUrl}/preview/MODELFEED_HLS_10_1688_1750/02/02_poster.webp`,
    cardPrice: 0
  }
]

public mockPurchasedModels = [
  {
    cardContentMobile: `https://${mockBaseUrl}/preview/MODELFEED_HLS_10_1688_1750/03/03_1688_1750k.mp4`,
    cardType: CARD_TYPE.MODEL,
    cardAuthor: 'Natallia Slabko',
    cardTitle: 'Model 2',
    cardDescription: 'The mecha is co-piloted by two different players, the first serving as a pilot, and the second serving as a gunner.',
    cardPosterMobile: `https://${mockBaseUrl}/preview/MODELFEED_HLS_10_1688_1750/03/03_poster.webp`,
    cardPrice: 0
  },
  {
    cardContentMobile: `https://${mockBaseUrl}/preview/MODELFEED_HLS_10_1688_1750/08/08_1688_1750k.mp4`,
    cardType: CARD_TYPE.MODEL,
    cardAuthor: 'Natallia Slabko',
    cardTitle: 'Model 7',
    cardDescription: 'The mecha is co-piloted by two different players, the first serving as a pilot, and the second serving as a gunner.',
    cardPosterMobile: `https://${mockBaseUrl}/preview/MODELFEED_HLS_10_1688_1750/08/08_poster.webp`,
    cardPrice: 0
  },
  {
    cardContentMobile: `https://${mockBaseUrl}/preview/MODELFEED_HLS_10_1688_1750/10/10_1688_1750k.mp4`,
    cardType: CARD_TYPE.MODEL,
    cardAuthor: 'Natallia Slabko',
    cardTitle: 'Model 9',
    cardDescription: 'The mecha is co-piloted by two different players, the first serving as a pilot, and the second serving as a gunner.',
    cardPosterMobile: `https://${mockBaseUrl}/preview/MODELFEED_HLS_10_1688_1750/10/10_poster.webp`,
    cardPrice: 0
  },
  {
    cardContentMobile: `https://${mockBaseUrl}/preview/MODELFEED_HLS_10_1688_1750/05/05_1688_1750k.mp4`,
    cardType: CARD_TYPE.MODEL,
    cardAuthor: 'Natallia Slabko',
    cardTitle: 'Model 4',
    cardDescription: 'The mecha is co-piloted by two different players, the first serving as a pilot, and the second serving as a gunner.',
    cardPosterMobile: `https://${mockBaseUrl}/preview/MODELFEED_HLS_10_1688_1750/05/05_poster.webp`,
    cardPrice: 0
  }
]

public basicCollectionVideo = `https://${mockBaseUrl}/preview/COLLECTION_HLS_9_OCT_RELEASE_1688_1750/LIGHNING_conv_videoplayback3/LIGHNING_conv_videoplayback3_1688_1750k.mp4`;
public basiccollectionPosterMobile = `https://${mockBaseUrl}/preview/COLLECTION_HLS_9_OCT_RELEASE_1688_1750/LIGHNING_conv_videoplayback3/LIGHNING_conv_videoplayback3_poster.webp`;

public collaboratorsMock: ICollaborator[] = [
  {
    id: '1',
    collaboratorCards: this.generateModelCardRenderedMocksBasedOnImgUrl(
      [
        `https://${mockBaseUrl}/preview/HLS_50_2500_/26/26_poster.webp`,
        `https://${mockBaseUrl}/preview/HLS_50_2500_/31/31_poster.webp`,
        `https://${mockBaseUrl}/preview/HLS_50_2500_/32/32_poster.webp`,
        `https://${mockBaseUrl}/preview/HLS_50_2500_/33/33_poster.webp`,
        `https://${mockBaseUrl}/preview/HLS_50_2500_/34/34_poster.webp`,

      ], '_1628_2500k.mp4')
  },
  {
    id: '2',
    collaboratorCards: this.generateModelCardRenderedMocksBasedOnImgUrl(
      [
        `https://${mockBaseUrl}/preview/HLS_50_2500_/30/30_poster.webp`,
        `https://${mockBaseUrl}/preview/HLS_50_2500_/01/01_poster.webp`,

      ], '_1628_2500k.mp4')
  },
  {
    id: '3',
    collaboratorCards: this.generateModelCardRenderedMocksBasedOnImgUrl(
      [
        `https://${mockBaseUrl}/preview/HLS_50_2500_/02/02_poster.webp`,
        `https://${mockBaseUrl}/preview/HLS_50_2500_/03/03_poster.webp`,
        `https://${mockBaseUrl}/preview/HLS_50_2500_/04/04_poster.webp`,
        `https://${mockBaseUrl}/preview/HLS_50_2500_/05/05_poster.webp`,


      ], '_1628_2500k.mp4')
  },
  {
    id: '4',
    collaboratorCards: this.generateModelCardRenderedMocksBasedOnImgUrl(
      [
        `https://${mockBaseUrl}/preview/HLS_50_2500_/06/06_poster.webp`,
        `https://${mockBaseUrl}/preview/HLS_50_2500_/07/07_poster.webp`,

      ], '_1628_2500k.mp4')
  }
]

public collaboratorsMock2: ICollaborator[] = [
  {
    id: '1',
    collaboratorCards: this.generateModelCardRenderedMocksBasedOnImgUrl(
      [
        `https://${mockBaseUrl}/preview/HLS_50_2500_/11/11_poster.webp`,
        `https://${mockBaseUrl}/preview/HLS_50_2500_/12/12_poster.webp`,
        `https://${mockBaseUrl}/preview/HLS_50_2500_/13/13_poster.webp`,
        `https://${mockBaseUrl}/preview/HLS_50_2500_/14/14_poster.webp`,
        `https://${mockBaseUrl}/preview/HLS_50_2500_/15/15_poster.webp`,

      ], '_1628_2500k.mp4')
  },
  {
    id: '2',
    collaboratorCards: this.generateModelCardRenderedMocksBasedOnImgUrl(
      [
        `https://${mockBaseUrl}/preview/HLS_50_2500_/09/09_poster.webp`,
        `https://${mockBaseUrl}/preview/HLS_50_2500_/08/08_poster.webp`,

      ], '_1628_2500k.mp4')
  },
  {
    id: '3',
    collaboratorCards: this.generateModelCardRenderedMocksBasedOnImgUrl(
      [
        `https://${mockBaseUrl}/preview/HLS_50_2500_/42/42_poster.webp`,
        `https://${mockBaseUrl}/preview/HLS_50_2500_/43/43_poster.webp`,
        `https://${mockBaseUrl}/preview/HLS_50_2500_/44/44_poster.webp`,
        `https://${mockBaseUrl}/preview/HLS_50_2500_/45/45_poster.webp`,


      ], '_1628_2500k.mp4')
  },
  {
    id: '4',
    collaboratorCards: this.generateModelCardRenderedMocksBasedOnImgUrl(
      [
        `https://${mockBaseUrl}/preview/HLS_50_2500_/36/36_poster.webp`,
        `https://${mockBaseUrl}/preview/HLS_50_2500_/37/37_poster.webp`,

      ], '_1628_2500k.mp4')
  }
]

public collaboratorsList: ICollaborator[] = [
  {
    id: this.generateMockCollectionId(),
    collaboratorCards: this.generateModelCardRenderedMocksBasedOnImgUrl(
      [
        `https://${mockBaseUrl}/preview/HLS_50_2500_/02/02_poster.webp`,
        `https://${mockBaseUrl}/preview/HLS_50_2500_/03/03_poster.webp`,
        `https://${mockBaseUrl}/preview/HLS_50_2500_/04/04_poster.webp`,
        `https://${mockBaseUrl}/preview/HLS_50_2500_/05/05_poster.webp`,
        `https://${mockBaseUrl}/preview/HLS_50_2500_/11/11_poster.webp`,
        `https://${mockBaseUrl}/preview/HLS_50_2500_/12/12_poster.webp`,
        `https://${mockBaseUrl}/preview/HLS_50_2500_/13/13_poster.webp`,
        `https://${mockBaseUrl}/preview/HLS_50_2500_/14/14_poster.webp`,
        `https://${mockBaseUrl}/preview/HLS_50_2500_/15/15_poster.webp`,

      ], '_1628_2500k.mp4')
  },
  {
    id: this.generateMockCollectionId(),
    collaboratorCards: this.generateModelCardRenderedMocksBasedOnImgUrl(
      [
        `https://${mockBaseUrl}/preview/HLS_50_2500_/09/09_poster.webp`,
        `https://${mockBaseUrl}/preview/HLS_50_2500_/08/08_poster.webp`,

      ], '_1628_2500k.mp4')
  },
  {
    id: this.generateMockCollectionId(),
    collaboratorCards: this.generateModelCardRenderedMocksBasedOnImgUrl(
      [
        `https://${mockBaseUrl}/preview/HLS_50_2500_/42/42_poster.webp`,
        `https://${mockBaseUrl}/preview/HLS_50_2500_/43/43_poster.webp`,
        `https://${mockBaseUrl}/preview/HLS_50_2500_/44/44_poster.webp`,
        `https://${mockBaseUrl}/preview/HLS_50_2500_/45/45_poster.webp`,


      ], '_1628_2500k.mp4')
  },
  {
    id: this.generateMockCollectionId(),
    collaboratorCards: this.generateModelCardRenderedMocksBasedOnImgUrl(
      [
        `https://${mockBaseUrl}/preview/HLS_50_2500_/36/36_poster.webp`,
        `https://${mockBaseUrl}/preview/HLS_50_2500_/37/37_poster.webp`,

      ], '_1628_2500k.mp4')
  },
  {
    id: this.generateMockCollectionId(),
    collaboratorCards: this.generateModelCardRenderedMocksBasedOnImgUrl(
      [
        `https://${mockBaseUrl}/preview/HLS_50_2500_/20/20_poster.webp`,
        `https://${mockBaseUrl}/preview/HLS_50_2500_/21/21_poster.webp`,

      ], '_1628_2500k.mp4')
  },
  {
    id: this.generateMockCollectionId(),
    collaboratorCards: this.generateModelCardRenderedMocksBasedOnImgUrl(
      [
        `https://${mockBaseUrl}/preview/HLS_50_2500_/17/17_poster.webp`,
        `https://${mockBaseUrl}/preview/HLS_50_2500_/18/18_poster.webp`,
        `https://${mockBaseUrl}/preview/HLS_50_2500_/19/19_poster.webp`,

      ], '_1628_2500k.mp4')
  },
  {
    id: this.generateMockCollectionId(),
    collaboratorCards: this.generateModelCardRenderedMocksBasedOnImgUrl(
      [
        `https://${mockBaseUrl}/preview/HLS_50_2500_/06/06_poster.webp`,
        `https://${mockBaseUrl}/preview/HLS_50_2500_/07/07_poster.webp`,
        `https://${mockBaseUrl}/preview/HLS_50_2500_/10/10_poster.webp`,
        `https://${mockBaseUrl}/preview/HLS_50_2500_/23/23_poster.webp`,
        `https://${mockBaseUrl}/preview/HLS_50_2500_/24/24_poster.webp`,


      ], '_1628_2500k.mp4')
  }
]

  public galleryCollectionDrafts: ICardCollectionMockSource[] = [
    {
      collectionId: undefined,
      collectionDescription: '',
      collectionTitle: 'Collection 1 Long Title Authors',
      collectionPosterMobile: undefined,
      collectionCards: [],
      cardImages: [
        `https://${mockBaseUrl}/preview/HLS_50_2500_/26/26_poster.webp`,
        `https://${mockBaseUrl}/preview/HLS_50_2500_/27/27_poster.webp`,
        `https://${mockBaseUrl}/preview/HLS_50_2500_/28/28_poster.webp`,

        `https://${mockBaseUrl}/preview/HLS_50_2500_/29/29_poster.webp`,
        `https://${mockBaseUrl}/preview/HLS_50_2500_/30/30_poster.webp`,
        `https://${mockBaseUrl}/preview/HLS_50_2500_/31/31_poster.webp`,
      ]
    },
    {
      collectionId: undefined,
      collectionTitle: 'Collection 2',
      collectionDescription: '',
      collectionPosterMobile: undefined,
      collectionCards: [],
      cardImages: [
        `https://${mockBaseUrl}/preview/HLS_50_2500_/32/32_poster.webp`,
        `https://${mockBaseUrl}/preview/HLS_50_2500_/33/33_poster.webp`,
        `https://${mockBaseUrl}/preview/HLS_50_2500_/34/34_poster.webp`,
      ]
    },
    {
      collectionId: undefined,
      collectionTitle: 'Collection 3',
      collectionPosterMobile: undefined,
      collectionCards: [],
      cardImages: [
        `https://${mockBaseUrl}/preview/HLS_50_2500_/35/35_poster.webp`,
      ]
    },
    {
      collectionId: undefined,
      collectionTitle: 'Collection 4 New updates',
      collectionPosterMobile: undefined,
      collectionCards: [],
      cardImages: [
        `https://${mockBaseUrl}/preview/HLS_50_2500_/07/07_poster.webp`,
        `https://${mockBaseUrl}/preview/HLS_50_2500_/08/08_poster.webp`,
        `https://${mockBaseUrl}/preview/HLS_50_2500_/09/09_poster.webp`,
      ]
    },
    {
      collectionId: undefined,
      collectionTitle: 'Collection 5 Long Title Authors',
      collectionPosterMobile: undefined,
      collectionCards: [],
      cardImages: [
        `https://${mockBaseUrl}/preview/HLS_50_2500_/10/10_poster.webp`,
        `https://${mockBaseUrl}/preview/HLS_50_2500_/11/11_poster.webp`,
        `https://${mockBaseUrl}/preview/HLS_50_2500_/12/12_poster.webp`,

        `https://${mockBaseUrl}/preview/HLS_50_2500_/13/13_poster.webp`,
        `https://${mockBaseUrl}/preview/HLS_50_2500_/14/14_poster.webp`,
        `https://${mockBaseUrl}/preview/HLS_50_2500_/15/15_poster.webp`,

        `https://${mockBaseUrl}/preview/HLS_50_2500_/16/16_poster.webp`,
        `https://${mockBaseUrl}/preview/HLS_50_2500_/17/17_poster.webp`,
        `https://${mockBaseUrl}/preview/HLS_50_2500_/18/18_poster.webp`,
      ]
    },
    {
      collectionId: undefined,
      collectionTitle: 'Collection 6 Long Title Authors',
      collectionPosterMobile: undefined,
      collectionCards: [],
      cardImages: [
        `https://${mockBaseUrl}/preview/HLS_50_2500_/19/19_poster.webp`,
        `https://${mockBaseUrl}/preview/HLS_50_2500_/20/20_poster.webp`,
        `https://${mockBaseUrl}/preview/HLS_50_2500_/21/21_poster.webp`,

        `https://${mockBaseUrl}/preview/HLS_50_2500_/22/22_poster.webp`,
        `https://${mockBaseUrl}/preview/HLS_50_2500_/23/23_poster.webp`,
        `https://${mockBaseUrl}/preview/HLS_50_2500_/24/24_poster.webp`,
      ]
    },
  ]

  public galleryNotInCollection: ICardCollectionMockSource[] = [
    {
      collectionId: undefined,
      collectionTitle: 'Collection 1 Long Title Authors',
      collectionPosterMobile: undefined,
      collectionCards: [],
      cardImages: [
        `https://${mockBaseUrl}/preview/HLS_50_2500_/49/49_poster.webp`,
        `https://${mockBaseUrl}/preview/HLS_50_2500_/41/41_poster.webp`,
        `https://${mockBaseUrl}/preview/HLS_50_2500_/42/42_poster.webp`,
        `https://${mockBaseUrl}/preview/HLS_50_2500_/43/43_poster.webp`,
        `https://${mockBaseUrl}/preview/HLS_50_2500_/44/44_poster.webp`,
        `https://${mockBaseUrl}/preview/HLS_50_2500_/45/45_poster.webp`,
        `https://${mockBaseUrl}/preview/HLS_50_2500_/46/46_poster.webp`,
        `https://${mockBaseUrl}/preview/HLS_50_2500_/47/47_poster.webp`,
        `https://${mockBaseUrl}/preview/HLS_50_2500_/48/48_poster.webp`,
      ]
    },
  ]

  public thumbLikedCards: string[] = [
    `https://${mockBaseUrl}/preview/HLS_50_2500_/01/01_poster.webp`,
    `https://${mockBaseUrl}/preview/HLS_50_2500_/03/03_poster.webp`,
    `https://${mockBaseUrl}/preview/HLS_50_2500_/05/05_poster.webp`,
    `https://${mockBaseUrl}/preview/HLS_50_2500_/06/06_poster.webp`,
    `https://${mockBaseUrl}/preview/HLS_50_2500_/09/09_poster.webp`,
    `https://${mockBaseUrl}/preview/HLS_50_2500_/13/13_poster.webp`,
  ]

  public allModelsCollection: ICardCollection = {
    collectionId: undefined,
    collectionTitle: 'All Models',
    collectionPosterMobile: undefined,
    collectionCards: [],
  }

  public galleryLikedCollections: ICardCollectionMockSource[] = [
    {
      collectionId: undefined,
      collectionTitle: 'Collection 1 Long Title Authors',
      collectionPosterMobile: `https://${mockBaseUrl}/preview/COLLECTION_HLS_9_OCT_RELEASE_1688_1750/TRIMMED5_videoplayback_battlefront/TRIMMED5_videoplayback_battlefront_poster.webp`,
      collectionCards: [],
      cardImages: [
        `https://${mockBaseUrl}/preview/HLS_50_2500_/10/10_poster.webp`,
        `https://${mockBaseUrl}/preview/HLS_50_2500_/11/11_poster.webp`,
        `https://${mockBaseUrl}/preview/HLS_50_2500_/09/09_poster.webp`,
        `https://${mockBaseUrl}/preview/HLS_50_2500_/13/13_poster.webp`,
        `https://${mockBaseUrl}/preview/HLS_50_2500_/14/14_poster.webp`,
        `https://${mockBaseUrl}/preview/HLS_50_2500_/15/15_poster.webp`,
      ]
    },
    {
      collectionId: undefined,
      collectionTitle: 'Collection 2',
      collectionPosterMobile: `https://${mockBaseUrl}/preview/COLLECTION_HLS_9_OCT_RELEASE_1688_1750/TRIMMED3_videoplayback_battlefront/TRIMMED3_videoplayback_battlefront_poster.webp`,
      collectionCards: [],
      cardImages: [
        `https://${mockBaseUrl}/preview/HLS_50_2500_/40/40_poster.webp`,
        `https://${mockBaseUrl}/preview/HLS_50_2500_/41/41_poster.webp`,
        `https://${mockBaseUrl}/preview/HLS_50_2500_/42/42_poster.webp`,
        `https://${mockBaseUrl}/preview/HLS_50_2500_/43/43_poster.webp`,
      ]
    },
    {
      collectionId: undefined,
      collectionTitle: 'Collection 3',
      collectionPosterMobile: `https://${mockBaseUrl}/preview/COLLECTION_HLS_9_OCT_RELEASE_1688_1750/TRIMMED4_videoplayback_battlefront/TRIMMED4_videoplayback_battlefront_poster.webp`,
      collectionCards: [],
      cardImages: [
        `https://${mockBaseUrl}/preview/HLS_50_2500_/20/20_poster.webp`,
        `https://${mockBaseUrl}/preview/HLS_50_2500_/21/21_poster.webp`,
        `https://${mockBaseUrl}/preview/HLS_50_2500_/22/22_poster.webp`,
        `https://${mockBaseUrl}/preview/HLS_50_2500_/23/23_poster.webp`,
      ]
    },
    {
      collectionId: undefined,
      collectionTitle: 'Collection 4 New updates',
      collectionCards: [],
      collectionPosterMobile: undefined,
      cardImages: [
        `https://${mockBaseUrl}/preview/HLS_50_2500_/46/46_poster.webp`,
        `https://${mockBaseUrl}/preview/HLS_50_2500_/45/45_poster.webp`,
        `https://${mockBaseUrl}/preview/HLS_50_2500_/44/44_poster.webp`,
        `https://${mockBaseUrl}/preview/HLS_50_2500_/28/28_poster.webp`,
        `https://${mockBaseUrl}/preview/HLS_50_2500_/27/27_poster.webp`,
        `https://${mockBaseUrl}/preview/HLS_50_2500_/30/30_poster.webp`,
      ]
    },
    {
      collectionId: undefined,
      collectionTitle: 'Collection 5 Long Title Authors',
      collectionPosterMobile: undefined,
      collectionCards: [],
      cardImages: [
        `https://${mockBaseUrl}/preview/HLS_50_2500_/31/31_poster.webp`,
        `https://${mockBaseUrl}/preview/HLS_50_2500_/32/32_poster.webp`,
        `https://${mockBaseUrl}/preview/HLS_50_2500_/33/33_poster.webp`,

      ]
    },
    {
      collectionId: undefined,
      collectionTitle: 'Collection 6 Long Title Authors',
      collectionPosterMobile: `https://${mockBaseUrl}/preview/COLLECTION_HLS_9_OCT_RELEASE_1688_1750/TRIMMED_videoplayback_battlefront/TRIMMED_videoplayback_battlefront_poster.webp`,
      collectionCards: [],
      cardImages: [
        `https://${mockBaseUrl}/preview/HLS_50_2500_/34/34_poster.webp`,
        `https://${mockBaseUrl}/preview/HLS_50_2500_/35/35_poster.webp`,
        `https://${mockBaseUrl}/preview/HLS_50_2500_/36/36_poster.webp`,
        `https://${mockBaseUrl}/preview/HLS_50_2500_/37/37_poster.webp`,
        `https://${mockBaseUrl}/preview/HLS_50_2500_/38/38_poster.webp`,
        `https://${mockBaseUrl}/preview/HLS_50_2500_/39/39_poster.webp`,
      ]
    },
  ]

  // OUTDATED !!!!!!!!
  public publishedCollectionSource: ICardCollectionMockSource[] = [
    {
      collectionId: undefined,
      collectionTitle: 'Collection 1',
      collectionContentMobile: `https://${mockBaseUrl}/preview/COLLECTION_HLS_9_OCT_RELEASE_1688_1750/LIGHNING_conv_videoplayback3/LIGHNING_conv_videoplayback3_1688_1750k.mp4`,
      collectionPosterMobile: `https://${mockBaseUrl}/preview/COLLECTION_HLS_9_OCT_RELEASE_1688_1750/LIGHNING_conv_videoplayback3/LIGHNING_conv_videoplayback3_poster.webp`,
      collectionCards: [],
      cardImages: [
        `https://${mockBaseUrl}/preview/HLS_50_2500_/01/01_poster.webp`,
        `https://${mockBaseUrl}/preview/HLS_50_2500_/02/02_poster.webp`,
        `https://${mockBaseUrl}/preview/HLS_50_2500_/03/03_poster.webp`,
        `https://${mockBaseUrl}/preview/HLS_50_2500_/04/04_poster.webp`,
        `https://${mockBaseUrl}/preview/HLS_50_2500_/05/05_poster.webp`,
        `https://${mockBaseUrl}/preview/HLS_50_2500_/06/06_poster.webp`,
        `https://${mockBaseUrl}/preview/HLS_50_2500_/09/09_poster.webp`,
        `https://${mockBaseUrl}/preview/HLS_50_2500_/10/10_poster.webp`,
        `https://${mockBaseUrl}/preview/HLS_50_2500_/11/11_poster.webp`,
        `https://${mockBaseUrl}/preview/HLS_50_2500_/13/13_poster.webp`,
        `https://${mockBaseUrl}/preview/HLS_50_2500_/14/14_poster.webp`,
        `https://${mockBaseUrl}/preview/HLS_50_2500_/15/15_poster.webp`,
      ]
    },
    {
      collectionId: undefined,
      collectionTitle: 'Collection 2',
      collectionContentMobile: `https://${mockBaseUrl}/preview/COLLECTION_HLS_9_OCT_RELEASE_1688_1750/QUIXEL2_videoplayback/QUIXEL2_videoplayback_1688_1750k.mp4`,
      collectionPosterMobile: `https://${mockBaseUrl}/preview/COLLECTION_HLS_9_OCT_RELEASE_1688_1750/QUIXEL2_videoplayback/QUIXEL2_videoplayback_poster.webp`,
      collectionCards: [],
      cardImages: [
        `https://${mockBaseUrl}/preview/HLS_50_2500_/16/16_poster.webp`,
        `https://${mockBaseUrl}/preview/HLS_50_2500_/17/17_poster.webp`,
        `https://${mockBaseUrl}/preview/HLS_50_2500_/18/18_poster.webp`,
        `https://${mockBaseUrl}/preview/HLS_50_2500_/19/19_poster.webp`,
        `https://${mockBaseUrl}/preview/HLS_50_2500_/20/20_poster.webp`,
        `https://${mockBaseUrl}/preview/HLS_50_2500_/21/21_poster.webp`,
      ]
    },
    {
      collectionId: undefined,
      collectionTitle: 'Collection 3',
      collectionContentMobile: `https://${mockBaseUrl}/preview/COLLECTION_HLS_9_OCT_RELEASE_1688_1750/TRIMMED4_videoplayback_battlefront/TRIMMED4_videoplayback_battlefront_1688_1750k.mp4`,
      collectionPosterMobile: `https://${mockBaseUrl}/preview/COLLECTION_HLS_9_OCT_RELEASE_1688_1750/TRIMMED4_videoplayback_battlefront/TRIMMED4_videoplayback_battlefront_poster.webp`,
      collectionCards: [],
      cardImages: [
        `https://${mockBaseUrl}/preview/HLS_50_2500_/22/22_poster.webp`,
        `https://${mockBaseUrl}/preview/HLS_50_2500_/23/23_poster.webp`,
        `https://${mockBaseUrl}/preview/HLS_50_2500_/24/24_poster.webp`,
        `https://${mockBaseUrl}/preview/HLS_50_2500_/26/26_poster.webp`,
      ]
    },
    {
      collectionId: undefined,
      collectionTitle: 'Collection 4',
      collectionContentMobile: `https://${mockBaseUrl}/preview/COLLECTION_HLS_9_OCT_RELEASE_1688_1750/TRIMMED2_videoplayback_battlefront/TRIMMED2_videoplayback_battlefront_1688_1750k.mp4`,
      collectionPosterMobile: `https://${mockBaseUrl}/preview/COLLECTION_HLS_9_OCT_RELEASE_1688_1750/TRIMMED2_videoplayback_battlefront/TRIMMED2_videoplayback_battlefront_poster.webp`,
      collectionCards: [],
      cardImages: [
        `https://${mockBaseUrl}/preview/HLS_50_2500_/26/26_poster.webp`,
        `https://${mockBaseUrl}/preview/HLS_50_2500_/27/27_poster.webp`,
        `https://${mockBaseUrl}/preview/HLS_50_2500_/28/28_poster.webp`,
        `https://${mockBaseUrl}/preview/HLS_50_2500_/30/30_poster.webp`,
        `https://${mockBaseUrl}/preview/HLS_50_2500_/31/31_poster.webp`,
        `https://${mockBaseUrl}/preview/HLS_50_2500_/32/32_poster.webp`,
      ]
    },
    {
      collectionId: undefined,
      collectionTitle: 'Collection 4',
      collectionContentMobile: `https://${mockBaseUrl}/preview/COLLECTION_HLS_9_OCT_RELEASE_1688_1750/QUIXEL4_videoplayback/QUIXEL4_videoplayback_1688_1750k.mp4`,
      collectionPosterMobile: `https://${mockBaseUrl}/preview/COLLECTION_HLS_9_OCT_RELEASE_1688_1750/QUIXEL4_videoplayback/QUIXEL4_videoplayback_poster.webp`,
      collectionCards: [],
      cardImages: [
        `https://${mockBaseUrl}/preview/HLS_50_2500_/33/33_poster.webp`,
        `https://${mockBaseUrl}/preview/HLS_50_2500_/34/34_poster.webp`,
        `https://${mockBaseUrl}/preview/HLS_50_2500_/35/35_poster.webp`,
        `https://${mockBaseUrl}/preview/HLS_50_2500_/36/36_poster.webp`,
        `https://${mockBaseUrl}/preview/HLS_50_2500_/37/37_poster.webp`,
        `https://${mockBaseUrl}/preview/HLS_50_2500_/38/38_poster.webp`,
      ]
    },
  ]


  public purchasedCollectionSource: ICardCollectionMockSource[] = [
    {
      collectionId: undefined,
      collectionTitle: 'Welcome Bundle',
      collectionDescription: 'The ruff is a medium-sized wading bird that breeds in marshes and wet meadows across northern Eurasia.',
      collectionContentMobile: `https://${mockBaseUrl}/preview/COLLECTION_HLS_9_OCT_RELEASE_1688_1750/LIGHNING2_conv_videoplayback3/LIGHNING2_conv_videoplayback3_1688_1750k.mp4`,
      collectionPosterMobile: `https://${mockBaseUrl}/preview/COLLECTION_HLS_9_OCT_RELEASE_1688_1750/LIGHNING2_conv_videoplayback3/LIGHNING2_conv_videoplayback3_poster.webp`,
      collectionCards: [],
      cardImages: [
        `https://${mockBaseUrl}/preview/HLS_50_2500_/01/01_poster.webp`,
        `https://${mockBaseUrl}/preview/HLS_50_2500_/02/02_poster.webp`,
        `https://${mockBaseUrl}/preview/HLS_50_2500_/03/03_poster.webp`,
        `https://${mockBaseUrl}/preview/HLS_50_2500_/04/04_poster.webp`,
        `https://${mockBaseUrl}/preview/HLS_50_2500_/05/05_poster.webp`,
        `https://${mockBaseUrl}/preview/HLS_50_2500_/06/06_poster.webp`,
        `https://${mockBaseUrl}/preview/HLS_50_2500_/07/07_poster.webp`,
        `https://${mockBaseUrl}/preview/HLS_50_2500_/08/08_poster.webp`,
        `https://${mockBaseUrl}/preview/HLS_50_2500_/09/09_poster.webp`,
      ]
    },
    {
      collectionId: undefined,
      collectionTitle: 'Editors choice, April',
      collectionContentMobile: `https://${mockBaseUrl}/preview/COLLECTION_HLS_9_OCT_RELEASE_1688_1750/TRIMMED3_videoplayback_battlefront/TRIMMED3_videoplayback_battlefront_1688_1750k.mp4`,
      collectionPosterMobile: `https://${mockBaseUrl}/preview/COLLECTION_HLS_9_OCT_RELEASE_1688_1750/TRIMMED3_videoplayback_battlefront/TRIMMED3_videoplayback_battlefront_poster.webp`,
      collectionCards: [],
      cardImages: [
        `https://${mockBaseUrl}/preview/HLS_50_2500_/33/33_poster.webp`,
        `https://${mockBaseUrl}/preview/HLS_50_2500_/34/34_poster.webp`,
        `https://${mockBaseUrl}/preview/HLS_50_2500_/35/35_poster.webp`,
        `https://${mockBaseUrl}/preview/HLS_50_2500_/36/36_poster.webp`,
        `https://${mockBaseUrl}/preview/HLS_50_2500_/37/37_poster.webp`,
        `https://${mockBaseUrl}/preview/HLS_50_2500_/38/38_poster.webp`,
      ]
    },
  ]

  public romeMock: ModelCardDetails = {
    preview: {
      activeImage: '/assets/images/scenesPage/scenes-preview-big.png',
      previewImages: [
        '/assets/images/scenesPage/scenes_small_1.png',
        '/assets/images/scenesPage/scenes-preview-big.png',
        '/assets/images/scenesPage/scenes_small_3.png',
        '/assets/images/scenesPage/scenes_small_1.png',
        '/assets/images/scenesPage/scenes_small_2.png',
        '/assets/images/scenesPage/scenes_small_3.png',
      ]
    },
    detailsRatingValue: 4.9,
    detailsNumberOfVotes: '13.4K',
    detailsPrice: '$399.99',
    modifiedDate: (new Date()).toString(),
    detailsFullDescription: `According to legend, Rome was founded in 753 BC by twin sons Romulus and Remus who were raised by a she-wolf.
    During its twelve-century history, the Roman civilization shifted from a monarchy to an oligarchic republic to a immense empire.
    Since then it has been continously inhabited, and, as headquaters first of the Roman Empire and then of the Roman Catholic Church, it has had an immense impact on the world.

    Rome began as an Iron Age hut village, founded in the mid-8th century BC.

    In 616, the Romans' sophisticated Etruscan neighbours seized power, but were ousted in 509, the inception of the Roman Republic. It conquered most of the rest of Italy, then turned its attentions overseas, and by the 1st century BC, ruled Spain, North Africa and Greece.
    The expansion of the Roman Empire provided opportunities for power-hungry individuals, and the clashing of egos led to colaps of democracy.`,
    id: 'N/A',
    cardContentMobile: '/assets/images/scenesPage/scenes-preview-big.png',
    cardPosterMobile: '',
    cardPosterWeb: '',
    cardContentWeb: '',
    cardType: CARD_TYPE.MODEL,
    cardAuthor: 'Pixar',
    cardTitle: 'Building Rome in a day',
    cardDescription: `According to legend, Rome was founded in 753 BC by twin sons Romulus and Remus who were raised by a she-wolf.
    During its twelve-century history, the Roman civilization shifted from a monarchy to an oligarchic republic to a immense empire.
    Since then it has been continously inhabited, and, as headquaters first of the Roman Empire.`,
    cardPrice: 0,
    cardCategory: 'Sculpture',
    feedbackBlock: [
      {
        message: `Just bought these and they are amazing. Thank you for this, I would say it might be a little overpriced,
           but I do have a question. Would it be possible for you to do any facial animations? If so this would definatly be perfect!`,
        rating: 5,
        date: 'Jul 9',
        author: 'Alex Stark'
      },
      {
        message: `Good work. That would be great to have at least the possibility to move the eyes (separate the eye globles, add bones on it ?),
         and to make them blink eyes. Then more haircuts/more faces (not warriors :) )`,
        rating: 5,
        date: 'Jul 25',
        author: 'Alex Stark'
      },
      {
        message: `Just bought these and they are amazing. Thank you for this, I would say it might be a little overpriced,
           but I do have a question. Would it be possible for you to do any facial animations? If so this would definatly be perfect!`,
        rating: 5,
        date: 'Jul 9',
        author: 'Alex Stark'
      },
    ],
    informationBlock: [
      {name: 'Published' , value: '28 May 2019' },
      {name: 'Triangles' , value: '97.6k' },
      {name: 'Verticles' , value: '164.1k' },
    ],
    purchaseStatus: CARD_PURCHASE_STATUS.DEFAULT_3DWAY
  };

  public carlMock: ModelCardDetails = {
    preview: {
      activeImage: '/assets/images/homePage/carl_fredrecksen.png',
      previewImages: undefined
    },
    detailsRatingValue: 4.6,
    detailsNumberOfVotes: '13.4K',
    detailsPrice: '$39.99',
    modifiedDate: (new Date()).toString(),
    detailsFullDescription: `According to legend, Rome was founded in 753 BC by twin sons Romulus and Remus who were raised by a she-wolf.
    During its twelve-century history, the Roman civilization shifted from a monarchy to an oligarchic republic to a immense empire.
    Since then it has been continously inhabited, and, as headquaters first of the Roman Empire and then of the Roman Catholic Church, it has had an immense impact on the world.

    Rome began as an Iron Age hut village, founded in the mid-8th century BC.

    In 616, the Romans' sophisticated Etruscan neighbours seized power, but were ousted in 509, the inception of the Roman Republic. It conquered most of the rest of Italy, then turned its attentions overseas, and by the 1st century BC, ruled Spain, North Africa and Greece.
    The expansion of the Roman Empire provided opportunities for power-hungry individuals, and the clashing of egos led to colaps of democracy.`,
    id: 'N/A',
    cardContentMobile: '/assets/images/homePage/carl_fredrecksen.png',
    cardPosterMobile: '',
    cardPosterWeb: '',
    cardContentWeb: '',
    cardType: CARD_TYPE.MODEL,
    cardAuthor: 'Pixar',
    cardTitle: 'Hey, Carl Fredricksen!',
    cardPrice: 0,
    cardCategory: 'Sculpture',
    cardDescription: `Carl Fredricksen is not your average hero. He's a retired balloon salesman who, at the age of 78, is forced to
    leave the house he and his late wife Ellie built together. But instead of moving into the old folks' home, Carl takes action. He ties thousands of balloons to the roof, lifts the house into the air, and sets off toward South America.`,
    feedbackBlock: [
      {
        message: `Just bought these and they are amazing. Thank you for this, I would say it might be a little overpriced,
           but I do have a question. Would it be possible for you to do any facial animations? If so this would definatly be perfect!`,
        rating: 5,
        date: 'Jul 9',
        author: 'Alex Stark'
      },
      {
        message: `Good work. That would be great to have at least the possibility to move the eyes (separate the eye globles, add bones on it ?),
         and to make them blink eyes. Then more haircuts/more faces (not warriors :) )`,
        rating: 5,
        date: 'Jul 25',
        author: 'Alex Stark'
      },
      {
        message: `Just bought these and they are amazing. Thank you for this, I would say it might be a little overpriced,
           but I do have a question. Would it be possible for you to do any facial animations? If so this would definatly be perfect!`,
        rating: 5,
        date: 'Jul 9',
        author: 'Alex Stark'
      },
    ],
    informationBlock: [
      {name: 'Seller' , value: 'Pixar' },
      {name: 'Category' , value: 'Character' },
      {name: 'Assets' , value: '24' },
      {name: 'Size' , value: '2Gb' },
      {name: 'Published' , value: '28 May 2019' },
    ],
    purchaseStatus: CARD_PURCHASE_STATUS.DEFAULT_3DWAY
  };

  public meridaMock: ModelCardDetails = {
    preview: {
      activeImage: '/assets/images/homePage/merida.jpg',
      previewImages: undefined
    },
    detailsRatingValue: 4.6,
    detailsNumberOfVotes: '13.4K',
    detailsPrice: '$39.99',
    detailsFullDescription: `Merida is an exuberant, adventurous girl who dreams of exploring the world.`,
    cardContentMobile: '/assets/images/homePage/merida.jpg',
    cardPosterMobile: '',
    cardPosterWeb: '',
    cardContentWeb: '',
    cardType: CARD_TYPE.MODEL,
    id: 'N/A',
    modifiedDate: (new Date()).toString(),
    cardAuthor: 'Pixar',
    cardTitle: 'Merida',
    cardDescription: `Merida is an exuberant, adventurous girl who dreams of exploring the world.`,
    cardPrice: 0,
    cardCategory: 'Sculpture',
    feedbackBlock: [
      {
        message: `there might be a little bug with the shoes physical assets. it works well for us with the sneakers (2 capsules and associted on the feet). but the 2 others shoes, there is one big capsule associated to the pelvis, which `,
        rating: 4,
        date: 'May 29',
        author: 'MehruDagon'
      },
      {
        message: `Good work. That would be great to have at least the possibility to move the eyes (separate the eye globles, add bones on it ?),
         and to make them blink eyes. Then more haircuts/more faces (not warriors :) )`,
        rating: 5,
        date: 'Jul 25',
        author: 'Alex Stark'
      },
      {
        message: `Just bought these and they are amazing. Thank you for this, I would say it might be a little overpriced,
           but I do have a question. Would it be possible for you to do any facial animations? If so this would be perfect!`,
        rating: 5,
        date: 'Jul 9',
        author: 'Alex Stark'
      },
    ],
    informationBlock: [
      {name: 'Published' , value: '28 May 2019' },
      {name: 'Triangles' , value: '97.6k' },
      {name: 'Verticles' , value: '164.1k' },
    ],
    purchaseStatus: CARD_PURCHASE_STATUS.DEFAULT_3DWAY
  };

  public editCollectioncardSelectData = this.generateModelCardRenderedMocksBasedOnImgUrl([
    `https://${mockBaseUrl}/preview/MODELFEED_HLS_10_1688_1750/02/02_poster.webp`,
    `https://${mockBaseUrl}/preview/MODELFEED_HLS_10_1688_1750/03/03_poster.webp`,
    `https://${mockBaseUrl}/preview/MODELFEED_HLS_10_1688_1750/04/04_poster.webp`,
    `https://${mockBaseUrl}/preview/MODELFEED_HLS_10_1688_1750/05/05_poster.webp`,
    `https://${mockBaseUrl}/preview/MODELFEED_HLS_10_1688_1750/06/06_poster.webp`,
    `https://${mockBaseUrl}/preview/MODELFEED_HLS_10_1688_1750/07/07_poster.webp`,
    `https://${mockBaseUrl}/preview/MODELFEED_HLS_10_1688_1750/08/08_poster.webp`,
    `https://${mockBaseUrl}/preview/MODELFEED_HLS_10_1688_1750/09/09_poster.webp`,
    `https://${mockBaseUrl}/preview/MODELFEED_HLS_10_1688_1750/10/10_poster.webp`,
  ])



  public getMockData(): ModelCardDetails {

    this.urlTree = this.router.parseUrl(this.router.url);
    if (this.urlTree.root.children.primary === undefined) { return this.romeMock; }
    this.urlSegments = this.urlTree.root.children.primary.segments;
    const path = this.urlSegments[this.urlSegments.length - 1].path;
    let mockData;
    switch (path) {
      case 'rome':
        mockData = this.romeMock;
        break;
      case 'carl':
        mockData = this.carlMock;
        break;
      case 'merida':
          mockData = this.meridaMock;
          break;
      default:
        mockData = this.romeMock;
        break;
    }

    return JSON.parse(JSON.stringify(mockData));
  }

  public getMockedCardDetailsByTitle(cardTitle: string, customCardData?: ICard): ModelCardDetails {
    let mockData: ModelCardDetails;
    switch (cardTitle) {
      case 'rome':
        mockData = this.romeMock;
        break;
      case 'Hey, Carl Fredricksen!':
        mockData = this.carlMock;
        break;
      case 'Merida':
          mockData = this.meridaMock;
          break;
      default:
        mockData = this.romeMock;
        break;
    }
    if(customCardData) {
      mockData.cardContentMobile = customCardData.cardContentMobile;
      mockData.preview.activeImage = customCardData.cardContentMobile;
      mockData.cardTitle = customCardData.cardTitle;
      mockData.cardType = customCardData.cardType;
      mockData.cardDescription = customCardData.cardDescription
    }
    return JSON.parse(JSON.stringify(mockData));
  }

  public fillMockedCardsDiscoverFeed(): ModelCardService[] {
    const mockCardsDiscovery: ModelCardService[] = this.mockCardsDiscovery;

    return mockCardsDiscovery
  }

  public getMockedCardsDiscoverFeed(): ICard[] {
  return this.mockCardsDiscovery.filter((card) => {
    return card.showInMock === true;
  })
  }

  public getMockedCardsFollowingFeed(): ICard[] {
    const mockCardsFeed: ICard[] = [];

    // Example to compare hires vs lowres;
    mockCardsFeed.push({
      id: 'N/A',
      modifiedDate: (new Date()).toString(),
      cardContentMobile: '/assets/images/cards/model_hires_girl.jpg',
      cardPosterMobile: '',
      cardPosterWeb: '',
      cardContentWeb: '',
      cardType: CARD_TYPE.MODEL,
      cardCategory: 'Sculpture',
      cardAuthor: 'Pixar',
      cardTitle: 'Model (HiRes)',
      cardDescription: `Merida is an exuberant, adventurous girl who dreams of exploring the world. According to legend is not an average hero. She's just a big dreamer`,
      cardPrice: 0,
      purchaseStatus: CARD_PURCHASE_STATUS.DEFAULT_3DWAY
    });

    mockCardsFeed.push({
      id: 'N/A',
      modifiedDate: (new Date()).toString(),
      cardContentMobile: '/assets/images/cards/model_lowres_girl.jpg',
      cardPosterMobile: '',
      cardPosterWeb: '',
      cardContentWeb: '',
      cardType: CARD_TYPE.MODEL,
      cardCategory: 'Sculpture',
      cardAuthor: 'Pixar',
      cardTitle: 'Model (LowRes)',
      cardDescription: `Carl Fredricksen is not your average hero. He's a retired balloon to his house and flies away to the wilds of South America. of South America.`,
      cardPrice: 0,
      purchaseStatus: CARD_PURCHASE_STATUS.DEFAULT_3DWAY
    });

  //   mockCardsFeed.push({
  //     cardContentMobile: '/assets/images/scenesPage/mosh-full.jpg',
  //     cardType: CARD_TYPE.ALBUM,
  //     cardAuthor: 'DRAFT',
  //     cardTitle: 'Mosh',
  //     cardDescription: `Create the Sci-Fi fantasy environment of your dreams with this collection of peculiar plants, ethereal rocks and surfaces.`,
  //   });

  //   mockCardsFeed.push({
  //     cardContentMobile: '/assets/images/scenesPage/ferm-full.jpg',
  //     cardType: CARD_TYPE.MODEL,
  //     cardAuthor: 'DRAFT',
  //     cardTitle: 'Ferm',
  //     cardDescription: `According to legend, Rome was founded in 753 BC by twin sons Romulus and Remus `,
  //   });
    return mockCardsFeed
  }

  public getMockedCardsDraftsFeed(): ICard[]{
    const mockDraftsFeed: ICard[] = [];
    mockDraftsFeed.push({
      id: 'N/A',
      modifiedDate: (new Date()).toString(),
      cardContentMobile: '/assets/images/scenesPage/coliseum.jpg',
      cardPosterMobile: '',
      cardPosterWeb: '',
      cardContentWeb: '',
      cardType: CARD_TYPE.MODEL,
      cardCategory: 'Sculpture',
      cardAuthor: 'DRAFT',
      cardTitle: 'Coliseum',
      cardDescription: ``,
      cardPrice: 0,
      purchaseStatus: CARD_PURCHASE_STATUS.DEFAULT_3DWAY
    });

    mockDraftsFeed.push({
        id: 'N/A',
        modifiedDate: (new Date()).toString(),
        cardContentMobile: '/assets/images/homePage/carl_fredrecksen.png',
        cardPosterMobile: '',
        cardPosterWeb: '',
        cardContentWeb: '',
        cardType: CARD_TYPE.MODEL,
        cardCategory: 'Sculpture',
        cardAuthor: 'DRAFT',
        cardTitle: '',
        cardDescription: ``,
        cardPrice: 0,
        purchaseStatus: CARD_PURCHASE_STATUS.DEFAULT_3DWAY
    });
    return mockDraftsFeed
  }

  public getMockedCardsPublishedFeed(): ICard[]{
    const mockPublishedFeed: ICard[] = [];
    mockPublishedFeed.push({
      id: 'N/A',
      modifiedDate: (new Date()).toString(),
      cardContentMobile: '/assets/images/scenesPage/mosh-full.jpg',
      cardPosterMobile: '',
      cardPosterWeb: '',
      cardContentWeb: '',
      cardType: CARD_TYPE.MODEL,
      cardCategory: 'Sculpture',
      cardAuthor: 'DRAFT',
      cardTitle: 'Mosh',
      cardDescription: `Create the Sci-Fi fantasy environment of your dreams with this collection of peculiar plants, ethereal rocks and surfaces.`,
      cardPrice: 0,
      purchaseStatus: CARD_PURCHASE_STATUS.DEFAULT_3DWAY
      });
    return mockPublishedFeed
  }

  public getMockedRecCardsFeed(): IRecCard[] {
    const img1: string = '/assets/images/menuImages/rec-img-1.jpg';
    const img2: string = '/assets/images/menuImages/rec-img-2.jpg';
    const img3: string = '/assets/images/menuImages/rec-img-3.jpg';
    const img4: string = '/assets/images/menuImages/rec-img-4.jpg';

    const cardsInreview = [
      {
        previewImage: img2,
        progressValue: 100,
        title: 'GRIFFITH OBSERVATORY',
        category: 'ARCHITECTURE',
        author: 'Pixar',
        estimation: '2d 16h 15m',
        locationSegments: ['California', 'Los Angeles', 'Mount Hollywood', 'Observatory Rd']
      },
      {
        previewImage: img3,
        progressValue: 100,
        title: 'OLD DOWNTOWN',
        category: 'ARCHITECTURE',
        author: 'Pixar',
        estimation: '3d 6h 34m',
        locationSegments: ['California', 'Los Angeles', 'Downtown area', 'Main Rd']
      },
      {
        previewImage: img4,
        progressValue: 15,
        title: 'VILLA MCARTNEY',
        category: 'ARCHITECTURE',
        author: 'Pixar',
        estimation: '2d 16h 15m',
        locationSegments: ['California', 'San Francisco', 'Sunnyvalley']
      },
      {
        previewImage: img2,
        progressValue: 80,
        title: 'GRIFFITH OBSERVATORY',
        category: 'ARCHITECTURE',
        author: 'Pixar',
        estimation: '2d 16h 15m',
        locationSegments: ['California', 'Los Angeles', 'Mount Hollywood', 'Observatory Rd']
      },
      {
        previewImage: img1,
        progressValue: 30,
        title: 'HOLLYWOOD',
        category: 'ARCHITECTURE',
        author: 'Pixar',
        estimation: '57m',
        locationSegments: ['California', 'Los Angeles', 'North Hollywood', 'Hollywood blvd']
      },
      {
        previewImage: img2,
        progressValue: 100,
        title: 'OLD DOWNTOWN',
        category: 'ARCHITECTURE',
        author: 'Pixar',
        estimation: '3d 6h 34m',
        locationSegments: ['California', 'Los Angeles', 'Downtown area', 'Main Rd']
      },
      {
        previewImage: img3,
        progressValue: 15,
        title: 'VILLA MCARTNEY',
        category: 'ARCHITECTURE',
        author: 'Pixar',
        estimation: '2d 16h 15m',
        locationSegments: ['California', 'San Francisco', 'Sunnyvalley']
      },
      {
        previewImage: img2,
        progressValue: 95,
        title: 'GRIFFITH OBSERVATORY',
        category: 'ARCHITECTURE',
        author: 'Pixar',
        estimation: '2d 16h 15m',
        locationSegments: ['California', 'Los Angeles', 'Mount Hollywood', 'Observatory Rd']
      },
    ];
    return cardsInreview
  }

  public genereteModelCardFeed(urlString: string[]): ModelCardRendered[] {
    const baseMock = {
      cardContentMobile: `N/A`,
      cardType : CARD_TYPE.MODEL,

      cardAuthor: 'Natallia Slabko',
      cardTitle: 'Model 1',
      cardDescription: 'The mecha is co-piloted by two different players, the first serving as a pilot, and the second serving as a gunner.',
      cardPosterMobile: `https://storage-dev.3dway.io/preview/MODELFEED_HLS_10_1688_1750/02/02_poster.webp`
    }
    const result = [];

    urlString.forEach((el)=>{
      const currentMock: ModelCardRendered = JSON.parse(JSON.stringify(baseMock));
      currentMock.cardContentMobile = el;
      currentMock.cardPosterMobile = el;
      result.push(currentMock);
    })

    return result
  }

  public generateRandomCard(): ICard {
    const randomNumberSource = Math.floor(Math.random() * 9) + 2;
    const randomNumber = randomNumberSource < 10 ? '0' + randomNumberSource : randomNumberSource;

    const baseMock = {
      id: this.generateMockCollectionId(),
      modifiedDate: (new Date()).toString(),
      cardContentMobile: `https://${mockBaseUrl}/preview/MODELFEED_HLS_10_1688_1750/${randomNumber}/${randomNumber}_1688_1750k.mp4`,
      cardPosterWeb: '',
      cardContentWeb: '',
      cardType : CARD_TYPE.MODEL,

      cardAuthor: 'Natallia Slabko',
      cardTitle: `Model ${randomNumber}`,
      cardDescription: 'The mecha is co-piloted by two different players, the first serving as a pilot, and the second serving as a gunner.',
      cardPosterMobile: `https://storage-dev.3dway.io/preview/MODELFEED_HLS_10_1688_1750/${randomNumber}/${randomNumber}_poster.webp`,
      purchaseStatus: CARD_PURCHASE_STATUS.UPLOADED,
      cardPrice: undefined,
      cardCategory: 'Architecture',
    }
    return baseMock
  }

  public generateMockCollectionId(): string {
    return window.btoa(Math.random().toString().slice(0,6));
  }

  public generateRandomPrice(): number {
    return Math.floor(Math.random() * (100 - 1 + 1) + 1);
  }

  public addDataToCollectionAndSendToReview(collect: ICardCollection): ICardCollection {
    collect.collectionDescription = 'The mecha is co-piloted by two different players, the first serving as a pilot, and the second serving as a gunner.';
    collect.collectionContentMobile = `https://${mockBaseUrl}/preview/COLLECTION_HLS_9_OCT_RELEASE_1688_1750/LIGHNING2_conv_videoplayback3/LIGHNING2_conv_videoplayback3_1688_1750k.mp4`;
    collect.collectionPosterMobile = `https://${mockBaseUrl}/preview/COLLECTION_HLS_9_OCT_RELEASE_1688_1750/LIGHNING2_conv_videoplayback3/LIGHNING2_conv_videoplayback3_poster.webp`;
    return collect
  }

  public generateModelCardRenderedMocksBasedOnImgUrl(imgUrl: string[], videoReplaceString?: string): ModelCardRendered[] {
    const generatedResult: ModelCardRendered[] = []
    const baseMock: ModelCardRendered = {
      id: 'N/A',
      modifiedDate: (new Date()).toString(),
      cardContentMobile: `https://${mockBaseUrl}/preview/MODELFEED_HLS_10_1688_1750/02/02_1688_1750k.mp4`,
      cardType : CARD_TYPE.MODEL,
      cardCategory: 'Sculpture',
      cardAuthor: 'Natallia Slabko',
      cardTitle: 'Model ',
      cardDescription: 'The mecha is co-piloted by two different players, the first serving as a pilot, and the second serving as a gunner.',
      cardPosterMobile: `https://${mockBaseUrl}/preview/MODELFEED_HLS_10_1688_1750/02/02_poster.webp`,
      cardPosterWeb: '',
      cardContentWeb: '',
      purchaseStatus: CARD_PURCHASE_STATUS.DEFAULT_3DWAY,
      cardPrice: 0,
    }

    imgUrl.forEach((url, index) => {
      const cloneMock = JSON.parse(JSON.stringify(baseMock))
      cloneMock.id = this.generateMockCollectionId();
      cloneMock.cardPrice = Math.floor(Math.random() * (100 - 1 + 1) + 1);
      cloneMock.cardPosterMobile = url;
      if(videoReplaceString) cloneMock.cardContentMobile = url.replace('_poster.webp',videoReplaceString);
      cloneMock.cardTitle += (index+1).toString();
      generatedResult.push(cloneMock);
    })
    return generatedResult
  }

  public generateCollectionMockBasedOnImgArrays(sourceMocks: ICardCollectionMockSource[], videoReplaceString?: string): ICardCollection[] {
    const generatedCollections: ICardCollection[] = [];

    if(sourceMocks?.length > 0) {
      sourceMocks.forEach((source) => {
        // const collectionCardMock: ModelCardRendered = this.generateModelCardRenderedMocksBasedOnImgUrl([source.collectionCardImage])[0];
        // let videoUrls: string[]
        // if(videoReplaceString) videoUrls = source.cardImages.map(img => img.replace('_poster.webp',videoReplaceString)) // _1628_2500k.mp4
        generatedCollections.push({
          collectionId: this.generateMockCollectionId(),
          collectionTitle: source.collectionTitle,
          collectionPosterMobile: source.collectionPosterMobile,
          collectionContentMobile: source.collectionContentMobile,
          collectionAuthor: source.collectionAuthor ? source.collectionAuthor : 'Natalia Slabko',
          collectionDescription: source.collectionDescription === undefined ? 'The mecha is co-piloted by two different players, the first serving as a pilot, and the second serving as a gunner.' : source.collectionDescription ,
          showFullscreenBackgroundPreview: source.showFullscreenBackgroundPreview ? source.showFullscreenBackgroundPreview : false,
          accessType: 'Private',
          collectionCards: this.generateModelCardRenderedMocksBasedOnImgUrl(source.cardImages, videoReplaceString)
        })
      })
    }

    return generatedCollections
  }

  public generateNotInCollectionGalleryBasedOnMock(sourceMocks: ICardCollectionMockSource[], videoReplaceString?: string): ICardCollection {
    const generatedCollections: ICardCollection[] = this.generateCollectionMockBasedOnImgArrays(sourceMocks, videoReplaceString);
    generatedCollections[0].collectionId = DEFAULT_USER_COLLECTION_ID;
    generatedCollections[0].collectionTitle = 'Default colleсtion';
    generatedCollections[0].collectionDescription = '';
    generatedCollections[0].collectionCards.forEach((card) => {
      const min = 1;
      const max = 100;
      card.cardPrice = Math.floor(Math.random() * (max - min + 1) + min);
    })
    return generatedCollections[0]
  }

  public generateEmptyCollection(cards: ICard[], collName?: string): ICardCollection {
    return {
      collectionId: this.generateMockCollectionId(),
      collectionTitle: collName ? collName : 'Empty Collection',
      collectionCards: cards,
    }
  }



}
