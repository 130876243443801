<!-- FULLSCREEN OVERLAY -->
<div class="cardDetails-fullscreen-wrapper" (swipeup)="onSwipeUp($event)">
  <div class="cardDetails-fullscreen-img" [ngStyle]="{'background-image': 'url(' + fullscreenImg + ')'}"></div>
  <!-- <div class="fullscreen-bottom-row" fxLayout="row" fxLayoutAlign="space-between center">
    <button class="fullscreen-btn-close" mat-icon-button color="primary"  >
      <mat-icon  svgIcon="icon-close">close-icon</mat-icon>
    </button>
    <button class="fullscreen-btn-more" mat-icon-button color="primary" >
      <mat-icon  svgIcon="arrow-down">down-icon</mat-icon>
    </button>
    <div>
      <button class="bookmark-btn" mat-icon-button  color="primary" (click)="$event.stopPropagation()"  >
        <mat-icon svgIcon="bookmark-icon">bookmark-btn</mat-icon>
      </button>
      <button class="share-btn" mat-icon-button color="primary"  (click)="$event.stopPropagation()" >
        <mat-icon svgIcon="share">share-btn</mat-icon>
      </button>
    </div>
  </div> -->
</div>
