import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { IThumbCard, ICard, CARD_PURCHASE_STATUS, SLIDER_CARD_TYPE } from 'src/app/models/card';
import { ICardCollection } from 'src/app/models/collection';
import { UploadFileService } from '../../providers/upload-file.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-card-composite-slider',
  templateUrl: './card-composite-slider.component.html',
  styleUrls: ['./card-composite-slider.component.scss']
})
export class CardCompositeSliderComponent implements OnInit, OnChanges {
    @Input() title: string;
    @Input() publishRejectedReviewsCards: ICard[];
    @Input() sourceCollections: ICardCollection[];
    threeTimesArray = [0, 1, 2];

    CARD_PURCHASE_STATUS = CARD_PURCHASE_STATUS


    @Output() titleClicked = new EventEmitter<string>();
    @Output() publishRejectedReviewsGalleryClicked = new EventEmitter<boolean>();
    @Output() savedCollectionClicked = new EventEmitter<ICardCollection>();


    SLIDER_CARD_TYPE = SLIDER_CARD_TYPE;

    constructor(public uploadService: UploadFileService,) { }



    ngOnInit(): void {
    }

    ngOnChanges(changes: SimpleChanges): void {
      if(changes['inputImg']) {
        // this.thumbs = this.inputImg;
        // if(this.inputImg && this.inputImg[0] &&  !this.isComplexThumbCard(this.inputImg[0])) {
        //   this.thumbs = this.inputImg as string[];
        // }
      }
    }

    titleClick(event: Event): void {
      console.log('titleclick')
      event.stopPropagation();
      this.titleClicked.emit(this.title);
    }

    hasButtonsInHeader(): boolean {
      if(this.title === 'Portfolio') return true;
      return false;
    }

    processcardPosterMobile(cardPosterMobile: string): string {
      if(cardPosterMobile === undefined || cardPosterMobile === null || cardPosterMobile === '') {
        return '/assets/images/gradientMock.png'
      }
      return cardPosterMobile
    }

    onPublishRejectedReviewThumbClicked(event: Event): void {
      event.stopPropagation();
      this.publishRejectedReviewsGalleryClicked.emit(true);
      return
    }

    onSavedCollectionThumbClicked(collectionThumb: ICardCollection, event: Event): void {
      event.stopPropagation();
      this.savedCollectionClicked.emit(collectionThumb);
    }

    // public getFlexDimensionsComposite(): string {
    //   if(environment.type === 'mobile') return '0 1 calc(50% - 8px)';
    //   if(environment.type === 'web') return '0 1 calc(20% - 68px)';
    // }

    // public getFlexDimensionsSingle(): string {
    //   if(environment.type === 'mobile') return '0 0 calc(33.5% - 13px)';
    //   if(environment.type === 'web') return '0 0 215px';
    // }

    public getMobileOrWebLayoutClass(): string {
      if(environment.type === 'mobile') return 'mobilelayout';
      if(environment.type === 'web') return 'weblayout';
    }

    public isMobileView(): boolean {
      if(environment.type === 'mobile') return true;
      return false;
    }


  }
