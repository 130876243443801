<!-- Should be refactored in future: -->
<!-- 1)remove BEM class naming -->
<!-- 2)use parent css selector for different @media, e.g: 'terms-page lt-sm' as a parent for nested elements -->
<!-- 3)update HTML view to use ngFor cycles, follow DRY approach (error spans, for example)-->


<div class="terms-page"  ngClass.lt-sm="terms-page-lt-sm" ngClass.lt-md="terms-page-lt-md" ngClass.gt-sm="terms-page-gt-sm" ngClass.gt-lg="terms-page-gt-lg"  >
  <!-- <div class="rectangle rectangle-top" ngClass.gt-sm="rectangle-top-gt-sm"></div> -->
  <form class="terms-form" >
    <div class="terms-wrapper">
      <h1 class="terms-title">3DWay Terms of Service</h1>
      <p class="terms-description">Last Updated: August 26, 2024</p>

      <p><strong>Welcome to 3DWay!</strong></p>
      <p>The terms and conditions of this agreement (the "<strong>Terms of Service</strong>" or "<strong>Terms</strong>") govern the access and use of the 3DWay website and mobile application (collectively, the "<strong>Platform</strong>"), owned and operated by 3DWay LLC ("<strong>3DWay</strong>," "<strong>we</strong>," "<strong>us</strong>," or "<strong>our</strong>"), by all visitors and users of the Platform ("<strong>Users</strong>," "<strong>you</strong>," or "<strong>your</strong>"). </p>
      <p>THESE TERMS OF SERVICE CONTAIN A MANDATORY AND BINDING INDIVIDUAL ARBITRATION CLAUSE, CLASS ACTION WAIVER, WAIVER OF RIGHT TO A JURY TRIAL, AND OTHER IMPORTANT INFORMATION REGARDING YOUR LEGAL RIGHTS, REMEDIES, AND OBLIGATIONS. </p>
      <p><strong>Please read these Terms of Service carefully before accessing and using the Platform. </strong></p>
      <p class="article"><strong>1. </strong><strong>Acceptance</strong> </p>
      <p>Your access to and use of the Platform is conditional on your acceptance of these Terms. By accessing and using the Platform, you agree on your own behalf and on behalf of any organization, entity, or another person on whose behalf you may act, to accept and abide by these Terms for each and every use of the Platform. Please do not access or use the Platform if you do not agree with these Terms of Service.</p>
      <p>By agreeing to these Terms of Service, you also agree to the terms of our Privacy Policy available at <a href="https://www.3dway.io/legal/privacy-policy">https://www.3dway.io/legal/privacy-policy</a>. </p>
      <p class="article"><strong>2. </strong><strong>Updates to these Terms</strong></p>
      <p>We reserve the right to modify these Terms anytime by posting an updated version on the Platform. At our sole discretion, we may also provide Users who register with our Platform or who purchase our Subscription Services (defined below) with notification of changes. You are responsible for regularly reviewing these Terms, and your continued use of the Platform after the effective date of a change constitutes your acceptance of the updated Terms. If any modification is unacceptable, you shall cease using the Platform. If you have any questions about these Terms, contact us at <a href="mailto:support@3dway.io">support&#64;3dway.io</a>. </p>
      <p class="article"><strong>3. </strong><strong>Eligibility</strong></p>
      <p>You may only use the Platform if you comply with these Terms and all applicable local, state, national, and international laws, rules, and regulations. You must be 18 or older to use our Platform. Any access or use of the Platform by Users under 18 violates these Terms. The Platform may not be available to Users previously removed from the Platform by us.</p>
      <p class="article"><strong>4. </strong><strong>Disclaimers</strong></p>
      <p><strong>As Available</strong>. We do not warrant, guarantee, or represent that the Platform or the services used to provide it will be available at all times or that it will be error or interruption-free. The Platform is provided "as is," and you acknowledge and agree that you will not rely on any existing features of the Platform being available in the future or any proposed updates or additional features becoming available. </p>
      <p><strong>No Pre-Screening</strong>. We do not screen or otherwise verify the Users of our Platform. Users are solely responsible for any interaction they have with other Users on the Platform, and at no point can we be held liable for any conduct, action, or omission of Users, whether online or offline.</p>
      <p><strong>No Endorsement</strong>. We do not select, recommend, or endorse any Models (defined below) uploaded, published, or shared on the Platform. If you access Models of other Users on the Platform, you are solely responsible for determining whether the Models meet your intended purposes, that the applicable User who created the Model has the right to share the Model, and that the Model does not violate these Terms or the rights of any third party. Any dispute regarding a Model available on the Platform from another User should be handled strictly between you and the applicable User.</p>
      <p><strong>No Partnership</strong>. We do not act in any way except as a provider of technology to Users. We do not offer any advice, opinions, recommendations, or referrals. Unless expressly stated otherwise, we are not partners, joint venturers, employers, or affiliated with any User.</p>
      <p><strong>No Guarantee of Unauthorized Use</strong>. We use commercially reasonable efforts to protect the intellectual property rights in the Models you upload to the Platform, but you recognize and agree that no efforts to protect your Models from unauthorized access or use can guarantee that a User or any third party will not misappropriate your Models or use it beyond the uses permitted in these Terms. You agree to hold us harmless from and against any unauthorized use of your Models resulting from actions or omissions beyond our reasonable control.</p>
      <p class="article"><strong>5. </strong><strong>Platform Overview</strong></p>
      <p>Our Platform allows Users to upload, render, and store 3D renderings ("<strong>Models</strong>") and to share such Models with other Users, subject to the terms and conditions of these Terms and any additional terms specified on the Platform at the time a Model is rendered or shared.</p>
      <p>Users may render and store one Model on the Platform for no fee. Additional Models may be rendered by paying the additional fees specified on the Platform at the time of rendering the Model (&ldquo;<strong>Rendering Fee</strong>&rdquo;). Additional Models can be stored via that Platform up to the limits specified on the Platform at the time of registration (&ldquo;<strong>Subscription Fees</strong>&rdquo;). Rendering Fees are one-time fees charged for each Model rendered on the Platform. SUBSCRIPTION FEES ARE CHARGED ON A MONTHLY RECURRING BASIS UNTIL CANCELED BY US OR YOU. </p>
      <p class="article"><strong>6. </strong><strong>Access to Our Platform</strong></p>
      <p>We grant you a limited license to download our application on your mobile, tablet, and other compatible devices to access and use the Platform, subject to these Terms. Our application may not be compatible with all devices, carriers, and service providers, and we do not make any representations or warranties with respect to such compatibility. The rights granted through these Terms are personal to you and may not be transferred or otherwise shared with any third parties, such as by sharing your account and permitting others to render Models under your account.</p>
      <p>Your use of the Platform is subject to your fulfillment of all requirements stipulated in these Terms. The rights granted herein are limited to 3DWay and its licensor's intellectual property rights in the Platform. Software products not proprietary to 3DWay, including, without limitation, Third-Party Services (defined below), are licensed separately from their respective proprietary owner(s). 3DWay, our licensors, or designees reserve all rights not expressly granted in these Terms.</p>
      <p class="article"><strong>7. </strong><strong>Changes to the Platform</strong></p>
      <p>We reserve the right, in our sole discretion, to make any changes to the Platform that we deem necessary or useful to maintain and enhance the quality of the Platform and to comply with the law.</p>
      <p class="article"><strong>8. </strong><strong>Platform Support</strong></p>
      <p>We will, at our sole discretion, deliver enhancements, bug and error fixes, updates, and upgrades to the Platform that are made generally and commercially available without levying an incremental fee. The Platform is maintained and receives periodic updates and upgrades through releases. We will use reasonable efforts to schedule releases such that any impact on the availability and performance of the Platform is kept to a minimum, but we make no guarantee that the Platform will be available to you at all times. You acknowledge that any updates or upgrades to the Platform are entirely at our discretion, and we do not represent or guarantee that additional updates or upgrades will be made to the Platform. </p>
      <p>You may send support requests by email to <a href="mailto:support@3dway.io">support&#64;3dway.io</a>. We will try to respond within twenty-four (24) hours, but we make no guarantee that we will respond within any particular timeframe or that we will answer all requests satisfactorily.</p>
      <p class="article"><strong>9. </strong><strong>Your Responsibility</strong></p>
      <p>You may only access and use the Platform for its intended purpose and in accordance with these Terms. You are responsible for your compliance with these Terms and your own acts and omissions while using the Platform. You agree to immediately notify us of any unauthorized use of your user account or any other security breach. We cannot and will not be liable for any loss or damage arising from your failure to comply with this section. </p>
      <p>By accessing and using the Platform, you represent and warrant that: (i) you have and will continue to comply with all applicable federal, state, and local laws, regulations, and ordinances; (ii) you agree to be bound by these Terms; and (iii) if applicable, you have the legal right and authorization to use the credit or debit cards for any purchases made through the Platform with our Payment Processor (defined below). </p>
      <p class="article"><strong>10. </strong><strong>User Conduct</strong></p>
      <p>In using the Platform, you must comply at all times with all applicable laws, including, without limitation, all intellectual property, non-discrimination, privacy, and data security laws, and will not take any action that harms or violates the rights of any person or entity. Without limitation, you agree to not:</p>
      <p>● Reproduce, duplicate, copy, sell, rent, lease, resell, or exploit for commercial purposes any portion of the Platform without our express written consent;</p>
      <p>● Share Models or features of the Platform or any content contained in the Models or on the Platform with any third party not in compliance with these Terms of Service;</p>
      <p>● Use the Platform in any way to discriminate against any individual or class of individuals protected under federal, state, or local laws, or which may have a discriminatory impact against any individual or class of individuals, or which otherwise promotes illegal, racist, or discriminatory activities or outcomes;</p>
      <p>● Use the Platform or any third party&rsquo;s Models accessed through the Platform in any manner or for any purpose that infringes, misappropriates, or otherwise violates any intellectual property rights or other rights of any person or that violates any applicable law;</p>
      <p>● Modify or create derivative works based on the Platform or any third party&rsquo;s Models accessed through the Platform;</p>
      <p>● Impersonate any person or entity or falsely state or otherwise misrepresent your affiliation with a person or entity;</p>
      <p>● Remove or alter any copyright, watermark, attribution marks, or other proprietary notices on the Platform or contained on any Models or in the software used to provide the Platform;</p>
      <p>● Use or access the Platform to provide service bureau, time-sharing, or other computer hosting services to third parties;</p>
      <p>● Upload, download, post, email, or otherwise transmit any material that contains software viruses or any other computer code, files, or programs designed to interrupt, destroy, or limit the functionality of any computer software or hardware, or telecommunications equipment;</p>
      <p>● Disrupt or interfere with the security of, or otherwise abuse, the Platform, system resources, accounts, servers, or networks connected to or accessible through the Platform or affiliated or linked to the Platform;</p>
      <p>● Reverse engineer, decompile, disassemble, or otherwise attempt to derive the source code of the software used to provide the Platform;</p>
      <p>● Access, tamper with, or use non-public areas of the Platform. Unauthorized individuals attempting to access these areas of the Platform may be subject to prosecution;</p>
      <p>● Disrupt or interfere with any other Users' enjoyment of the Platform;</p>
      <p>● Use any robot, spider, scraper, or other automated means to access the Platform for any purpose;</p>
      <p>● Access the Platform to build, or help others to build, a similar or competitive product;</p>
      <p>● Frame or link to the Platform or any Models on the Platform except as permitted in writing by us; or</p>
      <p>● Share your use of the Platform with third parties.</p>
      <p class="article"><strong>11. </strong><strong>User-Generated Content</strong></p>
      <p>Our Platform may contain interactive features that allow you to upload, render, create, link, store, share, access, and otherwise submit Models and other content, pictures, and messages (collectively, "<strong>User-Generated Content</strong>"). User-Generated Content will be treated as non-confidential and may be viewable by other Users of the Platform. You are solely responsible for the accuracy, quality, and legality of your User-Generated Content.</p>
      <p>If you upload, render, store, or share User-Generated Content on the Platform, you represent and warrant that: (i) the User-Generated Content submitted to the Platform is your original work and does not violate the rights of any third party, including intellectual property rights or rights under applicable privacy laws; (ii) you have obtained all necessary consents and releases, if applicable, for the User-Generated Content submitted to the Platform; (iii) there are no outstanding disputes in connection with the property rights, intellectual property rights, or other rights in or to the User-Generated Content you make available on the Platform; (iv) you created or acquired any User-Generated Content legally and in accordance with all applicable laws; and (v) you will not submit any User-Generated Content that does not comply with the limitations imposed in the "Prohibited Content" Section of these Terms. You also acknowledge and agree that you are solely responsible for (a) safeguarding your User-Generated Content uploaded to the Platform by keeping a backup copy; and (b) ensuring that your Models and any communications you have with other Users comply with all applicable laws.</p>
      <p>By submitting User-Generated Content, including your Models, to any part of the Platform, you automatically grant us, and you represent and warrant that you have the right to grant us, a non-exclusive, transferable, royalty-free, fully paid, and worldwide right and license to use, reproduce, and distribute your User-Generated Content as reasonably necessary: (i) to provide the Platform as required under these Terms; (ii) to develop, distribute, improve and promote the Platform; (iii) to exercise our other rights, and perform our other obligations, under these Terms; and (iv) for any other use not expressly prohibited by these Terms or the applicable law.</p>
      <p class="article"><strong>12. </strong><strong>Model Descriptions</strong></p>
      <p>After uploading a Model to the Platform, you will be asked to provide information related to your Model and/or the collection that your Model is included in, such as the title, name, reference number, description, tags, categories, and so forth ("<strong>Descriptions</strong>"). You must provide accurate and non-misleading Descriptions. We reserve the right to add, remove, or modify Descriptions at our discretion that we believe are misleading, violate these Terms, or are otherwise objectionable.</p>
      <p class="article"><strong>13. </strong><strong>Use of Generative AI </strong></p>
      <p>You acknowledge and agree that you are required to include a "CreatedWithAI" or similar Description to all User-Generated Content created with the support of Generative AI Programs (defined below). "<strong>Generative AI Programs</strong>&rdquo; means an artificial intelligence system designed to automate the generation of or aid in the creation of new content, including but not limited to audio, visual, or text-based content.</p>
      <p class="article"><strong>14. </strong><strong>Content Policy</strong></p>
      <p>By uploading a Model or other User-Generated Content to the Platform, you agree to abide by the content policy described in these terms ("<strong>Content Policy</strong>''). 3DWay reserves the right to add, remove, or modify the Content Policy at any time at its discretion. 3DWay may include additional Content Policies at the time a Model is uploaded to the Platform. You agree not to upload, download, post, email, or otherwise transmit any User-Generated Content that, without limitation:</p>
      <p>● May infringe any intellectual or proprietary rights of any third party or imply a connection between you and a third party that does not otherwise exist;</p>
      <p>● Contains or depicts the trademark, service mark, trade dress, or other identifying indicia of source for a business or third party without such third party&rsquo;s express permission;</p>
      <p>● May be considered false or misleading information;</p>
      <p>● Violates another person&rsquo;s rights of celebrity or privacy, which includes, without limitation, uploading a Model containing an identifiable image of another person without such person&rsquo;s consent or uploading a Model containing an identifiable image of private property without first obtaining a property release;</p>
      <p>● Is unlawful, harmful, threatening, abusive, vulgar, harassing, defamatory, obscene, pornographic, profane, indecent, inflammatory, libelous, tortious, hateful, racially, ethnically, socially, politically, legally, morally, religiously or otherwise objectionable;</p>
      <p>● Consist of advertising, promotional materials, junk mail, spam, chain letters, or any other form of solicitation;</p>
      <p>● Would constitute or encourage a criminal offense, violate the rights of any party, or that would otherwise create liability or violate any local, state, or national law; or</p>
      <p>● Would likely incite, promote, or support discrimination, hostility, or violence.</p>
      <p>Without limiting the foregoing, we or our designees shall have the right (but not the obligation) to reject or remove any User-Generated Content that violates these Terms, applicable law, or is otherwise objectionable to us, and we may do so without any obligation to you, monetarily or otherwise. We will remove User-Generated Content from the Platform if properly notified that such User-Generated Content infringes on another's intellectual property rights.</p>
      <p class="article"><strong>15. </strong><strong>Rendering Fees</strong></p>
      <p>All Rendering Fees are specified on the Platform at the time of rendering a Model and, unless otherwise specified, are calculated individually for each Model rendered on the Platform. Rendering Fees are due, payable, and nonrefundable, upon the rendering of a Model and are charged to your payment method on file at the end of the billing cycle in which they came due in the aggregate for all Models rendered during the billing cycle. By rendering a Model on the Platform, you expressly agree to the Rendering Fees specified on the Platform at the time of rendering. If you cancel your Subscription Services prior to the end of the applicable billing cycle, you acknowledge that all accrued but unpaid Rendering Fees may be charged to you either at the end of the billing cycle or at the time of cancelation. You are required to provide accurate and up-to-date payment information on file with our Payment Processor (defined below) prior to rendering any Models on the Platform. If we are unable to successfully charge the applicable Rendering Fees as specified above due to any reason, including, without limitation, expired charge cards or insufficient funds, you agree to pay all unsuccessful charges within 14 days of demand from us. After such time, your right to render Models and your access to the Subscription Services may be suspended until all outstanding charges are paid in full, including any applicable late fees and/or insufficient fund fees.</p>
      <p class="article"><strong>16. </strong><strong>Subscription Fees </strong>&nbsp;</p>
      <p>If you purchase a subscription to our Platform ("<strong>Subscription Services</strong>"), you will receive access to the features and functionality that we make available to you, including the ability to upload, publish, store, and share Models to the Platform for access and use by other Users during the Subscription Period (defined below), subject to our Content Policy and any other guidelines that we provide to you. Our Subscription Services are available for the Subscription Fee specified to you at the time of purchase. Except as expressly provided otherwise, all Subscription Fees will be considered non-cancellable and non-refundable when paid, and no refunds will be permitted if you terminate the Subscription Services before the end of the Subscription Period (defined below). Unless otherwise specified at the time of purchase, Subscription Fees are stated in U.S. Dollars.</p>
      <p>Your right to store and share Models on the Platform commences when you pay the Subscription Fees and continues on a monthly basis until canceled by you or us (&ldquo;<strong>Subscription Period</strong>&rdquo;). YOU ACKNOWLEDGE AND AGREE THAT THE INITIAL SUBSCRIPTION PERIOD WILL AUTOMATICALLY RENEW FOR SUCCESSIVE EQUAL TERMS THEREAFTER UNLESS EARLIER TERMINATED AS PROVIDED IN THESE TERMS. NO REFUNDS WILL BE PERMITTED AFTER THE DATE OF RENEWAL.</p>
      <p>We reserve the right to revise the Subscription Fees at any time prior to your purchase of our Subscription Services and any time thereafter before the renewal of the Subscription Period. Any Subscription Fee changes will become effective at the end of the then-current Subscription Period. We will provide you with no less than fifteen (15) days&rsquo; written notice before any change to the Subscription Fees to give you an opportunity to terminate the Subscription Services before such change becomes effective. Your continued use of the Subscription Services after the fee change comes into effect constitutes your agreement to pay the updated Subscription Fees.</p>
      <p>If Subscription Fees are not received from you as required under these Terms, then, in addition to the other rights and remedies available and at our discretion, we may terminate the Subscription Services and delete or otherwise make all your Models uploaded under the Subscription Services unavailable on the Platform. WE WILL NOT BE LIABLE FOR ANY LOSSES WHATSOEVER INCURRED BY YOU RELATED TO YOUR FAILURE TO PAY UNDISPUTED FEES IN ACCORDANCE WITH THIS SECTION.</p>
      <p>All Subscription Fees are charged in advance on a recurring and periodic basis during the Subscription Period. YOU ACKNOWLEDGE AND AGREE THAT BY PURCHASING OUR SUBSCRIPTION SERVICES, YOU AUTHORIZE US TO CHARGE YOU AUTOMATICALLY ON A RECURRING AND PERIODIC BASIS FOR THE SUBSCRIPTION PERIOD AND UPON ANY RENEWAL PERIOD. </p>
      <p class="article"><strong>17. </strong><strong>Payments</strong></p>
      <p>All payments you make to us are processed through our third-party payment processor, such as Stripe (&ldquo;<strong>Payment Processor</strong>&rdquo;), and we do not store any of your financial information, such as credit card or bank account information. If you place payment information on file with our Payment Processor, YOU AUTHORIZE US TO CHARGE YOUR STORED PAYMENT METHOD AUTOMATICALLY FOR ALL SUBSCRIPTION FEES ON A RECURRING BASIS DURING THE SUBSCRIPTION PERIOD AND ALL RENDERING FEES AT THE END OF THE APPLICABLE BILLING CYCLE OR AS OTHERWISE SPECIFIED IN THESE TERMS OR ON THE PLATFORM AT THE TIME OF RENDERING. By using our Payment Processor, you agree to their separate Terms of Service and/or Privacy Policy. We reserve the right to change the Payment Processor at any time. You understand and agree that we will not be held liable for failure to complete a transaction through the Payment Processor. We are not responsible for resolving any dispute, support, penalty, or other issues that may occur between you and the Payment Processor. If we are required to pay any fees associated with your purchase of the Subscription Services or the rendering of Models, such as an overdraft or insufficient funds fee, you are responsible for such charges, which will be charged to your payment method on file and/or invoiced to you.</p>
      <p class="article"><strong>18. </strong><strong>Taxes</strong></p>
      <p>Unless otherwise stated, Rendering Fees and Subscription Fees do not include any taxes, levies, duties, or similar governmental assessments of any nature, including, without limitation, value-added, sales, use, or withholding taxes assessable by any local, state, provincial, federal, or foreign jurisdiction (collectively, "<strong>Taxes</strong>"). If we are required to collect or withhold any Taxes, you acknowledge that such costs will be added to any applicable fee on the Platform.</p>
      <p class="article"><strong>19. </strong><strong>Ownership of Models</strong></p>
      <p>You shall retain all rights, title, and interest in and to your Models, including any and all copyrights, trademarks, other intellectual property rights, and the goodwill pertaining thereto ("<strong>User</strong> <strong>IP</strong>"). These Terms do not grant Users the right to reproduce, copy, modify, distribute, license, sell, transfer, publicly display, perform, or otherwise exploit any User IP, except to the extent that such rights are granted to you under the license terms expressly provided under these Terms. You acknowledge and agree that we cannot control what other Users may or may not do with Models that you have shared with them through the Platform. We are expressly not liable for the use of Models by Users or any third party with whom you have shared or have granted access to your Models on the Platform.</p>
      <p class="article"><strong>20. </strong><strong>Suspension; Termination</strong></p>
      <p>You may terminate these Terms at any time by canceling your Subscription Services on the Platform or by emailing us at <a href="mailto:support@3dway.io">support&#64;3dway.io</a>. If you purchased our Subscription Services, termination will become effective upon the end of the then-current Subscription Period. Upon cancellation, your right to use the Subscription Services immediately terminates and you will lose all access to Models stored on the Platform.</p>
      <p>We, without prior notice, may suspend or terminate your use of the Platform at any time if, in our sole discretion, your use of the Platform is in violation of these Terms or applicable laws or if we otherwise reasonably believe that your use of the Platform could cause damage to the Platform, the rights of other Users, or for any other reason, even if not expressly outlined in these Terms. Our right to suspend and/or terminate your access to the Platform does not limit our right to seek any other remedy through these Terms or at law.</p>
      <p>Suspension or termination of your access to the Platform may result in the deletion of your Personal Information (defined below) and your User-Generated Content. All Models you uploaded to the Platform may be deleted within thirty (30) days of the effective termination date of these Terms, but we reserve the right to maintain your Models for longer periods of time at our discretion unless you expressly request otherwise, and we have no other legal basis for retaining such Models. </p>
      <p class="article"><strong>21. </strong><strong>Our Proprietary Rights</strong></p>
      <p>You acknowledge and agree that the Platform contains proprietary information protected by applicable intellectual property and other laws. We retain all rights, title, and interest in and to the Platform, including without limitation our trademarks (all names and logos) and all other content provided on the Platform by us (collectively, "<strong>3DWay IP</strong>"). These Terms do not grant you any right to reproduce, copy, modify, distribute, license, sell, transfer, publicly display, perform, or otherwise exploit the 3DWay IP. No rights are granted to you other than as expressly outlined in these Terms.</p>
      <p>By using the Platform, you grant us a non-exclusive, royalty-free, assignable, transferable, sublicensable, and irrevocable right and license to use and disclose the information contained in or derived from your use of the Platform, including your Personal Information and Related Data (defined below), on a de-identified basis only, for statistical, analytical, research, marketing, product/service improvement, and other commercial purposes. "<strong>Related Data</strong>" means data or information (excluding Personal information) associated with or arising out of your access and use of the Platform (including, without limitation, data associated with requests made to, and responses generated in connection with, the use of the Platform).</p>
      <p class="article"><strong>22. </strong><strong>Feedback; Promotional Rights</strong></p>
      <p>You may provide us or make public on the Platform notes, emails, postings, letters, suggestions, reviews, concepts, or other written materials related to the Platform or the Models (collectively, "<strong>Feedback</strong>"). You acknowledge and agree that you do not maintain and will not assert any ownership, intellectual property right, or other rights to the Feedback. You further agree that Feedback will not be considered confidential information and that we are permitted to freely share (including with other Users), publicly display, modify, create derivative works of, or otherwise use the Feedback for any purpose. Should the ownership of the Feedback be found under applicable law not to be our property, you hereby grant us a royalty-free, worldwide, transferable, sublicensable, irrevocable, perpetual license to use or incorporate into any of our products or services any Feedback provided by you relating to the provision of the Platform. </p>
      <p>You hereby grant us a license and right to use your name, business name, and logo, as well as portions of your Models, User-Generated Content, and all associated Descriptions, to advertise and promote the Platform to the public.</p>
      <p class="article"><strong>23. </strong><strong>DMCA Notice</strong></p>
      <p>We respect the intellectual property rights of others. Per the Digital Millennium Copyright Act ("<strong>DMCA</strong>"), we will respond expeditiously to claims of copyright infringement on the Platform if submitted to our Copyright Agent as described below. Upon receipt of a notice alleging copyright infringement, we will take whatever action we deem appropriate within our sole discretion, including removal of the allegedly infringing materials and termination of access for repeat infringers of copyright-protected content.</p>
      <p>If you believe that your intellectual property rights have been violated by a third party who has uploaded materials to our Platform or by us, please provide the following information to us:</p>
      <p>● A description of the copyrighted work or other intellectual property that you claim has been infringed;</p>
      <p>● A description of where the material that you claim is infringing is located on the Platform;</p>
      <p>● An address, telephone number, and email address where we can contact you and, if different, an email address where the alleged infringing party, if not we, can contact you;</p>
      <p>● A statement that you have a good-faith belief that the use is not authorized by the copyright owner or other intellectual property rights owner, by its agent, or by law;</p>
      <p>● A statement by you under penalty of perjury that the information in your notice is accurate and that you are the copyright or intellectual property owner or are authorized to act on the owner's behalf; and</p>
      <p>● Your electronic or physical signature.</p>
      <p>We may request additional information before removing any allegedly infringing material. If we remove the allegedly infringing materials, we may notify the person responsible for posting such materials that we removed or disabled access to the materials. We may also provide the responsible person with your email address so they may respond to your allegations.</p>
      <p>For questions regarding this DMCA Notice or to send us a notice, please contact <a href="mailto:support@3dway.io">support&#64;3dway.io</a>. </p>
      <p class="article"><strong>24. </strong><strong>User Privacy</strong></p>
      <p>Your privacy is very important to us. Our Privacy Policy sets out the categories of Personal Information (defined below) and other data we collect and how we collect, store, and use it. By accepting these Terms, you expressly consent to our disclosure and use of your Personal information as described in our Privacy Policy. We encourage you to read our Privacy Policy before you submit any Personal Information to the Platform. We reserve the right, but not the obligation, to maintain your Personal Information, even after you terminate these Terms. Without limiting the terms and conditions of our Privacy Policy, you acknowledge and agree that we may disclose your Personal Information, including name and email address, to our Payment Processor as necessary to complete payments that you authorize through your use of the Platform. You additionally acknowledge and agree that we may preserve and disclose your Personal Information, including your email address, when we believe, in our sole discretion, that such disclosure is necessary or appropriate to: (i) comply with legal processes; (ii) enforce these Terms; (iii) respond to claims that your User-Generated Content, including your Models, violates the rights of third-parties; (iv) investigate the use of or respond to alleged violations or infringement of Third-Party Content (as defined in these Terms); or (v) protect our rights, property, or personal safety or that of other Users or the public. </p>
      <p>"<strong>Personal Information</strong>" means any information relating to an identified or identifiable natural person; an identifiable natural person can be identified, directly or indirectly, in particular by reference to an identifier such as a name, an identification number, location data, an online identifier or to one or more factors specific to the physical, physiological, genetic, mental, economic, cultural or social identity of that natural person.</p>
      <p class="article"><strong>25. </strong><strong>Third-Party Content</strong></p>
      <p>The Platform may contain content or links to third-party applications, services, websites, and other content not owned or controlled by us ("<strong>Third-Party Content</strong>"). We do not endorse or assume any responsibility for any such Third-Party Content. If you access Third-Party Content from the Platform, you do so at your own risk and understand that these Terms and our Privacy Policy do not apply to your use of Third-Party Content. You expressly relieve us from any and all liability arising from your use of Third-Party Content, and any terms related to Third-Party Content are solely between you and the Third-Party Content provider. You agree that we will not be responsible for any reliance or damages relating to your use of Third-Party Content.</p>
      <p class="article"><strong>26. </strong><strong>Third-Party Services</strong></p>
      <p>The Platform may provide access to or integrate with third-party applications, services, websites, and other services not owned or controlled by us ("<strong>Third-Party Services</strong>"). Third-Party Services include, without limitation, our Payment Processor as well as your Apple ID or Google Account if you choose to use one to log into the Platform. All Third-Party Services are provided "As-Is," and we do not warrant any Third-Party Services, regardless of whether they are required to use our Platform. You expressly relieve us from any liability arising from your use of Third-Party Services, and any use by you of Third-Party Services is solely between you and the applicable Third-Party Services provider. We do not endorse or assume any responsibility for Third-Party Services. If you access Third-Party Services from the Platform, you do so at your own risk and understand that these Terms do not apply to your use of Third-Party Services. We shall not be responsible for any disclosure, modification, or deletion of your Personal Information, User-Generated Content, including your Models, resulting from access by Third-Party Services. Additionally, we are not responsible for downtime or unavailability of Third-Party Services outside our reasonable control. Under no circumstances will we be liable for any indirect, special, incidental, punitive, or consequential damages, including loss of data, business interruption, or loss of profits arising from the use or the inability to use Third-Party Services.</p>
      <p class="article"><strong>27. </strong><strong>Disclaimer of Warranties</strong> </p>
      <p>WE PROVIDE THE PLATFORM ON AN "AS-IS'' AND "AS-AVAILABLE" BASIS. YOUR USE OF THE PLATFORM AND ANY MODELS MADE AVAILABLE ON THE PLATFORM IS AT YOUR SOLE RISK, AND WE MAKE NO REPRESENTATIONS OR WARRANTIES, WHETHER EXPRESS OR IMPLIED, THAT: (I) THE PLATFORM WILL MEET YOUR REQUIREMENTS OR EXPECTATIONS; (II) THE OPERATION OF THE PLATFORM WILL BE UNINTERRUPTED, TIMELY, OR ERROR-FREE; (III) DEFECTS, IF ANY, WILL BE CORRECTED; (IV) THAT THE MODELS YOU STORE ON THE PLATFORM WILL BE AVAILABLE FOR ANY PERIOD OF TIME, ARE FREE FROM UNAUTHORIZED ACCESS OR DISCLOSURE, OR WILL BE SAFE FROM HACKING, CORRUPTION, OR DAMAGE; OR (V) THE INFORMATION, CONTENT, OR THE MODELS PROVIDED ON THE PLATFORM WILL BE ACCURATE, RELIABLE, OR CURRENT. TO THE EXTENT PERMISSIBLE UNDER APPLICABLE LAW, WE DISCLAIM ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, INCLUDING, WITHOUT LIMITATION, ANY IMPLIED WARRANTIES OF MERCHANTABILITY, TITLE, FITNESS FOR A PARTICULAR PURPOSE, NON-INFRINGEMENT, AND ACCURACY. THE INFORMATION AND ANY CONTENT PROVIDED BY US ON THE PLATFORM ARE FOR INFORMATIONAL PURPOSES ONLY AND DO NOT, AND ARE NOT INTENDED TO, CONSTITUTE SPECIFIC ADVICE OR ENDORSEMENT.</p>
      <p>YOU EXPRESSLY ACKNOWLEDGE THAT: (I) YOU ARE SOLELY RESPONSIBLE FOR THE MODELS YOU PROVIDE TO THE PLATFORM; AND (II) 3DWAY DOES NOT VERIFY THAT YOU HAVE ALL LEGAL RIGHTS TO USE AND PROVIDE THE MODELS TO THE PLATFORM. 3DWAY EXPRESSLY DISCLAIMS ALL WARRANTIES RELATED TO THE MODELS, INCLUDING, WITHOUT LIMITATION, ANY WARRANTY OF NON-INFRINGEMENT AND ANY WARRANTY RELATED TO THE SECURITY OR AVAILABILITY OF THE MODELS YOU STORE ON THE PLATFORM.</p>
      <p class="article"><strong>28. </strong><strong>Limitation of Liability</strong> </p>
      <p>TO THE MAXIMUM EXTENT PERMITTED UNDER APPLICABLE LAW, UNDER NO CIRCUMSTANCES INCLUDING, WITHOUT LIMITATION, OUR NEGLIGENCE, SHALL WE OR OUR OFFICERS, EMPLOYEES, AFFILIATES, DIRECTORS, AGENTS, OR ANY OTHER PARTY INVOLVED IN CREATING, PRODUCING, TRANSMITTING, OR DISTRIBUTING THE PLATFORM BE LIABLE FOR ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL OR EXEMPLARY DAMAGES, INCLUDING, WITHOUT LIMITATION, DAMAGES FOR LOSS OF PROFITS, GOODWILL, USER DATA OR OTHER INTANGIBLE LOSSES (EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES), ARISING OUT OF OR RELATING TO THE PLATFORM, THE MODELS, OR THESE TERMS. THIS LIMITATION APPLIES TO, WITHOUT LIMITATION: (I) THE USE OR INABILITY TO ACCESS OR USE THE PLATFORM OR THE MODELS; (II) ANY ACTIONS WE TAKE OR FAIL TO TAKE AS A RESULT OF ANY ELECTRONIC MESSAGES YOU SEND US; (III) THE COST OF PROCUREMENT OF SUBSTITUTE GOODS AND SERVICES RESULTING FROM ANY MODELS ACCESSED THROUGH THE PLATFORM; (IV) STATEMENTS OR CONDUCT OF ANY OTHER USER OR THIRD PARTY, WHETHER ONLINE OR OFFLINE; OR (V) ANY OTHER MATTER RELATING TO THE PLATFORM OR THE MODELS. </p>
      <p>YOU ARE SOLELY RESPONSIBLE FOR THE MODELS RENDERED AND STORED ON THE PLATFORM, AND WE SHALL HAVE NO RESPONSIBILITY FOR ANY DAMAGES SUFFERED BY YOU OR YOUR PROPERTY THAT RESULT FROM ANY OF YOUR OWN ACTS OR OMISSIONS. </p>
      <p>YOU ARE SOLELY RESPONSIBLE FOR KEEPING BACKUP COPIES OF ALL MODELS UPLOADED TO THE PLATFORM, AND WE WILL NOT BE LIABLE FOR ANY LOSS OR DAMAGE TO ANY OF YOUR MODELS AS A RESULT OF YOUR USE OF, OR INABILITY TO USE, THE PLATFORM. </p>
      <p><strong>TO THE EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT WILL OUR TOTAL LIABILITY EXCEED THE GREATOR OF $25 OR THE TOTAL AMOUNT OF FEES PAID BY YOU TO US DURING THE TWELVE (12) MONTH PERIOD IMMEDIATELY PRECEDING THE DATE OF THE MOST RECENT ACT OR OMISSION GIVING RISE TO OUR LIABILITY. ANY CLAIM ARISING UNDER THESE TERMS MUST BE BROUGHT BY YOU WITHIN 12 MONTHS AFTER THE EVENTS GIVING RISE TO THE CAUSE OF ACTION ARE DISCOVERED, OTHERWISE YOU RELEASE US OF ANY CLAIM.</strong></p>
      <p>SOME JURISDICTIONS DO NOT ALLOW THE LIMITATION OR EXCLUSION OF LIABILITY FOR INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO SOME OF THE ABOVE MAY NOT APPLY TO YOU. IN SUCH JURISDICTIONS, LIABILITY IS LIMITED TO THE FULLEST EXTENT PERMITTED BY LAW.</p>
      <p class="article"><strong>29. </strong><strong>Indemnification</strong> </p>
      <p>TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, YOU AGREE TO RELEASE, INDEMNIFY, AND HOLD HARMLESS 3DWAY AND OUR OFFICERS, DIRECTORS, SHAREHOLDERS, EMPLOYEES, AGENTS, AND OUR RESPECTIVE SUCCESSORS AND ASSIGNS ("<strong>INDEMNIFIED PARTIES</strong>'') FROM ANY THIRD-PARTY CLAIM, SUIT, PROCEEDING, OR GOVERNMENT ENFORCEMENT ACTIONS ARISING OUT OF, RELATED TO, OR ALLEGING AN INJURY OR LOSS CAUSED BY YOUR ACCESS AND USE OF THE PLATFORM THAT, WITHOUT LIMITATION, INCLUDES: (I) YOUR ACCESS TO OR USE OF THE PLATFORM FOR ILLEGAL, FRAUDULENT, OFFENSIVE, OR TORTIOUS PURPOSES; (II) YOUR INTERACTION WITH OTHER USERS; (III) YOUR VIOLATION OF THESE TERMS; OR (IV) YOUR VIOLATION OF ANY RIGHTS OF ANY THIRD PARTY. IN ANY EVENT, WE WILL HAVE THE RIGHT TO PARTICIPATE IN THE DEFENSE OF ANY SUCH SUIT OR PROCEEDING THROUGH COUNSEL OF OUR OWN CHOOSING AT OUR OWN EXPENSE. YOU WILL ALSO INDEMNIFY AND HOLD HARMLESS THE INDEMNIFIED PARTIES FROM ANY COSTS AND EXPENSES, INCLUDING REASONABLE ATTORNEYS&rsquo; FEES, INCURRED IN RESPONDING TO ANY LEGAL ACTION, SUBPOENA, SEARCH WARRANT, OR COURT ORDER REQUIRING THE PRODUCTION OF INFORMATION OR DOCUMENTS RELATED TO SUBSECTION (I) THROUGH (IV) ABOVE.</p>
      <p class="article"><strong>30. </strong><strong>Allocation of Risk</strong> </p>
      <p>You acknowledge and agree that we provide the Platform in reliance upon the disclaimers of warranty and the limitations of liability contained herein and that the terms of these Terms reflect an allocation of risk between you and us (including the risk that a contract remedy may fail of its essential purpose and cause consequential loss), and that the provisions herein form an essential basis of the bargain between you and us. If you are subject to applicable laws that prohibit you from indemnifying us or prohibit you from entering into the risk allocation arrangement as set forth herein, then the terms will apply to you to the fullest extent permitted by applicable law. The parties agree that each wishes to enforce the provisions of these Terms to the maximum extent permitted by applicable law.</p>
      <p class="article"><strong>31. </strong><strong>Release</strong> </p>
      <p>If you have a dispute with one or more Users, you release 3DWay (and our affiliates, officers, directors, agents, subsidiaries, joint ventures, and employees) from claims, demands, and damages (actual and consequential) of every kind and nature, known and unknown, arising out of or in any way connected with such disputes. </p>
      <p><strong>If you are a California resident, you waive California Civil Code &sect;1542, which states: "A general release does not extend to claims which the creditor does not know or suspect to exist in his favor at the time of executing the release, which if known by him must have materially affected his settlement with the debtor."</strong></p>
      <p class="article"><strong>32. </strong><strong>General Provisions</strong></p>
      <p><strong>Entire Agreement</strong>. These Terms of Service and any other terms and agreements referenced in these Terms constitute the entire agreement between you and us concerning the Platform.</p>
      <p><strong>Severability</strong>. In the event that any provision of these Terms is considered invalid, illegal, or unenforceable by a court of competent jurisdiction having authority to bind the parties under any applicable statute or rule of law, such provision shall be deemed amended to achieve as nearly as possible the same economic effect as the original provision and the remaining provisions of these Terms shall in no way be affected or impaired.</p>
      <p><strong>No Waiver</strong>. Our failure at any time to enforce any of the provisions of these Terms or any right or remedy available hereunder or at law or in equity, or to exercise any option herein provided, will not constitute a waiver of such provision, right, remedy or option or in any way affect the validity of these Terms. A waiver of any default by us will not be deemed a continuing waiver but will apply solely to the instance to which such waiver is directed.</p>
      <p><strong>Headings; Summaries</strong>. The section headings appearing in these Terms are inserted only as a matter of convenience and in no way define, limit, construe, or describe the scope or extent of such section or affect such section.</p>
      <p><strong>Arbitration</strong>. For any dispute, you agree first to contact us at <a href="mailto:support@3dway.io">support&#64;3dway.io</a> and attempt to resolve the dispute with us informally for at least thirty (30) days before initiating any arbitration or court proceeding. In the event we are unable to resolve the dispute informally, you and we agree that any dispute, claim, or controversy arising out of or relating to these Terms or the breach, termination, enforcement, interpretation, or validity thereof, including the determination of the scope or applicability of this provision to arbitrate, shall be determined solely and exclusively by binding arbitration before a single arbitrator. You and we also agree that the arbitration shall be conducted by the American Arbitration Association ("<strong>AAA</strong>") under the Commercial Arbitration Rules. Each party will be responsible for paying AAA filing, administrative, and arbitrator fees per AAA rules. The award rendered by the arbitrator shall include costs of arbitration, reasonable attorneys&rsquo; fees, and reasonable costs for expert and other witnesses, and any judgment on the award rendered by the arbitrator may be entered in any court of competent jurisdiction. This arbitration provision is governed by the Federal Arbitration Act.</p>
      <p><strong>No Class Action; No Jury Trial</strong>. ALL CLAIMS MUST BE BROUGHT IN THE PARTIES&rsquo; INDIVIDUAL CAPACITY, AND NO CLASS ACTION OR REPRESENTATIVE OR PRIVATE ATTORNEY GENERAL THEORIES OF LIABILITY OR PRAYERS FOR RELIEF MAY BE MAINTAINED IN ANY ARBITRATION OR OTHER PROCEEDING UNDER THESE TERMS. UNLESS WE AGREE OTHERWISE, THE ARBITRATOR MAY NOT CONSOLIDATE MORE THAN ONE PERSON&rsquo;S CLAIMS. YOU AGREE THAT, BY ENTERING INTO THESE TERMS, YOU AND WE ARE EACH WAIVING THE RIGHT TO A TRIAL BY JURY OR TO PARTICIPATE IN A CLASS ACTION.</p>
      <p><strong>Governing Law and Dispute Resolution</strong>. The laws of the United States and the State of Florida shall govern these Terms without regard to the principles of conflict of laws. Any dispute excluded from the arbitration provision or that cannot be heard in small claims court shall be resolved in the United States District Courts located in the State of Florida, and the parties submit to the personal jurisdiction of that court. If neither subject matter nor diversity jurisdiction exists in the United States District Courts located in the State of Florida, then the exclusive forum and venue for any such action shall be the courts of the State of Florida, and the parties hereby submit to the personal jurisdiction of that court.</p>
      <p><strong>Notices; Electronic Communication</strong>. By providing us with your email address, you consent to receive our related communications and notices electronically, and you agree that all agreements, notices, disclosures, and other communication that we provide to you via the Platform or email satisfy any legal requirement for such communications to be in writing. We may also use your email address to send you other messages, such as changes to the Platform's features and special offers ("<strong>Promotional</strong> <strong>Messages</strong>"). If you do not want to receive Promotional Messages, you may opt out by clicking the "Unsubscribe" link in an email or by emailing us at <a href="mailto:support@3dway.io">support&#64;3dway.io</a>. If you would like a physical address to send correspondence to 3DWay, you may email us, and we will provide you with a mailing address.</p>
      <p><strong>Force Majeure</strong>. Neither party shall be liable for any failure or delay in its performance under these Terms, or any and all addenda due to circumstances beyond its reasonable control (other than the payment of sums due), provided that it notifies the other party as soon as practicable and uses its best efforts to resume performance (such a "<strong>Force Majeure Event</strong>").</p>
      <p><strong>Contact</strong></p>
      <p>For questions regarding these Terms of Service, contact us at <a href="mailto:support@3dway.io">support&#64;3dway.io</a>.</p>
    </div>
  </form>
  <!-- <div class="rectangle rectangle-bottom" ngClass.gt-sm="rectangle-bottom-gt-sm"></div> -->
</div>
