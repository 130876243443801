import { FluidLayoutService } from '../../../shared/providers/fluid-layout/fluid-layout.service';
import { Component, OnInit, HostListener } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { lastValueFrom } from 'rxjs';

interface StorageImage {
  url: string;
  thumbUrl: string;
  date: string;
  width: number;
  height: number;
}

interface ImageSegmentSource {
  id: string;
  date: string;
  photos: StorageImage[];
}

interface ImageSectionSource {
  sectionId: string;
  date: string;
  segments: {
      id: string;
      date: string;
      totalPhotos: number;
  }[];
}

export interface ImageItem {
  path: string;
  width: number;
  height: number;
  date: Date;
}

export interface ImageGalleryItem extends ImageItem {
  trackId: string;
  left: number;
  top: number;
}

export interface ImageGallerySection {
    sectionId: string;
    trackId: string;
    sectionDate: Date;
    sectionWidth: string;
    sectionHeight: string;
    sectionYOffsetStart: number;
    sectionYOffsetEnd: number;
    sectionItems: ImageGalleryItem[];
    loaded: boolean;
}

const STORAGE_API_URL = 'https://europe-west1-dway-web-dev.cloudfunctions.net/getGalleryImagesData';
// const BASE_URL: string = 'https://storage.googleapis.com/world.3dway.io/';


const ACTION_BAR_WIDTH: number = 65;
const ADDITIONAL_MARGIN: number = 20;
const CONTAINER_PADDING: number = 20;
let timeout;

@Component({
  selector: 'app-photos-world',
  templateUrl: './photos-world.component.html',
  styleUrls: ['./photos-world.component.scss']
})
export class PhotosWorldComponent implements OnInit {
  public images: ImageItem[] = [];
  public galleryImages: ImageGalleryItem[] = [];
  public imageGallreySections: ImageGallerySection[] = [];
  public sectionDateHeading: string;
  public imagesWithoutCustomMetadata: ImageItem[] = [];
  public bucketFileList: any;
  public segmentsSourceResponse: any;

  public queryLocationName: string;
  public queryLocationCode: string;
  public isPhotosLoading: boolean;

  @HostListener('window:resize', ['$event'])
  getScreenSize(event?) {
    if (timeout) {
      window.cancelAnimationFrame(timeout);
    }

    // Setup the new requestAnimationFrame()
    timeout = window.requestAnimationFrame(() => {
      console.log('resize happened!!!');
      this.imageGalleryLayoutInit();
    });
  }

  @HostListener('window:scroll', ['$event'])
  doSomething(event) {
    const scrollPosition = window.pageYOffset + window.innerHeight;
    const currentSegmentID = this.getCurrentSectionIdByScrollPosition(scrollPosition);
    console.log('Scroll Event', currentSegmentID);

    this.loadSegmentForImageGallery(currentSegmentID);
    // TODO: Think about optimization here;
  }

  constructor(
    private http: HttpClient,
    private fluidLayoutService: FluidLayoutService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
  ) {}

  ngOnInit() {
        // const gallerySections = this.getGallerySegmentsFromLocalFile();
        // const replceExternalURL = true;
        // WORKING VARIANT: UNCOMMENT;
        // this.imageGallreySections = this.getGallerySegmentsFromLocalFile();
        this.queryLocationName = this.route.snapshot.queryParamMap.get('location');
        this.route.queryParamMap.subscribe(queryParams => {
          this.isPhotosLoading = true;
          this.queryLocationName = queryParams.get('location');
          this.queryLocationCode = queryParams.get('code');

          const paramsSections = this.queryLocationName && this.queryLocationCode ? this.getRequestParams('sections') : new HttpParams({fromString: 'type=sections'});
          const paramsSegments = this.queryLocationName && this.queryLocationCode ? this.getRequestParams('segments') : new HttpParams({fromString: 'type=segments'});

          lastValueFrom(this.http.get(STORAGE_API_URL, {params: paramsSegments})).then((jsonSegmentsResponse) => {
          this.segmentsSourceResponse = jsonSegmentsResponse;
          return lastValueFrom(this.http.get(STORAGE_API_URL, {params: paramsSections}));
        })
        .then((jsonSectionsResponse) => {
          this.imageGallreySections = this.generateImagePlaceholdersFromSections(jsonSectionsResponse);
          console.log(this.imageGallreySections);
          this.imageGalleryLayoutInit();
          this.loadSegmentForImageGallery(this.imageGallreySections[0].sectionId);
          this.isPhotosLoading = false;
        });
      });
  }

  private getRequestParams(type: string): HttpParams {
    return new HttpParams({fromObject: {type, locationType: this.queryLocationName,  locationCode: this.queryLocationCode}});
  }

  public trackByTrackIdGalleryItem(_index: number, photo: ImageGalleryItem): string {
    return photo.trackId;
  }

  public trackByTrackIdSectionItem(_index: number, photo: ImageGallerySection): string {
    return photo.trackId;
  }

  private loadSegmentForImageGallery(segmentId: string): void {
    const loadedSegment = this.getSegmentByIdFromServer(segmentId);
    const currentSection: ImageGallerySection = this.imageGallreySections.find((el) => {
      return el.sectionId === segmentId;
    });

    if (currentSection === undefined || currentSection.loaded === true) { return; }
    currentSection.loaded = true;
    currentSection.trackId = Math.random().toString();
    currentSection.sectionItems.forEach((item, index) => {
      item.path = loadedSegment.photos[index].url;
      item.width = loadedSegment.photos[index].width;
      item.height = loadedSegment.photos[index].height;
    });
    this.imageGalleryLayoutInit();
  }

  private getSegmentByIdFromServer(sectionID: string): ImageSegmentSource {
    const requestedSegment = this.segmentsSourceResponse.find((el) => {
      return el.id === sectionID;
    });
    return requestedSegment;
  }

  private getCurrentSectionIdByScrollPosition(scrollPos: number): string {
    if (this.imageGallreySections === undefined) { return; }
    const currSection = this.imageGallreySections.find((section) => {
      return scrollPos >= section.sectionYOffsetStart && section.sectionYOffsetEnd >= scrollPos;
    });
    return currSection ? currSection.sectionId : undefined;
  }

  private generateImagePlaceholdersFromSections(jsonSectionsReponse: any): ImageGallerySection[] {
    const sampleWidth = 300;
    const sampleHeight = 400;

    // const segmentSource: {id: string; date: string; totalPhotos: number} = sectionsSourceJSON.reduce((prevVal, currentVal) => {
    //   return {}
    // });
    const segmentSource: {id: string; date: string; totalPhotos: number}[] = jsonSectionsReponse.map((val: ImageSectionSource) => {
      return val.segments;
    }).reduce((acc, val) => acc.concat(val), []);


    const result: ImageGallerySection[] = segmentSource.map((img, index) => {
      return {
        sectionId: img.id,
        trackId: Math.random().toString(),
        sectionDate: new Date(img.date),
        sectionWidth: undefined,
        sectionHeight: undefined,
        sectionYOffsetStart: undefined,
        sectionYOffsetEnd: undefined,
        loaded: false,
        sectionItems: new Array(img.totalPhotos).fill(0, 0, img.totalPhotos).map(() => {
          return {
            trackId: Math.random().toString(),
            path: undefined,
            width: sampleWidth,
            height: sampleHeight,
            date: new Date(img.date),
            left: undefined,
            top: undefined,
          };
        })
      };
    });
    return result;
  }

  public convertDateToMonthDayString(date: Date): string {
      return date.getDate() + ' ' + new Intl.DateTimeFormat('en-US', { month: 'short'}).format(date);
  }

  private imageGalleryLayoutInit(): void {
    let totalYOffset: number = 0;
    // TODO: Dynamically calculate segmentGapHeight;
    const segmentGapHeight: number = 60;
    this.imageGallreySections.forEach((gallerySection) => {
      const layoutGeometry: any = this.fluidLayoutService.getCalculatedLayout(
        gallerySection.sectionItems,
        {
          containerWidth: this.getContainerWidth()
        }
      );
      gallerySection.sectionHeight = this.setGalleryHeight(layoutGeometry.containerHeight);
      gallerySection.sectionYOffsetStart = totalYOffset;
      gallerySection.sectionYOffsetEnd = totalYOffset + segmentGapHeight + parseInt(gallerySection.sectionHeight, 10);
      totalYOffset = gallerySection.sectionYOffsetEnd;
      gallerySection.sectionItems = this.applyCalculatedLayoutToImages(
        gallerySection.sectionItems,
        layoutGeometry
      );
    });
  }

  private getContainerWidth(): number {
    const screenWidth: number = window.innerWidth - CONTAINER_PADDING;
    const containerWidth: number =
      screenWidth > 1024 ? screenWidth - ACTION_BAR_WIDTH - ADDITIONAL_MARGIN : screenWidth;
    return containerWidth;
  }

  private setGalleryHeight(containerHeight: number): string {
    return containerHeight.toString() + 'px';
  }

  private applyCalculatedLayoutToImages(source: ImageGalleryItem[], layoutGeometry: any): ImageGalleryItem[] {
    const replaceString = 'https://storage.googleapis.com/world.3dway.io/';
    const localUrl = 'assets/images/photosPage/images/';
    const useLocalImagesURL = false;
    return source.map((img, index) => {
      return {
        trackId: img.trackId,
        path: useLocalImagesURL && img.path ? img.path.replace(replaceString, localUrl) : img.path,
        // path: img.path,
        date: img.date,
        height: layoutGeometry.boxes[index].height,
        width: layoutGeometry.boxes[index].width,
        left: layoutGeometry.boxes[index].left,
        top: layoutGeometry.boxes[index].top
      };
    });
  }
  // private getGallerySegmentsFromLocalFile(useLocalImagesURL: boolean = false): ImageGallerySection[] {
  //   const replaceString = 'https://storage.googleapis.com/world.3dway.io/';
  //   const localUrl = 'assets/images/photosPage/images/';
  //   // const sectionsSource: ImageSectionSource[] = sectionsSourceJSON;
  //   const segmentSource: ImageSegmentSource[] = segmentsSourceJSON;
  //   const result: ImageGallerySection[] = segmentSource.map((img, index) => {
  //     return {
  //       sectionId: img.id,
  //       trackId: Math.random().toString(),
  //       sectionDate: new Date(img.date),
  //       sectionWidth: undefined,
  //       sectionHeight: undefined,
  //       sectionYOffsetStart: undefined,
  //       sectionYOffsetEnd: undefined,
  //       loaded: false,
  //       sectionItems: img.photos.map((photo) => {
  //         return {
  //           path: useLocalImagesURL ? photo.url.replace(replaceString, localUrl) : photo.url,
  //           width: photo.width,
  //           height: photo.height,
  //           date: new Date(photo.date),
  //           left: undefined,
  //           top: undefined,
  //         };
  //       })
  //     };
  //   });
  //   return result;
  // }


  // private getListOfImagesFromGoogleStorage(): Promise<ImageItem[]> {
  //   return new Promise((resolve, reject) => {
  //     this.http.get(STORAGE_API_URL).subscribe((data: any) => {
  //       const storageItems: ImageItem[] = [];
  //       let currentStorage: ImageItem[] = [];
  //       this.bucketFileList = data;
  //       this.bucketFileList.items.forEach(element => {

  //         if (
  //           element.metadata === undefined ||
  //           element.metadata.width === undefined ||
  //           element.metadata.height === undefined
  //         ) {
  //           currentStorage = this.imagesWithoutCustomMetadata;
  //           element.metadata = {
  //             height: null,
  //             width: null
  //           };
  //         } else {
  //           currentStorage = storageItems;
  //         }

  //         currentStorage.push({
  //           path: BASE_URL + element.name,
  //           date: new Date(element.timeCreated),
  //           height: element.metadata.height,
  //           width: element.metadata.width
  //         });
  //       });
  //       resolve(storageItems);
  //     });
  //   });
  // }

  // private fillGalleryWithImagePlaceholders(imageGallerySection: ImageGallerySection[]): ImageGallerySection[] {
  //   const sampleWidth = 300;
  //   const sampleHeight = 400;

  //   const gallerySectionsWithPlaceHolders = imageGallerySection.map((gallery: ImageGallerySection) => {
  //     return { sectionDate: gallery.sectionDate,
  //       sectionWidth: gallery.sectionWidth,
  //       sectionHeight: gallery.sectionHeight,
  //       sectionYOffsetStart: gallery.sectionYOffsetStart,
  //       sectionYOffsetEnd: gallery.sectionYOffsetEnd,
  //       sectionItems: gallery.sectionItems.map((item: ImageGalleryItem) => {
  //         return {
  //           path: '',
  //           width: sampleWidth,
  //           height: sampleHeight,
  //           date: item.date,
  //           left: item.left,
  //           top: item.top,
  //         };
  //       })
  //     };
  //   });

  //   return gallerySectionsWithPlaceHolders;
  // }

  // private createimageGallreySections(imageItems: ImageItem[]): ImageGallerySection[] {
  //   const reducedArr = imageItems.reduce(function(obj, item) {
  //     const getFullDate = (imgItem: ImageItem) => {
  //       const date = imgItem.date;
  //       return new Date(
  //         date.getFullYear(),
  //         date.getMonth(),
  //         date.getDate()
  //       ).toString();
  //     };

  //     // Check if the criteria is a function to run on the item or a property of it
  //     const key = getFullDate(item);

  //     // If the key doesn't exist yet, create it
  //     if (!obj.hasOwnProperty(key)) {
  //       obj[key] = [];
  //     }

  //     // Push the value to the object
  //     obj[key].push(item);
  //     return obj;
  //   }, {});

  //   return Object.keys(reducedArr).map(k => {
  //     return {
  //       sectionDate: new Date(k),
  //       sectionItems: [...reducedArr[k]],
  //       sectionYOffsetStart: undefined,
  //       sectionYOffsetEnd: undefined,
  //       sectionWidth: undefined,
  //       sectionHeight: undefined
  //     };
  //   });
  // }

  // private getDimensionsOfImagesWithoutMetadata(sourceImages: ImageItem[], sourceImagesWithMetada: ImageItem[]): Promise<ImageItem[]> {
  //   return new Promise((resolve, reject) => {
  //     const getImageData = (imageItem: ImageItem) =>
  //       new Promise(res => {
  //         const img = new Image();
  //         img.onload = () =>
  //           res({
  //             path: imageItem.path,
  //             date: imageItem.date,
  //             width: img.width,
  //             height: img.height
  //           });
  //         img.onerror = () =>
  //           res({
  //             imageItem,
  //             status: 'error'
  //           });

  //         img.src = imageItem.path;
  //       });

  //     const loadImg = (...imageItems: ImageItem[]) =>
  //       Promise.all(imageItems.map(getImageData)).then((el: ImageItem[]) => {
  //         const sourceImagesProcessed: ImageItem[] = el;
  //         resolve([...sourceImagesWithMetada, ...sourceImagesProcessed]);
  //       });
  //     if (sourceImages.length > 0) {
  //       loadImg(...sourceImages);
  //     } else {
  //       resolve(sourceImagesWithMetada);
  //     }
  //   });
  // }



}
