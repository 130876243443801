<section class="adv-wrapper" appResponsiveClass="page">
  <!-- <div class="hero-background" [ngStyle]="{'background-image': 'url(;' + backgroundImage + ')'}" ></div> -->
  <div class="hero-background" [ngStyle]="imageLoaded ? {} : {'background-image': 'url;(' + backgroundImage + ')'}"></div>
  <div class="overscroll-background"></div>

  <h1 class="headline">
  <svg class="vertical-line" xmlns="http://www.w3.org/2000/svg" width="11" height="237" viewBox="0 0 11 237" fill="none">
    <path d="M5.5 236.5L5.5 0" stroke="url(#paint0_linear_22572_13853)" stroke-width="10"/>
    <defs>
      <linearGradient id="paint0_linear_22572_13853" x1="5.50003" y1="376.5" x2="5.50004" y2="2.24411e-05" gradientUnits="userSpaceOnUse">
        <stop stop-color="#4D4E52"/>
        <stop offset="1" stop-color="#1D1D1F"/>
      </linearGradient>
    </defs>
  </svg>
    Upload Your Entire Portfolio.<br>
    <span class="second-part">Share and Discover&nbsp;3D.</span>
      <svg *ngIf="!isMobile" class="horizontal-line" width="344" height="10" viewBox="0 0 344 10" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0 5.00082L343.5 5" stroke="url(#paint0_linear_22572_13852)" stroke-width="10"/>
        <defs>
        <linearGradient id="paint0_linear_22572_13852" x1="-68.1582" y1="5.49999" x2="249" y2="5.50002" gradientUnits="userSpaceOnUse">
        <stop stop-color="#4E4F53"/>
        <stop offset="1" stop-color="#1D1D1F"/>
        </linearGradient>
        </defs>
      </svg>
  </h1>
  <p class="watch">
    <a class="watch-link" (click)="openYoutubeVideo()"><span>Watch the film</span> <i class="material-icons">play_circle_outline</i></a>
  </p>
  <div class="tiles-outerwrapper">
    <div class="tiles-container" fxLayout="column" fxLayout="row wrap">
      <div class="tile tile1 long">
        <div class="tile-gradient"></div>
        <mat-icon class="plus-icon" svgIcon="plus-dark-icon" [ngClass]="{'rotate': isUnlimitedStorageFeatureShown}" (click)="onFirstFeatureMoreClick()"></mat-icon>

        <div class="tile-content" *ngIf="!isUnlimitedStorageFeatureShown">
          <h3>Goodbye files.&nbsp; <br>Hello&nbsp;3D.</h3>
          <h5>
            Unlimited Storage.
          </h5>
        </div>

        <div class="additional-content" [@inOutAnimation] *ngIf="isUnlimitedStorageFeatureShown"  fxLayout="row wrap" fxLayoutAlign="start center" >
          <h5>Unlimited Storage.</h5>
          <p>Carl Fredricksen is not your average hero. He's a retired balloon salesman who,
            at the age of 78, is forced to leave the house he and his late wife Ellie built together.
            But instead of moving into the old folks' home, Carl takes action. He ties thousands of balloons
            to the roof, lifts the house into the air.
          </p>
        </div>
      </div>

      <div class="tile short formula">
        <video fxHide fxShow.xs class="background-video-tile" (canplaythrough)="playBackgroundVideo()" #videoPlayer  autoplay playsinline muted loop >
          <source src="/assets/images/landing/Williams_FW44_horizontal_web.mp4" type="video/mp4">
        </video>
        <video fxHide.xs fxShow.xs class="background-video-tile " (canplaythrough)="playBackgroundVideo()" #videoPlayer  autoplay playsinline muted loop >
          <source src="/assets/images/landing/Williams_FW44_horizontal_web.mp4" type="video/mp4">
        </video>
        <mat-icon class="plus-icon" svgIcon="plus-dark-icon" [ngClass]="{'rotate': is60FPSrenderFeatureShown}" (click)="onSecondFeatureMoreClick()"></mat-icon>
        <div class="tile-content top-align" >
          <h3 class="second-tile-heading">Highest Quality.
          </h3>
          <h5>
            60 fps HD Render.
          </h5>
        </div>
        <div class="additional-content" [@inOutAnimation] *ngIf="is60FPSrenderFeatureShown"  fxLayout="row wrap" fxLayoutAlign="start center" >
          <h5>60 fps HD Render.</h5>
          <p>
            Render is not your average hero. He's a retired balloon salesman who, at the age of 78,
            is forced to leave the house he and his late wife Ellie built together.
            But instead of moving into the old folks' home, Carl takes action.
          </p>
        </div>
      </div>

      <div class="tile double-tile long">
        <div class="tile-filter"></div>
        <video class="background-video-tile long-tile" (canplaythrough)="playBackgroundVideo()" #videoPlayer  autoplay playsinline muted loop >
          <source src="/assets/images/landing/museum_collection_web_resized.mp4" type="video/mp4">
        </video>
        <mat-icon class="plus-icon" svgIcon="plus-dark-icon" [ngClass]="{'rotate': isShareFeatureShown}" (click)="onThirdFeatureMoreClick()"></mat-icon>
        <div class="tile-content tile-creative"  >
          <h2 fxHide fxShow.gt-xs>Creative Chaos.<br>
            Packed into Collections.
          </h2>
          <h2 fxHide.gt-xs fxShow.xs>
            <span>Creative</span> <span>Chaos.</span>
            <span>Packed</span> <span>into</span> <span>Collections.</span>
          </h2>
          <h5 class="small-txt" style="text-align: left;">
            Share Models. Share Collections. Discover&nbsp;with&nbsp;3DWay.
          </h5>
        </div>
        <div class="additional-content" [@inOutAnimation] *ngIf="isShareFeatureShown"  fxLayout="row wrap" fxLayoutAlign="start center" >
          <h5>Share your models.</h5>
          <p>
            Models is not your average hero. He's a retired balloon salesman who, at the age of 78,
            is forced to leave the house he and his late wife Ellie built together.
            But instead of moving into the old folks' home, Carl takes action.
          </p>
        </div>
      </div>

      <!-- WEB VERSION -->
      <div fxHide fxShow.gt-xs class="tile double-tile last-tile" style="background-image: url('/assets/images/landing/iphone_ipad_mac.png'); background-size: 45%;">
        <div class="tile-content last-content-tile"  >
          <h4>
            Leaving home, take portfolio with you.
          </h4>
          <h5 >
            Now crafted for iPhone.
          </h5>
          <div class="appstore-buttons">
            <a href="https://apps.apple.com/us/app/3dway/id6448911494" title="Open the app">
              <img src="assets/images/landing/appstore.svg" alt="Download on the App Store" class="appstore-button">
            </a>
          </div>
        </div>
      </div>

      <!-- MOBILE -->
      <div fxHide.gt-xs fxShow.xs class="tile double-tile last-tile short" style="background-image: url('/assets/images/landing/iphone_ipad_mac_mobile.png')">
        <div class="tile-content last-content-tile"  >
          <h3>
            Coming home, Scale Your Experience.
          </h3>
          <h5 >
            Now crafted for Desktop.
          </h5>
        </div>
      </div>
      <!-- WEB VERSION -->
      <div fxLayout="row" fxLayoutAlign="space-between center" fxFlex="0 1 100%">
        <div fxHide fxShow.gt-xs  class="links" fxLayout="row" fxLayoutAlign="space-between center">
          <a routerLink="/legal/privacy-policy" alt="Privacy Policy">Privacy Policy</a>
          |
          <a routerLink="/legal/terms-of-service" alt="Terms of Service">Terms of Service</a>
        </div>
      </div>
      <!-- MOBILE -->
      <div fxLayout="row" fxLayoutAlign="center center" fxFlex="0 1 100%">
        <div fxHide.gt-xs fxShow.xs  class="links first-adv-mobile" fxLayout="row" fxLayoutAlign="center center">
          <p class="terms-text">
            <u><a href="https://www.3dway.io/legal/terms-of-service">Terms of Service</a></u> | <u><a href="https://www.3dway.io/legal/privacy-policy">Privacy Policy</a></u>
          </p>
        </div>
      </div>
      <!-- -->
    </div>

  </div>
</section>
