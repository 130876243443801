<app-subscription-splash [@fullscreenPanelAnimation]  *ngIf="isSubscriptionSplashShown" [tryForFreeMode]="!userDataService.isFreeUploadLimitReached && !userDataService.isTryFreeEnabled" ></app-subscription-splash>
<app-webnav #navbarComponent (navbarButtonClick)="onWebLibraryNavbarBtnClicked($event)"></app-webnav>

<div class="portfolio-page ">

  <!-- <div class="cards-not-incollection-area" > -->
  <div>
    <ng-container *ngIf="webNavService.currentWebSwitchPanelMode === SWITCH_PANEL_ITEM.UPLOADS">

      <app-thumbnail-gallery [sourceCards]="userDataService.uploadedCards"  (cardClicked)="onUploadedCardClicked($event)"  class="zero-margin last-slider" >
        <div class="nodata-wrapper" *ngIf="uploadService.uploadModels?.length === 0 && userDataService.uploadedCards?.length === 0">
          <h1 class="nodata-title">Upload your first model</h1>
          <h3 class="nodata-desc">You don't have any uploaded models</h3>
        </div>
        <!-- fxFlex="0 1 calc(33.3334% - 6.33px)" -->
        <app-card-thumbs-upload  class="gallery-thumb" [ngClass]="getLayoutClass()" *ngFor="let upCard of uploadService.uploadModels | callback: filterUploadCards" [uploadCard]="upCard"  fxLayout="column" fxLayoutAlign="start center"></app-card-thumbs-upload>
      </app-thumbnail-gallery>
    </ng-container>
    <ng-container *ngIf="webNavService.currentWebSwitchPanelMode === SWITCH_PANEL_ITEM.LIKES">
      <div class="nodata-wrapper" *ngIf="userDataService.getSavedCards()?.length === 0">
        <h1 class="nodata-title">No saved models</h1>
        <h3 class="nodata-desc">You don't have any saved models</h3>
      </div>

      <app-thumbnail-gallery *ngIf="userDataService.getSavedCards()?.length > 0" [sourceCards]="userDataService.getSavedCards()" (cardClicked)="onLikedCardClicked($event)"  class="zero-margin last-slider" ></app-thumbnail-gallery>

    </ng-container>
    <ng-container *ngIf="webNavService.currentWebSwitchPanelMode === SWITCH_PANEL_ITEM.PURCHASES">
      <div class="nodata-wrapper" *ngIf="userDataService.userPurchasedCards?.length === 0">
        <h1 class="nodata-title">Buy your first model</h1>
        <h3 class="nodata-desc">You don't have any purchased models</h3>
      </div>
      <app-card-horizontal-slider  [sourceCards]="userDataService.userPurchasedCards" (cardClicked)="onPurchasedModelsCardClicked($event)" ></app-card-horizontal-slider>
      <app-thumbnail-gallery *ngIf="userDataService.userPurchasedCollections?.length > 0" [sourceCollections]="userDataService.userPurchasedCollections" [galleryMode]="GALLERY_MODE.AGGREGATED_STATIC" [galleryTitle]="'Collections'" class="last-slider"></app-thumbnail-gallery>
    </ng-container>
    <ng-container *ngIf="webNavService.currentWebSwitchPanelMode === SWITCH_PANEL_ITEM.ALL">
      <div class="nodata-wrapper" *ngIf="filteredAllModels.length === 0 && uploadService.uploadModels.length === 0 && userDataService.isRecievedDraftCollectionsRespFromBackend && userDataService.isRecievedDraftCollectionsRespFromBackend">
        <h1 class="nodata-title">Your models right here</h1>
        <h3 class="nodata-desc">You don't have any models</h3>
      </div>
      <app-thumbnail-gallery [sourceCards]="filteredAllModels" (cardClicked)="onAllModelsCardClicked($event)"  class="zero-margin last-slider" >
        <!-- fxFlex="0 1 calc(33.3334% - 6.33px)" -->
        <app-card-thumbs-upload  class="gallery-thumb" [ngClass]="getLayoutClass()" *ngFor="let upCard of uploadService.uploadModels | callback: filterUploadCards" [uploadCard]="upCard"  fxLayout="column" fxLayoutAlign="start center"></app-card-thumbs-upload>
      </app-thumbnail-gallery>
    </ng-container>

  </div>

</div>

<app-name-change-dialogue [@fullscreenPanelAnimation] *ngIf="isNameChangeDialogueShown" [inputValue]="nameDialogDefaultValue" (valueChanged)="onNameDialogValueChanged($event)"></app-name-change-dialogue>

<app-navpanel *ngIf="isNavpanelShown" [@navpanelAnimation]="navpanelAnimationState"  #navPanelRef [navpanelActionItems]="portfolioNavpanelItems" (hideNavpanelEvent)="onHideNavpanelEventEmitted()" (navpanelActionClickEvent)="onNavpanelActionItemClicked($event)" ></app-navpanel>
<app-web-models-card @modelFeedAnimation #modelCardsFeed class="feed-overlap model-feed" *ngIf="isModelFeedShown" [startPosition]="cardFeedCardStartPosition" [feedIsVisible]="isModelFeedShown" [cardsRaw]="modelsToShowInFeed" [feedMode]="cardFeedMode" (cardPressed)="onModelCardPressed()" (cardClicked)="onModelCardClicked($event)" (actionBtnClicked)="onCardControlBtnClicked($event)"></app-web-models-card>
<app-fullsize-dragdrop></app-fullsize-dragdrop>
