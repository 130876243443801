import { IRecCard } from './../footer-menu/footer-menu.component';
import { Component, OnInit, Output, EventEmitter, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-make-reconstruct',
  templateUrl: './make-reconstruct.component.html',
  styleUrls: ['./make-reconstruct.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MakeReconstructComponent implements OnInit {
  @Input() recCard: IRecCard = {
    previewImage: 'data:image/gif;base64,R0lGODlhAQABAIAAAAUEBAAAACwAAAAAAQABAAACAkQBADs=',
    progressValue: 0,
    title: 'N/A',
    category: 'N/A',
    author: 'N/A',
    estimation: 'N/A',
    locationSegments: [],
    controlButtonName: 'N/A'
  };
  @Output() makeReconstructCancelClicked = new EventEmitter<string>();

  constructor() { }

  ngOnInit() {
  }

  public onReconstructOverlayClicked(): void {
    this.makeReconstructCancelClicked.emit('overlay clicked');
  }

  public onCancelButtonClicked(): void {
    this.makeReconstructCancelClicked.emit('cancelled');
  }

  public getMakeReconstructionFooterText(buttonName: string): string {
    switch (buttonName) {
      case 'stop':
        return 'Touch ID to STOP making 3D';
      case 'pause':
        return 'Touch ID to PAUSE making 3D';
      case 'play':
        return 'Touch ID to MAKE 3D';
      default:
        return 'Touch ID to MAKE 3D';
    }
  }


}
