import { FirebaseAuthService, PURCHASE_PROCESS } from 'src/app/shared/providers/firebase-auth.service';
import { SearchComponent } from './../../shared/components/search/search.component';
import { IAdditionalFooterData } from './../../models/common';
import { SplashScreen } from '@capacitor/splash-screen';
// eslint-disable-next-line max-len
import { CARD_CONTROL_BTN, ICardAction, INavpanelAction, LIBARY_MODE, ModelCardRenderedSelection, NAVPANEL_ACTION_TYPE, SWITCH_PANEL_ITEM, COLLECTION_CONTROL_BTN, CARD_PURCHASE_STATUS, SUBSCRIPTION_STATUS, GALLERY_MODE } from './../../models/card';
import { UploadFileService } from './../../shared/providers/upload-file.service';
import { Subscription } from 'rxjs';
// MIME TYPE HELPER https://trac.webkit.org/browser/trunk/Source/WebCore/platform/MIMETypeRegistry.cpp

import { Component, OnInit, OnDestroy, AfterViewInit, Inject, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { ICard, ModelCardRendered, CARDS_MODE } from 'src/app/models/card';
import { IGroupedCard, UPLOAD_STATUS } from 'src/app/models/groupedCard';
// eslint-disable-next-line max-len
import { collectionDetailsFadeAnimation, fullscreenPanelAnimation, inOutAnimation, libraryCardsGroundFadeOutAnimation, modelFeedAnimation, navpanelAnimation } from 'src/app/shared/providers/animations-list';
import { SettingsService } from 'src/app/shared/providers/settings.service';
import { environment } from 'src/environments/environment';
import { AnimationEvent } from '@angular/animations';
import { CardsService } from 'src/app/shared/providers/cards.service';
import { MockDataService } from 'src/app/shared/providers/mock-data.service';
import { PageScrollInstance, PageScrollService } from 'ngx-page-scroll-core';
import { DOCUMENT } from '@angular/common';
import { ICardCollection, ICollectionEditOptions } from 'src/app/models/collection';
import { UserDataService } from 'src/app/shared/providers/user-data.service';
import { UtilityFunctionsService } from 'src/app/shared/providers/utility-functions.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { DialogData, ConfirmationDialogComponent } from 'src/app/shared/components/dialog/confirmation-dialog.component';
import { CardSelectPanelComponent } from 'src/app/shared/components/card-select-panel/card-select-panel.component';
import { FilePicker } from '@capawesome/capacitor-file-picker';
import { CollectionDetailsComponent } from 'src/app/shared/components/collection-details/collection-details.component';
import { ModelsCardComponent } from 'src/app/shared/components/models-card/models-card.component';
import { FOOTER_MODE, NAVBAR_BTN } from 'src/app/models/menu';
import { NavbarComponent } from 'src/app/shared/components/navbar/navbar.component';

@Component({
  selector: 'app-library',
  templateUrl: './library.component.html',
  styleUrls: ['./library.component.scss'],
  animations: [
    inOutAnimation,
    fullscreenPanelAnimation,
    modelFeedAnimation,
    navpanelAnimation,
    collectionDetailsFadeAnimation
],
})
export class LibraryComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('collectionDetailsComponent', { static: false })
  public collectionDetailsComponent: CollectionDetailsComponent;

  @ViewChild('libraryPageViewRef')
  public libraryPageViewRef: ElementRef;

  @ViewChild('modelCardsFeed', { static: false })
  public modelCardsFeedRef: ModelsCardComponent;

  @ViewChild('searchInput') searchInput: ElementRef<HTMLInputElement>;

  @ViewChild('cardSelectRef')
  public cardSelectRef: CardSelectPanelComponent;

  @ViewChild('searchRef')
  public searchRef: SearchComponent;


  @ViewChild('navbarRef')
  public navbarRef: NavbarComponent;

  public libraryScrollInstance: PageScrollInstance;

  SWITCH_PANEL_ITEM = SWITCH_PANEL_ITEM;
  FOOTER_MODE = FOOTER_MODE;
  CARDS_MODE = CARDS_MODE;
  LIBARY_MODE = LIBARY_MODE;
  GALLERY_MODE = GALLERY_MODE;

  public isFirstUserAdvShown: boolean = false;
  public currentLibraryFooterMode: FOOTER_MODE = FOOTER_MODE.LIBRARY;
  public currentLibraryMode: LIBARY_MODE = LIBARY_MODE.DEFAULT;
  public currentSeeAllSwitchPanelMode: SWITCH_PANEL_ITEM = SWITCH_PANEL_ITEM.PUBLISHED;

  // public currentSwitchPanelMode: SWITCH_PANEL_ITEM = SWITCH_PANEL_ITEM.PORTFOLIO;

  public inAppPurchaseSubscriptionProcessSubscription: Subscription;

  public libraryItems: IGroupedCard[] = [];

  public isSearchActive: boolean = false;

  public isSubscriptionSplashShown: boolean = false;
  public isRejectedScreenShown: boolean = false;
  public isSubscriptionInProcess: boolean = false;

  public isModelFeedShown: boolean = false;
  public isCreateNewCollectionShown: boolean = false;
  public isCardSelectShown: boolean = false;
  public isCollectionSelectShown: boolean = false;
  public isNavpanelShown: boolean = false;
  public isNavpanelHidden: boolean = false;
  public isNameChangeDialogueShown: boolean = false;
  public isRestoreDialogShown: boolean = false;
  public navpanelAnimationState: string;

  public cardSelectData: ModelCardRenderedSelection[];
  public  libraryAdditionalFooterData: IAdditionalFooterData = {priceData: undefined};

  public libraryNavpanelItems: INavpanelAction[] = [];

  // ModelFeed
  public modelCardsMock: ModelCardRendered[]
  public cardFeedCardStartPosition: number;
  public cardFeedMode: CARDS_MODE = CARDS_MODE.MODEL_CARD_FEED;


  public cardsLiked: ICard[];
  public collectionsLiked: ICardCollection[];

  public cardToEdit: ICard;


  public fileToUpload: File;
  public nameDialogDefaultValue: string;
  public lastNameInputValue: string;
  public isCardPriceShown: boolean;


  public collectionDetailsSource: ICardCollection;
  public collectionEditOptions: ICollectionEditOptions;
  public filterCardMode: NAVPANEL_ACTION_TYPE;
  public isCollectionDetailsShown: boolean;
  uploadErrorSub: Subscription;
  uploadStatusSub: Subscription;


  constructor(
    public userDataService: UserDataService, public mockDataService: MockDataService,private readonly route: ActivatedRoute, private readonly router: Router,
    public firebaseAuth: FirebaseAuthService,
    private cdr: ChangeDetectorRef,
    public pageScrollService: PageScrollService, public settingService: SettingsService, public cardService: CardsService, public uploadService: UploadFileService,
    @Inject(DOCUMENT) private document: any, public dialog: MatDialog, public utilityService: UtilityFunctionsService,) {

  }

  ngOnInit() {

    // this.cardService.getUserDraftCollections();
    // this.cardService.deleteUserCollection();
    // this.cardService.createCollectionPostRequest();

    // LOGIC TO GET SOME MOCK IMAGES;
    // this.cardService.getCollectionFeed().then(()=>{
    //   this.userDataService.userDraftCollections = this.userDataService.userDraftCollections;

    //   // FOR DEBUG PURPOSES;
    //   // this.onLikedCollectionsClicked(this.userDataService.getSavedCollections()[0]);

    //   // FOR DEBUG PURPOSES;
    //   // this.currentLibraryFooterMode = FOOTER_MODE.PORTFOLIO;
    //   // this.currentLibraryMode = LIBARY_MODE.PORTFOLIO;
    //   // this.currentPortfolioSwitchPanelMode = SWITCH_PANEL_ITEM.PURCHASES;
    //   // this.filteredAllModels = this.userDataService.allUserCards;

    // });

    this.settingService.hideSplashScreen();

    this.uploadErrorSub = this.uploadService.uploadErrorSubscription.subscribe(()=> {
      this.handleNavpanelAction({ actionName: 'Upload Model usdz', actionType: NAVPANEL_ACTION_TYPE.TRIAL_EXPIRED });
    })

    this.uploadStatusSub = this.uploadService.uploadStatusSubscription.subscribe(()=> {
      this.cdr.detectChanges();
    });



    this.inAppPurchaseSubscriptionProcessSubscription = this.cardService.onSubscriptionPurchaseStateChange.subscribe((purchaseState: PURCHASE_PROCESS) => {
      console.log('subscriptionPurchaseProcessSubscription: ', purchaseState )
      switch (purchaseState) {
        case PURCHASE_PROCESS.DEFAULT:
          this.isSubscriptionInProcess = false;
          break;
        case PURCHASE_PROCESS.ERROR:
          this.isSubscriptionInProcess = false;
          break;
        case PURCHASE_PROCESS.PROCESSING:
          this.isSubscriptionInProcess = true;
          break;
        case PURCHASE_PROCESS.SUCCESS:
          console.log('subscriptionPurchaseProcessSubscription SUCCESS ' )

          setTimeout(() => {
            if(this.isSubscriptionSplashShown === true) {
              this.isSubscriptionSplashShown = false;
              // this.utilityService.showImportantSnackBar('You have successfully been subscribed for 3DWay !', 750)
              this.currentLibraryFooterMode = FOOTER_MODE.LIBRARY;
              this.cdr.detectChanges();

              setTimeout(() => {
                this.uploadService.pickAndUploadFileNative();
              }, 150);
            }
          }, 50);
          break;
        default:
          break;
      }
    });


  }

  ngAfterViewInit(): void {
    if(this.userDataService.librarySavedScrollPosition > 0) {
      window.setTimeout(()=>{
        this.libraryPageViewRef.nativeElement.scrollTo({
          top: this.userDataService.librarySavedScrollPosition,
          left: 0,
        });
        this.userDataService.librarySavedScrollPosition = 0;
      },0)
    }
  }


  // HANDLING CLICK ACTIONS ON DIFFERENT LIBRARY COMPONENTS HERE:

  public onDraftCollectionClicked(collection: ICardCollection): void {
    console.log(collection)
    this.userDataService.librarySavedScrollPosition = this.libraryPageViewRef.nativeElement.scrollTop;
    this.router.navigate(['/editcollection'], {
      relativeTo: this.route,
      queryParams: { collectionId: collection.collectionId },
      queryParamsHandling: 'merge'
    });
  }

  public onPortfolioCollectionClciked(): void {
    this.userDataService.librarySavedScrollPosition = this.libraryPageViewRef.nativeElement.scrollTop;
    this.router.navigate(['/portfolio'], {
      relativeTo: this.route,
      queryParamsHandling: 'merge'
    });
  }

  public onPublishReviewRejectModelsCardClicked(card: ICard): void {
    this.handleCardClick(card, CARDS_MODE.COMPOSITE_FEED, this.userDataService.userReviewPublishRejectModels);
  }

  private handleCardClick(card: ICard, cardFeedMode: CARDS_MODE, cardArray: ICard[]): void {
    console.log('handleCardClick: ', card)
    this.utilityService.triggerHaptics();
    this.cardFeedCardStartPosition = cardArray.findIndex(thumb => thumb.cardPosterMobile === card.cardPosterMobile);
    this.modelCardsMock = cardArray;
    this.cardFeedMode = cardFeedMode;
    this.isModelFeedShown = true;
    this.currentLibraryFooterMode = FOOTER_MODE.DEFAULT;
  }


  // HELPER HANDLERS:

  public filterUploadCards(groupedCard: IGroupedCard) {
    return groupedCard.uploadStatus.status === UPLOAD_STATUS.INPROGRESS || groupedCard.uploadStatus.status === UPLOAD_STATUS.PROCESSING
  }

  public onCancelSearchClicked(): void {
    this.isSearchActive = false;
    this.currentLibraryMode = LIBARY_MODE.DEFAULT;
    this.currentLibraryFooterMode = FOOTER_MODE.LIBRARY;
  }

  public onSeeAllClicked(): void {
    console.log('see all clicked');
    this.currentLibraryMode = LIBARY_MODE.PUBLISH_REJECTED_REVIEW;
    // this.isSeeAllShown = true;
    this.currentLibraryFooterMode = FOOTER_MODE.LIBRARY_PUBLISH_REJECTED_REVIEW;
  }

  public onPublishReviewRejectGalleryClicked(): void {
    console.log('see all clicked');
    this.currentLibraryMode = LIBARY_MODE.PUBLISH_REJECTED_REVIEW;
    // this.isSeeAllShown = true;
    this.currentLibraryFooterMode = FOOTER_MODE.LIBRARY_PUBLISH_REJECTED_REVIEW;
  }

  public onSwitchBtnSeeAllClicked(button: SWITCH_PANEL_ITEM): void {
    this.currentSeeAllSwitchPanelMode = button;
  }

  public onCancelRejectedScreenClicked(): void {
    this.isRejectedScreenShown = false;
  }

  public async onDefaultCollectionOrganizeBtnClick(btnName: string): Promise<void> {
    if(btnName === 'Organize') {
      this.currentLibraryFooterMode = FOOTER_MODE.LIBRARY_SELECT_MODELS_DISABLED;
      this.userDataService.allUserCards.forEach(card => card.isSelected = false);
      this.cardSelectData = this.userDataService.allUserCards;
      this.isCardPriceShown = false;
      this.isCardSelectShown = true;
      setTimeout(() => {
        this.cardSelectRef.showSwitchPanel();
        this.cardSelectRef.setSwitchPanelItem(SWITCH_PANEL_ITEM.ALL);
        }, 200);
    }
  }

  public isNoDataShown(): boolean {
    return this.userDataService.userReviewPublishRejectModels?.length === 0 && this.userDataService.allUserDraftCollections?.length === 0 && this.uploadService.uploadModels.length === 0 &&
           this.userDataService.savedCards.length === 0 && this.userDataService.uploadedCards.length === 0 && this.userDataService.isRecievedModelsRespFromBackend && this.userDataService.isRecievedDraftCollectionsRespFromBackend
  }

  public isNoPurchasePossible(collection: ICardCollection): boolean {
    if(collection?.collectionCards?.length > 0) {
      const isAnyCardPossibleToPurchase = collection.collectionCards.some(card => card.cardPrice && card.cardPrice > 0);
      // const isAny = collection.collectionCards.every(card => card.cardPrice && card.cardPrice > 0);
      return !isAnyCardPossibleToPurchase;
    } else return true;
  }

  public onNameDialogValueChanged(value: string): void {
    this.lastNameInputValue = value;
    return
  }

  public wasnotAuthorizedBefore(): boolean {
    return !this.settingService.getOriginalUidFromLocalstorage();
  }

  public onFootermodeChanged(value: FOOTER_MODE): void {
    this.currentLibraryFooterMode = value;
  }

  public logOutClick(): void {
    this.navbarRef.onLogoutBtnEvent()
  }

  // CARD-FEED BASIC HANDLERS:

  public onModelCardPressed() {
    this.currentLibraryFooterMode = FOOTER_MODE.CARD_FULLSCREEN;
  }

  public onModelCardClicked(card: ModelCardRendered) {
    this.currentLibraryFooterMode = this.currentLibraryFooterMode === FOOTER_MODE.DEFAULT || this.currentLibraryFooterMode === FOOTER_MODE.SHOW_BUY_BTN ? FOOTER_MODE.CARD_FULLSCREEN : FOOTER_MODE.DEFAULT;
  }

  public onCardControlBtnClicked(action: ICardAction): void {
    this.utilityService.triggerHaptics();
    this.cardToEdit = action.card;
    console.log('card clicked', action);

    switch (action.controlAction) {
      case CARD_CONTROL_BTN.CONTROL_ACTIONS_DEFAULT:
      case CARD_CONTROL_BTN.CONTROL_ACTION_FOR_LIKED:
          if(this.userDataService.isCardExitsInSaved(action.card)) {
            this.libraryNavpanelItems = [
              {actionName: 'Remove from Library', actionType: NAVPANEL_ACTION_TYPE.UNLIKE_CARD },
              {actionName: 'Share Model', actionType: NAVPANEL_ACTION_TYPE.SHARE_MODEL },
            ]
          } else {
            this.libraryNavpanelItems = [
              {actionName: 'Add to Library', actionType: NAVPANEL_ACTION_TYPE.UNLIKE_CARD },
              {actionName: 'Share Model', actionType: NAVPANEL_ACTION_TYPE.SHARE_MODEL },
            ]
          }
          this.isNavpanelShown = true;
          break;
      case CARD_CONTROL_BTN.CONTROL_ACTION_FOR_EDIT_CARD:
        this.libraryNavpanelItems = [
        ]
        this.isNavpanelShown = true;
        break;

      case CARD_CONTROL_BTN.CONTROL_ACTIONS_FOR_PURCHASED:
        this.libraryNavpanelItems = [
        ]
        this.isNavpanelShown = true;
        break;
      case CARD_CONTROL_BTN.CONTROL_ACTION_FOR_IN_REVIEW:
        this.libraryNavpanelItems = [
          {actionName: 'Cancel Publishing', actionType: NAVPANEL_ACTION_TYPE.CANCEL_PUBLISHING },
        ]
        this.isNavpanelShown = true;
        break;
      case CARD_CONTROL_BTN.CONTROL_ACTION_REJECTED:
        this.utilityService.triggerHaptics();
        this.isRejectedScreenShown = true;
        break;
      case CARD_CONTROL_BTN.CONTROL_ACTION_MORE_FOR_REJECTED:
        this.libraryNavpanelItems = [
          {actionName: 'Cancel Publishing', actionType: NAVPANEL_ACTION_TYPE.CANCEL_PUBLISHING },
        ]
        this.isNavpanelShown = true;
        break;
      case CARD_CONTROL_BTN.CONTROL_ACTION_FOR_PUBLISHED:
        this.libraryNavpanelItems = [
          {actionName: 'Cancel Publishing', actionType: NAVPANEL_ACTION_TYPE.CANCEL_PUBLISHING },
        ]
        this.isNavpanelShown = true;
        break;
      case CARD_CONTROL_BTN.CONTROL_BACK_BTN:
        this.utilityService.triggerHaptics();
        console.log('onModelFeedBackBtnClick: ');
        switch (true) {
          case this.isCollectionDetailsShown && this.isModelFeedShown:
            this.onNavbarBtnClick(NAVBAR_BTN.BACK);
            break;
          case this.currentLibraryMode === LIBARY_MODE.PUBLISH_REJECTED_REVIEW:
            this.utilityService.triggerHaptics();
            this.isModelFeedShown = false;
            this.currentLibraryFooterMode = FOOTER_MODE.LIBRARY_PUBLISH_REJECTED_REVIEW;
            break;
          default:
            this.currentLibraryFooterMode = FOOTER_MODE.LIBRARY;
            break;
        }
        this.isModelFeedShown = false;
        break;
      default:
        break;
    }
  }



  // MAIN BUTTON HANDLERS
  public onTopNavAdditionalMenuBtnClicked(): void {
    this.utilityService.triggerHaptics();
    this.navbarRef.navbarBtnClick(NAVBAR_BTN.ACCOUNT)

    // this.isNavpanelShown = true;
    // this.libraryNavpanelItems = this.userDataService.getTopNavPanelMenuItems();
  }



  public onNavbarBtnClick(navbarBtn: NAVBAR_BTN): void {
    console.log('onNavbarBtnClick: ', navbarBtn);
    switch (true) {
      case navbarBtn === NAVBAR_BTN.MORE_OPTIONS:
        if(this.currentLibraryFooterMode === FOOTER_MODE.PROFILE) {
            this.libraryNavpanelItems = this.userDataService.getProfileNavPanelItems();
        } else if (this.collectionDetailsSource && this.collectionEditOptions.mode === 'liked') {
          // BUTTONS LOGIC:
          const actionNaming = this.userDataService.isCollectionExitsInSaved(this.collectionDetailsSource) ? 'Remove from Library' : 'Add to Library';
          this.libraryNavpanelItems = [
            { actionName: actionNaming, actionType: NAVPANEL_ACTION_TYPE.UNLIKE_COLLECTION }
          ]
        } else if (this.collectionDetailsSource && this.collectionEditOptions.mode === 'purchased') {
          this.libraryNavpanelItems = [
            { actionName: 'Filter Purchases', actionType: NAVPANEL_ACTION_TYPE.FILTER_PURCHASES }
          ]
        }
        this.isNavpanelShown = true;
        this.utilityService.triggerHaptics();
        break;
      case navbarBtn === NAVBAR_BTN.ADD:
        this.isNavpanelShown = true;
        this.libraryNavpanelItems = [
          { actionName: 'New Collection', actionType: NAVPANEL_ACTION_TYPE.NEW_COLLECTION },
        ]

        console.log(this.userDataService.isFreeUploadLimitReached)
        console.log(this.firebaseAuth.currentUser?.uploadsNumber)

        // if(this.uploadService.isUploadingOrProcessingNow() && this.userDataService.userSubscriptionStatus !== SUBSCRIPTION_STATUS.SUBSCRIBER) {
        //   this.libraryNavpanelItems.push({ actionName: 'Uploading ...', actionType: NAVPANEL_ACTION_TYPE.UPLOAD_MODEL_DISABLED });
        //   break;
        // }

        // if(this.uploadService.isUploadingOrProcessingNow3inRow()) {
        //   this.libraryNavpanelItems.push({ actionName: 'Upload Model usdz', actionType: NAVPANEL_ACTION_TYPE.UPLOAD_MODEL_DISABLED });
        //   break;
        // }
        if (this.userDataService.userSubscriptionStatus === SUBSCRIPTION_STATUS.TRIAL && this.userDataService.isFreeUploadLimitReached) {
          this.libraryNavpanelItems.push({ actionName: 'Upload Model usdz', actionType: NAVPANEL_ACTION_TYPE.TRIAL_EXPIRED });
          break;
        }

        if(this.userDataService.userSubscriptionStatus === SUBSCRIPTION_STATUS.EXPIRED) {
          this.libraryNavpanelItems.push({ actionName: 'Upload Model usdz', actionType: NAVPANEL_ACTION_TYPE.TRIAL_EXPIRED });
          break;
        }


        if(this.userDataService.userSubscriptionStatus === SUBSCRIPTION_STATUS.NOT_SET) {
          this.libraryNavpanelItems.push({ actionName: 'Getting subscription status ...', actionType: NAVPANEL_ACTION_TYPE.UPLOAD_MODEL_DISABLED });
          break;
        }

        if(this.userDataService.userSubscriptionStatus === SUBSCRIPTION_STATUS.GUEST) {
          this.libraryNavpanelItems.push({ actionName: 'Upload Model usdz', actionType: NAVPANEL_ACTION_TYPE.UPLOAD_MODEL_NOT_SUBSCRIBED });
          break;
        }

        if (this.userDataService.userSubscriptionStatus === SUBSCRIPTION_STATUS.TRIAL) {
          this.libraryNavpanelItems.push({ actionName: 'Upload Model usdz', actionType: NAVPANEL_ACTION_TYPE.UPLOAD_MODEL });
          break;
        }

        if(this.userDataService.userSubscriptionStatus === SUBSCRIPTION_STATUS.TRIAL_EXPIRED) {
          this.libraryNavpanelItems.push({ actionName: 'Upload Model usdz', actionType: NAVPANEL_ACTION_TYPE.TRIAL_EXPIRED });
          break;
        }

        if (this.userDataService.userSubscriptionStatus === SUBSCRIPTION_STATUS.SUBSCRIBER) {
          this.libraryNavpanelItems.push({ actionName: 'Upload Model usdz', actionType: NAVPANEL_ACTION_TYPE.UPLOAD_MODEL });
          break;
        } else {
          this.utilityService.rollbarError(`Sorry, seems we having troubles with your subscription status; Please, contact support ${this.userDataService.userSubscriptionStatus}`)
          this.libraryNavpanelItems.push({ actionName: 'Upload Model', actionType: NAVPANEL_ACTION_TYPE.UPLOAD_MODEL_LIMIT_REACHED });
        }

        break;
      case navbarBtn === NAVBAR_BTN.FREE_TRIAL:
        this.utilityService.triggerHaptics();
        this.userDataService.tryForFreeClicked();
        this.isSubscriptionSplashShown = false;
        this.currentLibraryFooterMode = FOOTER_MODE.LIBRARY;
        this.utilityService.showImportantSnackBar('3 models free, subscribe after to store your entire portfolio', 150);
        if(this.settingService.devicePlatform === 'ios')  {
          setTimeout(() => {
            this.uploadService.pickAndUploadFileNative();
          }, 2750);
        }


        break;
        case navbarBtn === NAVBAR_BTN.SEARCH:
        this.utilityService.showImportantSnackBar('Coming soon', 250, 750);

        break;

      case navbarBtn === NAVBAR_BTN.HOME:
        this.router.navigateByUrl('/home');
        break;
      case navbarBtn === NAVBAR_BTN.UPLOAD:
        this.uploadService.requestFileUpload(this.fileToUpload, undefined);
        this.currentLibraryFooterMode = FOOTER_MODE.LIBRARY;
        this.isNameChangeDialogueShown = false;
        break;
      case navbarBtn === NAVBAR_BTN.SAVE && this.currentLibraryFooterMode === FOOTER_MODE.RENAME_ITEM:
        this.utilityService.notImplemented();
        // console.log('SAVE CLICK ON RENAME CLICKED')
        //   const editCardIndex = this.userDataService.defaultUserCollection.collectionCards.findIndex((card) => {
        //     return card.cardPosterMobile === this.cardToEdit.cardPosterMobile && card.cardTitle === this.cardToEdit.cardTitle
        //   })
        //   this.cardToEdit.cardTitle = this.lastNameInputValue;
        //   this.userDataService.defaultUserCollection.collectionCards[editCardIndex].cardTitle = this.lastNameInputValue;
        //   this.isNameChangeDialogueShown = false;
        //   this.currentLibraryFooterMode = FOOTER_MODE.DEFAULT;
        break;
      case navbarBtn === NAVBAR_BTN.SAVE:
        console.log('SAVE CLICK')
        if (this.currentLibraryFooterMode === FOOTER_MODE.LIBRARY_SELECT_COLLECTION_TO_MOVE) {
          // OLD: ORGANIZE MODELS TO COLLECTION; DEPRECATION

          this.isCollectionSelectShown = false;
          if(this.isCollectionDetailsShown)  this.currentLibraryFooterMode = FOOTER_MODE.LIBRARY_LIKED_COLLECTIONS_DEATAIL;
          else this.currentLibraryFooterMode = FOOTER_MODE.LIBRARY;

          const selectedCards: ModelCardRenderedSelection[] = (this.userDataService.allUserCards as ModelCardRenderedSelection[]).filter(card => card.isSelected);
          const selectedCollection: ICardCollection = this.userDataService.allUserDraftCollections.find(col => col.isSelected);

          this.userDataService.mutableOrganizeAllCardsToDraftCollectionOld(selectedCards, selectedCollection);
          this.cardService.updateCollectionPostRequest(selectedCollection).then((res) => {
            console.log(res);
            this.utilityService.showSnackBar('Collection updated', 500);
          }).catch(error => this.utilityService.showErrorSnackBar(`Error occured on update: ${JSON.stringify(error.error)}`, 500));

        }
        break;
      case navbarBtn === NAVBAR_BTN.SUBSCRIBE:
        this.utilityService.triggerHaptics();
        try {
          // DO NOT FORGET ADDING CURRENT USER;
          this.firebaseAuth.getUserUid().then((uid) => {
            // this.iapPurchaseService.orderBasicSubscription(uid);
          });
        } catch (error) {
          console.log(error)
        }
        break;
      case navbarBtn === NAVBAR_BTN.CREATE_COLLECTION:

        this.utilityService.triggerHaptics();

        const tempId = this.mockDataService.generateMockCollectionId();
        const emptyCollection: ICardCollection = {
          collectionId: tempId,
          collectionTitle: this.userDataService.newCollectionName !== '' ? this.userDataService.newCollectionName : this.userDataService.getNewCollectionDefaultName(),
          collectionCards: [],
          accessType: 'Private',
        }
        // this.userDataService.allUserDraftCollections.unshift(emptyCollection);
        this.userDataService.createUserCollection(emptyCollection);

        this.cardService.createCollectionPostRequest(emptyCollection).then((resp) => {
          this.utilityService.showSnackBar('Collection created', 500);
          const colId = resp.collection_id;
          this.userDataService.updateUserCollectionId(tempId, colId);
          const queryParamsUpdated: Params = { collectionId: colId };
          this.router.navigate(['/editcollection'], {
            relativeTo: this.route,
            queryParams: queryParamsUpdated,
            queryParamsHandling: 'merge'
          });
        }).catch(error => this.utilityService.showErrorSnackBar(`Error occured on update: ${JSON.stringify(error.error)}`, 500));

        // Add selected cards, if no collection was created before;

        // if(this.userDataService.userDraftCollections.length === 1 && this.thumbNotInCollection.filter(card => card.isSelected).length > 0) {
        //   this.userDataService.organizeNotInCollectionCardsToDraftCollection(this.thumbNotInCollection.filter(card => card.isSelected), this.userDataService.userDraftCollections[0])
        // }

        const queryParams: Params = { collectionId: emptyCollection.collectionId };
        this.router.navigate(['/editcollection'], {
          relativeTo: this.route,
          queryParams,
          queryParamsHandling: 'merge'
        });
        break;
      case navbarBtn === NAVBAR_BTN.TO_TOP:
        this.utilityService.notImplemented()
        break;
      case navbarBtn === NAVBAR_BTN.RESTORE:
        this.isRestoreDialogShown = false;
        this.currentLibraryFooterMode = FOOTER_MODE.LIBRARY;
        const restoredNubmer = this.userDataService.restoreRecentlyDeleted();
        if (restoredNubmer === 1) this.utilityService.showSnackBar(`Sucesfully restored 1 card`, 250)
        if (restoredNubmer > 1) this.utilityService.showSnackBar(`Sucesfully restored ${restoredNubmer} cards`, 250)
        break;
      case navbarBtn === NAVBAR_BTN.SELECT_ALL:
        this.cardSelectRef.selectOrDeselectAllItems();
        break;
      case navbarBtn === NAVBAR_BTN.PUBLISH:

        // First 'Publish' click:
        if(this.currentLibraryFooterMode === FOOTER_MODE.LIBRARY_PUBLISH_REJECTED_REVIEW) {
          this.cardSelectData = this.userDataService.allUserCards.filter(card => card.purchaseStatus === CARD_PURCHASE_STATUS.UPLOADED);
          this.cardSelectData?.forEach(cardRaw => cardRaw.isSelected = false);
          this.currentLibraryFooterMode = FOOTER_MODE.PUBLISH_SELECTED_MODELS;
          this.isCardSelectShown = true;
          break;
        }
          break;
      case navbarBtn === NAVBAR_BTN.NEXT:
        console.log('NEXT CLICKED')
        // Second Publish click
         this.userDataService.sendModelsForReview(this.cardSelectData.filter(card => card.isSelected));
         this.currentLibraryFooterMode = FOOTER_MODE.LIBRARY_PUBLISH_REJECTED_REVIEW;
         this.isCardSelectShown = false;

      //    {
      //     "review_id": "adb69b64-af8e-4cc4-a541-71c07fe2656d",
      //     "model_id": "uc1qbddqx_LOD01685557768536",
      //     "review_model_id": "03f85908-7a09-45ac-8c14-d0f6d71b1ca2"
      // }
        break;
      case navbarBtn === NAVBAR_BTN.BACK_PROFILE && this.currentLibraryMode === LIBARY_MODE.PUBLISH_REJECTED_REVIEW:
        this.currentLibraryFooterMode = FOOTER_MODE.LIBRARY_PUBLISH_REJECTED_REVIEW;
        break;
      case navbarBtn === NAVBAR_BTN.BACK_PROFILE:
        this.currentLibraryFooterMode = FOOTER_MODE.LIBRARY;
        break;
      case navbarBtn === NAVBAR_BTN.BACK && this.currentLibraryFooterMode === FOOTER_MODE.PUBLISH_SELECTED_MODELS:
        this.currentLibraryMode = LIBARY_MODE.PUBLISH_REJECTED_REVIEW;
        this.currentLibraryFooterMode = FOOTER_MODE.LIBRARY_PUBLISH_REJECTED_REVIEW;
        this.isCardSelectShown = false;
        break;
      case navbarBtn === NAVBAR_BTN.BACK && this.isCollectionDetailsShown && this.isCollectionSelectShown:
        this.utilityService.triggerHaptics();
        this.isCollectionSelectShown = false;
        this.currentLibraryFooterMode = FOOTER_MODE.LIBRARY_LIKED_COLLECTIONS_DEATAIL;
        break;
      case navbarBtn === NAVBAR_BTN.BACK && this.isCollectionDetailsShown && this.isCardSelectShown :
        this.currentLibraryFooterMode = FOOTER_MODE.LIBRARY_LIKED_COLLECTIONS_DEATAIL;
        this.isCardSelectShown = false;
        break

      case navbarBtn === NAVBAR_BTN.BACK && this.isCollectionDetailsShown && this.isModelFeedShown :
        this.currentLibraryFooterMode = FOOTER_MODE.LIBRARY_LIKED_COLLECTIONS_DEATAIL;
        this.isModelFeedShown = false;
        break;
      case navbarBtn === NAVBAR_BTN.BACK && this.isCollectionDetailsShown :
        this.currentLibraryFooterMode = FOOTER_MODE.LIBRARY;
        this.isCollectionDetailsShown = false;
        break;
      case navbarBtn === NAVBAR_BTN.BACK && this.isSearchActive:
        this.utilityService.triggerHaptics();
        this.isSearchActive = false;
        this.currentLibraryMode = LIBARY_MODE.DEFAULT;
        this.currentLibraryFooterMode = FOOTER_MODE.LIBRARY;
        break;
      case navbarBtn === NAVBAR_BTN.BACK && this.isSubscriptionSplashShown:
        this.utilityService.triggerHaptics();
        this.isSubscriptionSplashShown = false;
        this.currentLibraryFooterMode = FOOTER_MODE.LIBRARY;
        break;
      case navbarBtn === NAVBAR_BTN.BACK && this.currentLibraryMode === LIBARY_MODE.PUBLISH_REJECTED_REVIEW:
        this.utilityService.triggerHaptics();
        this.currentLibraryFooterMode = FOOTER_MODE.LIBRARY;
        this.currentLibraryMode = LIBARY_MODE.DEFAULT;
        break;
      case navbarBtn === NAVBAR_BTN.BACK && this.isModelFeedShown && this.isCollectionSelectShown:
        this.utilityService.triggerHaptics();
        this.isCollectionSelectShown = false;
        // this.isCardsNotInCollectionShown = false;
        this.currentLibraryMode = LIBARY_MODE.DEFAULT
        this.currentLibraryFooterMode = FOOTER_MODE.DEFAULT;
        break;
      case navbarBtn === NAVBAR_BTN.BACK && this.currentLibraryFooterMode === FOOTER_MODE.RENAME_ITEM:
        this.utilityService.triggerHaptics();
        this.isNameChangeDialogueShown = false;
        this.currentLibraryFooterMode = FOOTER_MODE.DEFAULT;
        break;
      case navbarBtn === NAVBAR_BTN.ORGANIZE:
        this.currentLibraryFooterMode = FOOTER_MODE.LIBRARY_SELECT_MODELS_DISABLED;
        this.userDataService.allUserCards.forEach(card => card.isSelected = false);
        this.cardSelectData = this.userDataService.allUserCards;
        this.isCardPriceShown = false;
        this.isCardSelectShown = true;
        setTimeout(() => {
          this.cardSelectRef.showSwitchPanel();
          this.cardSelectRef.setSwitchPanelItem(SWITCH_PANEL_ITEM.ALL);
          }, 200);
        break;
      case navbarBtn === NAVBAR_BTN.BUY:
        console.log('BUY BTN CLICKED');
        this.utilityService.notImplemented();
        break;
      case navbarBtn === NAVBAR_BTN.NOT_IMPL:
        this.utilityService.triggerHaptics();
        this.utilityService.notImplemented();
        break;
      default:
        this.utilityService.triggerHaptics();
        this.isModelFeedShown = false;
        this.isRestoreDialogShown = false;
        this.isCreateNewCollectionShown = false;
        this.isNameChangeDialogueShown = false;
        this.isCardSelectShown = false;
        this.isCollectionSelectShown = false;
        this.currentLibraryFooterMode = FOOTER_MODE.LIBRARY;
        break;
    }
  }

  public onNavpanelActionItemClicked(item: INavpanelAction): void {
    console.log(item.actionName);
    this.handleNavpanelAction(item);
    this.isNavpanelShown = false;
  }

  public handleNavpanelAction(action: INavpanelAction): void {
    const actionName = action.actionType
    switch (true) {
      case actionName === NAVPANEL_ACTION_TYPE.VIEW_ACCOUNT:
        this.navbarRef.navbarBtnClick(NAVBAR_BTN.ACCOUNT);
        break;
      case actionName === NAVPANEL_ACTION_TYPE.SIGN_OUT:
        this.navbarRef.onLogoutBtnEvent()
        break;
      case actionName === NAVPANEL_ACTION_TYPE.SETTINGS:
        this.router.navigateByUrl('/service');
        break;
      case actionName === NAVPANEL_ACTION_TYPE.CONTACT_US:
        this.utilityService.notImplemented();
        break;
      case actionName === NAVPANEL_ACTION_TYPE.LINK_PAYMENTS:
        this.utilityService.notImplemented();
        break;
      case actionName === NAVPANEL_ACTION_TYPE.SHARE_MODEL:
        this.modelCardsFeedRef.onShareBtnClicked(this.cardService.getCurrentCard());
        break;
      case actionName === NAVPANEL_ACTION_TYPE.UNLIKE_CARD:
        this.modelCardsFeedRef.dispatchCurrentCardLikeClick();
        break;


      case actionName === NAVPANEL_ACTION_TYPE.UPLOAD_MODEL:
        console.log('NAVPANEL UPLOAD ACTION');
        this.fileToUpload = action.optionalData;
        this.nameDialogDefaultValue = this.uploadService.getLastFileName() ? this.uploadService.getLastFileName() : 'default';
        this.uploadService.requestFileUpload(this.fileToUpload, undefined);
        this.currentLibraryFooterMode = FOOTER_MODE.LIBRARY;
        // this.isNameChangeDialogueShown = false;
        break;
      case actionName === NAVPANEL_ACTION_TYPE.UPLOAD_MODEL_NOT_SUBSCRIBED:
        this.isSubscriptionSplashShown = true;
        this.currentLibraryFooterMode = FOOTER_MODE.SUBSCRIPTION_SPLASH_SCREEN;
        // this.utilityService.notImplemented('You have to subscribe for 3DWay Base plan to be able to upload models.')
        break;
      case actionName === NAVPANEL_ACTION_TYPE.UPLOAD_MODEL_DISABLED:
        // this.isSubscriptionSplashShown = true;
        // this.utilityService.notImplemented('Only 1 model processing allowed for trial users at one time; Please, wait model to be processed')
        break;
      case actionName === NAVPANEL_ACTION_TYPE.UPLOAD_MODEL_LIMIT_REACHED:
        this.isSubscriptionSplashShown = true;
        this.utilityService.showErrorSnackBar('Upload limit reached. Subscribe to continue uploading.', 500);
        this.currentLibraryFooterMode = FOOTER_MODE.SUBSCRIPTION_SPLASH_SCREEN;
        break;
      case actionName === NAVPANEL_ACTION_TYPE.TRIAL_EXPIRED:
        this.isSubscriptionSplashShown = true;
        // this.utilityService.showErrorSnackBar('Your subscription expired', 500);
        console.log('this.utilityService.showErrorSnackBar(Your subscription expired);')
        this.currentLibraryFooterMode = FOOTER_MODE.SUBSCRIPTION_SPLASH_SCREEN;
        break;
      case actionName === NAVPANEL_ACTION_TYPE.SHOW_SUBSCRIBE:
        this.isSubscriptionSplashShown = true;
        this.currentLibraryFooterMode = FOOTER_MODE.SUBSCRIPTION_SPLASH_SCREEN;
        break;
      case actionName === NAVPANEL_ACTION_TYPE.NEW_COLLECTION:
        this.currentLibraryFooterMode = FOOTER_MODE.NEW_COLLECTION;
        this.isCreateNewCollectionShown = true;
        break;
      case actionName === NAVPANEL_ACTION_TYPE.RENAME_CARD:
        this.nameDialogDefaultValue = this.cardToEdit.cardTitle;
        this.currentLibraryFooterMode = FOOTER_MODE.RENAME_ITEM;
        this.isNameChangeDialogueShown = true;
        break;
      case actionName === NAVPANEL_ACTION_TYPE.DELETE_CARD:
        this.openDeleteCardConfirmationDialog(this.cardToEdit);
        break;
      case actionName === NAVPANEL_ACTION_TYPE.CANCEL_PUBLISHING:
        this.openCancelPublishingCardConfirmationDialog(this.cardToEdit)
        break;
      case actionName === NAVPANEL_ACTION_TYPE.UNLIKE_COLLECTION:
        // this.collectionDetailsComponent.dispatchLikeClick();
        this.collectionDetailsComponent.onLikeButtonClicked(this.collectionDetailsSource);
        break;
      case actionName === NAVPANEL_ACTION_TYPE.RECENTLY_DELETED:
        this.currentLibraryFooterMode = FOOTER_MODE.RESTORE_ITEMS;
        this.isRestoreDialogShown = true;
        break;
      case actionName === NAVPANEL_ACTION_TYPE.TEST_BUY_CONSUMABLE:
        // this.iapPurchaseService.testOrderConsumable(this.firebaseAuth.currentUser.uid)
        break;
      case actionName === NAVPANEL_ACTION_TYPE.TEST_BUY_SUBSCRIPTION:
        // this.iapPurchaseService.testOrderSubscription(this.firebaseAuth.currentUser.uid)
        break;
      default:
        break;
    }
  }


  public onHideNavpanelEventEmitted(): void {
    this.isNavpanelShown = false;
  }

  public onNavpanelDestroy(): void {
    this.navpanelAnimationState = 'visible';
    this.isNavpanelHidden = false;
  }

  public onNavpanelAnimationDone(event: AnimationEvent): void {
    console.log('animation done');
    if(event.toState !== 'void') {
      if(this.navpanelAnimationState === 'hidden') this.isNavpanelHidden = true;
    }
  }

  public openDeleteCardConfirmationDialog(card: ICard) {
    const dialogConfig: MatDialogConfig<DialogData> = {
      data: {
        dialogTitle: undefined,
        dialogText: `Do you want to delete this model from this collection ? `,
        confirmBtntext: 'Yes',
        declineBtnText: 'No'
      }
    };
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result === true) {
        if(card.purchaseStatus === CARD_PURCHASE_STATUS.IN_REVIEW || card.purchaseStatus === CARD_PURCHASE_STATUS.REJECTED) {
          this.userDataService.deleteCardInReview(card);
        } else {
          this.utilityService.showImportantErrorSnackBar('Error occured while deleting card. Please, contact support.', 500);
        }
        this.isModelFeedShown = false;
        this.currentLibraryFooterMode = FOOTER_MODE.PORTFOLIO;
      }
    });
  }

  public openCancelPublishingCardConfirmationDialog(card: ICard) {
    const dialogConfig: MatDialogConfig<DialogData> = {
      data: {
        dialogTitle: undefined,
        dialogText: `Are you sure you want to cancel publishing model "${card.cardTitle}" ? `,
        confirmBtntext: 'Yes',
        declineBtnText: 'No'
      }
    };
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result === true) {
        this.cardService.cancelReviewModelPostRequest(card).then((resp) => {
          this.utilityService.showSnackBar('Review cancelled succesfully.', 500);
        }).catch(error => this.utilityService.showErrorSnackBar(`Error occured on cancelling review: ${JSON.stringify(error.error)}`, 500));

        this.userDataService.cancelModelPublishing(this.cardToEdit);
        this.currentLibraryFooterMode = FOOTER_MODE.LIBRARY_PUBLISH_REJECTED_REVIEW;
        this.isModelFeedShown = false;

      }
    });
  }

  // FULLSCREEN PANELS HANDLERS:
  public onCardSelected(cardsSelected: ModelCardRenderedSelection[]) {
    if (cardsSelected?.length > 0) {
      this.currentLibraryFooterMode = FOOTER_MODE.LIBRARY_SELECT_MODELS
    } else {
      this.currentLibraryFooterMode = FOOTER_MODE.LIBRARY_SELECT_MODELS_DISABLED
    }
    console.log(cardsSelected?.length);
  }

  public onCardSelectSwitchPanelClicked(switchPanleBtn: SWITCH_PANEL_ITEM): void {
    switch (switchPanleBtn) {
      case SWITCH_PANEL_ITEM.UPLOADS:
        this.cardSelectData = this.userDataService.uploadedCards;
        break;
      case SWITCH_PANEL_ITEM.LIKES:
        this.cardSelectData = this.userDataService.getSavedCards();
        break;
      case SWITCH_PANEL_ITEM.PURCHASES:
        this.cardSelectData = this.userDataService.userPurchasedCards;
        break;
      case SWITCH_PANEL_ITEM.ALL:
        this.cardSelectData = this.userDataService.allUserCards;
        break;
      default:
        break;
    }
  }

  public onCollectionSelected(collectionSelected: ICardCollection): void {
    if(collectionSelected.isSelected) {
      this.currentLibraryFooterMode = FOOTER_MODE.LIBRARY_SELECT_COLLECTION_TO_MOVE
    } else {
      this.currentLibraryFooterMode = FOOTER_MODE.LIBRARY_SELECT_COLLECTION_TO_MOVE_DISABLED
    }
  }

  // LOGIC TO MAKE SAVED COLLECTION WORK PROPERLY;

  public onSavedCollectionClicked(collection: ICardCollection): void {
    console.log('saved collection clicked');
    this.utilityService.triggerHaptics();
    this.collectionDetailsSource = collection;
    this.collectionDetailsSource.showFullscreenBackgroundPreview = true;
    this.currentLibraryFooterMode = FOOTER_MODE.LIBRARY_LIKED_COLLECTIONS_DEATAIL;
    this.collectionEditOptions = {mode: 'liked'};
    this.filterCardMode = NAVPANEL_ACTION_TYPE.FILTER_ALL_MODELS;
    this.isCollectionDetailsShown = true;
  }

  public onCollectionDetailsThumbClick(thumbNumber: number): void {
    this.utilityService.triggerHaptics();
    console.log('on thumb click (library)');
    this.cardFeedCardStartPosition = thumbNumber;
    this.modelCardsMock = this.collectionDetailsSource.collectionCards;
    this.currentLibraryFooterMode = FOOTER_MODE.DEFAULT;
    this.cardFeedMode = CARDS_MODE.COMPOSITE_FEED;
    this.isModelFeedShown = true;
  }


  public onCollectionDetailsControlBtnClick(controlEvent: COLLECTION_CONTROL_BTN): void {
    console.log('onCollectionDetailsControlBtnClick', controlEvent);

    if(controlEvent === COLLECTION_CONTROL_BTN.DOWNLOAD_MODELS) {
      this.currentLibraryFooterMode = FOOTER_MODE.CARD_DOWNLOAD_PURCHASED;
      this.cardSelectData = JSON.parse(JSON.stringify(this.collectionDetailsSource.collectionCards.filter(cardRaw => cardRaw.purchaseStatus === CARD_PURCHASE_STATUS.PURCHASED)));
      this.cardSelectData.forEach(cardRaw => {
        cardRaw.isSelected = false;
        cardRaw.isDisabled = undefined;
      });
      this.isCardSelectShown = true;
    }
    if(controlEvent === COLLECTION_CONTROL_BTN.BUY_MODELS) {
      this.utilityService.notImplemented();
      // this.currentLibraryFooterMode = FOOTER_MODE.CARD_BUY_SECTION;
      // this.cardSelectData = this.collectionDetailsSource.collectionCards.filter(card =>  card.purchaseStatus === CARD_PURCHASE_STATUS.DEFAULT_3DWAY);
      // this.cardSelectData.forEach(cardRaw => cardRaw.isSelected = false);
      // this.libraryAdditionalFooterData.priceData = undefined;
      // this.cardSelectData.forEach(cardToSel => {
      //   if(cardToSel.purchaseStatus === CARD_PURCHASE_STATUS.PURCHASED) {
      //     cardToSel.isDisabled = true;
      //     cardToSel.isSelected = true;
      //   }
      // })

      // this.isBuySelectShown = true;
    }
    if(controlEvent === COLLECTION_CONTROL_BTN.FILTER_MODELS) {
        /// COMMON SERVICE MOVE

      this.libraryNavpanelItems = this.userDataService.getAvailableFiltersForCollection(this.collectionDetailsSource)

      const filterMode  = this.libraryNavpanelItems.find(item => item.actionType === this.filterCardMode)
      if(filterMode) filterMode.isSelected = true;
      else {
        this.filterCardMode = NAVPANEL_ACTION_TYPE.FILTER_ALL_MODELS;
        this.libraryNavpanelItems.find(item => item.actionType === NAVPANEL_ACTION_TYPE.FILTER_ALL_MODELS).isSelected = true;
      }
      this.isNavpanelShown = true;
    }
  }


  public ngOnDestroy(): void {
    this.inAppPurchaseSubscriptionProcessSubscription.unsubscribe();
    this.uploadErrorSub.unsubscribe();
    this.uploadStatusSub.unsubscribe();
  }

}
