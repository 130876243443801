import { Location_Type } from 'src/app/pages/albums/geolocation-albums/geolocation-albums.component';
 import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Router, RouterEvent, NavigationEnd } from '@angular/router';

export interface BreadCrumbLinkParam {
  name: string;
  code: string;
  type: string;
}

export interface BreadCrumbLinkUrl {
  name: string;
  url: string;
}



const LOCATION_SEPARATOR = '#';

@Component({
  selector: 'app-location-breadcrumbs',
  templateUrl: './location-breadcrumbs.component.html',
  styleUrls: ['./location-breadcrumbs.component.scss']
})
export class LocationBreadcrumbsComponent implements OnInit, OnChanges {
  @Input() locationCode: string;
  @Input() startBreadCrumbName: string;
  @Input() mode: string;
  // @Input('locationCode')
  // set locationCode(value: string) {
  //   const locationSegmentsRaw = this.convertUrlLocationCode(value);
  //   this.locationSegments = this.createUrlSegmentsLink(locationSegmentsRaw);
  //   console.log('setter');
  // }

  public locationSegments: BreadCrumbLinkParam[];
  public locationLinks: BreadCrumbLinkUrl[];

  public urlTree: any;
  public urlSegments: any;

  constructor(private router: Router) {}

  ngOnInit() {
    this.initNavbar();
    if (this.mode === 'navbar') {
      this.router.events.subscribe((event: RouterEvent) => {
        if (event instanceof NavigationEnd) {
        this.initNavbar();
        }
      });
    }
  }

  private initNavbar(): void {
    this.urlTree = this.router.parseUrl(this.router.url);
    if (this.urlTree.root.children.primary === undefined) { return; }
    this.urlSegments = this.urlTree.root.children.primary.segments;
    const segments = this.urlSegments.map(seg => seg.path);
    this.locationLinks = this.createLinksFromSegments(segments);

  }

  private createLinksFromSegments(segments: string[]): BreadCrumbLinkUrl[] {
    let currentPath = '';
    const pathArray = [];
    segments.forEach((seg) => {
      currentPath = currentPath + '/' + seg;
      pathArray.push({
        name: seg,
        url: currentPath
      });
    });
    return pathArray;

  }

  ngOnChanges(changes: SimpleChanges) {
    console.log('CHANGED');
    // if (changes['locationCode'].currentValue) {
    //   console.log('CHANGED VAL');
    const currValue = changes['locationCode'].currentValue;

    const locationSegmentsRaw = currValue ? this.convertUrlLocationCode(currValue) : [''];
    this.locationSegments = this.createUrlSegmentsLink(locationSegmentsRaw);
  }

  public convertUrlLocationCode(code: string): string[] {
    const decodedURIstring = decodeURIComponent(code);
    const decodedFromBase64 = window.atob(decodedURIstring);
    const splitted = decodedFromBase64.split(LOCATION_SEPARATOR);
    console.log(splitted);
    return splitted;
  }

  public createUrlSegmentsLink(segmentArr: string[]): BreadCrumbLinkParam[] {
    if (segmentArr[0] === '' || segmentArr[0] === null) {
      return [{ name: 'Continents', type: '', code: '' }];
    }
    const breadCrumbLinks: BreadCrumbLinkParam[] = [];
    let codeAccumulator: string = '';
    segmentArr.forEach((val, index) => {
      const name = val;
      let type;
      let code;
      codeAccumulator =
        codeAccumulator === ''
          ? codeAccumulator + name
          : codeAccumulator + LOCATION_SEPARATOR + name;
      switch (index) {
        case 0:
          type = Location_Type.countries;
          code = window.btoa(codeAccumulator);
          break;
        case 1:
          type = Location_Type.regions;
          code = window.btoa(codeAccumulator);
          break;
        case 2:
          type = Location_Type.cities;
          code = window.btoa(codeAccumulator);
          break;
        case 3:
          type = Location_Type.scenes;
          code = window.btoa(codeAccumulator);
          break;
        case 4:
          type = Location_Type.scene;
          code = window.btoa(codeAccumulator);
          break;
      }
      breadCrumbLinks.push({
        name,
        type,
        code
      });
    });
    breadCrumbLinks.unshift({name: 'Continents', type: '', code: ''});
    return breadCrumbLinks;
  }
}
