import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';


export interface DialogData {
  dialogTitle: string;
  dialogText: string;
  confirmBtntext?: string;
  declineBtnText?: string;
}



@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: 'confirmation-dialog.component.html',
  styleUrls: ['confirmation-dialog.component.scss']
})
export class ConfirmationDialogComponent {
  public isMobile: boolean = environment.type === 'mobile';

  constructor(@Inject(MAT_DIALOG_DATA) public data: DialogData) {
    this.data.confirmBtntext = this.data.confirmBtntext || 'Yes';
    this.data.declineBtnText = this.data.declineBtnText || 'No';
  }
}


