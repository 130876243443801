import { WebNavService } from './../../../web/webnavigation.service';
import { Component, OnDestroy } from '@angular/core';
import { FirebaseAuthService } from '../../providers/firebase-auth.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-authpopup',
  templateUrl: './authpopup.component.html',
  styleUrls: ['./authpopup.component.scss']
})
export class AuthpopupComponent implements OnDestroy {
  public isUserAuthenticated: boolean;
  public isDev: boolean = !environment.production;
  public errorMessage: string = '';
  public isActionActivated: boolean = false;

  ifEmailLoginShown: boolean = false;
  email: string = '';
  password: string = '';

  constructor(
    public authService: FirebaseAuthService,
    public webNavService: WebNavService,
  ) { }

  async onSignInWithAppleClicked()  {
  this.isActionActivated = true;

    try {
      await this.authService.loginWithAppleWeb();
    } catch (error) {

    }
  }

  async onSignInWithGoogleClicked()  {
  this.isActionActivated = true;

    try {
      await this.authService.loginWithGoogle();
    } catch (error) {

    }
  }

  async onSignInWithEmailClicked()  {
    this.ifEmailLoginShown = true;
    console.log('onSignInWithEmailClicked');
  }

  async onSubmitLoginFormClicked()  {
  this.isActionActivated = true;

    try {
      await this.authService.loginWithEmailAndPassword(this.email, this.password);
    } catch (error) {
      this.errorMessage = error;
    }
  }

  async signWithEmailLink() {
    this.authService.sendSignInLinkToEmail(this.email);
  }

  ngOnDestroy() {
    if(!this.isActionActivated) {
      this.webNavService.webAuthPopupClosed.emit(true);
    }


  }

}
