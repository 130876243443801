import { CardsService } from 'src/app/shared/providers/cards.service';
import { UploadFileService } from './../../providers/upload-file.service';
import { Component, EventEmitter, OnInit, Output, Input, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { PURCHASE_PROCESS } from '../../providers/firebase-auth.service';

@Component({
  selector: 'app-subscription-splash',
  templateUrl: './subscription-splash.component.html',
  styleUrls: ['./subscription-splash.component.scss']
})
export class SubscriptionSplashComponent implements OnInit, OnDestroy {
  @Input() tryForFreeMode: boolean = false;
  @Output() subscribeClicked = new EventEmitter<string>();
  @Output() cancelClicked = new EventEmitter<string>();
  @Output() tryforfreeClicked = new EventEmitter<string>();

  public inAppPurchaseSubscriptionProcessSubscription: Subscription;
  public isSubscriptionInProcess: boolean = false;



  constructor(
    public uploadService: UploadFileService,
    public cardService: CardsService
    ) { }

  ngOnInit(): void {
    this.inAppPurchaseSubscriptionProcessSubscription = this.cardService.onSubscriptionPurchaseStateChange.subscribe((purchaseState: PURCHASE_PROCESS) => {
      console.log('subscriptionPurchaseProcessSubscription: ', purchaseState )
      switch (purchaseState) {
        case PURCHASE_PROCESS.DEFAULT:
          this.isSubscriptionInProcess = false;
          break;
        case PURCHASE_PROCESS.ERROR:
          this.isSubscriptionInProcess = false;
          break;
        case PURCHASE_PROCESS.PROCESSING:
          this.isSubscriptionInProcess = true;
          break;
        default:
          break;
      }
    });
  }

  onSubscribeClick() {
    this.subscribeClicked.emit();
  }

  onCancelClick() {
    this.cancelClicked.emit();
  }

  onTryForFreeClick() {
    this.tryforfreeClicked.emit();
  }

  public handleNavbarFileInput(event: any): void {


    const file = (event.target as HTMLInputElement).files.item(0);
    if(file) {
      this.uploadService.requestFileUpload(file);
      // const generatedCardId = this.addUploadCard();
      // console.log('upload click, file selected!')
      // this.uploadButtonClicked.emit({
      //   file,
      //   cardId: generatedCardId
      // });
    }
    event.stopPropagation();

}

public handleTryForFreeClick() {
  console.log('handleClickUpload')
  this.tryforfreeClicked.emit();
}

  ngOnDestroy(): void {
    this.inAppPurchaseSubscriptionProcessSubscription.unsubscribe();
  }

}
