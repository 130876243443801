<div class="fullscreen-panel restore-deleted-panel-wrapper">
  <div class="restore-deleted-panel-content-row" fxLayout="column"   fxLayoutAlign="center center" >
    <span class="select-title">{{title}}</span>
  </div>
  <app-thumbnail-gallery [sourceCards]="deletedCards" [galleryMode]="GALLERY_MODE.SELECTION" (cardClicked)="onCardSelected($event)" class="low-margin-title last-slider"></app-thumbnail-gallery>
  <!-- <div class="switch-host">
    <div class="switch-wrapper" fxLayout="row" fxLayoutAlign="space-between center">
      <button class="switch-button " [ngClass]="{'selected' : isCollectionsActive}" (click)="onSwitchClick('Collections')">Collections</button>
      <button class="switch-button " [ngClass]="{'selected' : isModelsActive}" (click)="onSwitchClick('Models')">Models</button>
    </div>
  </div> -->
</div>
<div class="background-overlay dark-background-overscroll"></div>
