import {Directive} from '@angular/core';
import {ShowHideDirective, ClassDirective} from '@angular/flex-layout';

const inputs = [
    'ngClass', 'ngClass.xs', 'ngClass.sm', 'ngClass.md', 'ngClass.lg', 'ngClass.xl',
    'ngClass.lt-sm', 'ngClass.lt-md', 'ngClass.lt-lg', 'ngClass.lt-xl',
    'ngClass.gt-xs', 'ngClass.gt-sm', 'ngClass.gt-md', 'ngClass.gt-lg', 'ngClass.xxl', 'ngClass.gt-xl'
  ];

const selector = `
    [ngClass], [ngClass.xs], [ngClass.sm], [ngClass.md], [ngClass.lg], [ngClass.xl],
    [ngClass.lt-sm], [ngClass.lt-md], [ngClass.lt-lg], [ngClass.lt-xl],
    [ngClass.gt-xs], [ngClass.gt-sm], [ngClass.gt-md], [ngClass.gt-lg], [ngClass.xxl], [ngClass.gt-xl]
  `;


// eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
@Directive({selector, inputs})
export class CustomClassDirective extends ClassDirective {
  protected inputs = inputs;
}

