export interface IAdditionalFooterData {
  priceData?: string;
  selectToggleLabel?: string;
}

export enum LOG_LEVEL_OPTION {
  DEFAULT = 'default',
  ERROR_ONLY = 'error-only',
  PROD_MODE = 'prod-mode',
}

export const ALLOWED_MODELS_EXTENSIONS = ['.usdz', '.usda', '.usdc', '.usd', '.fbx', '.obj'];
