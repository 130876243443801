import { Component, OnInit, Inject } from '@angular/core';
import { Router } from '@angular/router';
import {MatDialog, MAT_DIALOG_DATA, MatDialogConfig} from '@angular/material/dialog';
import { ConfirmationDialogComponent, DialogData } from 'src/app/shared/components/dialog/confirmation-dialog.component';
import { ICard, CARD_TYPE, CARDS_MODE, CARD_PURCHASE_STATUS } from 'src/app/models/card';

@Component({
  selector: 'app-trashbin',
  templateUrl: './trashbin.component.html',
  styleUrls: ['./trashbin.component.scss'],
})

export class TrashbinComponent implements OnInit {

  public cards: ICard[] = [];
  public CARDS_MODE = CARDS_MODE;

  constructor(private readonly router: Router, public dialog: MatDialog) { }

  ngOnInit() {
    // this.cards.push({
    //   cardContentMobile: '/assets/images/scenesPage/ferm-full.jpg',
    //   cardType: CARD_TYPE.MODEL,
    //   cardCategory: 'Sculpture',
    //   cardAuthor: 'DRAFT',
    //   cardTitle: 'Ferm',
    //   cardDescription: `According to legend, Rome was founded in 753 BC by twin sons Romulus and Remus `,
    //   cardPrice: 0,
    //   purchaseStatus: CARD_PURCHASE_STATUS.DEFAULT_3DWAY
    // });

    // this.cards.push({
    //   cardContentMobile: '/assets/images/scenesPage/mosh-full.jpg',
    //   cardCategory: 'Sculpture',
    //   cardType: CARD_TYPE.MODEL,
    //   cardAuthor: 'DRAFT',
    //   cardTitle: 'Mosh',
    //   cardDescription: `Create the Sci-Fi fantasy environment of your dreams with this collection of peculiar plants, ethereal rocks and surfaces.`,
    //   cardPrice: 0,
    //   purchaseStatus: CARD_PURCHASE_STATUS.DEFAULT_3DWAY
    //   });
  }

  public loadSceneDetails() {
    this.router.navigateByUrl('/scenes/rome');
  }

  public onDeleteCardEventRecieved(cardTitle: string) {
    this.openDeleteConfirmationDialog(cardTitle);
  }

  public deleteCard(cardTitle: string) {
    const cardIndex = this.cards.findIndex(card => card.cardTitle === cardTitle);
    this.cards.splice(cardIndex, 1);
    console.log(cardTitle);
  }

  public onRestoreCardEventRecieved(cardTitle: string) {
    const cardIndex = this.cards.findIndex(card => card.cardTitle === cardTitle);
    this.cards.splice(cardIndex, 1);
    console.log(cardTitle);
  }

  public openDeleteConfirmationDialog(cardTitle: string) {
    const dialogConfig: MatDialogConfig<DialogData> = {
      data: {
        dialogTitle: 'Delete',
        dialogText: 'Are you sure you  want to delete this model permanently?',
        confirmBtntext: 'Yes, delete',
        declineBtnText: 'Cancel'
      }
    };
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
          if (result === true) { this.deleteCard(cardTitle); }
    });

    // this.dialog.open(ExampleDialogComponent, {
    //   data: 'panda'
    // });
  }
}
