import { CdkDragEnter, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, ViewEncapsulation, OnInit, OnDestroy, AfterViewInit, ViewChild, ElementRef, Output, Input, EventEmitter } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DomSanitizer } from '@angular/platform-browser';
import { throttleTime, switchMap, Observable, of } from 'rxjs';
import { NAVPANEL_ACTION_TYPE, CARD_PURCHASE_STATUS, COLLECTION_CONTROL_BTN, IImgPreloadSanitized, ICard, ModelCardRenderedSelection } from 'src/app/models/card';
import { ICardCollection, ICollectionEditOptions } from 'src/app/models/collection';
import { CardsService } from 'src/app/shared/providers/cards.service';
import { SettingsService } from 'src/app/shared/providers/settings.service';
import { UploadFileService } from 'src/app/shared/providers/upload-file.service';
import { UserDataService } from 'src/app/shared/providers/user-data.service';
import { UtilityFunctionsService } from 'src/app/shared/providers/utility-functions.service';



@Component({
  selector: 'app-web-collection-details',
  templateUrl: './web-collection-details.component.html',
  styleUrls: ['./web-collection-details.component.scss'],
  encapsulation: ViewEncapsulation.None,
  // changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
],

})
export class WebCollectionDetailsComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('collectionDetailsRef')
  public collectionDetailsRef: ElementRef;

  @ViewChild('collectionDescriptionTextareaRef')
  public collectionDescriptionTextareaRef: ElementRef;

  @Input() sourceCollection: ICardCollection;
  @Input() collectionEditOptions: ICollectionEditOptions;
  @Input() filterOptions: NAVPANEL_ACTION_TYPE;


  CARD_PURCHASE_STATUS = CARD_PURCHASE_STATUS;

  @Output() collectionLiked = new EventEmitter<ICardCollection>();

  @Output() collectionDetailsThumbClick = new EventEmitter<number>  ();
  @Output() controlButtonClick = new EventEmitter<COLLECTION_CONTROL_BTN> ();


  COLLECTION_CONTROL_BTN = COLLECTION_CONTROL_BTN;

  public imagesToPreloadSanitized: IImgPreloadSanitized[];

  private lastLikedBtnElementTarget: HTMLButtonElement;
  public collectionLikeClick: EventEmitter<ICardCollection> = new EventEmitter<ICardCollection>();

  constructor(
    public cardService: CardsService,
    public userDataService: UserDataService,
    public settingService: SettingsService,
    public uploadService: UploadFileService,
    private domSanitizer: DomSanitizer,
    private _snackBar: MatSnackBar,
    public utilityService: UtilityFunctionsService,
    ) {

      this.collectionLikeClick.pipe(
        throttleTime(500, undefined, { leading: true, trailing: false }),
        switchMap((col) => this.handleCollectionLikeClick(col))
      ).subscribe((response) => { });

     }

  ngOnInit(): void {
    this.imagesToPreloadSanitized = this.sourceCollection.collectionCards.map((element, index) => {
      return {
        url: element.cardPosterMobile,
        readyToPreload: index === 0 ? true : false,
        urlSanitized: this.domSanitizer.bypassSecurityTrustResourceUrl(element.cardPosterMobile),
      };
    });

    if(this.sourceCollection?.preloadSettings?.numberOfCardsToPreload) {
      const preloadArrLength = this.imagesToPreloadSanitized.length;
      const preloadSettingLength = this.sourceCollection.preloadSettings?.numberOfCardsToPreload;
      const totalPreloadCount = preloadArrLength > preloadSettingLength ? preloadSettingLength : preloadArrLength;
      this.imagesToPreloadSanitized.length = totalPreloadCount;
    }

  }

  dragStart(): void {
    this.utilityService.triggerHaptics();
  }

  dragReleased(): void {
    this.utilityService.triggerHaptics();
  }

  dragEntered(event: CdkDragEnter<number>) {
    const drag = event.item;
    const dropList = event.container;
    const dragIndex = drag.data;
    const dropIndex = dropList.data;

    const phContainer = dropList.element.nativeElement;
    const phElement = phContainer.querySelector('.cdk-drag-placeholder');
    phContainer.removeChild(phElement);
    phContainer.parentElement.insertBefore(phElement, phContainer);

    moveItemInArray(this.sourceCollection.collectionCards, dragIndex, dropIndex);
  }




  newfunc(): void {
    console.log('SCROLL NEW FUNC');
  }

  ngAfterViewInit(): void {
  }

  public async onShareBtnClicked(collectionToShare: ICardCollection, event?: Event, ): Promise<void> {
    if(event) event.stopPropagation();
    this.utilityService.notImplemented();
  }

  public preloadFinished(elementIndx: number): void {
    // DISABLED IN COLLECTION-DETAILS DUE TO ERROR IN SAFARI; CHECK LATER;
    // console.log('Img preloaded!', elementIndx);
    if (this.imagesToPreloadSanitized[elementIndx + 1]) {
      this.imagesToPreloadSanitized[elementIndx + 1].readyToPreload = true;
    }
  }

  public isCollectionSaved(collection: ICardCollection): boolean {
    // TODO: Create Logic for like collection/like card;

    return this.userDataService.isCollectionExitsInSaved(collection);
  }

  public selectDescriptionTextarea(): void {
      const inputElem: HTMLInputElement = this.collectionDescriptionTextareaRef.nativeElement;
      inputElem.setSelectionRange(inputElem.value.length, inputElem.value.length)
  }

  public nativeScrollToTopLight(): void {
    this.collectionDetailsRef.nativeElement.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }

  public dispatchLikeClick() {
    (this.collectionDetailsRef.nativeElement as HTMLElement).getElementsByClassName('collDetails-like-btn')[0].dispatchEvent(new Event('click'));
  }

  public onLikeButtonClicked(collection: ICardCollection, event?: Event): void {
    if(event) {
      // TODO: Logic of like collection cards;
      event.stopPropagation();
      // Workaround to trigger animation only when 'like' click button was performed;
      const eventTarget: any = event.currentTarget;
      this.lastLikedBtnElementTarget = eventTarget;
      eventTarget.classList.add('touched');
    }

    // this.userDataService.isCollectionExitsInSaved(collection) ? this.userDataService.removeFromSavedCollections(collection) : this.userDataService.addToSavedCollections(collection);
    this.collectionLikeClick.next(collection);


    if(this.userDataService.isCollectionExitsInSaved(collection)) {
      this._snackBar.open('Added to Library.',undefined,{
        horizontalPosition: 'start',
        verticalPosition: 'bottom',
        duration: 750,
        panelClass: 'like-snackbar'
      })
    } else {
      this._snackBar.open('Removed from Library.',undefined,{
        horizontalPosition: 'start',
        verticalPosition: 'bottom',
        duration: 750,
        panelClass: 'like-snackbar'
      })
    }
    // Emit 'Like' event to home page for FooterStateChange;
    this.collectionLiked.emit(collection);
  }

  public getPoster(url: string): string {
    const processedUrl = url ? url : 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNk+A8AAQUBAScY42YAAAAASUVORK5CYII='
    return processedUrl
  }

  public preloadError(event: Event): void {
    console.log('preloadErrror ', event)
  }

  public onThumbClick(thumbIndex: number): void {
    this.cardService.modelFeedInitStart = performance.now();

    if(this.collectionEditOptions?.mode === 'active-edit') return;
    console.log('On Thumb clicked (collection details)', thumbIndex);
    this.collectionDetailsThumbClick.emit(thumbIndex);
  }

  public onDeleteSignClick(thumbIndex: number): void {
    console.log('deleteClicked', thumbIndex);
    this.collectionDetailsThumbClick.emit(thumbIndex);
  }


  public onControlButtonClick(event: Event, buttonName: COLLECTION_CONTROL_BTN): void {
    console.log('controlbuttonClick', buttonName)
    event.stopPropagation();
    this.controlButtonClick.emit(buttonName);
  }

  public removeLikeTouchedClass(): void {
    if(this.lastLikedBtnElementTarget) {
      this.lastLikedBtnElementTarget.classList.remove('touched');
    }
  }

  public isCardPurchasedCheckById(card: ICard): boolean {
    return this.userDataService.isCardPurchasedCheckById(card.id);
  }

  public isAllCardsPurchased(): boolean {
    const standartLength = this.sourceCollection.collectionCards.length;
    const filteredCardsLength = this.sourceCollection.collectionCards.filter(card => card.purchaseStatus === CARD_PURCHASE_STATUS.PURCHASED).length
    if(standartLength === filteredCardsLength) return true;
    if(this.sourceCollection.collectionCards.filter(card => card.purchaseStatus === CARD_PURCHASE_STATUS.DEFAULT_3DWAY).length === 0) return true
  }

  public hasDraftLikedCards(): boolean {
    return this.sourceCollection.collectionCards.filter(card => card.purchaseStatus === CARD_PURCHASE_STATUS.DRAFT_LIKED).length > 0;
  }

  public filterCards = (card: ModelCardRenderedSelection) => {
    switch (this.filterOptions) {
      case NAVPANEL_ACTION_TYPE.FILTER_ALL_MODELS :
        return true
      case NAVPANEL_ACTION_TYPE.FILTER_SAVED_MODELS :
        return this.userDataService.isCardExitsInSaved(card) || card.purchaseStatus === CARD_PURCHASE_STATUS.DRAFT_LIKED;
      case NAVPANEL_ACTION_TYPE.FILTER_UPLOADED_MODELS :
        // TEMPORARY: REVERT BACK IN FUTURE (!!!!!)
        return card.purchaseStatus === CARD_PURCHASE_STATUS.UPLOADED || card.purchaseStatus === CARD_PURCHASE_STATUS.IN_REVIEW || card.purchaseStatus === CARD_PURCHASE_STATUS.PUBLISHED
        || card.purchaseStatus === CARD_PURCHASE_STATUS.REJECTED || card.purchaseStatus === CARD_PURCHASE_STATUS.REVIEW_PUBLISH_REJECT;
      case NAVPANEL_ACTION_TYPE.FILTER_PURCHASE_MODELS :
        return card.purchaseStatus === CARD_PURCHASE_STATUS.PURCHASED;
      case NAVPANEL_ACTION_TYPE.FILTER_REVIEW_MODELS :
        return card.purchaseStatus === CARD_PURCHASE_STATUS.IN_REVIEW || card.purchaseStatus === CARD_PURCHASE_STATUS.PUBLISHED || card.purchaseStatus === CARD_PURCHASE_STATUS.REJECTED;
      default:
        return true
    }
    // if(card.linkedCollectionId !== this.editCollectionSource?.collectionId) return false;
    // return card.uploadStatus.status === UPLOAD_STATUS.INPROGRESS || card.uploadStatus.status === UPLOAD_STATUS.PROCESSING

  }

  public isCustomFilterSelected(): boolean {
    switch (this.filterOptions) {
      case NAVPANEL_ACTION_TYPE.FILTER_SAVED_MODELS :
        return true
      case NAVPANEL_ACTION_TYPE.FILTER_UPLOADED_MODELS :
        return true;
      case NAVPANEL_ACTION_TYPE.FILTER_PURCHASE_MODELS :
        return true
      default:
        return false
    }
  }

  public isFilteringPossible(cards: ICard[]): boolean {
    if(!cards) return false;
    const isAnyCardLiked = cards.filter(card => this.userDataService.isCardExitsInSaved(card));
    const isAnyCardUploaded = cards.filter(card => card.purchaseStatus === CARD_PURCHASE_STATUS.UPLOADED);
    const isAnyCardPurchased = cards.filter(card => card.purchaseStatus === CARD_PURCHASE_STATUS.PURCHASED);
    if(isAnyCardLiked.length > 0) return true
    if(isAnyCardUploaded.length > 0) return true
    if(isAnyCardPurchased.length > 0) return true
    return false;
  }

  public isDownloadingPossible(cards: ICard[]): boolean {
    return cards.filter(card => card.purchaseStatus === CARD_PURCHASE_STATUS.PURCHASED).length > 0
  }

  private handleCollectionLikeClick(collection: ICardCollection): Observable<{ action: string }> {
    if (this.userDataService.isCollectionExitsInSaved(collection)) {
      this.userDataService.removeFromSavedCollections(collection);
      return of({ action: 'removed' });
    } else {
      this.userDataService.addToSavedCollections(collection);
      return of({ action: 'added' });
    }
  }

  ngOnDestroy(): void {
  }
}
