import * as Rollbar from 'rollbar'; // When using Typescript < 3.6.0.
// `import Rollbar from 'rollbar';` is the required syntax for Typescript 3.6.x.
// However, it will only work when setting either `allowSyntheticDefaultImports`
// or `esModuleInterop` in your Typescript options.
const buildInfo = require('../../../assets/build-info.json');


import {
  Injectable,
  Inject,
  InjectionToken,
  ErrorHandler
} from '@angular/core';
import { environment } from 'src/environments/environment';
import { LOCALSTORAGE_VAR } from 'src/app/models/localstorage';

const rollbarConfig: Rollbar.Configuration = {
  accessToken: environment.rollbarFullLogEnabled ? 'c8b0f1c8507e48aa904e82b351194277' : 'N/A',
  captureUncaught: true,
  captureDeviceInfo: true,
  captureUnhandledRejections: true,
  itemsPerMinute: environment.rollbarFullLogEnabled ? 10 : 1,
  maxItems: environment.rollbarFullLogEnabled ? 0 : 1,
  includeItemsInTelemetry: true,
  autoInstrument: { log: environment.rollbarFullLogEnabled },
  payload: {
    code_version: '1.0.0',
    source_map_enabled: true,
    environment: `${environment.type} ${environment.production ? 'prod' : 'dev'}`,
    envType: environment.production ? 'production' : 'development',
    envPlatform: environment.type,
    // platform: environment.type,
    custom_data: {
      commitHash: buildInfo?.commitHash || 'buildinfo.json ',
      buildDate: buildInfo?.buildDate || 'defaultBuildDate',
      originalUID: localStorage.getItem(LOCALSTORAGE_VAR.ORIGINAL_UID) || 'N/A'
    }
  }
};

export interface CustomRollbar {
  info(message: string, err?: any): void;
  error(message: string, err?: any): void;
}

export class DummyRollbar {
  error(err: any): void {
    // Ничего не делаем
    console.error(err.originalError || err);
  }
  handleError(err: any): void {
    this.error(err.originalError || err);
  }

  info(message: string, err: any): void {
    console.log(message, err?.originalError || err)
  }
}

export const RollbarService = new InjectionToken<Rollbar | DummyRollbar>('rollbar');
@Injectable()
export class RollbarErrorHandler implements ErrorHandler {
  constructor(@Inject(RollbarService) private rollbar: Rollbar) {}

  error(err: any): void {
    console.log('rollbar handling error: ', err);
    this.rollbar.error(err.originalError || err);
  }

  handleError(err: any): void {
    console.log('rollbar handling error: ', err);
    this.rollbar.error(err.originalError || err);
  }

  info(message: string, err: any): void {
    this.rollbar.info(message, err.originalError || err);
  }
}

export function rollbarFactory() {
  console.log('  rollbarFullLogEnabled: ', environment.rollbarFullLogEnabled);
  if (environment.rollbarFullLogEnabled) {
    return new Rollbar(rollbarConfig);
  } else {
    return new DummyRollbar();
  }
}
