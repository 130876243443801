import { Subscription } from 'rxjs';
import { ModelsCardComponent } from './../../shared/components/models-card/models-card.component';
import { FirebaseAuthService, PURCHASE_PROCESS } from './../../shared/providers/firebase-auth.service';
import { CardPublishPanelComponent } from './../../shared/components/card-publish-panel/card-publish-panel.component';
import { EditCollaboratorsComponent } from './../../shared/components/edit-collaborators/edit-collaborators.component';
import { UtilityFunctionsService } from './../../shared/providers/utility-functions.service';
import { IAdditionalFooterData } from './../../models/common';
import { PriceSelectPanelComponent } from './../../shared/components/price-select-panel/price-select-panel.component';
import { UserDataService } from 'src/app/shared/providers/user-data.service';
import { ICardCollection, ICollectionEditOptions } from 'src/app/models/collection';
import { CardSelectPanelComponent } from './../../shared/components/card-select-panel/card-select-panel.component';
import { NAVPANEL_ACTION_TYPE, INavpanelAction, CARDS_MODE, COLLECTION_CONTROL_BTN, CARD_CONTROL_BTN, ICardAction, ICard, ModelCardRenderedSelection, CARD_PURCHASE_STATUS, CARD_TYPE, SWITCH_PANEL_ITEM, SUBSCRIPTION_STATUS } from './../../models/card';
import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AnimationEvent } from '@angular/animations';
import { ActivatedRoute, Router } from '@angular/router';
import { PageScrollService } from 'ngx-page-scroll-core';
import { ModelCardRendered } from 'src/app/models/card';
import { CardsService } from 'src/app/shared/providers/cards.service';
import { MockDataService } from 'src/app/shared/providers/mock-data.service';
import { SettingsService } from 'src/app/shared/providers/settings.service';
import { UploadFileService } from 'src/app/shared/providers/upload-file.service';
import { fullscreenPanelAnimation, inOutAnimation, modelFeedAnimation, navpanelAnimation } from 'src/app/shared/providers/animations-list';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { DialogData, ConfirmationDialogComponent } from 'src/app/shared/components/dialog/confirmation-dialog.component';
import { CollectionDetailsComponent } from 'src/app/shared/components/collection-details/collection-details.component';
import { IGroupedCard, UPLOAD_STATUS } from 'src/app/models/groupedCard';
import { FOOTER_MODE, NAVBAR_BTN } from 'src/app/models/menu';

export interface ICollectionSnapshot {
  collection: ICardCollection;
}


@Component({
  selector: 'app-edit-collection',
  templateUrl: './edit-collection.component.html',
  styleUrls: ['./edit-collection.component.scss'],
  animations: [
    navpanelAnimation,
    inOutAnimation,
    fullscreenPanelAnimation,
    modelFeedAnimation
  ]
})
export class EditCollectionComponent implements OnInit, OnDestroy {

  @ViewChild('priceSelectRef')
  public priceSelectRef: PriceSelectPanelComponent;

  @ViewChild('editCollaboratorsPanelRef')
  public editCollaboratorsPanelRef: EditCollaboratorsComponent;

  @ViewChild('cardSelectRef')
  public cardSelectRef: CardSelectPanelComponent;

  @ViewChild('cardPublishRef')
  public cardPublishRef: CardPublishPanelComponent;

  @ViewChild('modelCardsFeed')
  public modelCardsFeed: ModelsCardComponent;

  @ViewChild('collectionDetailsComponent', { static: false })
  public collectionDetailsComponent: CollectionDetailsComponent;

  FOOTER_MODE = FOOTER_MODE;
  NAVPANEL_ACTION_TYPE = NAVPANEL_ACTION_TYPE;
  COLLECTION_CONTROL_BTN = COLLECTION_CONTROL_BTN;
  PURCHASE_PROCESS = PURCHASE_PROCESS;

  public isNavpanelShown: boolean = false;
  public isNavpanelHidden: boolean = false;
  public isNameChangeDialogueShown: boolean = false;
  public cardSelectTitle: string;
  public isCardSelectShown: boolean = false;
  public isModelFeedShown: boolean = false;
  public isPriceSelectShown: boolean = false;
  public isCollectionSelectShown: boolean = false;
  public isEditCollaboratorsShown: boolean = false;
  public isCardPublishShown: boolean = false;

  public isSendToReviewShown: boolean = false;

  public nameDialogDefaultValue: string;
  public lastNameInputValue: string;



  public editCollectionNavpanelItems: INavpanelAction[] = [];
  public navpanelAnimationState: string;

  public editCollectionSource: ICardCollection;
  public fileToUpload: File;

  public cardFeedCardStartPosition: number;
  public modelCardsMock: ModelCardRendered[];
  public cardToEdit: ModelCardRenderedSelection;

  public cardFeedMode: CARDS_MODE = CARDS_MODE.COMPOSITE_FEED;

  public editCollectionAdditionalfooterData: IAdditionalFooterData = {priceData: undefined};
  public currentEditCollectionFooterMode: FOOTER_MODE = FOOTER_MODE.EDIT_COLLECTION;
  public currentPurchaseState: PURCHASE_PROCESS;

  public filterCardMode: NAVPANEL_ACTION_TYPE = NAVPANEL_ACTION_TYPE.FILTER_ALL_MODELS;

  public currentPrice: number;

  public collectionEditOptions: ICollectionEditOptions = {
    mode: 'edit'
  }

  public cardSelectData: ModelCardRenderedSelection[];
  public cardsToPurchaseIds: string[];

  public editCollectionSnapshot: ICardCollection;
  public sourceCollectionId: string;
  public isCardPriceShown: boolean;
  public isSubscriptionSplashShown: boolean;
  public isMoveCardProcessActivated: boolean;
  public isBuySelectShown: boolean;
  public isRejectedScreenShown: boolean;



  public cardPurchaseStateChangeSubscription: Subscription;
  public inAppPurchaseSubscriptionProcessSubscription: Subscription;
  public uploadServiceSubscription: Subscription;
  public uploadErrorSub: Subscription;

  constructor(
    public mockDataService: MockDataService,private readonly router: Router, public pageScrollService: PageScrollService,
    public firebaseAuth: FirebaseAuthService,
    public settingService: SettingsService, public cardService: CardsService, public uploadService: UploadFileService,
    public userDataService: UserDataService, private readonly route: ActivatedRoute,
    public dialog: MatDialog, public utilityService: UtilityFunctionsService,
  ) {
   }

  ngOnInit(): void {
    this.route.queryParamMap.subscribe(queryParams => {
      this.sourceCollectionId = queryParams.get('collectionId');
      if (this.sourceCollectionId) {
        this.editCollectionSource = this.userDataService.allUserDraftCollections.find(col => col.collectionId === this.sourceCollectionId)

        // THIS LOGIC WRITTEN ONLY FOR DEBUG PURPOSES;
        if (this.editCollectionSource === undefined) {
          this.editCollectionSource = this.utilityService.generateMockCollection();
          const checkInterval = setInterval(() => {
            if (this.userDataService.allUserDraftCollections.find(col => col.collectionId === this.sourceCollectionId)) {
              setTimeout(() => {
                this.editCollectionSource = this.userDataService.allUserDraftCollections.find(col => col.collectionId === this.sourceCollectionId)
              }, 1000);
              clearInterval(checkInterval);
            }
          }, 1000);
        }
      } else {
        this.editCollectionSource = this.userDataService.allUserDraftCollections[this.userDataService.allUserDraftCollections.length - 1];
      }
    });
     this.editCollectionAdditionalfooterData = {
       priceData: undefined,
     }

     this.uploadErrorSub = this.uploadService.uploadErrorSubscription.subscribe(()=> {
      this.handleNavpanelAction({ actionName: 'Upload Model usdz', actionType: NAVPANEL_ACTION_TYPE.TRIAL_EXPIRED });
    })

     this.uploadServiceSubscription = this.uploadService.latestUploadedModelSubscription.subscribe((uploadStatus) => {
        // DIRTY HACK;
        setTimeout(() => {
          console.log('uploadServiceSubscription UPDATE TRIGGERED-> ', uploadStatus);
         this.editCollectionSource = this.userDataService.allUserDraftCollections.find(col => col.collectionId === this.sourceCollectionId)
        }, 500);
     });

     this.cardPurchaseStateChangeSubscription = this.cardService.onCardPurchaseStateChange.subscribe((purchaseState) => {
      this.currentPurchaseState = purchaseState;
      switch (purchaseState) {
        case PURCHASE_PROCESS.ERROR:
          this.editCollectionAdditionalfooterData.priceData = undefined
          this.editCollectionSource?.collectionCards?.forEach(card => card.isSelected = false);
          break;
        case PURCHASE_PROCESS.SUCCESS:
          console.log('PURCHASE_PROCESS.SUCCESS -> PORTFOLIO');
          this.editCollectionAdditionalfooterData.priceData = undefined
          this.editCollectionSource?.collectionCards?.forEach(card => card.isSelected = false);

          // COMPLEX LOGIC FOR PURCHASED LIKED CARD GOES HERE:
          // EASY STEP: FIND CARD IN LIKED AND MOVE TO PURCHASED --

          if(this.isModelFeedShown) {
            const purchased = this.editCollectionSource.collectionCards.find((cardToFind) => {return cardToFind.id === this.cardsToPurchaseIds[0]});
            purchased.purchaseStatus = CARD_PURCHASE_STATUS.PURCHASED;
            this.modelCardsMock = this.editCollectionSource.collectionCards;
            const purchasedCardIndexModelFeed = this.modelCardsFeed.cards.findIndex((cardToFind) => {
              return cardToFind.id === this.cardsToPurchaseIds[0];
            })
            this.modelCardsFeed.cards[purchasedCardIndexModelFeed].purchaseStatus = CARD_PURCHASE_STATUS.PURCHASED;
          }
          if(this.isBuySelectShown) {
            const purchased = this.editCollectionSource.collectionCards.find((cardToFind) => {return cardToFind.id === this.cardsToPurchaseIds[0]});
            purchased.purchaseStatus = CARD_PURCHASE_STATUS.PURCHASED;
            this.cardSelectData = this.editCollectionSource.collectionCards.filter(card => card.purchaseStatus === CARD_PURCHASE_STATUS.DRAFT_LIKED);
          }

          this.utilityService.showSnackBar('Purchase added to Library', 750);
          break;
        default:
          break;
      }
    })

    this.inAppPurchaseSubscriptionProcessSubscription = this.cardService.onSubscriptionPurchaseStateChange.subscribe((purchaseState: PURCHASE_PROCESS) => {
      // console.log('subscriptionPurchaseProcessSubscription: ', purchaseState )
      // switch (purchaseState) {
      //   case PURCHASE_PROCESS.DEFAULT:
      //     this.isSubscriptionInProcess = false;
      //     break;
      //   case PURCHASE_PROCESS.ERROR:
      //     this.isSubscriptionInProcess = false;
      //     break;
      //   case PURCHASE_PROCESS.PROCESSING:
      //     this.isSubscriptionInProcess = true;
      //     break;
      //   case PURCHASE_PROCESS.SUCCESS:
      //     console.log('subscriptionPurchaseProcessSubscription SUCCESS ' )

      //     setTimeout(() => {
      //       if(this.isSubscriptionSplashShown === true) {
      //         this.isSubscriptionSplashShown = false;
      //         this.utilityService.showImportantSnackBar('You have successfully been subscribed for 3DWay !', 750)
      //         this.currentLibraryFooterMode = FOOTER_MODE.LIBRARY;
      //         this.cdr.detectChanges();
      //       }
      //     }, 50);
      //     break;
      //   default:
      //     break;
      // }
    });


  }

  public onFootermodeChanged(value: FOOTER_MODE): void {
    this.currentEditCollectionFooterMode = value;
  }

  public onNameDialogValueChanged(value: string): void {
    this.lastNameInputValue = value;
    return
  }
  public onCollectionDetailsThumbClick(thumbNumber: number): void {
    switch (this.filterCardMode) {
      case NAVPANEL_ACTION_TYPE.FILTER_ALL_MODELS :
        this.modelCardsMock = this.editCollectionSource.collectionCards;
      break;
      case NAVPANEL_ACTION_TYPE.FILTER_SAVED_MODELS :
        this.modelCardsMock = this.editCollectionSource.collectionCards.filter((card) => this.userDataService.isCardExitsInSaved(card) || card.purchaseStatus === CARD_PURCHASE_STATUS.DRAFT_LIKED );
      break;
      case NAVPANEL_ACTION_TYPE.FILTER_UPLOADED_MODELS :
        this.modelCardsMock = this.editCollectionSource.collectionCards.filter((card) => card.purchaseStatus === CARD_PURCHASE_STATUS.UPLOADED );
      break;
      case NAVPANEL_ACTION_TYPE.FILTER_PURCHASE_MODELS :
        this.modelCardsMock = this.editCollectionSource.collectionCards.filter((card) => card.purchaseStatus === CARD_PURCHASE_STATUS.PURCHASED );
      break;
      case NAVPANEL_ACTION_TYPE.FILTER_REVIEW_MODELS :
        this.modelCardsMock = this.editCollectionSource.collectionCards.filter((card) => card.purchaseStatus === CARD_PURCHASE_STATUS.PUBLISHED ||
        card.purchaseStatus === CARD_PURCHASE_STATUS.IN_REVIEW || card.purchaseStatus === CARD_PURCHASE_STATUS.REJECTED );
      break;
      default:
        break;
    }

    this.cardFeedMode =  CARDS_MODE.COMPOSITE_FEED;
    if(this.collectionEditOptions.mode === 'active-edit') {
      console.log(' Active edit actions: ')

      // HERE LOGIC TO DELETE ONLY AFTER SAVE;
      // this.openThumbDeleteConfirmationDialog(this.editCollectionSource.collectionCards[thumbNumber], thumbNumber);
      this.userDataService.addCardToDeleteBuffer(this.editCollectionSource.collectionCards[thumbNumber]);
      this.editCollectionSource = this.userDataService.deleteCardFromCollection(this.editCollectionSource.collectionCards[thumbNumber], this.editCollectionSource);
    }
    if(this.collectionEditOptions.mode === 'edit') {
      this.utilityService.triggerHaptics();
      this.cardFeedCardStartPosition = thumbNumber;
      // this.modelCardsMock = this.editCollectionSource.collectionCards;
      this.currentEditCollectionFooterMode = FOOTER_MODE.DEFAULT;
      this.isModelFeedShown = true;
    }
    console.log(' Edit-collection onCollectionDetailsThumbClick')
  }


  public onNavbarBtnClick(navbarBtn: NAVBAR_BTN): void {
    switch (true) {
      case navbarBtn === NAVBAR_BTN.UPLOAD:
        this.collectionEditOptions.isUploadProgressBarShown = true;
        this.uploadService.requestFileUpload(this.fileToUpload, this.editCollectionSource.collectionId);
        this.currentEditCollectionFooterMode = FOOTER_MODE.EDIT_COLLECTION;
        this.isNameChangeDialogueShown = false;
        break;
      case navbarBtn === NAVBAR_BTN.HOME:
        this.router.navigateByUrl('/library');
        break;
      case navbarBtn === NAVBAR_BTN.IN_REVIEW:
        this.onCollectionDetailsControlButtonClicked(COLLECTION_CONTROL_BTN.EDIT);
        break;
      case navbarBtn === NAVBAR_BTN.INVITE_COLLABORATOR:
        const collaborator = this.userDataService.addMockCollaborator();
        if(collaborator) {
          this.editCollectionSource.collaborators.unshift(collaborator);
          this.utilityService.showSnackBar('Collaborator successfully invited!', 0)
        } else {
          this.utilityService.notImplemented();
        }
        break;
      case navbarBtn === NAVBAR_BTN.REMOVE_COLLABORATOR:
        this.openRemoveCollaboratorDialog();

        // this.utilityService.notImplemented();
        break;
      case navbarBtn === NAVBAR_BTN.FREE_TRIAL:
        this.utilityService.triggerHaptics();
        this.userDataService.tryForFreeClicked();
        this.isSubscriptionSplashShown = false;
        this.currentEditCollectionFooterMode = FOOTER_MODE.EDIT_COLLECTION;
        this.utilityService.showImportantSnackBar('3 models free, subscribe after to store your entire portfolio', 150);
        if(this.settingService.devicePlatform === 'ios')  {
          setTimeout(() => {
            this.uploadService.pickAndUploadFileNative();
          }, 2750);
        }
        break;
        case navbarBtn === NAVBAR_BTN.SUBSCRIBE:
          this.utilityService.triggerHaptics();
          try {
            // DO NOT FORGET ADDING CURRENT USER;
            this.firebaseAuth.getUserUid().then((uid) => {
              // this.iapPurchaseService.orderBasicSubscription(uid);
            });
          } catch (error) {
            console.log(error)
          }
          break;
      case navbarBtn === NAVBAR_BTN.ADD:
        console.log('NAVBAR_BTN.ADD');
        const isDisabled = this.userDataService.allUserCards.length === 0 ? true : false;
        this.editCollectionNavpanelItems = [
          {actionName: 'Add from Library', actionType: NAVPANEL_ACTION_TYPE.ADD_FROM_LIBRARY, optionalData: {disabled: isDisabled} },
        ]

        this.utilityService.triggerHaptics();
        this.isNavpanelShown = true;

        console.log(this.userDataService.isFreeUploadLimitReached)
        console.log(this.firebaseAuth.currentUser?.uploadsNumber)

        // if(this.uploadService.isUploadingOrProcessingNow() && this.userDataService.userSubscriptionStatus !== SUBSCRIPTION_STATUS.SUBSCRIBER) {
        //   this.editCollectionNavpanelItems.push({ actionName: 'Uploading ...', actionType: NAVPANEL_ACTION_TYPE.UPLOAD_MODEL_DISABLED });
        //   break;
        // }

        // if(this.uploadService.isUploadingOrProcessingNow3inRow()) {
        //   this.editCollectionNavpanelItems.push({ actionName: 'Upload Model usdz', actionType: NAVPANEL_ACTION_TYPE.UPLOAD_MODEL_DISABLED });
        //   break;
        // }

        if(this.userDataService.userSubscriptionStatus === SUBSCRIPTION_STATUS.NOT_SET) {
          this.editCollectionNavpanelItems.push({ actionName: 'Getting subscription status ...', actionType: NAVPANEL_ACTION_TYPE.UPLOAD_MODEL_DISABLED });
          break;
        }

        if(this.userDataService.userSubscriptionStatus === SUBSCRIPTION_STATUS.GUEST) {
          this.editCollectionNavpanelItems.push({ actionName: 'Upload Model usdz', actionType: NAVPANEL_ACTION_TYPE.UPLOAD_MODEL_NOT_SUBSCRIBED });
          break;
        }

        if (this.userDataService.userSubscriptionStatus === SUBSCRIPTION_STATUS.TRIAL) {
          this.editCollectionNavpanelItems.push({ actionName: 'Upload Model usdz', actionType: NAVPANEL_ACTION_TYPE.UPLOAD_MODEL, optionalData: this.editCollectionSource.collectionId });
          break;
        }

        if(this.userDataService.userSubscriptionStatus === SUBSCRIPTION_STATUS.TRIAL_EXPIRED) {
          this.editCollectionNavpanelItems.push({ actionName: 'Upload Model usdz', actionType: NAVPANEL_ACTION_TYPE.TRIAL_EXPIRED });
          break;
        }

        if(this.userDataService.userSubscriptionStatus === SUBSCRIPTION_STATUS.EXPIRED) {
          this.editCollectionNavpanelItems.push({ actionName: 'Upload Model usdz', actionType: NAVPANEL_ACTION_TYPE.TRIAL_EXPIRED });
          break;
        }

        if (this.userDataService.userSubscriptionStatus === SUBSCRIPTION_STATUS.SUBSCRIBER) {
          this.editCollectionNavpanelItems.push({ actionName: 'Upload Model usdz', actionType: NAVPANEL_ACTION_TYPE.UPLOAD_MODEL, optionalData: this.editCollectionSource.collectionId });
          break;
        } else {
          this.utilityService.rollbarError(`Sorry, seems we having troubles with your subscription status; Please, contact support ${this.userDataService.userSubscriptionStatus}`)
          this.editCollectionNavpanelItems.push({ actionName: 'Upload Model', actionType: NAVPANEL_ACTION_TYPE.UPLOAD_MODEL_LIMIT_REACHED });
        }


        break;
      case navbarBtn === NAVBAR_BTN.MORE_OPTIONS:
        this.editCollectionNavpanelItems = [
          { actionName: 'Edit Collection', actionType: NAVPANEL_ACTION_TYPE.EDIT_COLLECTION },
          { actionName: 'Delete Collection', actionType: NAVPANEL_ACTION_TYPE.DELETE_COLLECTION },
        ]

        // const isPublishAvailable = this.editCollectionSource?.collectionCards?.some((card) => {return card.purchaseStatus === CARD_PURCHASE_STATUS.UPLOADED});
        // const publishItem = isPublishAvailable ? { actionName: 'Publish Models', actionType: NAVPANEL_ACTION_TYPE.PUBLISH_MODELS } :
        // { actionName: 'Publish Models', actionType: NAVPANEL_ACTION_TYPE.PUBLISH_MODELS_DISABLED };

        const buyItems = { actionName: 'Buy Models', actionType: NAVPANEL_ACTION_TYPE.BUY_MODELS };
        const downloadItems = { actionName: 'Download Models', actionType: NAVPANEL_ACTION_TYPE.DOWNLOAD_MODELS };



        // this.editCollectionNavpanelItems.splice(0, 0, publishItem);
        if(this.editCollectionSource.collectionCards.filter(card => card.purchaseStatus === CARD_PURCHASE_STATUS.DRAFT_LIKED || card.purchaseStatus === CARD_PURCHASE_STATUS.DEFAULT_3DWAY).length > 0) {
          if(this.editCollectionSource.collectionCards.filter(card => card.cardPrice && card.cardPrice > 0).length > 0) {
            this.editCollectionNavpanelItems.splice(1, 0, buyItems);
          }
        }
        if(this.editCollectionSource.collectionCards.filter(card => card.purchaseStatus === CARD_PURCHASE_STATUS.PURCHASED).length > 0) {
          this.editCollectionNavpanelItems.splice(2, 0, downloadItems);
        }


        this.isNavpanelShown = true;
        this.utilityService.triggerHaptics();

        // else if (this.editCollectionSource.accessType === 'Collaborative') {
        //   this.editCollectionNavpanelItems = [
        //     // {actionName: 'Make Private', actionType: NAVPANEL_ACTION_TYPE.MAKE_NON_COLLABORATIVE },
        //     // {actionName: 'Edit Collaborators', actionType: NAVPANEL_ACTION_TYPE.EDIT_COLLABORATORS },
        //     { actionName: 'Edit Collection', actionType: NAVPANEL_ACTION_TYPE.EDIT_COLLECTION },
        //     // { actionName: 'Delete Collection', actionType: NAVPANEL_ACTION_TYPE.DELETE_COLLECTION },
        //     { actionName: 'Publish Models', actionType: NAVPANEL_ACTION_TYPE.PUBLISH_MODELS },

        //   ]
        // }

        break;
      case navbarBtn === NAVBAR_BTN.PUBLISH:
        // REWORK THERE !!!

        const selectedCardsToPublish = this.cardPublishRef.cardsToSelect.filter((cardToFilter => cardToFilter.isSelected));
        selectedCardsToPublish.forEach((card)=>card.parentCollectionId = this.sourceCollectionId)
        this.userDataService.sendModelsForReview(selectedCardsToPublish);
        this.currentEditCollectionFooterMode = FOOTER_MODE.EDIT_COLLECTION;
        this.isCardPublishShown = false;
        this.isModelFeedShown = false;
        this.utilityService.showSnackBar('Models successfuly sent to review', 0);
        break;
      case navbarBtn === NAVBAR_BTN.SELECT_ALL:
        this.cardSelectRef.selectOrDeselectAllItems();
        break;
      case navbarBtn === NAVBAR_BTN.BUY:
        console.log('BUY BTN CLICKED');
        const purchasedCards = this.editCollectionSource.collectionCards.filter(card => card.purchaseStatus !== CARD_PURCHASE_STATUS.PURCHASED && card.isSelected);
        console.log(' SELECTED CARD TO PURCHASE: ', purchasedCards);

        this.cardsToPurchaseIds = purchasedCards.map(card => card.id);
        this.cardService.processConsumableInAppPurchaseRequest(purchasedCards);
        break;
      case navbarBtn === NAVBAR_BTN.NOT_IMPL:
        this.utilityService.notImplemented();
        break;
      case navbarBtn === NAVBAR_BTN.TO_TOP && this.isEditCollaboratorsShown:
        this.editCollaboratorsPanelRef.nativeScrollToPanelTop();
        break;
      case navbarBtn === NAVBAR_BTN.TO_TOP && !this.isEditCollaboratorsShown:
      this.collectionDetailsComponent.nativeScrollToTopLight();
        break;
      case navbarBtn === NAVBAR_BTN.SAVE && this.currentEditCollectionFooterMode === FOOTER_MODE.RENAME_ITEM:
        console.log('SAVE CLICK ON RENAME CLICKED')
          const editCardIndex = this.editCollectionSource.collectionCards.findIndex((card) => {
            return card.cardPosterMobile === this.cardToEdit.cardPosterMobile && card.cardTitle === this.cardToEdit.cardTitle
          })

          this.cardService.updateModelPostRequest(this.cardToEdit).then((res) => {
            this.utilityService.showSnackBar('Model renamed', 500);
          }).catch(error => this.utilityService.showErrorSnackBar(`Error occured on rename: ${JSON.stringify(error.error)}`, 500));

          this.cardToEdit.cardTitle = this.lastNameInputValue === '' ? this.userDataService.getNewUploadFileDefaultName() : this.lastNameInputValue;

          this.editCollectionSource.collectionCards[editCardIndex].modifiedDate = this.utilityService.getCurrentDateWithNanoseconds();
          this.editCollectionSource.collectionCards[editCardIndex].cardTitle = this.lastNameInputValue === '' ? this.userDataService.getNewUploadFileDefaultName() : this.lastNameInputValue;
          this.isNameChangeDialogueShown = false;
          this.currentEditCollectionFooterMode = FOOTER_MODE.DEFAULT;
        break;
      // ADD FROM USER LIBRARY -> SAVE BTN
      case navbarBtn === NAVBAR_BTN.SAVE && this.isCardSelectShown:

        this.currentEditCollectionFooterMode = FOOTER_MODE.EDIT_COLLECTION;
        this.isCardSelectShown = false
        const selectedCards = this.cardSelectRef.cardsToSelect.filter((cardToFilter => cardToFilter.isSelected));
        this.editCollectionSource = this.userDataService.organizeAllCardsToDraftCollection(selectedCards, this.editCollectionSource);

         this.cardService.updateCollectionPostRequest(this.editCollectionSource).then((res) => {
          console.log(res);
          this.utilityService.showSnackBar('Collection updated', 500);
        }).catch(error => this.utilityService.showErrorSnackBar(`Error occured on update: ${JSON.stringify(error.error)}`, 500));

        break;
      // case navbarBtn === NAVBAR_BTN.SAVE && this.isCollectionSelectShown:
      //   this.currentEditCollectionFooterMode = FOOTER_MODE.EDIT_COLLECTION;
      //   this.isCollectionSelectShown = false;
      //   this.isModelFeedShown = false;

      //   // NOT SURE WILL THIS WORK :
      //   const selectedCollection: ICardCollection = this.userDataService.allUserDraftCollections.find(col => col.isSelected);
      //   this.editCollectionSource = this.userDataService.moveSelectedCardBetweenCollectionDrafts(this.cardToEdit, this.editCollectionSource, selectedCollection);

      //   this.cardService.updateCollectionPostRequest(selectedCollection).then((res) => {
      //   }).catch(error => this.utilityService.showErrorSnackBar(`Error occured on update: ${JSON.stringify(error.error)}`, 500));

      //   this.cardService.updateCollectionPostRequest(this.editCollectionSource).then((res) => {
      //     this.utilityService.showSnackBar('Model moved, Collection updated', 500);
      //   }).catch(error => this.utilityService.showErrorSnackBar(`Error occured on update: ${JSON.stringify(error.error)}`, 500));

      //   break;
      // case navbarBtn === NAVBAR_BTN.SAVE && this.currentEditCollectionFooterMode === FOOTER_MODE.SET_PRICE:
      //   this.currentEditCollectionFooterMode = FOOTER_MODE.PUBLISH_SELECTED_MODELS;
      //   if(this.cardToEdit.cardPrice === undefined && this.currentPrice) {
      //     this.cardToEdit.isSelected = true;
      //     this.userDataService.changeStateIsNavbarMainActionDisabledTo(false);
      //   }
      //   this.cardToEdit.cardPrice = this.currentPrice;
      //   this.isPriceSelectShown = false

      //   this.cardService.updateModelPostRequest(this.cardToEdit).then((res) => {
      //     this.utilityService.showSnackBar('Price updated succesfully', 500);
      //   }).catch(error => this.utilityService.showErrorSnackBar(`Error occured on update: ${JSON.stringify(error.error)}`, 500));

      //   break;
      case navbarBtn === NAVBAR_BTN.SAVE:
        console.log('SAVE CLICK ON BASIC EDIT COLLECTION')
        this.currentEditCollectionFooterMode = FOOTER_MODE.EDIT_COLLECTION;
        this.collectionEditOptions.mode = 'edit';
        this.collectionEditOptions.isDescriptionEditing = false;
        this.userDataService.updateUserCollectionInUserDrafCollections(this.editCollectionSource);
          this.cardService.updateCollectionPostRequest(this.editCollectionSource).then((res) => {
            this.utilityService.showSnackBar('Collection updated', 500);
          }).catch(err => {this.utilityService.showErrorSnackBar(`Error occured on update: ${JSON.stringify(err.error)}` , 500)})
        break;
      case navbarBtn === NAVBAR_BTN.BACK && this.currentEditCollectionFooterMode === FOOTER_MODE.REMOVE_COLLABORATORS:
        this.currentEditCollectionFooterMode = FOOTER_MODE.EDIT_COLLABORATORS;
        this.editCollaboratorsPanelRef.setDefaultState();
        break;
      case navbarBtn === NAVBAR_BTN.BACK && this.collectionEditOptions.mode === 'active-edit':
        this.currentEditCollectionFooterMode = FOOTER_MODE.EDIT_COLLECTION;
        this.userDataService.cleanCardToDeleteBuffer();
        this.collectionEditOptions.mode = 'edit';
        this.collectionEditOptions.isDescriptionEditing = false;
        this.restoreFromSnapshot();
        break;
      case navbarBtn === NAVBAR_BTN.BACK && this.isCardSelectShown:
        console.log('BACK BTN');
        this.currentEditCollectionFooterMode = FOOTER_MODE.EDIT_COLLECTION;
        this.restoreFromSnapshot();
        this.isCardSelectShown = false
        break;
      case navbarBtn === NAVBAR_BTN.BACK && this.isPriceSelectShown:
        this.isPriceSelectShown = false;
        this.currentEditCollectionFooterMode = FOOTER_MODE.PUBLISH_SELECTED_MODELS;
        break;
      case navbarBtn === NAVBAR_BTN.BACK && this.isModelFeedShown && this.isCardPublishShown:
        console.log('BACK PUBLISH BTN isModelFeedShown');
        this.currentEditCollectionFooterMode = FOOTER_MODE.DEFAULT
        this.isCardPublishShown = false
        break;
      case navbarBtn === NAVBAR_BTN.BACK && this.isCardPublishShown:
        console.log('BACK PUBLISH BTN');
        this.currentEditCollectionFooterMode = FOOTER_MODE.EDIT_COLLECTION;
        this.isCardPublishShown = false
        break;
      case navbarBtn === NAVBAR_BTN.BACK && this.isNameChangeDialogueShown:
        this.currentEditCollectionFooterMode = FOOTER_MODE.EDIT_COLLECTION;
        this.isNameChangeDialogueShown = false
        break;
      case navbarBtn === NAVBAR_BTN.BACK && this.isPriceSelectShown:
        this.restoreFromSnapshot();
        this.currentEditCollectionFooterMode = FOOTER_MODE.EDIT_COLLECTION;
        this.isPriceSelectShown = false
        break;
      case navbarBtn === NAVBAR_BTN.BACK && this.isCollectionSelectShown:
        this.currentEditCollectionFooterMode = FOOTER_MODE.DEFAULT;
        this.isCollectionSelectShown = false
        break;
      case navbarBtn === NAVBAR_BTN.BACK && this.isEditCollaboratorsShown:
        this.currentEditCollectionFooterMode = FOOTER_MODE.EDIT_COLLECTION;
        this.isEditCollaboratorsShown = false
        break;
      case navbarBtn === NAVBAR_BTN.BACK && this.isSubscriptionSplashShown:
        this.currentEditCollectionFooterMode = FOOTER_MODE.EDIT_COLLECTION;
        this.isSubscriptionSplashShown = false
        break;
      case navbarBtn === NAVBAR_BTN.BACK && this.isBuySelectShown:
        this.currentEditCollectionFooterMode = FOOTER_MODE.EDIT_COLLECTION;
        this.isBuySelectShown = false
        break;
      case navbarBtn === NAVBAR_BTN.BACK:
        this.router.navigateByUrl('/library');
        break;
    }
  }

  public onCollectionDetailsControlButtonClicked(buttonName: COLLECTION_CONTROL_BTN): void {
    this.isSendToReviewShown = false;
    switch (buttonName) {
      case COLLECTION_CONTROL_BTN.FILTER_MODELS:
        this.editCollectionNavpanelItems = this.userDataService.getAvailableFiltersForCollection(this.editCollectionSource);
        const filterMode = this.editCollectionNavpanelItems.find(item => item.actionType === this.filterCardMode)
        if(filterMode) filterMode.isSelected = true;
        else {
          this.filterCardMode = NAVPANEL_ACTION_TYPE.FILTER_ALL_MODELS;
          this.editCollectionNavpanelItems.find(item => item.actionType === NAVPANEL_ACTION_TYPE.FILTER_ALL_MODELS).isSelected = true;
        }
        this.isNavpanelShown = true;
        break;
      case COLLECTION_CONTROL_BTN.EDIT_ADD_DESCRIPTION:
        this.collectionEditOptions.isDescriptionEditing = true;
        setTimeout(() => {
        this.collectionDetailsComponent.selectDescriptionTextarea();
        }, 150);
        break;
      case COLLECTION_CONTROL_BTN.EDIT_CHANGE_VIDEO:
        this.utilityService.notImplemented();
        break;
      case COLLECTION_CONTROL_BTN.COLLABORATIVE_SHARE:
        if(this.editCollectionSource.accessType === 'Private') {
          this.openCollaborativeConfirmationDialog();
        }
        if(this.editCollectionSource.accessType === 'Collaborative') {
          this.utilityService.showShareDialog(this.editCollectionSource.collectionTitle)
        };
        break
      default:
        break;
    }
  }

  public onCardControlBtnClicked(action: ICardAction): void {
    this.utilityService.triggerHaptics();
    this.cardToEdit = action.card;
    console.log('card clicked', action);

    switch (action.controlAction) {
      case CARD_CONTROL_BTN.CONTROL_ACTIONS_DEFAULT:
        this.editCollectionNavpanelItems = [
          {actionName: 'Rename Model', actionType: NAVPANEL_ACTION_TYPE.RENAME_CARD },
          {actionName: 'Delete Model', actionType: NAVPANEL_ACTION_TYPE.DELETE_CARD }
        ]
        this.isNavpanelShown = true;
        break;
      case CARD_CONTROL_BTN.CONTROL_ACTION_FOR_UPLOADED:
        this.editCollectionNavpanelItems = [
          {actionName: 'Rename Model', actionType: NAVPANEL_ACTION_TYPE.RENAME_CARD },
          {actionName: 'Remove from Collection', actionType: NAVPANEL_ACTION_TYPE.REMOVE_CARD_FROM_COLLECTION }
        ]
        this.isNavpanelShown = true;
        break;
      case CARD_CONTROL_BTN.CONTROL_ACTION_FOR_REVIEW_PUBLISH_REJECT:
        this.editCollectionNavpanelItems = [
          {actionName: 'Rename Model', actionType: NAVPANEL_ACTION_TYPE.RENAME_CARD },
          {actionName: 'Remove from Collection', actionType: NAVPANEL_ACTION_TYPE.REMOVE_CARD_FROM_COLLECTION }
        ]
        this.isNavpanelShown = true;
        break;
      case CARD_CONTROL_BTN.CONTROL_ACTION_FOR_LIKED:
        this.editCollectionNavpanelItems = [
          {actionName: 'Remove from Collection', actionType: NAVPANEL_ACTION_TYPE.UNLIKE_CARD },
        ]
        this.isNavpanelShown = true;
        break;
      case CARD_CONTROL_BTN.CONTROL_ACTIONS_FOR_UNLIKED:
        this.editCollectionNavpanelItems = [
          {actionName: 'Add to Collection', actionType: NAVPANEL_ACTION_TYPE.UNLIKE_CARD },
        ]
        this.isNavpanelShown = true;
        break;

      case CARD_CONTROL_BTN.CONTROL_ACTIONS_FOR_PURCHASED:
        this.editCollectionNavpanelItems = [
          {actionName: 'Remove from Collection', actionType: NAVPANEL_ACTION_TYPE.REMOVE_CARD_FROM_COLLECTION }
        ]
        this.isNavpanelShown = true;
        break;

        // REFACTOR; NOT ACTUAL NOW:
        case CARD_CONTROL_BTN.CONTROL_ACTION_FOR_IN_REVIEW:
          this.editCollectionNavpanelItems = [
            {actionName: 'Cancel Publishing', actionType: NAVPANEL_ACTION_TYPE.CANCEL_PUBLISHING },
            {actionName: 'Delete Model', actionType: NAVPANEL_ACTION_TYPE.DELETE_CARD },
          ]
          this.isNavpanelShown = true;
          break;
        case CARD_CONTROL_BTN.CONTROL_ACTION_REJECTED:
          this.utilityService.triggerHaptics();
          this.isRejectedScreenShown = true;
          break;
        case CARD_CONTROL_BTN.CONTROL_ACTION_MORE_FOR_REJECTED:
          this.editCollectionNavpanelItems = [
            {actionName: 'Cancel Publishing', actionType: NAVPANEL_ACTION_TYPE.CANCEL_PUBLISHING },
            {actionName: 'Delete Model', actionType: NAVPANEL_ACTION_TYPE.DELETE_CARD },
          ]
          this.isNavpanelShown = true;
          break;
        case CARD_CONTROL_BTN.CONTROL_ACTION_FOR_PUBLISHED:
          this.editCollectionNavpanelItems = [
            {actionName: 'Cancel Publishing', actionType: NAVPANEL_ACTION_TYPE.CANCEL_PUBLISHING },
          ]
          this.isNavpanelShown = true;
          break;

      case CARD_CONTROL_BTN.CONTROL_ACTION_FOR_PUBLISHED:
        this.editCollectionNavpanelItems = [
          {actionName: 'Cancel Publishing', actionType: NAVPANEL_ACTION_TYPE.CANCEL_PUBLISHING },
        ]
        this.isNavpanelShown = true;
        break;
      case CARD_CONTROL_BTN.PUBLISH_SINGLE_CARD:
        const selectedCardsToPublish = [this.editCollectionSource.collectionCards.find(card => card.id === action.card.id)];
        selectedCardsToPublish.forEach((card)=>card.parentCollectionId = this.sourceCollectionId)
        this.userDataService.sendModelsForReview(selectedCardsToPublish);
        this.currentEditCollectionFooterMode = FOOTER_MODE.EDIT_COLLECTION;
        this.isCardPublishShown = false;
        this.isModelFeedShown = false;
        this.utilityService.showSnackBar('Models successfuly sent to review', 0);

        //
        // console.log('PUBLISH SINGLE CARD', action);
        // this.cardSelectTitle = 'Select Models.'
        // this.cardSelectData = [this.editCollectionSource.collectionCards.find(card => card.cardContentMobile === action.card.cardContentMobile)];
        // this.cardSelectData[0].isSelected = false;
        // this.currentEditCollectionFooterMode = FOOTER_MODE.PUBLISH_SELECTED_MODELS;
        // this.isCardPublishShown = true;
        break;
      case CARD_CONTROL_BTN.BUY_MODEL:
        this.cardsToPurchaseIds = [action.card.id];
        this.cardService.processConsumableInAppPurchaseRequest([action.card]);
        break;
      case CARD_CONTROL_BTN.CONTROL_BACK_BTN:
        this.utilityService.triggerHaptics();
        this.currentEditCollectionFooterMode = FOOTER_MODE.EDIT_COLLECTION;
        this.isModelFeedShown = false;
        this.filterUnlikeCards();
        break;
      case CARD_CONTROL_BTN.LIKE_DRAFT_CARD:
        this.utilityService.triggerHaptics();
        const cardToLike = this.editCollectionSource.collectionCards.find(card => card.cardContentMobile === action.card.cardContentMobile);
        cardToLike.purchaseStatus = CARD_PURCHASE_STATUS.DRAFT_LIKED;
        this.modelCardsMock = this.editCollectionSource.collectionCards;

        this.cardService.updateCollectionPostRequest(this.editCollectionSource).then((res) => {
        }).catch(error => this.utilityService.showErrorSnackBar(`Error occured on update: ${JSON.stringify(error.error)}`, 500));
        break;
      case CARD_CONTROL_BTN.UNLIKE_DRAFT_CARD:
        this.utilityService.triggerHaptics();
        const cardToUnlike = this.editCollectionSource.collectionCards.find(card => card.cardContentMobile === action.card.cardContentMobile);
        cardToUnlike.purchaseStatus = CARD_PURCHASE_STATUS.DRAFT_NOT_LIKED;
        this.modelCardsMock = this.editCollectionSource.collectionCards;

        this.cardService.updateCollectionPostRequest(this.editCollectionSource).then((res) => {
        }).catch(error => this.utilityService.showErrorSnackBar(`Error occured on update: ${JSON.stringify(error.error)}`, 500));
        break;
      default:
        break;
    }

  }

  private filterUnlikeCards(): void {
    // this.userDataService.deleteCardFromCollection(this.editCollectionSource.collectionId, this.cardToEdit.cardId);
    this.editCollectionSource.collectionCards.filter(card => card.purchaseStatus === CARD_PURCHASE_STATUS.DRAFT_NOT_LIKED).forEach(modelCardUnLiked => {
      this.userDataService.deleteCardFromCollection(modelCardUnLiked, this.editCollectionSource)
    });
    this.editCollectionSource.collectionCards = this.editCollectionSource.collectionCards.filter(card => card.purchaseStatus !== CARD_PURCHASE_STATUS.DRAFT_NOT_LIKED);
    this.modelCardsMock = this.modelCardsMock.filter(card => card.purchaseStatus !== CARD_PURCHASE_STATUS.DRAFT_NOT_LIKED);
  }

  public onNavpanelActionItemClicked(item: INavpanelAction): void {
    console.log(item.actionName);
    this.handleNavpanelAction(item);
    this.isNavpanelShown = false;
  }

  public handleNavpanelAction(action: INavpanelAction): void {
    const actionName = action.actionType
    switch (true) {
      case actionName === NAVPANEL_ACTION_TYPE.MAKE_COLLABORATIVE:
        this.openCollaborativeConfirmationDialog();
        break;
      case actionName === NAVPANEL_ACTION_TYPE.MAKE_NON_COLLABORATIVE:
        this.openNonCollaborativeConfirmationDialog();
        break;
      case actionName === NAVPANEL_ACTION_TYPE.EDIT_COLLECTION:
        this.currentEditCollectionFooterMode = FOOTER_MODE.ACTIVE_EDIT_COLLECTION;
        this.createCollectionSnapshot();
        this.collectionEditOptions.mode = 'active-edit';
        this.filterCardMode = NAVPANEL_ACTION_TYPE.FILTER_ALL_MODELS;
        if(this.editCollectionSource.collectionDescription) this.collectionEditOptions.isDescriptionEditing = true;
        break;
      case actionName === NAVPANEL_ACTION_TYPE.DELETE_COLLECTION:
        this.openDeleteCollectionConfirmationDialog();

        break;
      case actionName === NAVPANEL_ACTION_TYPE.CANCEL_PUBLISHING:
        this.openCancelPublishingCardConfirmationDialog(this.cardToEdit)
        break;
      case actionName === NAVPANEL_ACTION_TYPE.ADD_FROM_LIBRARY:
        this.createCollectionSnapshot();
        // this.cardSelectData = JSON.parse(JSON.stringify(this.mockDataService.editCollectioncardSelectData));
        this.cardSelectData = this.userDataService.allUserCards;
        this.cardSelectData?.forEach(cardRaw => cardRaw.isSelected = false);
        this.currentEditCollectionFooterMode = FOOTER_MODE.ACTIVE_EDIT_COLLECTION;
        this.cardSelectTitle = 'Add to Collection.';
        this.isCardSelectShown = true;
        setTimeout(() => {
          this.cardSelectRef.showSwitchPanel();
          this.cardSelectRef.setSwitchPanelItem(SWITCH_PANEL_ITEM.ALL);
        }, 200);
        break;
      case actionName === NAVPANEL_ACTION_TYPE.UNLIKE_CARD:
        // this.cardToEdit

        this.editCollectionSource.collectionCards.splice(this.editCollectionSource.collectionCards.findIndex(card => card.id === this.cardToEdit.id), 1);
        this.userDataService.updateUserCollectionInUserDrafCollections(this.editCollectionSource);
        this.isModelFeedShown = false;
        this.currentEditCollectionFooterMode = FOOTER_MODE.EDIT_COLLECTION;
        this.cardService.updateCollectionPostRequest(this.editCollectionSource).then((res) => {
          this.utilityService.showSnackBar('Collection updated, model removed', 500);
        }).catch(err => {this.utilityService.showErrorSnackBar(`Error occured on update: ${JSON.stringify(err.error)}` , 500)})
        // this.utilityService.notImplemented();
        // this.modelCardsFeed.dispatchCurrentCardLikeClick()
        // this.userDataService.removeFromSavedCards(this.cardToEdit);
        break;
        case actionName === NAVPANEL_ACTION_TYPE.UPLOAD_MODEL:
          console.log('NAVPANEL UPLOAD ACTION');
          this.fileToUpload = action.optionalData;
          this.nameDialogDefaultValue = this.uploadService.getLastFileName() ? this.uploadService.getLastFileName() : 'default';
          this.uploadService.requestFileUpload(this.fileToUpload, this.editCollectionSource.collectionId);
          this.currentEditCollectionFooterMode = FOOTER_MODE.EDIT_COLLECTION;
          // this.isNameChangeDialogueShown = false;
          break;
        case actionName === NAVPANEL_ACTION_TYPE.UPLOAD_MODEL_NOT_SUBSCRIBED:
          this.isSubscriptionSplashShown = true;
          this.currentEditCollectionFooterMode = FOOTER_MODE.SUBSCRIPTION_SPLASH_SCREEN;
          // this.utilityService.notImplemented('You have to subscribe for 3DWay Base plan to be able to upload models.')
          break;
        case actionName === NAVPANEL_ACTION_TYPE.UPLOAD_MODEL_DISABLED:
          // this.isSubscriptionSplashShown = true;
          this.utilityService.notImplemented('Only 1 model processing allowed for trial users at one time; Please, wait model to be processed')
          break;
        case actionName === NAVPANEL_ACTION_TYPE.UPLOAD_MODEL_LIMIT_REACHED:
          this.isSubscriptionSplashShown = true;
          this.currentEditCollectionFooterMode = FOOTER_MODE.SUBSCRIPTION_SPLASH_SCREEN;
          break;
        case actionName === NAVPANEL_ACTION_TYPE.TRIAL_EXPIRED:
          this.isSubscriptionSplashShown = true;
          this.currentEditCollectionFooterMode = FOOTER_MODE.SUBSCRIPTION_SPLASH_SCREEN;
          break;
        case actionName === NAVPANEL_ACTION_TYPE.SHOW_SUBSCRIBE:
          this.isSubscriptionSplashShown = true;
          this.currentEditCollectionFooterMode = FOOTER_MODE.SUBSCRIPTION_SPLASH_SCREEN;
          break;
        case actionName === NAVPANEL_ACTION_TYPE.DOWNLOAD_MODELS:
          console.log(' COLLECTION_CONTROL_BTN.DOWNLOAD_MODEL')
          this.cardSelectTitle = 'Select Models';
          this.isCardPriceShown = false;
          this.currentEditCollectionFooterMode = FOOTER_MODE.CARD_DOWNLOAD_PURCHASED;
          this.cardSelectData = JSON.parse(JSON.stringify(this.editCollectionSource.collectionCards.filter(cardRaw => cardRaw.purchaseStatus === CARD_PURCHASE_STATUS.PURCHASED)));
          this.cardSelectData.forEach(cardRaw => {
            cardRaw.isSelected = false;
            cardRaw.isDisabled = undefined;
          });
          this.isCardSelectShown = true;
          break;
        case actionName === NAVPANEL_ACTION_TYPE.BUY_MODELS:
          console.log(' COLLECTION_CONTROL_BTN.BUY_MODEL')
          this.isBuySelectShown = true;
          this.currentEditCollectionFooterMode = FOOTER_MODE.CARD_BUY_SECTION;
          this.cardSelectData = this.editCollectionSource.collectionCards.filter((card) => {return card.purchaseStatus === CARD_PURCHASE_STATUS.DRAFT_LIKED || card.purchaseStatus === CARD_PURCHASE_STATUS.DEFAULT_3DWAY});
          console.log('BUY MODELS: ', this.cardSelectData);
          this.cardSelectData.forEach(cardRaw => cardRaw.isSelected = false);
          break;
      case actionName === NAVPANEL_ACTION_TYPE.PUBLISH_MODELS:
        this.cardSelectTitle = 'Select Models.'
        this.cardSelectData = this.editCollectionSource.collectionCards.filter(card => card.purchaseStatus === CARD_PURCHASE_STATUS.UPLOADED)
        this.currentEditCollectionFooterMode = FOOTER_MODE.PUBLISH_SELECTED_MODELS;
        this.isCardPublishShown = true;
        // this.utilityService.notImplemented();
        break;
      case actionName === NAVPANEL_ACTION_TYPE.ADD_DESCRIPTION:
        this.collectionEditOptions.mode = 'active-edit';
        this.createCollectionSnapshot();
        this.collectionEditOptions.isDescriptionEditing = true;
        this.currentEditCollectionFooterMode = FOOTER_MODE.ACTIVE_EDIT_COLLECTION;
        setTimeout(() => {
          this.collectionDetailsComponent.selectDescriptionTextarea();
        }, 100);
        break;
      case actionName === NAVPANEL_ACTION_TYPE.EDIT_COLLABORATORS:
        this.isEditCollaboratorsShown = true;
        this.currentEditCollectionFooterMode = FOOTER_MODE.EDIT_COLLABORATORS;
        break;

      case actionName === NAVPANEL_ACTION_TYPE.ADD_VIDEO:
        this.editCollectionSource.collectionContentMobile = JSON.parse(JSON.stringify(this.mockDataService.basicCollectionVideo));
        this.editCollectionSource.collectionPosterMobile = this.mockDataService.basiccollectionPosterMobile
        this.editCollectionSource.showFullscreenBackgroundPreview = true;
        console.log('add video triggered');
        break;
      // case actionName === NAVPANEL_ACTION_TYPE.ADD_PRICE:
      //   this.createCollectionSnapshot();
      //   this.currentEditCollectionFooterMode = FOOTER_MODE.ACTIVE_EDIT_COLLECTION;
      //   this.isPriceSelectShown = true;
      //   break;
      case actionName === NAVPANEL_ACTION_TYPE.RENAME_CARD:
        this.nameDialogDefaultValue = this.cardToEdit.cardTitle;
        this.currentEditCollectionFooterMode = FOOTER_MODE.RENAME_ITEM;
        this.isNameChangeDialogueShown = true;
        break;
      case actionName === NAVPANEL_ACTION_TYPE.REMOVE_CARD_FROM_COLLECTION:
        this.openRemoveFromCollectionCardConfirmationDialog(this.cardToEdit);
        break;
      case actionName === NAVPANEL_ACTION_TYPE.DELETE_CARD:
        this.openDeleteCardConfirmationDialog(this.cardToEdit);
        break;
      case actionName === NAVPANEL_ACTION_TYPE.FILTER_ALL_MODELS:
        this.setFilteringMode(NAVPANEL_ACTION_TYPE.FILTER_ALL_MODELS)
        break;
      case actionName === NAVPANEL_ACTION_TYPE.FILTER_REVIEW_MODELS:
        this.setFilteringMode(NAVPANEL_ACTION_TYPE.FILTER_REVIEW_MODELS)
        break;
      case actionName === NAVPANEL_ACTION_TYPE.FILTER_SAVED_MODELS:
        this.setFilteringMode(NAVPANEL_ACTION_TYPE.FILTER_SAVED_MODELS)
        break;
      case actionName === NAVPANEL_ACTION_TYPE.FILTER_PURCHASE_MODELS:
        this.setFilteringMode(NAVPANEL_ACTION_TYPE.FILTER_PURCHASE_MODELS)
        break;
      case actionName === NAVPANEL_ACTION_TYPE.FILTER_UPLOADED_MODELS:
        this.setFilteringMode(NAVPANEL_ACTION_TYPE.FILTER_UPLOADED_MODELS)
        break;
      default:
        break;
    }
  }

  private setFilteringMode(filterMode: NAVPANEL_ACTION_TYPE) {
    this.filterCardMode = filterMode;
    const navpanelItems: INavpanelAction[] = [
      {actionName: 'All Models', actionType: NAVPANEL_ACTION_TYPE.FILTER_ALL_MODELS },
      {actionName: 'Saved Models', actionType: NAVPANEL_ACTION_TYPE.FILTER_SAVED_MODELS },
      {actionName: 'By You Models', actionType: NAVPANEL_ACTION_TYPE.FILTER_UPLOADED_MODELS },
      {actionName: 'Purchased Models', actionType: NAVPANEL_ACTION_TYPE.FILTER_PURCHASE_MODELS },
      {actionName: 'Published Models', actionType: NAVPANEL_ACTION_TYPE.FILTER_REVIEW_MODELS },

    ]
    navpanelItems.find(item => item.actionType === this.filterCardMode).isSelected = true;
    this.editCollectionNavpanelItems = navpanelItems;

  }

  public onCardSelectSwitchPanelClicked(switchPanleBtn: SWITCH_PANEL_ITEM): void {
    switch (switchPanleBtn) {
      case SWITCH_PANEL_ITEM.UPLOADS:
        this.cardSelectData = this.userDataService.uploadedCards;
        break;
      case SWITCH_PANEL_ITEM.LIKES:
        this.cardSelectData = this.userDataService.getSavedCards();
        break;
      case SWITCH_PANEL_ITEM.PURCHASES:
        this.cardSelectData = this.userDataService.userPurchasedCards;
        break;
      case SWITCH_PANEL_ITEM.ALL:
        this.cardSelectData = this.userDataService.allUserCards;
        break;
      default:
        break;
    }
  }

  public onEmptyCardClicked(): void {
      this.onNavbarBtnClick(NAVBAR_BTN.ADD);
  }

  private isCollectionHaveMainVideo(): boolean {
    return !!this.editCollectionSource.collectionContentMobile
  }

  private isCollectionHaveDescription(): boolean {
    return !!this.editCollectionSource.collectionDescription
  }

  // public isSendForReviewActive(): boolean {
  //   const allCheckupCompleted = this.isCollectionHaveMainVideo() && this.isCollectionHaveDescription() && this.isCollectionHavePrice()
  //   return allCheckupCompleted && this.editCollectionSource.reviewState !== REVIEW_STATE.IN_REVIEW;
  // }

  public onNavpanelAnimationDone(event: AnimationEvent): void {
    console.log('animation done');
    console.log(event.phaseName);
    if(event.toState !== 'void') {
      if(this.navpanelAnimationState === 'hidden') this.isNavpanelHidden = true;
    }
  }

  public createCollectionSnapshot(): void {
    console.log('SEND REQUEST FOR SNAP TO USER DATA SERVICE');
    this.userDataService.createCollectionSnapshot(this.editCollectionSource);
    // this.editCollectionSnapshot = JSON.parse(JSON.stringify(this.editCollectionSource)),
  }

  public restoreFromSnapshot(): void {
    console.log('SEND REQUEST FOR RESTORE TO USER DATA SERVICE');
    this.editCollectionSource = this.userDataService.restoreCollectionFromSnapshot(this.editCollectionSource);
    // t Object.assign(this.editCollectionSource, this.editCollectionSnapshot);
  }

  public onCardToPurchaseSelected(cardsToPurch: ModelCardRenderedSelection[]) {
    // setting additional purchase data;
    console.log('Card Selected -> ', cardsToPurch);
    const isAllCardsSelected = this.editCollectionSource.collectionCards.length === cardsToPurch.length;
    if(isAllCardsSelected) this.editCollectionAdditionalfooterData.selectToggleLabel = 'Cancel';
    else this.editCollectionAdditionalfooterData.selectToggleLabel = 'Select All';

    const selectedCardPrice = cardsToPurch.reduce((acc, val) => {
      return acc = acc + val.cardPrice;
    }, 0)
    this.editCollectionAdditionalfooterData.priceData = '$' + selectedCardPrice.toString() + '.00' ;
    console.log(selectedCardPrice)
  }

  public onCardSeletected(cardsToPurch: ModelCardRenderedSelection[]): void {
      // setting additional purchase data;
      console.log('CARDS LENGTH SELECTED: ', cardsToPurch.length);
      const isAllCardsSelected = this.editCollectionSource.collectionCards.filter(card => card.purchaseStatus === CARD_PURCHASE_STATUS.DEFAULT_3DWAY).length === cardsToPurch.length;
      if(isAllCardsSelected) this.editCollectionAdditionalfooterData.selectToggleLabel = 'Cancel';
      else this.editCollectionAdditionalfooterData.selectToggleLabel = 'Select All';

    if(this.currentEditCollectionFooterMode === FOOTER_MODE.CARD_BUY_SECTION) {
      const selectedCardPrice = cardsToPurch.filter(card => card.purchaseStatus !== CARD_PURCHASE_STATUS.PURCHASED ).reduce((acc, val) => {
        return acc = acc + val.cardPrice;
      }, 0)
      this.editCollectionAdditionalfooterData.priceData = '$' + selectedCardPrice.toString() + '.00' ;
      console.log(selectedCardPrice)
    }
    if(this.currentEditCollectionFooterMode === FOOTER_MODE.CARD_DOWNLOAD_PURCHASED) {
      const selectedForDownloadPrice = cardsToPurch.reduce((acc, val) => {
        return acc = acc + val.cardPrice;
      }, 0)
      this.editCollectionAdditionalfooterData.priceData = '$' + selectedForDownloadPrice.toString() + '.00' ;
      console.log(selectedForDownloadPrice)
    }

  }


  public onCancelRejectedScreenClicked(): void {
    this.isRejectedScreenShown = false;
  }

  public updateUserSelectedCards(): void {
    const selectedCards = this.cardSelectRef.cardsToSelect.filter((cardToFilter => cardToFilter.isSelected));
    this.editCollectionSource.collectionCards.push(...JSON.parse(JSON.stringify(selectedCards)));
    this.cardSelectRef.cardsToSelect.forEach((col) => col.isSelected = false);
  }

  public onHideNavpanelEventEmitted(): void {
    this.isNavpanelShown = false;
  }

  public onNavpanelDestroy(): void {
    this.navpanelAnimationState = 'visible';
    this.isNavpanelHidden = false;
  }

  onReviewCollaboratorModeClicked(): void {
    this.currentEditCollectionFooterMode = FOOTER_MODE.REMOVE_COLLABORATORS;
  }

  public onPriceSelected(price: number): void {
    this.currentPrice = price
  }

  // public onShowCardPriceSelectEvent(cardToSetPrice: ModelCardRenderedSelection): void {
  //   this.currentPrice = cardToSetPrice.cardPrice;
  //   this.cardToEdit = cardToSetPrice;
  //   this.currentEditCollectionFooterMode = FOOTER_MODE.SET_PRICE;
  //   this.isPriceSelectShown = true;
  // }

  public onCollectionSelected(collectionSelected: ICardCollection): void {
    if(collectionSelected.isSelected) {
      this.currentEditCollectionFooterMode = FOOTER_MODE.LIBRARY_SELECT_COLLECTION_TO_MOVE
    } else {
      this.currentEditCollectionFooterMode = FOOTER_MODE.LIBRARY_SELECT_COLLECTION_TO_MOVE_DISABLED
    }
  }



  public openDeleteCollectionConfirmationDialog() {
    const collectionName = this.editCollectionSource.collectionTitle ? this.editCollectionSource.collectionTitle : 'Collection';
    const dialogConfig: MatDialogConfig<DialogData> = {
      data: {
        dialogTitle: undefined,
        dialogText: `Are you sure you want to delete this collection permanently?`,
        confirmBtntext: 'Yes',
        declineBtnText: 'No'
      }
    };
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result === true) {
        console.log('ANSWER WAS YES');

        this.cardService.deleteUserCollection(this.editCollectionSource.collectionId).then((resp) => {
          this.utilityService.showSnackBar('Collection deleted.', 0);
        }).catch(err => {this.utilityService.showErrorSnackBar(`Error occured on delete: ${JSON.stringify(err.error)}` , 500)})

        // Delete collection:
        this.userDataService.deleteCollectionFromDrafts(this.editCollectionSource);
        this.router.navigateByUrl('/library');
      }
    });
  }

  public openCollaborativeConfirmationDialog() {
    const dialogConfig: MatDialogConfig<DialogData> = {
      data: {
        dialogTitle: 'Make collaborative?',
        dialogText: 'You remain owner and can edit collaborators list.',
        confirmBtntext: 'Yes',
        declineBtnText: 'No'
      }
    };
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result === true) {
        this.editCollectionSource.accessType = 'Collaborative';
        this.editCollectionSource.collaborators = [];
        this.utilityService.showShareDialog(this.editCollectionSource.collectionTitle)
      }
    });
  }

  public openNonCollaborativeConfirmationDialog() {
    const dialogConfig: MatDialogConfig<DialogData> = {
      data: {
        dialogTitle: 'Make non-collaborative?',
        dialogText: 'Models of collaborators will be deleted.',
        confirmBtntext: 'Yes',
        declineBtnText: 'No'
      }
    };
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result === true) {
        this.editCollectionSource.accessType = 'Private';
        this.editCollectionSource.collaborators = [];
        this.utilityService.showSnackBar('Collection is private now.', 0);
      }
    });
  }

  public openRemoveCollaboratorDialog() {
    const dialogConfig: MatDialogConfig<DialogData> = {
      data: {
        dialogTitle: 'Remove collaborator?',
        dialogText: 'All models of this collaborator will also be deleted.',
        confirmBtntext: 'Yes',
        declineBtnText: 'No'
      }
    };
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result === true) {
        this.userDataService.removeCollaboratorFromCollection(this.editCollectionSource, this.editCollaboratorsPanelRef.currentCollaborator);
        this.currentEditCollectionFooterMode = FOOTER_MODE.EDIT_COLLABORATORS;
        this.editCollaboratorsPanelRef.setDefaultState();
        this.utilityService.showSnackBar('You have succesfully removed collaborator', 0);
      }
    });
  }

  public openCancelPublishingCardConfirmationDialog(card: ICard) {
    const dialogConfig: MatDialogConfig<DialogData> = {
      data: {
        dialogTitle: undefined,
        dialogText: `Are you sure you want to cancel publishing model "${card.cardTitle}" ? `,
        confirmBtntext: 'Yes',
        declineBtnText: 'No'
      }
    };
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result === true) {
        // OUTDATED LOGIC !!!!!!! SHOULD BE REWORKED !!!!!!!

        this.userDataService.cancelModelPublishing(card);
        this.currentEditCollectionFooterMode = FOOTER_MODE.LIBRARY;
        const indx = this.editCollectionSource.collectionCards.findIndex((findCard) => { return findCard.id === card.id});
        this.editCollectionSource.collectionCards[indx].purchaseStatus = CARD_PURCHASE_STATUS.UPLOADED;
        this.currentEditCollectionFooterMode = FOOTER_MODE.EDIT_COLLECTION;
        this.isModelFeedShown = false;

        this.cardService.cancelReviewModelPostRequest(card).then((resp) => {
            this.utilityService.showSnackBar('Review cancelled succesfully.', 500);
          }).catch(error => this.utilityService.showErrorSnackBar(`Error occured on cancelling review: ${JSON.stringify(error.error)}`, 500));

      }
    });
  }


  public openThumbDeleteConfirmationDialog(modelCard: ICard, thumbNumber) {
    const dialogConfig: MatDialogConfig<DialogData> = {
      data: {
        dialogTitle: undefined,
        dialogText: `This model will be deleted from this collection`,
        confirmBtntext: 'Yes',
        declineBtnText: 'No'
      }
    };
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      if (result === true) {

        this.cardService.deleteModelPostRequest(modelCard).then((resp) => {
          this.utilityService.showSnackBar('Model deleted succesfully.', 500);
        }).catch(error => this.utilityService.showErrorSnackBar(`Error occured on delete: ${JSON.stringify(error.error)}`, 500));

        this.userDataService.deleteCardFromCollection(modelCard, this.editCollectionSource)
        // this.editCollectionSource.collectionCards.splice(thumbNumber, 1);
      }
    });
  }

  public openRemoveFromCollectionCardConfirmationDialog(card: ICard) {
    const dialogConfig: MatDialogConfig<DialogData> = {
      data: {
        dialogTitle: undefined,
        // dialogText: `Do you want to remove "${card.cardTitle}" from collection ? It will not remove model from your library`,
        dialogText: 'Do you want to delete this model from this collection?',
        confirmBtntext: 'Yes',
        declineBtnText: 'No'
      }
    };
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result === true) {
        this.editCollectionSource = this.userDataService.deleteCardFromCollection(card, this.editCollectionSource)

        this.cardService.updateCollectionPostRequest(this.editCollectionSource).then((res) => {
          console.log(res);
          this.utilityService.showSnackBar('Model removed; Collection updated', 500);
        }).catch(error => this.utilityService.showErrorSnackBar(`Error occured on remove and upadte collection: ${JSON.stringify(error.error)}`, 500));

        this.isModelFeedShown = false;
        this.currentEditCollectionFooterMode = FOOTER_MODE.EDIT_COLLECTION;
      }
    });
  }


  public openDeleteCardConfirmationDialog(card: ICard) {
    const dialogConfig: MatDialogConfig<DialogData> = {
      data: {
        dialogTitle: undefined,
        dialogText: `Are you sure you want to delete "${card.cardTitle}" ? `,
        confirmBtntext: 'Yes',
        declineBtnText: 'No'
      }
    };
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result === true) {

        this.cardService.deleteModelPostRequest(card).then((resp) => {
          this.utilityService.showSnackBar('Model deleted succesfully.', 500);
        }).catch(error => this.utilityService.showErrorSnackBar(`Error occured on delete: ${JSON.stringify(error.error)}`, 500));

        this.userDataService.deleteCardFromCollection(card, this.editCollectionSource)
        this.isModelFeedShown = false;
        this.currentEditCollectionFooterMode = FOOTER_MODE.EDIT_COLLECTION;
      }
    });
  }




  public onModelCardPressed() {
    this.currentEditCollectionFooterMode = FOOTER_MODE.CARD_FULLSCREEN;
  }

  public onModelCardClicked(card: ModelCardRendered) {
    this.currentEditCollectionFooterMode = this.currentEditCollectionFooterMode === FOOTER_MODE.DEFAULT || this.currentEditCollectionFooterMode === FOOTER_MODE.SHOW_BUY_BTN ? FOOTER_MODE.CARD_FULLSCREEN : FOOTER_MODE.DEFAULT;
  }

  public filterUploadCards = (groupedCard: IGroupedCard) => {
    if(groupedCard.linkedCollectionId !== this.editCollectionSource?.collectionId) return false;
    return groupedCard.uploadStatus.status === UPLOAD_STATUS.INPROGRESS || groupedCard.uploadStatus.status === UPLOAD_STATUS.PROCESSING

  }

  ngOnDestroy(): void {
    this.cardPurchaseStateChangeSubscription.unsubscribe();
    this.inAppPurchaseSubscriptionProcessSubscription.unsubscribe();
    this.uploadServiceSubscription.unsubscribe();
    this.uploadErrorSub.unsubscribe();
    // this.cardService.onCardPurchaseStateChange.unsubscribe();
  }


}
