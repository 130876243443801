<div class="background-overlay"></div>
<div class="fullscreen-panel rejected-screen-wrapper ">
  <div class="picture"></div>
  <div class="stars"></div>
  <h2 class="rejected-label" >
    <mat-icon class="mat-3dway-logo" svgIcon="3dway-logo"></mat-icon>
    <span>3DWay</span>
  </h2>
  <p class="top-text">Model was rejected due to <span class="underline">Review Guidelines</span></p>
  <div class="main-block">
    <ng-container *ngFor="let rule of rejectedCard.reviewDetails">
        <ng-container *ngIf="rule.rule_review_status === 'rejected'">
          <h1 class="reject-title">{{rule.rule_name}}</h1>
          <p class="reject-details">{{rule.rule_details}}</p>
        </ng-container>
    </ng-container>

    <!-- <h1 class="reject-title">1.1. Mesh consistency</h1>
    <p class="reject-details">Model should not include holes, deformations.</p>

    <h1 class="reject-title">3.1.Texture Quality</h1>
    <p class="reject-details">Blurry Texture: Every region of the texture should look not blurry. </p> -->

  </div>
  <div class="bottom-block" >
    <a class="subscribe-btn" href="{{generateHref()}}"><span>Contact 3DWay</span></a>
    <button class="cancel-btn" (click)="onCancelClick()"><span>Cancel</span></button>
  </div>

</div>
