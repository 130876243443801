import { UtilityFunctionsService } from './../../providers/utility-functions.service';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FirebaseAuthService } from '../../providers/firebase-auth.service';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { PROFILE_REGION } from 'src/app/models/user';
import { trigger, transition, style, animate } from '@angular/animations';
import { SUBSCRIPTION_STATUS } from 'src/app/models/card';
import { MobileCommonService } from '../../mobile/mobileCommon.service';


@Component({
  selector: 'app-profile-main',
  templateUrl: './profile-main.component.html',
  styleUrls: ['./profile-main.component.scss'],
  animations: [
    trigger('slideToggle', [
      transition(':enter', [
        style({ height: 0, opacity: 0, overflow: 'hidden' }), // Start with no height and transparent
        animate('0.2s ease-out', style({ height: '*', opacity: 1 })) // Animate to the natural height and full opacity
      ]),
      transition(':leave', [
        style({ height: '*', opacity: 1, 'padding-bottom': '20px',  overflow: 'hidden' }), // Start with current height and full opacity
        animate('0.2s ease-out', style({ height: 0, opacity: 0, 'padding-bottom': '0px' })) // Animate to no height and transparent
      ])
    ])
  ]
})
export class ProfileMainComponent implements OnInit {
  @Output() logoutBtnClick = new EventEmitter<boolean>();
  @Output() deleteAccBtnClick = new EventEmitter<boolean>();

  environment = environment
  PROFILE_REGION = PROFILE_REGION;
  isAccountRegionShown: boolean = false;
  isSubscriptionRegionShown: boolean = false;
  isRenderLimitRegionShown: boolean = false;
  cardsService: any;

  constructor(
    public firebaseAuth: FirebaseAuthService,
    public router: Router,
    public mobileCommonService: MobileCommonService,
    public utilityFunctionsService: UtilityFunctionsService,
  ) { }

  ngOnInit(): void {
  }

  public logoutClick() {
    this.logoutBtnClick.emit();
  }

  public deleteAccClick() {
    this.deleteAccBtnClick.emit();
  }

  public betaSettings() {
    console.log('beta settings')
    this.router.navigateByUrl('/service');
  }

  public slicedFirstName() {
    if(this.firebaseAuth?.currentUser?.displayName) {
      const str = this.firebaseAuth?.currentUser?.displayName
      return str.substring(0,str.indexOf(' ')); // "72"
    }
  }

  public isSubscriber(): boolean {
    return this.firebaseAuth.currentUser?.subscriptionStatus === SUBSCRIPTION_STATUS.SUBSCRIBER;
  }

  //TODO: REFACTOR THIS PART ALSO TO DISABLE BTNS AND TO MOVE SOME FUNC TO SEPARATE MOBILE SERVICE;

  public signOut() {
    this.mobileCommonService.triggerSignOut();
    console.log('signOut')
  }

  public cancelSub() {
    this.cardsService.stripeCancelSubscription();
    console.log('cancelSub clicked')
  }

  public subscribeTry() {
    console.log('subscribeTry clicked')
  }

  public onManageSubscriptionClicked() {
    this.utilityFunctionsService.showImportantErrorSnackBar(`You can't manage your subscription in the app. We know, it's not ideal.`, 100);
    console.log('onManageSubscriptionClicked clicked')
  }

  public toggleRegion(event: Event, region: string) {
    console.log('region click')
    event.stopPropagation();
    switch (region) {
      case PROFILE_REGION.PERSONAL:
        this.isAccountRegionShown = !this.isAccountRegionShown;
        this.isSubscriptionRegionShown = false;
        this.isRenderLimitRegionShown = false;
        break;
      case PROFILE_REGION.SUBSCRIPT:
        this.isSubscriptionRegionShown = !this.isSubscriptionRegionShown;
        this.isAccountRegionShown = false;
        this.isRenderLimitRegionShown = false;

        break;
      case PROFILE_REGION.RENDER_LIMIT:
        this.isRenderLimitRegionShown = !this.isRenderLimitRegionShown;
        this.isAccountRegionShown = false;
        this.isSubscriptionRegionShown = false;
        break;

      default:
        break;
    }
    return
  }


}
