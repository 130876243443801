import { Component, OnInit, ViewEncapsulation, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { trigger, transition, style, animate } from '@angular/animations';
import { Subject } from 'rxjs';
import { IRecCard } from '../footer-menu/footer-menu.component';

@Component({
  selector: 'app-rec-card',
  templateUrl: './rec-card.component.html',
  styleUrls: ['./rec-card.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger(
      'inOutAnimation',
      [
        transition(
          ':enter',
          [
            style({opacity: 0, height: 0 }),
            animate('0.15s ease-out',
                    style({opacity: 1, height: '52px' }))
          ]
        ),
        transition(
          ':leave',
          [
            style({ opacity: 1, height: '52px' }),
            animate('0.15s ease-out',
                    style({opacity: 0, height: 0 }))
          ]
        )
      ]
    )
  ]
})
export class RecCardComponent implements OnInit, OnDestroy {
  @Input() previewImage: string = 'data:image/gif;base64,R0lGODlhAQABAIAAAAUEBAAAACwAAAAAAQABAAACAkQBADs=';
  @Input() progressValue: number = 0;
  @Input() title: string = 'N/A';
  @Input() category: string = 'N/A';
  @Input() author: string = 'N/A';
  @Input() estimation: string = 'N/A';
  @Input() locationSegments: string[] = [];

  @Input() parentSubject: Subject<any>;
  @Output() recCardClicked = new EventEmitter<string>();
  @Output() recCtrlsClicked = new EventEmitter<IRecCard>();

  public isControlPanelVisible: boolean = false;
  public recCardId: string = Math.random().toString(36).substring(2);

  // @Input() type: string = CARD_TYPE.DEFAULT;
  // @Input() assetPhotos: string[] = [];

  constructor() { }

  ngOnInit() {
      // this.parentSubject.subscribe(event => {
      //   if (event !== this.recCardId) {
      //     this.isControlPanelVisible = false;
      //   }
      // });
  }

  ngOnDestroy() {
      // this.parentSubject.unsubscribe();
  }



  public onRecCardClicked(): void {
    this.isControlPanelVisible = !this.isControlPanelVisible;
    this.recCardClicked.emit(this.recCardId);
  }

  public onRecControlsClicked(event: Event, buttonName: string): void {
    event.stopPropagation();
    this.recCtrlsClicked.emit({
      previewImage: this.previewImage,
      progressValue: this.progressValue,
      title: this.title,
      category: this.category,
      author: this.author,
      estimation: this.estimation,
      locationSegments: this.locationSegments,
      controlButtonName: buttonName
    });
  }

}
