import { Component, OnInit } from '@angular/core';

export class NavigationLink {
  label: string;
  path: string;
  isExternal?: boolean;
}

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  footerLinks: NavigationLink[] = [{
    label: 'System Status',
    path: '/systemStatus'
  }, {
    label: 'Privacy Policy',
    path: '/legal/privacy-policy'
  }, {
    label: 'Terms of Service',
    path: '/legal/terms-of-service'
  }, {
    label: 'Legal',
    path: '/legal'
  }];

  copyrightYear: string;

  constructor() { }

  ngOnInit() {
    this.copyrightYear = this.getCurrentYear();
  }

  public openIntercomMessenger($event): void {
    $event.preventDefault();
    (window as any).Intercom('showNewMessage');
  }

  public getCurrentYear(): string {
   return new Date().getFullYear().toString();
  }

}
