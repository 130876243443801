<div class="feedback-wrapper" *ngFor="let feedback of userFeedback">
  <div class="feedback-header" fxLayout="row" fxLayoutAlign="space-between start">
    <!-- <star-rating staticColor="default" [starType]="'svg'" [readOnly]="true" [rating]="feedback.rating"></star-rating> -->
    <span class="feedback-date">{{feedback.date}}</span>
  </div>
  <div class="feedback-body">
    <p>
      {{feedback.message}}
      <a class="more-btn" >more</a>
    </p>
  </div>
  <div class="feedback-footer">
    <span class="feedback-author">{{feedback.author}}</span>
  </div>
</div>
<div class="hack-element-for-scroll-margin"></div>
