  <div class="review-wrapper" fxFlex="100" fxLayout="column" fxLayoutAlign="start center">
    <div class="review-header"  fxFlex="0 1 auto" fxLayout="row" fxLayoutAlign="space-between center">
      <button fxFlex="0 0 75px" class="form-btn" mat-flat-button (click)="onCancelBtnClicked()">Cancel</button>
      <span class="review-title">Write a Review</span>
      <button fxFlex="0 0 75px" class="form-btn" mat-flat-button (click)="onSendBtnClicked()">Send</button>
    </div>
    <div class="rating-block">
      <!-- <star-rating staticColor="default" [showHalfStars]="true" [starType]="'svg'" [rating]="0"></star-rating> -->
      <p class="rating-hint">Tap a Start to Rate</p>
    </div>

    <div fxFlex="1 1 auto" class="review-textarea-wrapper">
      <textarea fxFlex="1 1 100" class="review-textarea" placeholder="Review (Optional)" type="review" #reviewInput ></textarea>
    </div>
  </div>

