import { SUBSCRIPTION_STATUS } from './card';
import {
  User,
} from '@angular/fire/auth';

// export class User {
//     id: number;
//     username: string;
//     firstName: string;
//     lastName: string;
//     token?: string;
// }

export interface I3DWayUser extends User {
  subscriptionStatus: SUBSCRIPTION_STATUS;
  uploadsNumber: number;
  uploadNumberLimit: number;

  originalTransactionId?: string;
}


export enum PROFILE_REGION {
  PERSONAL = 'personal',
  SUBSCRIPT = 'subscription',
  RENDER_LIMIT = 'renderLimit',

}
