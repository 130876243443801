  <!-- to work with notch on ios devices -->
<!-- <div *ngIf="headerBarOptions.isVisible" class="header-bar-safe-area"></div> -->

<div [ngClass]="{'header-bar-safe-area': true, 'transparent': footerBarMode === FOOTER_MODE.CARD_FULLSCREEN}" class="" ></div>

<div *ngIf="false" [ngClass]="{'headerNav-wrapper': true }" fxLayout="column" fxLayoutAlign="space-between start" >
  <div class="headerNav-row"  fxLayout="row"   fxLayoutAlign="space-between center" ngClass.lt-sm="lt-sm">
    <div class="header-nav-wrapper" fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="10px">
      <button *ngIf="!this.currentUser"  fxFlex="0 0 38px" class="user-button" mat-fab color="primary">
        <mat-icon svgIcon="user-icon"></mat-icon>
      </button>
      <button *ngIf="this.currentUser"  fxFlex="0 0 38px" class="user-button" mat-fab color="primary" (click)="signOut()">
        <span [innerHTML]="getUserInitials()"></span>
      </button>
    </div>
  </div>
</div>

<div class="navbar-content">
  <app-search *ngIf="isSearchComponentShown" [@inOutAnimation]></app-search>
  <app-navpanel *ngIf="isNavpanelComponentShown" [@inOutAnimation]  (hideNavpanelEvent)="onHideNavpanelEventEmitted()"></app-navpanel>
  <app-footer-menu *ngIf="isMenuShown" [@inOutAnimation] ></app-footer-menu>
  <app-trashbin *ngIf="isMenuShown" [@inOutAnimation]></app-trashbin>
  <app-make-reconstruct *ngIf="isMakeReconstructShown" [@inOutAnimation] [recCard]="selectedRecCard" ></app-make-reconstruct>
  <app-under-construction *ngIf="false" [@inOutAnimation] ></app-under-construction>
  <app-profile-main *ngIf="footerBarMode === FOOTER_MODE.PROFILE"  (logoutBtnClick)="onLogoutBtnEvent()" (deleteAccBtnClick)="onDeleteAccEvent()" [@inOutAnimation]></app-profile-main>
</div>

<div class="navbar-safe-area"></div>
<div *ngIf="footerBarMode !== FOOTER_MODE.DEFAULT"  class="navbar-footer-wrapper" [ngClass]="{'transparent-nav' : footerBarMode === FOOTER_MODE.CARD_FULLSCREEN }" fxLayout="column"  fxLayoutAlign="space-between start" >
<!-- <div class="navbar-footer-wrapper" fxLayout="column-reverse"  fxLayoutAlign="space-between start" [ngClass]="{'transparent-nav' : footerBarMode === FOOTER_MODE.CARD_FULLSCREEN }"> -->

  <!-- Tablet/Mobile horizontal footer bar -->
  <div class="footer-row">
    <ng-container #details *ngIf="this.footerBarMode === FOOTER_MODE.LIBRARY">
      <button  class="home-btn-left"  (click)="navbarBtnClick(NAVBAR_BTN.HOME, $event)" >
        <svg width="20" height="22" viewBox="0 0 20 22" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M10 0.75L0.75 8.22675V21.25H7.0278V14.531C7.0278 14.0567 7.4083 13.6722 7.8778 13.6722H12.1222C12.5917 13.6722 12.9722 14.0567 12.9722 14.531V21.25H19.25V8.22674L10 0.75Z" fill="none" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        <span class="home-btn-text" (click)="navbarBtnClick(NAVBAR_BTN.HOME, $event)">Home</span>
      </button>
      <button class="discover-btn" (click)="navbarBtnClick(NAVBAR_BTN.ADD, $event)"><span  (click)="navbarBtnClick(NAVBAR_BTN.ADD, $event)">Add</span></button>
      <button class="search-btn" [disabled]="true" (click)="navbarBtnClick(NAVBAR_BTN.SEARCH, $event)">
        <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none" (click)="navbarBtnClick(NAVBAR_BTN.SEARCH, $event)">
          <path d="M0.75 8.9375C0.75 13.4593 4.41567 17.125 8.9375 17.125C13.4593 17.125 17.125 13.4593 17.125 8.9375C17.125 4.41567 13.4593 0.75 8.9375 0.75C4.41567 0.75 0.75 4.41567 0.75 8.9375Z" stroke="white" stroke-width="1.5" stroke-linecap="round"/>
          <path d="M20.9824 20.9687L15.3558 15.355" stroke="white" stroke-width="2" stroke-linecap="round"/>
          <circle xmlns="http://www.w3.org/2000/svg" cx="9" cy="9" r="5" />
        </svg>
        <span class="search-btn-text" (click)="navbarBtnClick(NAVBAR_BTN.SEARCH, $event)">Search</span>
      </button>
    </ng-container>

    <ng-container #details *ngIf="this.footerBarMode === FOOTER_MODE.SEARCH">
      <button class="back-btn" (click)="navbarBtnClick(NAVBAR_BTN.BACK, $event)" >
        <svg (click)="navbarBtnClick(NAVBAR_BTN.BACK, $event)" width="12" height="20" viewBox="0 0 12 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M10.9823 1.5L0.833008 10L10.9823 18.5" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        <span  class="back-btn-text" (click)="navbarBtnClick(NAVBAR_BTN.BACK, $event)">Back</span>
      </button>
      <button class="discover-btn" (click)="navbarBtnClick(NAVBAR_BTN.NOT_IMPL, $event)"><span  (click)="navbarBtnClick(NAVBAR_BTN.NOT_IMPL, $event)">Search</span></button>
      <button class="home-btn" ></button>
    </ng-container>


    <!-- <ng-container #details *ngIf="this.footerBarMode === FOOTER_MODE.PORTFOLIO_OLD">
      <button class="back-btn" (click)="navbarBtnClick(NAVBAR_BTN.BACK, $event)" >
        <svg (click)="navbarBtnClick(NAVBAR_BTN.BACK, $event)" width="12" height="20" viewBox="0 0 12 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M10.9823 1.5L0.833008 10L10.9823 18.5" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        <span  class="back-btn-text" (click)="navbarBtnClick(NAVBAR_BTN.BACK, $event)">Back</span>
      </button>
      <button class="discover-btn" ><span (click)="onDiscoverBtnClick($event)">Search</span></button>
      <button  class="home-btn"  (click)="onHomeBtnClicked($event)" >
        <svg width="20" height="22" viewBox="0 0 20 22" fill="none" xmlns="http://www.w3.org/2000/svg" (click)="onHomeBtnClicked($event)">
          <path d="M10 0.960468L19.25 8.36046V21.25H12.9722V14.6C12.9722 14.1306 12.5917 13.75 12.1222 13.75H7.87778C7.40834 13.75 7.02778 14.1306 7.02778 14.6V21.25H0.75V8.36045L10 0.960468Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        <span class="home-btn-text" (click)="onHomeBtnClicked($event)">Home</span>
      </button>
    </ng-container> -->

    <ng-container *ngIf="this.footerBarMode === FOOTER_MODE.NEW_COLLECTION">
      <button class="back-btn" (click)="navbarBtnClick(NAVBAR_BTN.BACK, $event)" >
        <svg (click)="navbarBtnClick(NAVBAR_BTN.BACK, $event)" width="12" height="20" viewBox="0 0 12 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M10.9823 1.5L0.833008 10L10.9823 18.5" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        <span  class="back-btn-text" (click)="navbarBtnClick(NAVBAR_BTN.BACK, $event)">Back</span>
      </button>
      <button class="discover-btn" (click)="navbarBtnClick(NAVBAR_BTN.CREATE_COLLECTION, $event)"><span (click)="navbarBtnClick(NAVBAR_BTN.CREATE_COLLECTION, $event)">Create</span></button>
      <button class="home-btn" ></button>
    </ng-container>

    <ng-container *ngIf="this.footerBarMode === FOOTER_MODE.EDIT_COLLECTION">
      <button class="back-btn" (click)="navbarBtnClick(NAVBAR_BTN.BACK, $event)" >
        <svg (click)="navbarBtnClick(NAVBAR_BTN.BACK, $event)" width="12" height="20" viewBox="0 0 12 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M10.9823 1.5L0.833008 10L10.9823 18.5" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        <span  class="back-btn-text" (click)="navbarBtnClick(NAVBAR_BTN.BACK, $event)">Back</span>
      </button>
      <button class="discover-btn" (click)="navbarBtnClick(NAVBAR_BTN.ADD, $event)"><span (click)="navbarBtnClick(NAVBAR_BTN.ADD, $event)">Add</span></button>
      <button class="options-navbar-btn" (click)="navbarBtnClick(NAVBAR_BTN.MORE_OPTIONS, $event)">
        <svg xmlns="http://www.w3.org/2000/svg" width="42" height="42" viewBox="0 0 42 42" fill="none">
          <circle cx="13" cy="21" r="2" fill="white"/>
          <circle cx="21" cy="21" r="2" fill="white"/>
          <circle cx="29" cy="21" r="2" fill="white"/>
        </svg>
        <span  class="options-btn-text" (click)="navbarBtnClick(NAVBAR_BTN.MORE_OPTIONS, $event)">More</span>
      </button>
    </ng-container>

    <ng-container *ngIf="this.footerBarMode === FOOTER_MODE.PUBLISH_SELECTED_MODELS">
      <button class="back-btn" (click)="navbarBtnClick(NAVBAR_BTN.BACK, $event)" >
        <svg (click)="navbarBtnClick(NAVBAR_BTN.BACK, $event)" width="12" height="20" viewBox="0 0 12 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M10.9823 1.5L0.833008 10L10.9823 18.5" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        <span  class="back-btn-text" (click)="navbarBtnClick(NAVBAR_BTN.BACK, $event)">Back</span>
      </button>
      <!-- <button *ngIf="additionalData?.reviewState === REVIEW_STATE.IN_REVIEW" class="discover-btn" (click)="navbarBtnClick(NAVBAR_BTN.IN_REVIEW, $event)"><span (click)="navbarBtnClick(NAVBAR_BTN.IN_REVIEW, $event)">In Review</span></button> -->
      <button class="discover-btn" (click)="navbarBtnClick(NAVBAR_BTN.PUBLISH, $event)" [disabled]="userDataService.getIsNavbarMainActionDisabled()"><span (click)="navbarBtnClick(NAVBAR_BTN.PUBLISH, $event)">Publish</span></button>
      <!-- <button *ngIf="additionalData?.priceData && additionalData?.reviewState !== REVIEW_STATE.IN_REVIEW" class="analytics-price-btn" (click)="navbarBtnClick(NAVBAR_BTN.PUBLISH, $event)"><span (click)="navbarBtnClick(NAVBAR_BTN.PUBLISH, $event)">Publish<span class="bottom-tag" (click)="navbarBtnClick(NAVBAR_BTN.PUBLISH, $event)">{{additionalData?.priceData}} </span> </span></button> -->
      <button class="home-btn empty-btn void"  ></button>
    </ng-container>

    <ng-container *ngIf="this.footerBarMode === FOOTER_MODE.ACTIVE_EDIT_COLLECTION">
      <button class="back-btn" (click)="navbarBtnClick(NAVBAR_BTN.BACK, $event)" >
        <svg (click)="navbarBtnClick(NAVBAR_BTN.BACK, $event)" width="12" height="20" viewBox="0 0 12 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M10.9823 1.5L0.833008 10L10.9823 18.5" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        <span  class="back-btn-text" (click)="navbarBtnClick(NAVBAR_BTN.BACK, $event)">Back</span>
      </button>
      <button class="discover-btn" (click)="navbarBtnClick(NAVBAR_BTN.SAVE, $event)"><span (click)="navbarBtnClick(NAVBAR_BTN.SAVE, $event)">Save</span></button>
      <button class="home-btn empty-btn void"  ></button>
      <!-- <button class="top-btn" (click)="navbarBtnClick(NAVBAR_BTN.TO_TOP, $event)">
        <svg width="20" height="27" viewBox="0 0 20 27" fill="none" xmlns="http://www.w3.org/2000/svg" >
          <path d="M16.3516 12.5781L10.0625 7.54687L3.77344 12.5781" stroke="#BDBDBD" stroke-width="1.83333" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M16.3516 19.5781L10.0625 14.5469L3.77344 19.5781" stroke="#BDBDBD" stroke-width="1.83333" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        <span  class="top-btn-text" (click)="navbarBtnClick(NAVBAR_BTN.TO_TOP, $event)">Top</span>
      </button> -->
    </ng-container>

    <ng-container *ngIf="this.footerBarMode === FOOTER_MODE.SET_PRICE">
      <button class="back-btn" (click)="navbarBtnClick(NAVBAR_BTN.BACK, $event)" >
        <svg (click)="navbarBtnClick(NAVBAR_BTN.BACK, $event)" width="12" height="20" viewBox="0 0 12 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M10.9823 1.5L0.833008 10L10.9823 18.5" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        <span  class="back-btn-text" (click)="navbarBtnClick(NAVBAR_BTN.BACK, $event)">Back</span>
      </button>
      <button class="discover-btn" (click)="navbarBtnClick(NAVBAR_BTN.SAVE, $event)"><span (click)="navbarBtnClick(NAVBAR_BTN.SAVE, $event)">Save</span></button>
      <button class="home-btn" ></button>
    </ng-container>


    <ng-container *ngIf="this.footerBarMode === FOOTER_MODE.PROFILE">
      <button class="back-btn" (click)="navbarBtnClick(NAVBAR_BTN.BACK_PROFILE, $event)" >
        <svg (click)="navbarBtnClick(NAVBAR_BTN.BACK_PROFILE, $event)" width="12" height="20" viewBox="0 0 12 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M10.9823 1.5L0.833008 10L10.9823 18.5" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        <span  class="back-btn-text" (click)="navbarBtnClick(NAVBAR_BTN.BACK_PROFILE, $event)">Back</span>
      </button>
      <!-- <button class="discover-btn" (click)="signOut()"><span (click)="signOut()">Sign Out</span></button> -->
      <a class="discover-btn contact-us-link" href="{{generateHref()}}"><span>Contact Us</span> </a>
      <button class="home-btn" ></button>

    </ng-container>

    <ng-container *ngIf="this.footerBarMode === FOOTER_MODE.LIBRARY_SELECT_MODELS || this.footerBarMode === FOOTER_MODE.LIBRARY_SELECT_MODELS_DISABLED ">
      <button class="back-btn" (click)="navbarBtnClick(NAVBAR_BTN.BACK, $event)" >
        <svg (click)="navbarBtnClick(NAVBAR_BTN.BACK, $event)" width="12" height="20" viewBox="0 0 12 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M10.9823 1.5L0.833008 10L10.9823 18.5" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        <span  class="back-btn-text" (click)="navbarBtnClick(NAVBAR_BTN.BACK, $event)">Back</span>
      </button>
      <button class="discover-btn" [disabled]="this.footerBarMode === FOOTER_MODE.LIBRARY_SELECT_MODELS_DISABLED" (click)="navbarBtnClick(NAVBAR_BTN.NEXT, $event)"><span (click)="navbarBtnClick(NAVBAR_BTN.NEXT, $event)">Next</span></button>
      <button class="home-btn" ></button>
    </ng-container>


    <ng-container *ngIf="this.footerBarMode === FOOTER_MODE.RENAME_ITEM">
      <button class="back-btn" (click)="navbarBtnClick(NAVBAR_BTN.BACK, $event)" >
        <svg (click)="navbarBtnClick(NAVBAR_BTN.BACK, $event)" width="12" height="20" viewBox="0 0 12 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M10.9823 1.5L0.833008 10L10.9823 18.5" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        <span  class="back-btn-text" (click)="navbarBtnClick(NAVBAR_BTN.BACK, $event)">Back</span>
      </button>
      <button class="discover-btn"  (click)="navbarBtnClick(NAVBAR_BTN.SAVE, $event)"><span (click)="navbarBtnClick(NAVBAR_BTN.SAVE, $event)">Save</span></button>
      <button class="home-btn" ></button>
    </ng-container>

    <ng-container *ngIf="this.footerBarMode === FOOTER_MODE.RESTORE_ITEMS">
      <button class="back-btn" (click)="navbarBtnClick(NAVBAR_BTN.BACK, $event)" >
        <svg (click)="navbarBtnClick(NAVBAR_BTN.BACK, $event)" width="12" height="20" viewBox="0 0 12 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M10.9823 1.5L0.833008 10L10.9823 18.5" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        <span  class="back-btn-text" (click)="navbarBtnClick(NAVBAR_BTN.BACK, $event)">Back</span>
      </button>
      <button class="discover-btn" [disabled]="userDataService.getIsNavbarMainActionDisabled()" (click)="navbarBtnClick(NAVBAR_BTN.RESTORE, $event)"><span (click)="navbarBtnClick(NAVBAR_BTN.RESTORE, $event)">Restore</span></button>
      <button class="home-btn empty-btn void"  ></button>
    </ng-container>


    <ng-container *ngIf="this.footerBarMode === FOOTER_MODE.LIBRARY_SELECT_COLLECTION_TO_MOVE || this.footerBarMode === FOOTER_MODE.LIBRARY_SELECT_COLLECTION_TO_MOVE_DISABLED ">
      <button class="back-btn" (click)="navbarBtnClick(NAVBAR_BTN.BACK, $event)" >
        <svg (click)="navbarBtnClick(NAVBAR_BTN.BACK, $event)" width="12" height="20" viewBox="0 0 12 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M10.9823 1.5L0.833008 10L10.9823 18.5" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        <span  class="back-btn-text" (click)="navbarBtnClick(NAVBAR_BTN.BACK, $event)">Back</span>
      </button>
      <button class="discover-btn" [disabled]="this.footerBarMode === FOOTER_MODE.LIBRARY_SELECT_COLLECTION_TO_MOVE_DISABLED" (click)="navbarBtnClick(NAVBAR_BTN.SAVE, $event)"><span (click)="navbarBtnClick(NAVBAR_BTN.SAVE, $event)">Save</span></button>
      <button class="home-btn" ></button>
    </ng-container>

    <ng-container #details *ngIf="this.footerBarMode === FOOTER_MODE.LIBRARY_COL_DETAILS">
      <button class="back-btn" (click)="navbarBtnClick(NAVBAR_BTN.BACK, $event)" >
        <svg (click)="navbarBtnClick(NAVBAR_BTN.BACK, $event)" width="12" height="20" viewBox="0 0 12 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M10.9823 1.5L0.833008 10L10.9823 18.5" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        <span  class="back-btn-text" (click)="navbarBtnClick(NAVBAR_BTN.BACK, $event)">Back</span>
      </button>
      <button class="analytics-price-btn"><span>Analytics<span class="bottom-tag">$149.99 </span> </span></button>
      <button class="top-btn" (click)="navbarBtnClick(NAVBAR_BTN.TO_TOP, $event)">
        <svg width="20" height="27" viewBox="0 0 20 27" fill="none" xmlns="http://www.w3.org/2000/svg" >
          <path d="M16.3516 12.5781L10.0625 7.54687L3.77344 12.5781" stroke="#BDBDBD" stroke-width="1.83333" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M16.3516 19.5781L10.0625 14.5469L3.77344 19.5781" stroke="#BDBDBD" stroke-width="1.83333" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        <span  class="top-btn-text" (click)="navbarBtnClick(NAVBAR_BTN.TO_TOP, $event)">Top</span>
      </button>
  </ng-container>

  <ng-container #details *ngIf="this.footerBarMode === FOOTER_MODE.LIBRARY_LIKED_COLLECTIONS_DEATAIL">
    <button class="back-btn" (click)="navbarBtnClick(NAVBAR_BTN.BACK, $event)" >
      <svg (click)="navbarBtnClick(NAVBAR_BTN.BACK, $event)" width="12" height="20" viewBox="0 0 12 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M10.9823 1.5L0.833008 10L10.9823 18.5" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
      <span  class="back-btn-text" (click)="navbarBtnClick(NAVBAR_BTN.BACK, $event)">Back</span>
    </button>
    <button class="buy-btn" [disabled]="disableMainActionBtn" (click)="navbarBtnClick(NAVBAR_BTN.BUY, $event)"><span [ngClass]="{'top-tag': disableMainActionBtn}" >Buy<span *ngIf="disableMainActionBtn" class="bottom-tag">Not for sale </span></span></button>
    <button class="options-navbar-btn" (click)="navbarBtnClick(NAVBAR_BTN.MORE_OPTIONS, $event)">
      <svg xmlns="http://www.w3.org/2000/svg" width="42" height="42" viewBox="0 0 42 42" fill="none">
        <circle cx="13" cy="21" r="2" fill="white"/>
        <circle cx="21" cy="21" r="2" fill="white"/>
        <circle cx="29" cy="21" r="2" fill="white"/>
      </svg>
      <span  class="options-btn-text" (click)="navbarBtnClick(NAVBAR_BTN.MORE_OPTIONS, $event)">More</span>
    </button>
</ng-container>

  <ng-container #details *ngIf="this.footerBarMode === FOOTER_MODE.LIBRAR_COL_DETAILS_PURCHASE">
    <button class="back-btn" (click)="navbarBtnClick(NAVBAR_BTN.BACK, $event)" >
      <svg (click)="navbarBtnClick(NAVBAR_BTN.BACK, $event)" width="12" height="20" viewBox="0 0 12 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M10.9823 1.5L0.833008 10L10.9823 18.5" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
      <span  class="back-btn-text" (click)="navbarBtnClick(NAVBAR_BTN.BACK, $event)">Back</span>
    </button>

    <!-- Show proper download btn for prod/preview releases -->
    <button *ngIf="environment.production" class="price-btn"><span>Download</span></button>
    <a *ngIf="!environment.production" class="download-btn" [href]="usdzHref">Download</a>

    <button class="top-btn" (click)="navbarBtnClick(NAVBAR_BTN.TO_TOP, $event)">
      <svg width="20" height="27" viewBox="0 0 20 27" fill="none" xmlns="http://www.w3.org/2000/svg" >
        <path d="M16.3516 12.5781L10.0625 7.54687L3.77344 12.5781" stroke="#BDBDBD" stroke-width="1.83333" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M16.3516 19.5781L10.0625 14.5469L3.77344 19.5781" stroke="#BDBDBD" stroke-width="1.83333" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
      <span  class="top-btn-text" (click)="navbarBtnClick(NAVBAR_BTN.TO_TOP, $event)">Top</span>
    </button>
</ng-container>

<ng-container #details *ngIf="this.footerBarMode === FOOTER_MODE.ALL_PURCHASES">
  <button class="back-btn" (click)="navbarBtnClick(NAVBAR_BTN.BACK, $event)" >
    <svg (click)="navbarBtnClick(NAVBAR_BTN.BACK, $event)" width="12" height="20" viewBox="0 0 12 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M10.9823 1.5L0.833008 10L10.9823 18.5" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
    <span  class="back-btn-text" (click)="navbarBtnClick(NAVBAR_BTN.BACK, $event)">Back</span>
  </button>

  <!-- Show proper download btn for prod/preview releases -->
  <button *ngIf="environment.production" class="price-btn"><span>Download</span></button>
  <a *ngIf="!environment.production" class="download-btn" [href]="usdzHref">Download</a>

  <button class="top-btn" (click)="navbarBtnClick(NAVBAR_BTN.TO_TOP, $event)">
    <svg width="20" height="27" viewBox="0 0 20 27" fill="none" xmlns="http://www.w3.org/2000/svg" >
      <path d="M16.3516 12.5781L10.0625 7.54687L3.77344 12.5781" stroke="#BDBDBD" stroke-width="1.83333" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M16.3516 19.5781L10.0625 14.5469L3.77344 19.5781" stroke="#BDBDBD" stroke-width="1.83333" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
    <span  class="top-btn-text" (click)="navbarBtnClick(NAVBAR_BTN.TO_TOP, $event)">Top</span>
  </button>
</ng-container>



    <ng-container #details *ngIf="this.footerBarMode === FOOTER_MODE.SERVICE">
      <button class="back-btn" (click)="footernavMockedClick('Library')" >
        <svg (click)="footernavMockedClick('Library')" width="12" height="20" viewBox="0 0 12 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M10.9823 1.5L0.833008 10L10.9823 18.5" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        <span  class="back-btn-text" (click)="footernavMockedClick('Library')">Back</span>
      </button>
      <button class="discover-btn" (click)="onDiscoverBtnClick($event)"><span (click)="onDiscoverBtnClick($event)">Discover</span></button>
      <button  class="home-btn"  (click)="footernavMockedClick('Library')" >
        <svg width="20" height="22" viewBox="0 0 20 22" fill="none" xmlns="http://www.w3.org/2000/svg" (click)="footernavMockedClick('Library')">
          <path d="M10 0.960468L19.25 8.36046V21.25H12.9722V14.6C12.9722 14.1306 12.5917 13.75 12.1222 13.75H7.87778C7.40834 13.75 7.02778 14.1306 7.02778 14.6V21.25H0.75V8.36045L10 0.960468Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        <span class="home-btn-text" (click)="footernavMockedClick('Library')">Home</span>
    </button>
    </ng-container>

    <ng-container #details *ngIf="this.footerBarMode === FOOTER_MODE.COLLECTION_DETAILS_TRANSITION_TO_HOME">
      <button  class="library-btn-left"  (click)="navbarBtnClick(NAVBAR_BTN.LIBRARY, $event)" >
        <svg xmlns="http://www.w3.org/2000/svg" width="22" height="24" viewBox="0 0 22 24" fill="none">
          <path d="M16.9167 4.2H5.08333M15.3333 1.5H6.66667M18 22.5H4C2.61929 22.5 1.5 21.3807 1.5 20L1.5 9.62673C1.5 8.24602 2.61929 7.12673 4 7.12673H18C19.3807 7.12673 20.5 8.24602 20.5 9.62673V20C20.5 21.3807 19.3807 22.5 18 22.5Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        <span class="library-btn-text" (click)="navbarBtnClick(NAVBAR_BTN.HOME, $event)">Library</span>
      </button>
      <button class="discover-btn" (click)="navbarBtnClick(NAVBAR_BTN.ADD, $event)"><span  (click)="navbarBtnClick(NAVBAR_BTN.ADD, $event)">Open</span></button>
      <button class="search-btn" [disabled]="true" (click)="navbarBtnClick(NAVBAR_BTN.SEARCH, $event)">
        <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none" (click)="navbarBtnClick(NAVBAR_BTN.SEARCH, $event)">
          <path d="M0.75 8.9375C0.75 13.4593 4.41567 17.125 8.9375 17.125C13.4593 17.125 17.125 13.4593 17.125 8.9375C17.125 4.41567 13.4593 0.75 8.9375 0.75C4.41567 0.75 0.75 4.41567 0.75 8.9375Z" stroke="white" stroke-width="1.5" stroke-linecap="round"/>
          <path d="M20.9824 20.9687L15.3558 15.355" stroke="white" stroke-width="2" stroke-linecap="round"/>
          <circle xmlns="http://www.w3.org/2000/svg" cx="9" cy="9" r="5" />
        </svg>
        <span class="search-btn-text" (click)="navbarBtnClick(NAVBAR_BTN.SEARCH, $event)">Search</span>
      </button>
</ng-container>

    <ng-container #details *ngIf="this.footerBarMode === FOOTER_MODE.COLLECTION_DETAILS">
          <button class="back-btn" (click)="onLessButtonClicked($event)" >
            <svg (click)="onLessButtonClicked($event)" width="12" height="20" viewBox="0 0 12 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M10.9823 1.5L0.833008 10L10.9823 18.5" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
            <span  class="back-btn-text" (click)="onLessButtonClicked($event)">Back</span>
          </button>
          <button class="buy-btn" [disabled]="disableMainActionBtn" (click)="navbarBtnClick(NAVBAR_BTN.BUY, $event)"><span [ngClass]="{'top-tag': disableMainActionBtn}" >Buy<span *ngIf="disableMainActionBtn" class="bottom-tag">Not for sale </span></span></button>

          <button class="options-navbar-btn" (click)="navbarBtnClick(NAVBAR_BTN.MORE_OPTIONS, $event)">
            <svg xmlns="http://www.w3.org/2000/svg" width="42" height="42" viewBox="0 0 42 42" fill="none">
              <circle cx="13" cy="21" r="2" fill="white"/>
              <circle cx="21" cy="21" r="2" fill="white"/>
              <circle cx="29" cy="21" r="2" fill="white"/>
            </svg>
            <span  class="options-btn-text" (click)="navbarBtnClick(NAVBAR_BTN.MORE_OPTIONS, $event)">More</span>
          </button>
          <!-- <button  class="home-btn"  (click)="onHomeBtnClicked($event)" >
            <svg width="20" height="22" viewBox="0 0 20 22" fill="none" xmlns="http://www.w3.org/2000/svg" (click)="onHomeBtnClicked($event)">
              <path d="M10 0.960468L19.25 8.36046V21.25H12.9722V14.6C12.9722 14.1306 12.5917 13.75 12.1222 13.75H7.87778C7.40834 13.75 7.02778 14.1306 7.02778 14.6V21.25H0.75V8.36045L10 0.960468Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
            <span class="home-btn-text" (click)="onHomeBtnClicked($event)">Home</span>
          </button> -->
    </ng-container>

    <ng-container #details *ngIf="this.footerBarMode === FOOTER_MODE.COLLECTION_DETAILS_FOR_SHARED">
      <button class="back-btn" (click)="onLessButtonClicked($event)" >
        <svg (click)="onLessButtonClicked($event)" width="12" height="20" viewBox="0 0 12 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M10.9823 1.5L0.833008 10L10.9823 18.5" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        <span  class="back-btn-text" (click)="onLessButtonClicked($event)">Back</span>
      </button>
      <button class="buy-btn" [disabled]="true" (click)="navbarBtnClick(NAVBAR_BTN.BUY, $event)"><span >Buy</span></button>
      <button class="home-btn empty-btn void"  ></button>
    </ng-container>

    <ng-container #details *ngIf="this.footerBarMode === FOOTER_MODE.CARD_BUY_SECTION">
      <button class="back-btn"  (click)="navbarBtnClick(NAVBAR_BTN.BACK, $event)" >
        <svg (click)="navbarBtnClick(NAVBAR_BTN.BACK, $event)" width="12" height="20" viewBox="0 0 12 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M10.9823 1.5L0.833008 10L10.9823 18.5" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        <span  class="back-btn-text" (click)="navbarBtnClick(NAVBAR_BTN.BACK, $event)">Back</span>
      </button>
      <button class="buy-btn" [disabled]="(processTotalPrice(additionalData?.priceData) === 'Total' || processTotalPrice(additionalData?.priceData) === '$0.00') || isCardInPurchaseProcess" (click)="navbarBtnClick(NAVBAR_BTN.BUY, $event)">
        <ng-container *ngIf="!isCardInPurchaseProcess"><span>Buy</span></ng-container>
        <ng-container *ngIf="isCardInPurchaseProcess">
          &nbsp;
          <span>
            <div class="circle-progress-bar-wrapper navbar-progress" >
              <div class="pie spinner"></div>
              <div class="pie backcolor"></div>
            </div>
          </span>
        </ng-container>
        <!-- <span>{{processTotalPrice(additionalData?.priceData)}}</span> -->
      </button>
      <button class="home-btn empty-btn void"  ></button>

      <!-- <button class="select-all-btn" (click)="navbarBtnClick(NAVBAR_BTN.SELECT_ALL, $event)">
        <svg  *ngIf="!isAdditionalPaddingAdded()" width="21" height="21" viewBox="0 0 23 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M11.4927 23C13.0696 23 14.5522 22.7023 15.9404 22.107C17.3286 21.5117 18.5524 20.6816 19.6117 19.6168C20.671 18.552 21.5005 17.3274 22.1003 15.9432C22.7001 14.5589 23 13.0779 23 11.5C23 9.92214 22.7001 8.44108 22.1003 7.05682C21.5005 5.67257 20.6686 4.44803 19.6044 3.38322C18.5403 2.3184 17.3165 1.48833 15.9331 0.892983C14.5497 0.297661 13.0648 0 11.4782 0C9.91104 0 8.43333 0.297661 7.04511 0.892983C5.6569 1.48833 4.43555 2.3184 3.38108 3.38322C2.3266 4.44803 1.49947 5.67257 0.899684 7.05682C0.299895 8.44108 0 9.92214 0 11.5C0 13.0779 0.299895 14.5589 0.899684 15.9432C1.49947 17.3274 2.32902 18.552 3.38833 19.6168C4.44764 20.6816 5.6714 21.5117 7.05962 22.107C8.44785 22.7023 9.92556 23 11.4927 23ZM11.4927 21.3011C10.1384 21.3011 8.86867 21.0495 7.68359 20.5461C6.49853 20.0427 5.45857 19.3409 4.56373 18.4406C3.66888 17.5404 2.96993 16.4974 2.46687 15.3116C1.96383 14.1257 1.71232 12.8552 1.71232 11.5C1.71232 10.1448 1.96383 8.87427 2.46687 7.68845C2.96993 6.50264 3.66888 5.4596 4.56373 4.55933C5.45857 3.65908 6.49611 2.95728 7.67634 2.45393C8.85656 1.95055 10.1239 1.69887 11.4782 1.69887C12.8423 1.69887 14.1168 1.95055 15.3019 2.45393C16.487 2.95728 17.5269 3.65908 18.4218 4.55933C19.3166 5.4596 20.0204 6.50264 20.5331 7.68845C21.0458 8.87427 21.3022 10.1448 21.3022 11.5C21.3022 12.8552 21.0483 14.1257 20.5404 15.3116C20.0325 16.4974 19.3311 17.5404 18.4363 18.4406C17.5414 19.3409 16.5015 20.0427 15.3164 20.5461C14.1313 21.0495 12.8568 21.3011 11.4927 21.3011Z" fill="white"/>
          <path d="M10.2596 16.9016C10.5885 16.9016 10.8594 16.7467 11.0722 16.4369L16.4268 8.0297C16.4848 7.93289 16.5404 7.83125 16.5937 7.72477C16.6469 7.61829 16.6735 7.50696 16.6735 7.39079C16.6735 7.16816 16.5888 6.98908 16.4195 6.85355C16.2502 6.71803 16.0592 6.65027 15.8463 6.65027C15.5755 6.65027 15.3481 6.80031 15.1643 7.1004L10.216 14.9558L7.79269 11.8921C7.66694 11.7372 7.54601 11.6307 7.42991 11.5726C7.31383 11.5145 7.18807 11.4855 7.05263 11.4855C6.83014 11.4855 6.64391 11.5629 6.49395 11.7178C6.34401 11.8727 6.26904 12.0615 6.26904 12.2841C6.26904 12.4971 6.34643 12.7052 6.5012 12.9085L9.40344 16.4369C9.52919 16.6111 9.65978 16.7321 9.79522 16.7999C9.93066 16.8677 10.0854 16.9016 10.2596 16.9016Z" fill="white"/>
        </svg>
        <svg *ngIf="isAdditionalPaddingAdded()" width="21" height="21" viewBox="0 0 23 24" fill="none" xmlns="http://www.w3.org/2000/svg" >
          <path d="M11.4927 23.0087C13.0696 23.0087 14.5522 22.7109 15.9404 22.1153C17.3286 21.5198 18.5524 20.6894 19.6117 19.6242C20.671 18.559 21.5005 17.334 22.1003 15.9492C22.7001 14.5644 23 13.0828 23 11.5043C23 9.92588 22.7001 8.44426 22.1003 7.05948C21.5005 5.67471 20.6686 4.44971 19.6044 3.38449C18.5403 2.31928 17.3165 1.48889 15.9331 0.89332C14.5497 0.297773 13.0648 0 11.4782 0C9.91104 0 8.43333 0.297773 7.04511 0.89332C5.6569 1.48889 4.43555 2.31928 3.38108 3.38449C2.3266 4.44971 1.49947 5.67471 0.899684 7.05948C0.299895 8.44426 0 9.92588 0 11.5043C0 13.0828 0.299895 14.5644 0.899684 15.9492C1.49947 17.334 2.32902 18.559 3.38833 19.6242C4.44764 20.6894 5.6714 21.5198 7.05962 22.1153C8.44785 22.7109 9.92556 23.0087 11.4927 23.0087ZM11.4927 21.3092C10.1384 21.3092 8.86867 21.0574 7.68359 20.5538C6.49853 20.0503 5.45858 19.3482 4.56373 18.4476C3.66888 17.547 2.96993 16.5036 2.46687 15.3173C1.96383 14.1311 1.71232 12.8601 1.71232 11.5043C1.71232 10.1486 1.96383 8.87762 2.46687 7.69134C2.96993 6.50509 3.66888 5.46166 4.56373 4.56105C5.45858 3.66046 6.49611 2.9584 7.67634 2.45485C8.85656 1.95129 10.1239 1.69951 11.4782 1.69951C12.8423 1.69951 14.1168 1.95129 15.3019 2.45485C16.487 2.9584 17.5269 3.66046 18.4218 4.56105C19.3166 5.46166 20.0204 6.50509 20.5331 7.69134C21.0458 8.87762 21.3022 10.1486 21.3022 11.5043C21.3022 12.8601 21.0483 14.1311 20.5404 15.3173C20.0325 16.5036 19.3311 17.547 18.4363 18.4476C17.5414 19.3482 16.5015 20.0503 15.3164 20.5538C14.1313 21.0574 12.8568 21.3092 11.4927 21.3092Z" fill="white"/>
          <path d="M7.57459 16.2539C7.80677 16.2539 8.00509 16.1716 8.16956 16.007L11.4926 12.6516L14.8301 16.007C15.0043 16.1716 15.2026 16.2539 15.4251 16.2539C15.6476 16.2539 15.8386 16.1765 15.9982 16.0215C16.1579 15.8666 16.2377 15.6729 16.2377 15.4405C16.2377 15.2178 16.1555 15.0241 15.991 14.8595L12.6535 11.5186L16.0055 8.1486C16.17 7.98398 16.2522 7.79514 16.2522 7.5821C16.2522 7.35938 16.1724 7.17055 16.0128 7.0156C15.8531 6.86067 15.6621 6.7832 15.4396 6.7832C15.2267 6.7832 15.0333 6.86551 14.8591 7.03012L11.4926 10.3855L8.14052 7.04466C7.98575 6.88004 7.7971 6.79772 7.57459 6.79772C7.3521 6.79772 7.16103 6.87277 7.00141 7.02286C6.84178 7.17297 6.76196 7.36422 6.76196 7.59662C6.76196 7.81936 6.84419 8.00819 7.00866 8.16312L10.3462 11.5186L7.00866 14.874C6.84419 15.0289 6.76196 15.2178 6.76196 15.4405C6.76196 15.6729 6.84178 15.8666 7.00141 16.0215C7.16103 16.1765 7.3521 16.2539 7.57459 16.2539Z" fill="white"/>
        </svg>
        <span  class="top-btn-text" (click)="navbarBtnClick(NAVBAR_BTN.SELECT_ALL, $event)">
         <ng-container *ngIf="isAdditionalPaddingAdded()">&nbsp;</ng-container>
          {{getSelectLabelText()}}
        </span>
      </button> -->
    </ng-container>

    <ng-container #details *ngIf="this.footerBarMode === FOOTER_MODE.CARD_DOWNLOAD_PURCHASED">
      <button class="back-btn"  (click)="navbarBtnClick(NAVBAR_BTN.BACK, $event)" >
        <svg (click)="navbarBtnClick(NAVBAR_BTN.BACK, $event)" width="12" height="20" viewBox="0 0 12 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M10.9823 1.5L0.833008 10L10.9823 18.5" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        <span  class="back-btn-text" (click)="navbarBtnClick(NAVBAR_BTN.BACK, $event)">Back</span>
      </button>
      <button class="buy-btn" [disabled]="processTotalPrice(additionalData?.priceData) === 'Total'" (click)="navbarBtnClick(NAVBAR_BTN.DOWNLOAD_SELECTED_MODELS, $event)"><span>Download</span></button>
      <button class="select-all-btn" (click)="navbarBtnClick(NAVBAR_BTN.SELECT_ALL, $event)">
        <svg  *ngIf="!isAdditionalPaddingAdded()" width="21" height="21" viewBox="0 0 23 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M11.4927 23C13.0696 23 14.5522 22.7023 15.9404 22.107C17.3286 21.5117 18.5524 20.6816 19.6117 19.6168C20.671 18.552 21.5005 17.3274 22.1003 15.9432C22.7001 14.5589 23 13.0779 23 11.5C23 9.92214 22.7001 8.44108 22.1003 7.05682C21.5005 5.67257 20.6686 4.44803 19.6044 3.38322C18.5403 2.3184 17.3165 1.48833 15.9331 0.892983C14.5497 0.297661 13.0648 0 11.4782 0C9.91104 0 8.43333 0.297661 7.04511 0.892983C5.6569 1.48833 4.43555 2.3184 3.38108 3.38322C2.3266 4.44803 1.49947 5.67257 0.899684 7.05682C0.299895 8.44108 0 9.92214 0 11.5C0 13.0779 0.299895 14.5589 0.899684 15.9432C1.49947 17.3274 2.32902 18.552 3.38833 19.6168C4.44764 20.6816 5.6714 21.5117 7.05962 22.107C8.44785 22.7023 9.92556 23 11.4927 23ZM11.4927 21.3011C10.1384 21.3011 8.86867 21.0495 7.68359 20.5461C6.49853 20.0427 5.45857 19.3409 4.56373 18.4406C3.66888 17.5404 2.96993 16.4974 2.46687 15.3116C1.96383 14.1257 1.71232 12.8552 1.71232 11.5C1.71232 10.1448 1.96383 8.87427 2.46687 7.68845C2.96993 6.50264 3.66888 5.4596 4.56373 4.55933C5.45857 3.65908 6.49611 2.95728 7.67634 2.45393C8.85656 1.95055 10.1239 1.69887 11.4782 1.69887C12.8423 1.69887 14.1168 1.95055 15.3019 2.45393C16.487 2.95728 17.5269 3.65908 18.4218 4.55933C19.3166 5.4596 20.0204 6.50264 20.5331 7.68845C21.0458 8.87427 21.3022 10.1448 21.3022 11.5C21.3022 12.8552 21.0483 14.1257 20.5404 15.3116C20.0325 16.4974 19.3311 17.5404 18.4363 18.4406C17.5414 19.3409 16.5015 20.0427 15.3164 20.5461C14.1313 21.0495 12.8568 21.3011 11.4927 21.3011Z" fill="white"/>
          <path d="M10.2596 16.9016C10.5885 16.9016 10.8594 16.7467 11.0722 16.4369L16.4268 8.0297C16.4848 7.93289 16.5404 7.83125 16.5937 7.72477C16.6469 7.61829 16.6735 7.50696 16.6735 7.39079C16.6735 7.16816 16.5888 6.98908 16.4195 6.85355C16.2502 6.71803 16.0592 6.65027 15.8463 6.65027C15.5755 6.65027 15.3481 6.80031 15.1643 7.1004L10.216 14.9558L7.79269 11.8921C7.66694 11.7372 7.54601 11.6307 7.42991 11.5726C7.31383 11.5145 7.18807 11.4855 7.05263 11.4855C6.83014 11.4855 6.64391 11.5629 6.49395 11.7178C6.34401 11.8727 6.26904 12.0615 6.26904 12.2841C6.26904 12.4971 6.34643 12.7052 6.5012 12.9085L9.40344 16.4369C9.52919 16.6111 9.65978 16.7321 9.79522 16.7999C9.93066 16.8677 10.0854 16.9016 10.2596 16.9016Z" fill="white"/>
        </svg>
        <svg *ngIf="isAdditionalPaddingAdded()" width="21" height="21" viewBox="0 0 23 24" fill="none" xmlns="http://www.w3.org/2000/svg" >
          <path d="M11.4927 23.0087C13.0696 23.0087 14.5522 22.7109 15.9404 22.1153C17.3286 21.5198 18.5524 20.6894 19.6117 19.6242C20.671 18.559 21.5005 17.334 22.1003 15.9492C22.7001 14.5644 23 13.0828 23 11.5043C23 9.92588 22.7001 8.44426 22.1003 7.05948C21.5005 5.67471 20.6686 4.44971 19.6044 3.38449C18.5403 2.31928 17.3165 1.48889 15.9331 0.89332C14.5497 0.297773 13.0648 0 11.4782 0C9.91104 0 8.43333 0.297773 7.04511 0.89332C5.6569 1.48889 4.43555 2.31928 3.38108 3.38449C2.3266 4.44971 1.49947 5.67471 0.899684 7.05948C0.299895 8.44426 0 9.92588 0 11.5043C0 13.0828 0.299895 14.5644 0.899684 15.9492C1.49947 17.334 2.32902 18.559 3.38833 19.6242C4.44764 20.6894 5.6714 21.5198 7.05962 22.1153C8.44785 22.7109 9.92556 23.0087 11.4927 23.0087ZM11.4927 21.3092C10.1384 21.3092 8.86867 21.0574 7.68359 20.5538C6.49853 20.0503 5.45858 19.3482 4.56373 18.4476C3.66888 17.547 2.96993 16.5036 2.46687 15.3173C1.96383 14.1311 1.71232 12.8601 1.71232 11.5043C1.71232 10.1486 1.96383 8.87762 2.46687 7.69134C2.96993 6.50509 3.66888 5.46166 4.56373 4.56105C5.45858 3.66046 6.49611 2.9584 7.67634 2.45485C8.85656 1.95129 10.1239 1.69951 11.4782 1.69951C12.8423 1.69951 14.1168 1.95129 15.3019 2.45485C16.487 2.9584 17.5269 3.66046 18.4218 4.56105C19.3166 5.46166 20.0204 6.50509 20.5331 7.69134C21.0458 8.87762 21.3022 10.1486 21.3022 11.5043C21.3022 12.8601 21.0483 14.1311 20.5404 15.3173C20.0325 16.5036 19.3311 17.547 18.4363 18.4476C17.5414 19.3482 16.5015 20.0503 15.3164 20.5538C14.1313 21.0574 12.8568 21.3092 11.4927 21.3092Z" fill="white"/>
          <path d="M7.57459 16.2539C7.80677 16.2539 8.00509 16.1716 8.16956 16.007L11.4926 12.6516L14.8301 16.007C15.0043 16.1716 15.2026 16.2539 15.4251 16.2539C15.6476 16.2539 15.8386 16.1765 15.9982 16.0215C16.1579 15.8666 16.2377 15.6729 16.2377 15.4405C16.2377 15.2178 16.1555 15.0241 15.991 14.8595L12.6535 11.5186L16.0055 8.1486C16.17 7.98398 16.2522 7.79514 16.2522 7.5821C16.2522 7.35938 16.1724 7.17055 16.0128 7.0156C15.8531 6.86067 15.6621 6.7832 15.4396 6.7832C15.2267 6.7832 15.0333 6.86551 14.8591 7.03012L11.4926 10.3855L8.14052 7.04466C7.98575 6.88004 7.7971 6.79772 7.57459 6.79772C7.3521 6.79772 7.16103 6.87277 7.00141 7.02286C6.84178 7.17297 6.76196 7.36422 6.76196 7.59662C6.76196 7.81936 6.84419 8.00819 7.00866 8.16312L10.3462 11.5186L7.00866 14.874C6.84419 15.0289 6.76196 15.2178 6.76196 15.4405C6.76196 15.6729 6.84178 15.8666 7.00141 16.0215C7.16103 16.1765 7.3521 16.2539 7.57459 16.2539Z" fill="white"/>
        </svg>
        <span  class="top-btn-text" (click)="navbarBtnClick(NAVBAR_BTN.SELECT_ALL, $event)">
          <ng-container *ngIf="isAdditionalPaddingAdded()">&nbsp;</ng-container>
          {{getSelectLabelText()}}
        </span>
      </button>
    </ng-container>

    <ng-container #details *ngIf="this.footerBarMode === FOOTER_MODE.LIBRARY_SEE_ALL">
      <button class="back-btn" (click)="navbarBtnClick(NAVBAR_BTN.BACK, $event)" >
        <svg (click)="navbarBtnClick(NAVBAR_BTN.BACK, $event)" width="12" height="20" viewBox="0 0 12 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M10.9823 1.5L0.833008 10L10.9823 18.5" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        <span  class="back-btn-text" (click)="navbarBtnClick(NAVBAR_BTN.BACK, $event)">Back</span>
      </button>
      <button class="buy-btn" [disabled]="true"><span class="top-tag">Analytics<span class="bottom-tag">Coming soon</span> </span></button>
      <button class="home-btn empty-btn void"  ></button>

    </ng-container>

    <ng-container #details *ngIf="this.footerBarMode === FOOTER_MODE.LIBRARY_PUBLISH_REJECTED_REVIEW">
      <button class="back-btn" (click)="navbarBtnClick(NAVBAR_BTN.BACK, $event)" >
        <svg (click)="navbarBtnClick(NAVBAR_BTN.BACK, $event)" width="12" height="20" viewBox="0 0 12 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M10.9823 1.5L0.833008 10L10.9823 18.5" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        <span  class="back-btn-text" (click)="navbarBtnClick(NAVBAR_BTN.BACK, $event)">Back</span>
      </button>
      <button class="discover-btn" (click)="navbarBtnClick(NAVBAR_BTN.PUBLISH, $event)" [disabled]="userDataService.getIsNavbarMainActionDisabled()"><span (click)="navbarBtnClick(NAVBAR_BTN.PUBLISH, $event)">Publish</span></button>

      <button class="search-btn" [disabled]="true" (click)="navbarBtnClick(NAVBAR_BTN.SEARCH, $event)">
        <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none" (click)="navbarBtnClick(NAVBAR_BTN.SEARCH, $event)">
          <path d="M0.75 8.9375C0.75 13.4593 4.41567 17.125 8.9375 17.125C13.4593 17.125 17.125 13.4593 17.125 8.9375C17.125 4.41567 13.4593 0.75 8.9375 0.75C4.41567 0.75 0.75 4.41567 0.75 8.9375Z" stroke="white" stroke-width="1.5" stroke-linecap="round"/>
          <path d="M20.9824 20.9687L15.3558 15.355" stroke="white" stroke-width="2" stroke-linecap="round"/>
          <circle xmlns="http://www.w3.org/2000/svg" cx="9" cy="9" r="5" />
        </svg>
        <span class="search-btn-text" (click)="navbarBtnClick(NAVBAR_BTN.SEARCH, $event)">Search</span>
      </button>

    </ng-container>

    <ng-container #details *ngIf="this.footerBarMode === FOOTER_MODE.PORTFOLIO">
      <button class="back-btn" (click)="navbarBtnClick(NAVBAR_BTN.BACK, $event)" >
        <svg (click)="navbarBtnClick(NAVBAR_BTN.BACK, $event)" width="12" height="20" viewBox="0 0 12 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M10.9823 1.5L0.833008 10L10.9823 18.5" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        <span  class="back-btn-text" (click)="navbarBtnClick(NAVBAR_BTN.BACK, $event)">Back</span>
      </button>
      <!-- <button class="price-btn" (click)="navbarBtnClick(NAVBAR_BTN.ORGANIZE, $event)"><span >Organize</span></button> -->
      <button class="discover-btn" (click)="navbarBtnClick(NAVBAR_BTN.UPLOAD, $event)">
        <span (click)="navbarBtnClick(NAVBAR_BTN.UPLOAD, $event)">Upload</span>
      </button>

      <!-- <label for="file-upload-navbar" class="uploader-container">
        <span class="uploader-text">Upload</span>
      </label>
        <input type="file"
        id="file-upload-navbar"
        class="uploader-file-input"
        accept="model/vnd.usdz+zip"
        (change)="handleNavbarFileInput($event)"
        > -->
      <button class="search-btn" [disabled]="true" (click)="navbarBtnClick(NAVBAR_BTN.SEARCH, $event)">
        <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none" (click)="navbarBtnClick(NAVBAR_BTN.SEARCH, $event)">
          <path d="M0.75 8.9375C0.75 13.4593 4.41567 17.125 8.9375 17.125C13.4593 17.125 17.125 13.4593 17.125 8.9375C17.125 4.41567 13.4593 0.75 8.9375 0.75C4.41567 0.75 0.75 4.41567 0.75 8.9375Z" stroke="white" stroke-width="1.5" stroke-linecap="round"/>
          <path d="M20.9824 20.9687L15.3558 15.355" stroke="white" stroke-width="2" stroke-linecap="round"/>
          <circle xmlns="http://www.w3.org/2000/svg" cx="9" cy="9" r="5" />
        </svg>
        <span class="search-btn-text" (click)="navbarBtnClick(NAVBAR_BTN.SEARCH, $event)">Search</span>
      </button>
    </ng-container>

    <ng-container #details *ngIf="this.footerBarMode === FOOTER_MODE.EDIT_COLLABORATORS">
      <button class="back-btn" (click)="navbarBtnClick(NAVBAR_BTN.BACK, $event)" >
        <svg (click)="navbarBtnClick(NAVBAR_BTN.BACK, $event)" width="12" height="20" viewBox="0 0 12 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M10.9823 1.5L0.833008 10L10.9823 18.5" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        <span  class="back-btn-text" (click)="navbarBtnClick(NAVBAR_BTN.BACK, $event)">Back</span>
      </button>
      <button class="price-btn" (click)="navbarBtnClick(NAVBAR_BTN.INVITE_COLLABORATOR, $event)"><span >Invite</span></button>
      <button class="top-btn" (click)="navbarBtnClick(NAVBAR_BTN.TO_TOP, $event)">
        <svg width="20" height="27" viewBox="0 0 20 27" fill="none" xmlns="http://www.w3.org/2000/svg" >
          <path d="M16.3516 12.5781L10.0625 7.54687L3.77344 12.5781" stroke="#BDBDBD" stroke-width="1.83333" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M16.3516 19.5781L10.0625 14.5469L3.77344 19.5781" stroke="#BDBDBD" stroke-width="1.83333" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        <span  class="top-btn-text" (click)="navbarBtnClick(NAVBAR_BTN.TO_TOP, $event)">Top</span>
      </button>
    </ng-container>

    <ng-container #details *ngIf="this.footerBarMode === FOOTER_MODE.REMOVE_COLLABORATORS">
      <button class="back-btn" (click)="navbarBtnClick(NAVBAR_BTN.BACK, $event)" >
        <svg (click)="navbarBtnClick(NAVBAR_BTN.BACK, $event)" width="12" height="20" viewBox="0 0 12 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M10.9823 1.5L0.833008 10L10.9823 18.5" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        <span  class="back-btn-text" (click)="navbarBtnClick(NAVBAR_BTN.BACK, $event)">Back</span>
      </button>
      <button class="analytics-price-btn" (click)="navbarBtnClick(NAVBAR_BTN.REMOVE_COLLABORATOR, $event)">
        <span (click)="navbarBtnClick(NAVBAR_BTN.REMOVE_COLLABORATOR, $event)">Remove
          <span class="bottom-tag" (click)="navbarBtnClick(NAVBAR_BTN.REMOVE_COLLABORATOR, $event)">Collaborator </span>
        </span>
      </button>
      <button class="top-btn" (click)="navbarBtnClick(NAVBAR_BTN.TO_TOP, $event)">
        <svg width="20" height="27" viewBox="0 0 20 27" fill="none" xmlns="http://www.w3.org/2000/svg" >
          <path d="M16.3516 12.5781L10.0625 7.54687L3.77344 12.5781" stroke="#BDBDBD" stroke-width="1.83333" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M16.3516 19.5781L10.0625 14.5469L3.77344 19.5781" stroke="#BDBDBD" stroke-width="1.83333" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        <span  class="top-btn-text" (click)="navbarBtnClick(NAVBAR_BTN.TO_TOP, $event)">Top</span>
      </button>
    </ng-container>

    <ng-container #details *ngIf="this.footerBarMode === FOOTER_MODE.SUBSCRIPTION_SPLASH_SCREEN">
      <button class="back-btn" [disabled]="isSubscriptionInProcess" (click)="navbarBtnClick(NAVBAR_BTN.BACK, $event)" >
        <svg (click)="navbarBtnClick(NAVBAR_BTN.BACK, $event)" width="12" height="20" viewBox="0 0 12 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M10.9823 1.5L0.833008 10L10.9823 18.5" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        <span  class="back-btn-text" (click)="navbarBtnClick(NAVBAR_BTN.BACK, $event)">Back</span>
      </button>
      <a class="discover-btn contact-us-link" href="{{generateHref()}}"><span>Contact Us</span> </a>

      <!-- <button class="price-btn" [disabled]="isSubscriptionInProcess" (click)="navbarBtnClick(NAVBAR_BTN.SUBSCRIBE, $event)">
        <ng-container *ngIf="!isSubscriptionInProcess"><span>Subscribe</span></ng-container>
        <ng-container *ngIf="isSubscriptionInProcess">
          &nbsp;
          <span>
            <div class="circle-progress-bar-wrapper navbar-progress" >
              <div class="pie spinner"></div>
              <div class="pie backcolor"></div>
            </div>
          </span>
        </ng-container>
      </button> -->

      <button *ngIf="this.firebaseAuth.currentUser.subscriptionStatus === SUBSCRIPTION_STATUS.GUEST" [disabled]="isSubscriptionInProcess"  class="free-trial-btn-left" (click)="navbarBtnClick(NAVBAR_BTN.FREE_TRIAL, $event)" >
        <svg (click)="navbarBtnClick(NAVBAR_BTN.FREE_TRIAL, $event)" width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="12.5" cy="12.5" r="12.5" fill="white"/>
          <path d="M10.834 6.66669L16.0781 11.9108C16.4035 12.2362 16.4035 12.7638 16.0781 13.0893L10.834 18.3334" stroke="black" stroke-width="1.66667" stroke-linecap="round"/>
        </svg>
        <span  class="free-trial-btn-text" (click)="navbarBtnClick(NAVBAR_BTN.FREE_TRIAL, $event)">Free Trial</span>
      </button>
      <button *ngIf="this.firebaseAuth.currentUser.subscriptionStatus !== SUBSCRIPTION_STATUS.GUEST" class="home-btn empty-btn void"  ></button>
    </ng-container>

  </div>

  <div class="navbar-background-gradient"></div>
</div>
<app-splash-screen  *ngIf="isSplashScreenComponentShown" (closeClicked)="onSplashCloseClicked()" (signInAppleClicked)="onSplashSignInAppleClicked()" (signInGoogleClicked)="onSplashSignInGoogleClicked()" [progressBarValue]="loaderProgressBarValue" [showSignInForm]="showSingInForm" [@fullscreenPanelAnimation]></app-splash-screen>
