import { GALLERY_MODE } from './../../../models/card';
import { ICardCollection } from 'src/app/models/collection';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ModelCardRenderedSelection, ModelCardRendered, IThumbCard } from 'src/app/models/card';
import { inOutAnimation } from '../../providers/animations-list';

@Component({
  selector: 'app-collection-select-panel',
  templateUrl: './collection-select-panel.component.html',
  styleUrls: ['./collection-select-panel.component.scss'],
  animations: [
    inOutAnimation
  ]
})

export class CollectionSelectPanelComponent implements OnInit {
  @Input() sourceCollections: ICardCollection[];
  @Input() userSelectedCards: ModelCardRendered[];
  @Input() title: string;

  @Output() collectionSelected = new EventEmitter<ICardCollection>();

  GALLERY_MODE = GALLERY_MODE;


  constructor() { }

  ngOnInit(): void {
    // if(this.userSelectedCards && this.cardsToSelect) {
    //   this.cardsToSelect.forEach((currentCard) => {
    //     const isCardSelected = this.userSelectedCards.find(userSelectedCard => userSelectedCard.cardPosterMobile === currentCard.cardPosterMobile )
    //     if(isCardSelected) {
    //       currentCard.isSelected = true;
    //     }
    //     else currentCard.isSelected = false;
    //   })
    //   console.log(this.cardsToSelect)
    // }
  }

  onCollectionClicked(collection: ICardCollection): void {
    if(this.sourceCollections.filter((col) => {return col.isSelected}).length > 0 ) {
      if(collection.isSelected) {
        collection.isSelected = false;
        this.collectionSelected.emit(collection);
      }
    } else {
      collection.isSelected = !collection.isSelected;
      this.collectionSelected.emit(collection);
    }
    // const selectionValue: boolean = this.cardsToSelect[cardIndex].isSelected;
    // this.cardsToSelect[cardIndex].isSelected = !selectionValue;

    // this.cardSelected.emit(this.cardsToSelect.filter((card) =>{ return card.isSelected }))
  }

  onThumbClick(thumbClicked: IThumbCard, cardIndex: number): void {
    console.log('thumb Index ', cardIndex)
    // const selectionValue: boolean = this.thumbsComposite[cardIndex].isSelected;
    // this.thumbsComposite[cardIndex].isSelected = !selectionValue;
    // const cardToChange = this.cardsToSelect.find((cardToFind) => {
    //   return cardToFind === cardClicked.
    // })

  }

}
