<div class="mr-overlay" (click)="onReconstructOverlayClicked()"></div>
<div class="mr-wrapper"  fxLayout="column" fxLayoutAlign="start stretch" >
  <div class="mr-header" fxFlex="15" fxLayout="row" fxLayoutAlign="space-between center" >
    <h1>3DWay World</h1>
    <button mat-flat-button (click)="onCancelButtonClicked()" color="warn">Cancel</button>
  </div>
  <div class="mr-img-section" fxLayout="row">
    <div class="mr-rec-img" [ngStyle]="{'background-image': 'url(' + recCard.previewImage + ')'}"></div>
    <div class="mr-location-details" >
      <p *ngFor="let item of recCard.locationSegments" >{{item}}</p>
    </div>
  </div>
  <div class="mr-workspace-section"  fxLayout="row">
    <div class="mr-workspace-field" ><p>WORKSPACE</p></div>
    <div class="mr-workspace-name">
      <p>{{recCard.author}}</p>
    </div>
  </div>
  <div class="mr-payment-type-section" fxLayout="row">
    <h3>PAY-AS-GO</h3>
  </div>
  <div class="mr-touch-section" fxFlex="1 1 100" fxLayout="column" fxLayoutAlign="center stretch">
    <img class="dimensions-image" src="/assets/images/icons/fingerprint-icon.svg">
    <h3>{{getMakeReconstructionFooterText(recCard.controlButtonName)}}</h3>
  </div>
</div>
