import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { trigger, transition, style, animate } from '@angular/animations';
import { pageFadingAnimation } from 'src/app/shared/providers/animations-list';
import { ICard, CARD_TYPE, CARD_PURCHASE_STATUS, } from 'src/app/models/card';

@Component({
  selector: 'app-scenes-world',
  templateUrl: './scenes-world.component.html',
  styleUrls: ['./scenes-world.component.scss'],
  animations: [
    pageFadingAnimation
],
})
export class ScenesWorldComponent implements OnInit {

  public cards: ICard[] = [];

  constructor(private readonly router: Router) { }

  ngOnInit() {
    // this.cards.push({
    //   cardContentMobile: '/assets/images/scenesPage/mosh-full.jpg',
    //   cardType: CARD_TYPE.MODEL,
    //   cardCategory: 'Sculpture',
    //   cardAuthor: 'DRAFT',
    //   cardTitle: 'Mosh',
    //   cardDescription: `Create the Sci-Fi fantasy environment of your dreams with this collection of peculiar plants, ethereal rocks and surfaces.`,
    //   cardPrice: 0,
    //   purchaseStatus: CARD_PURCHASE_STATUS.DEFAULT_3DWAY
    //   });

    //   this.cards.push({
    //     cardContentMobile: '/assets/images/scenesPage/ferm-full.jpg',
    //     cardType: CARD_TYPE.MODEL,
    //     cardCategory: 'Sculpture',
    //     cardAuthor: 'DRAFT',
    //     cardTitle: 'Ferm',
    //     cardDescription: `According to legend, Rome was founded in 753 BC by twin sons Romulus and Remus `,
    //     cardPrice: 0,
    //     purchaseStatus: CARD_PURCHASE_STATUS.DEFAULT_3DWAY
    //   });
  }

  public loadSceneDetails() {
    this.router.navigateByUrl('/scenes/rome');
  }

}
