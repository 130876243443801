import { GALLERY_MODE } from './../../../models/card';
import { ICardCollection } from 'src/app/models/collection';
import { UserDataService } from 'src/app/shared/providers/user-data.service';
import { Component, EventEmitter, Input, OnInit, Output, OnDestroy } from '@angular/core';
import { ModelCardRenderedSelection, ModelCardRendered } from 'src/app/models/card';

@Component({
  selector: 'app-restore-deleted-dialogue',
  templateUrl: './restore-deleted-dialogue.component.html',
  styleUrls: ['./restore-deleted-dialogue.component.scss']
})

export class RestoreDeletedDialogueComponent implements OnInit, OnDestroy {
  @Input() title: string;

  GALLERY_MODE = GALLERY_MODE;

  // @Output() cardSelected = new EventEmitter<ModelCardRenderedSelection[]>();
  // @Output() collectionSelected = new EventEmitter<ICardCollection[]>();

  public deletedCards: ModelCardRenderedSelection[] = [];
  public deletedCollections: ICardCollection[] = [];

  public isModelsActive: boolean = true;
  public isCollectionsActive: boolean = false;

  constructor(public userDataService: UserDataService) { }


  ngOnInit(): void {
    this.deletedCards = this.userDataService.deletedCards;
    this.deletedCards.forEach(card => card.isSelected = false);
    this.userDataService.changeStateIsNavbarMainActionDisabledTo(true);

    // if(this.userSelectedCards && this.cardsToSelect) {
    //   this.cardsToSelect.forEach((currentCard) => {
    //     const isCardSelected = this.userSelectedCards.find(userSelectedCard => userSelectedCard.cardPosterMobile === currentCard.cardPosterMobile )
    //     if(isCardSelected) {
    //       currentCard.isSelected = true;
    //     }
    //     else currentCard.isSelected = false;
    //   })
    //   console.log(this.cardsToSelect)
    // }
  }

  onCardSelected(cardClicked: ModelCardRenderedSelection): void {
    cardClicked.isSelected = !cardClicked.isSelected;
    if (this.deletedCards.filter((card) => { return card.isSelected }).length > 0) {
      this.userDataService.changeStateIsNavbarMainActionDisabledTo(false);
    } else {
      this.userDataService.changeStateIsNavbarMainActionDisabledTo(true);
    }


    // const selectionValue: boolean = this.cardsToSelect[cardIndex].isSelected;
    // this.cardsToSelect[cardIndex].isSelected = !selectionValue;
    // this.cardSelected.emit(this.deletedCards.filter((card) =>{ return card.isSelected }))
  }

  onCollectionClicked(collection: ICardCollection): void {
    collection.isSelected = !collection.isSelected;
    // const selectionValue: boolean = this.cardsToSelect[cardIndex].isSelected;
    // this.cardsToSelect[cardIndex].isSelected = !selectionValue;
    // this.collectionSelected.emit(this.deletedCollections.filter((col) =>{ return col.isSelected }))
  }

  onSwitchClick(item: string) {
    switch (item) {
      case 'Models':
        this.isModelsActive = true;
        this.isCollectionsActive = false;
        if(this.deletedCollections?.length) this.deletedCollections.forEach(col => col.isSelected = false);
        this.deletedCollections = undefined;
        // this.collectionSelected.emit();

        this.deletedCards = this.userDataService.deletedCards;

        this.title = 'Select Models.';

        break;
      case 'Collections':
        this.isModelsActive = false;
        this.isCollectionsActive = true;
        if(this.deletedCards?.length) this.deletedCards.forEach(card => card.isSelected = false);
        this.deletedCards = undefined;
        // this.cardSelected.emit();

        this.deletedCollections = this.userDataService.deletedCollections;

        this.title = 'Select Collections.';
        break;
      default:
        break;
    }
  }

  ngOnDestroy() {
    this.userDataService.changeStateIsNavbarMainActionDisabledTo(false);
  }

}
