<div class="fullscreen-panel price-select-panel-wrapper">
  <div class="price-select-panel-content-row" fxLayout="column"   fxLayoutAlign="center center" >
    <span class="select-title">Add Price for Collection.</span>
  </div>
  <div class="group-wrapper">
     <h2 class="group-label"></h2>
    <mat-radio-group [color]="'primary'" class="basic-radio-group" [ngModel]="selectedPriceOption" (ngModelChange)="onPriceChange($event)">
      <h3 class="options-title popular">Popular options</h3>
      <mat-radio-button class="example-radio-button" *ngFor="let price of priceOptionsPopular" [value]="price">
        ${{price}}.00
      </mat-radio-button>
      <h3 class="options-title additional">Additional options</h3>
      <mat-radio-button class="example-radio-button" *ngFor="let price of priceOptionsAdditional" [value]="price">
        ${{price}}.00
      </mat-radio-button>
    </mat-radio-group>
  </div>



</div>
<div class="background-overlay dark-background-overscroll"></div>
