<footer class="footer gray" ngClass.gt-lg="footer-gt-lg" ngClass.gt-sm="footer-gt-sm" ngClass.lt-md="footer-lt-md" ngClass.lt-sm="footer-lt-sm">
  <div class="footer-bottom" fxLayout="row" fxLayout.lt-md="column wrap" fxLayoutAlign="center center" >
      <div class="left-part" fxLayout="row" fxLayout.lt-sm="row wrap"  fxLayoutAlign="space-between center" >
        <div class="copyright" >
          <a routerLink="/" alt="3DWay">Copyright  ©  {{copyrightYear}} 3DWay LLC. All rights reserved.</a>
        </div>
      </div>
      <div class="right-part" fxLayout="row" fxLayoutAlign="space-between center" fxFlex.gt-sm="0 1 430px" fxFlex.gt-lg="0 1 550px" >
          <div class="links" fxLayout="row" fxFlex.lt-sm="0 1 375px" fxLayoutAlign="space-between center">
            <a routerLink="/legal/privacy-policy" alt="Privacy Policy">Privacy Policy</a>
            <a routerLink="/legal/terms-of-service" alt="Terms of Service">Terms of Service</a>
            <!-- <a routerLink="/legal" alt="Legal">Legal</a> -->
            <a href="mailto:support@3dway.io" alt="Support">Support</a>
          </div>
      </div>
  </div>
</footer>
