import { Subscription } from 'rxjs';
import { UploadFileService } from 'src/app/shared/providers/upload-file.service';
import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import {
  IGroupedCard,
  IUploadCardEvent,
  UPLOAD_STATUS
} from 'src/app/models/groupedCard';
import {
  environment
} from 'src/environments/environment';
import { ProgressSpinnerMode } from '@angular/material/progress-spinner';
import { SettingsService } from '../../providers/settings.service';

@Component({
  selector: 'app-card-thumbs-upload',
  templateUrl: './card-thumbs-upload.component.html',
  styleUrls: ['./card-thumbs-upload.component.scss']
})
export class CardThumbsUploadComponent implements OnInit, OnDestroy {
  @Output() thumbCardClicked = new EventEmitter < IGroupedCard > ();
  @Output() stopUploadButtonClicked = new EventEmitter < IGroupedCard > ();
  @Output() retryUploadButtonClicked = new EventEmitter < IGroupedCard > ();
  @Output() uploadProgresschange = new EventEmitter < boolean > ();


  // eslint-disable-next-line max-len
  @Input() isStopBtnAllowed: boolean = true;
  @Input() isTotalProgressBar: boolean = false;
  @Input() uploadCard: IGroupedCard;
  @Input() smallProgressBar: boolean;

  public UPLOAD_STATUS = UPLOAD_STATUS;
  public totalProgress: number = 0;
  public debugMode: boolean = environment.showServicePageAndShowDebugData;
  uploadSub: Subscription;


  constructor(public uploadService: UploadFileService,
    public settingService: SettingsService) {}

  ngOnInit(): void {
    if(this.isTotalProgressBar) {
      this.uploadSub = this.uploadService.itemsTotalProgressSubject.subscribe((progressValue) => {
        console.log('val recieved: ', progressValue);
        this.totalProgress = progressValue;
        this.uploadProgresschange.emit(true);
      })
    }
  }

  public onThumbCardClick(thumb: IGroupedCard, event: Event): void {
    event.stopPropagation();
    this.thumbCardClicked.emit(thumb)
  }

  public retryClick(card: IGroupedCard, event: Event): void {
    event.stopPropagation();
    // this.retryUploadButtonClicked.emit(card);
    this.uploadService.retryCardUpload(card);
  }

  public stopUploadBtnClick(event: Event, card: IGroupedCard): void {
    console.log('STOP upload click, card id: ', card.id)

    if (this.isStopBtnAllowed === false) { this.thumbCardClicked.emit(); return }
    if (card.uploadStatus.status === UPLOAD_STATUS.PROCESSING) {
      this.uploadService.stopCardRender(card);
      return
    }
    if (card.uploadStatus.status !== UPLOAD_STATUS.INPROGRESS) return
    console.log('STOP upload click, card id EMIT: ', card.id)
    event.stopPropagation();
    // this.stopUploadButtonClicked.emit(card);
    this.uploadService.stopCardUpload(card);
  }

  public getCardUploadText(): string {
    const cardsInProgress = this.uploadService.uploadModels.filter((groupedCard) => {return groupedCard.uploadStatus.status === UPLOAD_STATUS.INPROGRESS || groupedCard.uploadStatus.status === UPLOAD_STATUS.PROCESSING});

    if(this.isTotalProgressBar && cardsInProgress.length > 1) {
      return `${cardsInProgress.length} models`
    }
    return this.uploadCard.uploadStatus.progressMsg
  }

  public getUploadProgressValue(): number {
    if(this.isTotalProgressBar) return this.totalProgress;
    return this.uploadCard.uploadStatus.progress;
  }

  public getSpinnerMode(): ProgressSpinnerMode {
    if(this.isTotalProgressBar) {
      return 'determinate'
      // const cardsInProgress = this.uploadService.uploadModels.filter((groupedCard) => {return groupedCard.uploadStatus.status === UPLOAD_STATUS.INPROGRESS })
      // if(cardsInProgress.length > 0) return 'determinate'
      // else return 'indeterminate'
    }
    return this.uploadCard.uploadStatus.spinnerMode
  }

  processcardPosterMobile(cardPosterMobile: string): string {
    if(cardPosterMobile === undefined) {
      return '/assets/images/gradientMock.png'
    }
    return cardPosterMobile
  }

  ngOnDestroy(): void {
    if(this.uploadSub) this.uploadSub.unsubscribe();
  }

}
