import { Injectable } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class CustomIconsRegistryService {

  private icons: { name: string; url: string }[] = [
    { name: '3dway-logo', url: '../assets/images/icons/3dway-logo.svg' },
    { name: '3dway-logo-shadow', url: '../assets/images/icons/3dway-logo-shadow.svg' },
    { name: 'apple-icon', url: '../assets/images/icons/apple-icon.svg' },
    { name: 'hamburger-menu', url: '../assets/images/icons/hamburger-menu-icon.svg' },
    { name: 'arrow-down', url: '../assets/images/icons/arrow-down.svg' },
    { name: 'arrow-up', url: '../assets/images/icons/arrow-up.svg' },
    { name: 'icon-close', url: '../assets/images/icons/close-icon.svg' },
    { name: 'icon-world-gray', url: '../assets/images/icons/world-ellipse.svg' },
    { name: 'icon-world-blue', url: '../assets/images/icons/world-icon.svg' },
    { name: 'logo-youtube', url: '../assets/images/icons/youtube-logo.svg' },
    { name: 'logo-twitter', url: '../assets/images/icons/twitter-logo.svg' },
    { name: 'icon-upload', url: '../assets/images/icons/upload.svg' },
    { name: 'icon-search', url: '../assets/images/icons/search.svg' },
    { name: 'icon-home', url: '../assets/images/icons/home-icon.svg' },
    { name: 'action-albums', url: '../assets/images/icons/action-albums.svg' },
    { name: 'action-albums-active', url: '../assets/images/icons/action-albums-active.svg' },
    { name: 'action-cube', url: '../assets/images/icons/action-cube.svg' },
    { name: 'action-cube-active', url: '../assets/images/icons/action-cube-active.svg' },
    { name: 'action-photo', url: '../assets/images/icons/action-photo.svg' },
    { name: 'action-photo-active', url: '../assets/images/icons/action-photo-active.svg' },
    { name: 'action-scenes', url: '../assets/images/icons/action-scenes.svg' },
    { name: 'action-scenes-active', url: '../assets/images/icons/action-scenes-active.svg' },
    { name: 'action-today', url: '../assets/images/icons/action-today.svg' },
    { name: 'action-today-active', url: '../assets/images/icons/action-today-active.svg' },
    { name: 'bar-switch-icon', url: '../assets/images/icons/bar-switch-icon.svg' },
    { name: 'bar-hamburger-option', url: '../assets/images/icons/bar-hamburger-option.svg' },
    { name: 'location', url: '../assets/images/icons/search-icons/location.svg' },
    { name: 'layers', url: '../assets/images/icons/search-icons/layers.svg' },
    { name: 'cube', url: '../assets/images/icons/search-icons/cube.svg' },
    { name: 'search-glass', url: '../assets/images/icons/search-icons/search-glass.svg' },
    { name: 'pause', url: '../assets/images/icons/control-pause.svg' },
    { name: 'play', url: '../assets/images/icons/control-play.svg' },
    { name: 'stop', url: '../assets/images/icons/control-stop.svg' },
    { name: 'share', url: '../assets/images/icons/share.svg' },
    { name: 'restore', url: '../assets/images/icons/restore.svg' },
    { name: 'trashbin', url: '../assets/images/icons/trashbin.svg' },
    { name: 'edit', url: '../assets/images/icons/edit-icon.svg' },
    { name: 'user-icon', url: '../assets/images/icons/user-icon.svg' },
    { name: 'cart-icon', url: '../assets/images/icons/cart-icon.svg' },
    { name: 'panel-trash-icon', url: '../assets/images/icons/panel-trash-icon.svg' },
    { name: 'published-icon', url: '../assets/images/icons/published-icon.svg' },
    { name: 'review-icon', url: '../assets/images/icons/review-icon.svg' },
    { name: 'sync-icon', url: '../assets/images/icons/sync-icon.svg' },
    { name: 'analytics-icon', url: '../assets/images/icons/analytics-icon.svg' },
    { name: 'bookmark-icon', url: '../assets/images/icons/bookmark-icon.svg' },
    { name: 'bookmark-icon-active', url: '../assets/images/icons/bookmark-icon-active.svg' },
    { name: 'upload-draft-icon', url: '../assets/images/icons/upload-draft-icon.svg' },
    { name: 'drafts-section-icon', url: '../assets/images/icons/drafts-section-icon.svg' },
    { name: 'store-section-icon', url: '../assets/images/icons/store-section-icon.svg' },
    { name: 'plus-icon', url: '../assets/images/icons/plus-icon.svg' },
    { name: 'plus-dark-icon', url: '../assets/images/icons/plus-dark-icon.svg' },
    { name: 'lock-icon', url: '../assets/images/icons/lock-icon.svg' },
    { name: 'unlock-icon', url: '../assets/images/icons/unlock-icon.svg' },
    { name: 'fullscreen-icon', url: '../assets/images/icons/fullscreen-icon.svg' },
    { name: 'arrow-light-icon', url: '../assets/images/icons/arrow-light-icon.svg' },
    { name: 'elipsis-icon', url: '../assets/images/icons/elipsis-icon.svg' },
    { name: 'store-icon', url: '../assets/images/icons/store-icon.svg' },
    { name: 'add-icon', url: '../assets/images/icons/add-icon.svg' },
    { name: 'following-icon', url: '../assets/images/icons/following-icon.svg' },
    { name: 'library-icon', url: '../assets/images/icons/library-icon.svg' },
    { name: 'like-icon', url: '../assets/images/icons/like-icon.svg' },
    { name: 'camera-auto-icon', url: '../assets/images/icons/camera-auto-icon.svg' },
    { name: 'camera-free-icon', url: '../assets/images/icons/camera-free-icon.svg' },
    { name: 'list-add-icon', url: '../assets/images/icons/list-add-icon.svg' },
    { name: 'double-arrow-up-icon', url: '../assets/images/icons/double-arrow-up-icon.svg' },
    { name: 'arrow-back-icon', url: '../assets/images/icons/arrow-back.svg' },
    { name: 'arrow-right-small', url: '../assets/images/icons/arrow-right-small.svg' },
    { name: 'arrow-down-small', url: '../assets/images/icons/arrow-down-small.svg' },
    { name: 'retry-icon', url: '../assets/images/icons/retry.svg' },
    { name: 'checkbox-icon', url: '../assets/images/icons/checkbox-circle-icon.svg' },
    { name: 'purchase-icon', url: '../assets/images/icons/purchase-icon.svg' },
    { name: 'group-icon', url: '../assets/images/icons/group-icon.svg' },
    { name: 'checkbox-checked-icon', url: '../assets/images/icons/checkbox-checked-black.svg' }
  ];

  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
  ) { }

  public registerIcons(): void {
    this.icons.forEach(icon => {
      this.matIconRegistry.addSvgIcon(
        icon.name,
        this.domSanitizer.bypassSecurityTrustResourceUrl(icon.url)
      );
    });
  }
}
