import { Injectable } from '@angular/core';
import * as chroma from 'chroma-js';

// https://github.com/larsenwork/postcss-easing-gradients/tree/master
// https://css-tricks.com/easing-linear-gradients/
// https://www.joshwcomeau.com/gradient-generator/

@Injectable({
  providedIn: 'root'
})
export class GradientService {
  constructor() {}

  private hasParenthesis(str: string): boolean {
    return str.includes('(');
  }

  private getBeforeParenthesisMaybe(str: string): string {
    return this.hasParenthesis(str) ? str.substring(0, str.indexOf('(')) : str;
  }

  isTimingFunction(str: string): boolean {
    const timingFunctions = ['ease', 'ease-in', 'ease-out', 'ease-in-out', 'cubic-bezier', 'steps'];
    return timingFunctions.includes(this.getBeforeParenthesisMaybe(str));
  }

  getParenthesisInsides(str: string): string {
    return str.match(/\((.*)\)/)?.pop() || '';
  }

  transparentFix(colors: string[]): string[] {
    return colors.map(color => {
      if (color === 'transparent') {
        return chroma('black').alpha(0).css('hsl');
      }
      return color;
    });
  }

  divToSemiColon(obj: any[]): any[] {
    return obj.map(childObj => {
      if (childObj.type === 'div') {
        childObj.value = ';';
      }
      return childObj;
    });
  }

  roundHslAlpha(color: string, alphaDecimals: number): string {
    const prefix = this.getBeforeParenthesisMaybe(color);
    const values = this.getParenthesisInsides(color)
      .split(',')
      .map(str => str.includes('%') ? str.trim() : Number(parseFloat(str).toFixed(alphaDecimals)).toString());
    return `${prefix}(${values.join(', ')})`;
  }

  errorMsg(input: string): string {
    return `Couldn't parse:\n${input}\nCheck the syntax to see if it's correct/supported.`;
  }

  generateColorStops(colors: string[], coordinates: { x: number; y: number }[], alphaDecimals = 5, colorMode = 'hsl'): string[] {
    const colorStops: string[] = [];
    colors = this.transparentFix(colors);
    coordinates.forEach(coordinate => {
      const amount = coordinate.y;
      const percent = coordinate.x * 100;
      let color = chroma.mix(colors[0], colors[1], amount, colorMode).css('hsl');
      color = this.roundHslAlpha(color, alphaDecimals);
      if (Number(coordinate.x) !== 0 && Number(coordinate.x) !== 1) {
        colorStops.push(`${color} ${+percent.toFixed(2)}%`);
      } else {
        colorStops.push(color);
      }
    });
    return colorStops;
  }
}
