import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SWITCH_PANEL_ITEM } from 'src/app/models/card';

@Component({
  selector: 'app-see-all-switch',
  templateUrl: './see-all-switch.component.html',
  styleUrls: ['./see-all-switch.component.scss']
})
export class SeeAllSwitchComponent implements OnInit {

  @Input() selectedSwitchBtn = SWITCH_PANEL_ITEM.PUBLISHED;
  @Output() switchBtnClicked = new EventEmitter<SWITCH_PANEL_ITEM>();

  constructor() { }

  isLeftBtnActive: boolean = false;
  isCenterBtnActive: boolean = false;
  isRightBtnActive: boolean = false;
  isReviewBtnActive: boolean = false;


  SWITCH_PANEL_ITEM = SWITCH_PANEL_ITEM


  ngOnInit(): void {
    switch (this.selectedSwitchBtn) {
      case SWITCH_PANEL_ITEM.IN_REVIEW:
        this.isLeftBtnActive = true;
        break;
      case SWITCH_PANEL_ITEM.REJECTED:
        this.isCenterBtnActive = true;
        break;
      case SWITCH_PANEL_ITEM.PUBLISHED:
        this.isRightBtnActive = true;
        break;
      default:
        break;
    }
  }

  switchBtnClick(btnName: SWITCH_PANEL_ITEM): void {
    this.isLeftBtnActive = false;
    this.isCenterBtnActive = false;
    this.isRightBtnActive = false;

    switch (btnName) {
      case SWITCH_PANEL_ITEM.IN_REVIEW:
        this.isLeftBtnActive = true;
        this.switchBtnClicked.emit(SWITCH_PANEL_ITEM.IN_REVIEW);
        break;
      case SWITCH_PANEL_ITEM.REJECTED:
        this.isCenterBtnActive = true;
        this.switchBtnClicked.emit(SWITCH_PANEL_ITEM.REJECTED);
        break;
      case SWITCH_PANEL_ITEM.PUBLISHED:
        this.isRightBtnActive = true;
        this.switchBtnClicked.emit(SWITCH_PANEL_ITEM.PUBLISHED);
        break;
      default:
        break;
    }

  }

}
