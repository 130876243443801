import { Component, OnInit,  ViewEncapsulation, ChangeDetectorRef } from '@angular/core';

import { HttpClient, HttpParams } from '@angular/common/http';
// import {
//   MatAutocompleteSelectedEvent,
//   MatAutocomplete
// } from '@angular/material/autocomplete';
// import { FormControl } from '@angular/forms';
// import { Observable } from 'rxjs';
// import { map, startWith } from 'rxjs/operators';
// import { MatChipInputEvent } from '@angular/material';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { MockDataService } from 'src/app/shared/providers/mock-data.service';
import { CARD_TYPE, ModelCardDetails } from 'src/app/models/card';
import { lastValueFrom } from 'rxjs';
// import segmentsSourceJSON from '../../photos/photos-world/googlecloudSegments.json';

// const TAGS_API_URL = 'https://europe-west1-dway-web-dev.cloudfunctions.net/getTagsData';
const LOCATION_API_URL = 'https://europe-west1-dway-web-dev.cloudfunctions.net/getGeolocationDataFromSpanner';
const STORAGE_API_URL = 'https://europe-west1-dway-web-dev.cloudfunctions.net/getGalleryImagesData';

const BLANK_IMG = 'data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==';


export enum Location_Type {
  continents = 'continents',
  countries = 'countries',
  regions = 'regions',
  cities = 'cities',
  scenes = 'scenes',
  scene = 'scene',
}

// interface TagsDto {
//   tag_id: string;
//   tag_cover_image: string;
//   name: string;
//   public_name: string;
//   category: string[];
//   count: string;
// }

interface LocationDto {
  location_code: string;
  location_name: string;
  location_parentCode: string;
  location_type: string;
  imgCountResponse: string;
  imgPreviewScenes: string[];
}

interface Album {
  albumName: string;
  albumImages: string[];
  albumLocationCode: string;
  albumType: string;
  items: string;
}


@Component({
  selector: 'app-geolocation-albums',
  templateUrl: './geolocation-albums.component.html',
  styleUrls: ['./geolocation-albums.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
})
export class GeolocationAlbumsComponent implements OnInit {
  // separatorKeysCodes: number[] = [ENTER, COMMA];
  // tagCtrl = new FormControl();
  // filteredTags: Observable<Album[]>;
  public CARD_TYPE = CARD_TYPE;
  public isLocationLoading: boolean = false;

  public Location_Type = Location_Type;


  // public fullListOfTags: Album[] = [];
  public mockAlbumImages: Album[] = [];
  public queryLocationName: string;
  public queryLocationCode: string;
  public cardDetails: ModelCardDetails;
  public assetPhotos: string[] = [];

  // @ViewChild('tagInput', { static: false }) tagInput: ElementRef<HTMLInputElement>;
  // @ViewChild('auto', { static: false }) matAutocomplete: MatAutocomplete;

  constructor(private http: HttpClient, private readonly route: ActivatedRoute, private readonly router: Router, private mockDataService: MockDataService, private cdr: ChangeDetectorRef ) {

  }

  ngOnInit() {
    // this.mockAlbumImages = this.createMockAlbumImages();
    // this.cardDetails = {...this.mockDataService.getMockData()};
    // this.cardDetails = JSON.parse(JSON.stringify(this.mockDataService.getMockData()));
    this.cardDetails = this.mockDataService.getMockData();

    this.queryLocationName = this.route.snapshot.queryParamMap.get('location');
    this.route.queryParamMap.subscribe(queryParams => {
      this.queryLocationName = queryParams.get('location');
      this.queryLocationCode = queryParams.get('code');

      if (this.queryLocationName === Location_Type.scene) {
        this.generateLocationDetails();
      }

      const currentLocationType = this.queryLocationName ? this.queryLocationName : Location_Type.continents;
      const currentLocationCode = this.queryLocationCode ? this.queryLocationCode : '';

      const paramsSegments = new HttpParams({fromObject: {type: currentLocationType,  parentCode: currentLocationCode}});

      this.locationRequest(paramsSegments);

    });

  }

  private generateLocationDetails(): void {
    const currentScene = this.mockAlbumImages.find((album) => {
      return album.albumLocationCode === this.queryLocationCode;
    });
    this.cardDetails.preview.previewImages[0] = currentScene && currentScene.albumImages ? currentScene.albumImages[0] : BLANK_IMG;
    this.cardDetails.preview.previewImages[1] = currentScene && currentScene.albumImages ? currentScene.albumImages[1] : BLANK_IMG;
    this.cardDetails.preview.previewImages[2] = currentScene && currentScene.albumImages ? currentScene.albumImages[2] : BLANK_IMG;

    this.cardDetails = this.setCardDetailsLocationData(this.cardDetails);
    this.cardDetails.cardTitle = currentScene && currentScene.albumName ? currentScene.albumName : '';

  }

  public getCurrentLocationType(): string {
    let formattedLocationType;
    switch (this.queryLocationName) {
      case Location_Type.countries:
        formattedLocationType = 'Country';
        break;
      case Location_Type.regions:
        formattedLocationType = 'Region';
        break;
      case Location_Type.cities:
        formattedLocationType = 'City';
        break;
      case Location_Type.scenes:
        formattedLocationType = 'Scene';
        break;
      default:
        formattedLocationType = 'Continent';
        break;
    }
    return formattedLocationType;
  }

  public getAlbumByLocation(type: string, locationCode: string) {
    // if (type === Location_Type.scenes) {
    //   this.navigateToPhotos(Location_Type.scene, locationCode );
    //   return;
    // }

    let requestType;
    switch (type) {
      case Location_Type.continents:
        requestType = Location_Type.countries;
        break;
      case Location_Type.countries:
        requestType = Location_Type.regions;
        break;
      case Location_Type.regions:
        requestType = Location_Type.cities;
        break;
      case Location_Type.cities:
        requestType = Location_Type.scenes;
        break;
      case Location_Type.scenes:
        requestType = Location_Type.scene;
        break;
      default:
        requestType = Location_Type.continents;
        break;
    }

    const queryParams: Params = { location: requestType, code: locationCode };
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams,
      queryParamsHandling: 'merge'
    });

  }

  public locationRequest(paramsSegments: HttpParams) {
    this.isLocationLoading = true;
    this.assetPhotos = [];
    lastValueFrom(this.http.get(LOCATION_API_URL, { params: paramsSegments }))
      .then((tagsSourceResponse: LocationDto[]) => {
        this.mockAlbumImages = tagsSourceResponse.map(locDto => {
          return {
            albumName: locDto.location_name,
            albumImages: this.getProperImageForAlbum(
              locDto.imgPreviewScenes,
              locDto.location_type
            ),
            albumLocationCode: locDto.location_code,
            albumType: locDto.location_type,
            items: locDto.imgCountResponse ? locDto.imgCountResponse : 'N/A'
          };
        });
        this.isLocationLoading = false;
        if (this.queryLocationName === Location_Type.scene) { this.updateMockDataForCardDetails(); }
      });
  }

  private updateMockDataForCardDetails(): void {
    this.getPhotosForAssets().then((resp: any) => {

      if (resp && resp[0] && resp[0].photos ) {
        let photoArray = [];

        for (const element of resp) {
          photoArray = photoArray.concat([...element.photos]);
        }

        const photosRaw = photoArray.map((photo) => photo.url);
        const sliceIndex = photosRaw.length > 20 ? 20 : photosRaw.length - 1;
        // this.assetPhotos = photosRaw.slice(0, sliceIndex);
      }
    });
    if (this.cardDetails.preview.previewImages[0] !== BLANK_IMG) { return; }
    this.cardDetails.preview.previewImages[0] = this.mockAlbumImages[0].albumImages[0];
    this.cardDetails.preview.previewImages[1] = this.mockAlbumImages[0].albumImages[1];
    this.cardDetails.preview.previewImages[2] = this.mockAlbumImages[0].albumImages[2];

    this.cardDetails = this.setCardDetailsLocationData(this.cardDetails);
    this.cardDetails.cardTitle = this.mockAlbumImages[0].albumName;

    this.cardDetails = JSON.parse(JSON.stringify(this.cardDetails));
  }

  private getPhotosForAssets(): Promise<any> {
    const assetRequestParams = new HttpParams({fromObject: {type: 'segments', locationType: this.queryLocationName,  locationCode: this.queryLocationCode}});
    return lastValueFrom(this.http.get(STORAGE_API_URL, { params: assetRequestParams }));
  }

  setCardDetailsLocationData(modelCard: ModelCardDetails): ModelCardDetails {
    const decodedURIstring = decodeURIComponent(this.queryLocationCode);
    const decodedFromBase64 = window.atob(decodedURIstring);
    const locationString = decodedFromBase64.split('#').splice(2, 3).reverse().join(', ');
    modelCard.detailsPrice = 'MAKE 3D';
    // modelCard.assetCards = [];
    modelCard.cardType = CARD_TYPE.COLLECTION;

    modelCard.locationData = {locationCode: locationString};
    modelCard.cardDescription = `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed feugiat sem eu dui scelerisque sollicitudin. Curabitur sollicitudin dictum efficitur.`;
    modelCard.detailsFullDescription = modelCard.cardDescription;
    return modelCard;
  }

  private getProperImageForAlbum(images: string[], locationType: string): string[] {
    if ( images.length < 6) { return [images[0], images[1], images[2]]; }
    let index = 0;
    switch (locationType) {
      case Location_Type.continents:
        return [images[index], images[index + 1], images[index + 2]];
      case Location_Type.countries:
        index = 1;
        return [images[index], images[index + 1], images[index + 2]];
      case Location_Type.regions:
        index = 2;
        return [images[index], images[index + 1], images[index + 2]];
      case Location_Type.cities:
        index = 3;
        return [images[index], images[index + 1], images[index + 2]];
      case Location_Type.scenes:
        index = 4;
        return [images[index], images[index + 1], images[index + 2]];
      default:
        return [images[index], images[index + 1], images[index + 2]];
    }
  }

  public navigateToPhotos(type: string, locationCode: string) {
    const queryParams: Params = { location: type, code: locationCode };
    this.router.navigate(['/photos'], {
      relativeTo: this.route,
      queryParams,
      queryParamsHandling: 'merge'
    });

      // this.router.navigate(['/heroes', { id: heroId, foo: 'foo' }]);
  }

  public createMockAlbumImages(): Album[] {

    const mockAlbum = [{
      'location_name': 'Europe',
      'location_code': 'RXVyb3Bl',
      'location_type': 'continents',
      'imgCountResponse': '3460',
      'imgPreviewValue': ['https://storage.googleapis.com/world.3dway.io/_img4_citywall-20140923/P1010504__preview.JPG']
    }, {
      'location_name': 'North America',
      'location_code': 'Tm9ydGggQW1lcmljYQ==',
      'location_type': 'continents',
      'imgCountResponse': '2035',
      'imgPreviewValue': ['https://storage.googleapis.com/world.3dway.io/_img8_yva5-pc90_photogrammetry_terrasterial/TAO_PEX_02_433__preview.jpg']
    }];

    const mock = mockAlbum.map(locDto => {
      return {
        albumName: locDto.location_name,
        albumImages: this.getProperImageForAlbum(
          locDto.imgPreviewValue,
          locDto.location_type
        ),
        albumLocationCode: locDto.location_code,
        albumType: locDto.location_type,
        items: locDto.imgCountResponse ? locDto.imgCountResponse : 'N/A'
      };
    });
    return mock;
  }


  // removeElement(el: Album) {
  //   const index = this.mockAlbumImages.indexOf(el);

  //   if (index >= 0) {
  //     this.mockAlbumImages.splice(index, 1);
  //   }
  // }

  // add(event: MatChipInputEvent) {
  //   if (!this.matAutocomplete.isOpen) {
  //     const input = event.input;
  //     const value = event.value;

  //     console.log(value);
  //     if ((value || '').trim()) {
  //       const currentTag = this.fullListOfTags.find((val: Album) => {
  //         return val.albumInternalName.toLowerCase() === value.toLowerCase();
  //       });
  //       if (currentTag) { this.mockAlbumImages.push(currentTag); }
  //     }

  //     // Reset the input value
  //     if (input) {
  //       input.value = '';
  //     }

  //     this.tagCtrl.setValue(null);
  //   }
  // }

  // selected(event: MatAutocompleteSelectedEvent): void {
  //   const value = event.option.viewValue;
  //   if ((value || '').trim()) {
  //     const currentTag = this.fullListOfTags.find((val: Album) => {
  //       return val.albumInternalName.toLowerCase() === value.toLowerCase();
  //     });
  //     if (currentTag) {
  //       const alreadyExists = this.mockAlbumImages.find((val: Album) => {
  //         return val.albumInternalName.toLowerCase() === value.toLowerCase();
  //       });
  //       if (alreadyExists) { return; }
  //       this.mockAlbumImages.push(currentTag);
  //     }
  //   }
  //   this.tagInput.nativeElement.value = '';
  //   this.tagCtrl.setValue(null);
  // }

  // private _filter(value: string): Album[] {
  //   const filterValue = value.toLowerCase();

  //   return this.fullListOfTags.filter(album => album.albumInternalName.toLowerCase().indexOf(filterValue) === 0);
  // }

}
