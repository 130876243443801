import { EventEmitter, Injectable, Output } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class MobileHelperService {
  @Output() public mobileAuthPopupClosed: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor() { }
}
