
  <div class="thumb-upload-card" (click)="onThumbCardClick(uploadCard, $event)" fxFlex="0 1 100%" fxLayout="column" fxLayoutAlign="end center" [ngStyle]="{'background-image': 'url(' + processcardPosterMobile(uploadCard?.previewImageMobile) + ')'}">
    <div class="upload-progress-container" fxFlex="64px" fxLayoutAlign="center center">
      <ng-container *ngIf="isStopBtnAllowed">
        <div *ngIf="uploadCard.uploadStatus.status === UPLOAD_STATUS.INPROGRESS || (uploadCard.uploadStatus.status === UPLOAD_STATUS.PROCESSING)  || uploadCard.uploadStatus.status === UPLOAD_STATUS.STOPPED" class="stop-btn" (click)="stopUploadBtnClick($event, uploadCard)"></div>
      </ng-container>
      <ng-container *ngIf="!smallProgressBar">
        <svg preserveAspectRatio="xMidYMid meet" focusable="false" aria-hidden="true" ng-reflect-ng-switch="false" viewBox="0 0 35 35" style="width: 42px;height: 42px;position: absolute;">
          <circle cx="50%" cy="50%" r="16" class="ng-star-inserted" style="stroke-dashoffset: 202px; stroke-dasharray: 101px; stroke-width: 7.14286%;fill: transparent;transform-origin: center;transition: stroke-dashoffset 225ms linear;
          stroke: #5a5a5a;"></circle>
        </svg>
      </ng-container>
      <ng-container *ngIf="smallProgressBar">
        <svg preserveAspectRatio="xMidYMid meet" focusable="false" aria-hidden="true" ng-reflect-ng-switch="false" viewBox="0 0 35 35" style="width: 26px;height: 26px;position: absolute;">
          <circle cx="50%" cy="50%" r="16" class="ng-star-inserted" style="stroke-dashoffset: 202px; stroke-dasharray: 101px; stroke-width: 7.14286%;fill: transparent;transform-origin: center;transition: stroke-dashoffset 225ms linear;
          stroke: #5a5a5a;"></circle>
        </svg>
      </ng-container>
      <mat-spinner [ngClass]="uploadCard.uploadStatus.status" (click)="stopUploadBtnClick($event, uploadCard)" *ngIf="uploadCard.uploadStatus.status === UPLOAD_STATUS.INPROGRESS || uploadCard.uploadStatus.status === UPLOAD_STATUS.PROCESSING || uploadCard.uploadStatus.status === UPLOAD_STATUS.STOPPED" class="upload-spinner" [strokeWidth]="smallProgressBar ? 2 : 3" [color]="'white'" [diameter]="smallProgressBar ? 26 : 42" [value]="getUploadProgressValue()" [mode]="getSpinnerMode()">
      </mat-spinner>
      <mat-icon *ngIf="uploadCard.uploadStatus.status === UPLOAD_STATUS.ERROR" class="retry-icon" (click)="retryClick(uploadCard, $event)" svgIcon="retry-icon"></mat-icon>
    </div>

    <!-- temporary commented -->
    <!-- <div class="thumb-footer" >
      <div class="title-area" >
        <h3 class="thumb-title">{{getCardUploadText()}}</h3>
        <ng-container *ngIf="uploadCard.uploadStatus.status === UPLOAD_STATUS.INPROGRESS || uploadCard.uploadStatus.status === UPLOAD_STATUS.STOPPED">
        </ng-container>
        <ng-container *ngIf="uploadCard.uploadStatus.status === UPLOAD_STATUS.PROCESSING">
        </ng-container>
        <ng-container *ngIf="uploadCard.uploadStatus.status === UPLOAD_STATUS.UPLOADED || uploadCard.uploadStatus.status === UPLOAD_STATUS.ERROR">
        </ng-container>
      </div>
    </div> -->
  </div>

